import { styled } from 'styled-components';
import { ScreenLarge } from 'constants/screen-breakpoints';
import { COLORS } from 'constants/colors';

export const SummaryDetailsBlock = styled.div`
  padding: 32px 0 0;
  background-color: ${COLORS.BgElevated};
  width: 100%;
`;

export const DetailCardsRow = styled.div`
  display: flex;
  column-gap: 24px;
  margin-bottom: 24px;
  width: 100%;
`;

export const DetailCardRow = styled.div`
  display: flex;
  column-gap: 30px;
  margin-bottom: 10px;
  ${ScreenLarge`
    column-gap: 10px;
  `}
`;

export const StyledSkeletonWrapper = styled.div`
  margin-bottom: 4px;
`;
