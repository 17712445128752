import styled from 'styled-components';

import Image from 'components/shared/Image';
import { TextRegular } from 'components/shared/Text';
import { COLORS } from 'constants/colors';

export const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100000;
`;

export const Modal = styled.div`
  min-width: 428px;
  max-width: 1440px;
  border-radius: 8px;
  padding: 16px 24px 24px;
  background: ${COLORS.BgContainer};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

export const Title = styled(TextRegular)`
  font-size: 16px;
  font-weight: 600;
`;

export const CloseBtn = styled(Image)`
  width: 14px;
  height: 14px;
  cursor: pointer;
`;
