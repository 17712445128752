import { getAppRole } from 'utils';

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Flex } from 'antd';

import { Container } from 'components/communities/styles';
import { StyledHeader } from 'components/members/styles';
import TaxPrices from 'components/pricing/components/TaxPrices';
import TabsComponent from 'components/shared/Tabs';
import { COLORS } from 'constants/colors';
import AppRoutes from 'constants/routes';
import { USER_ROLES } from 'constants/types';
import {
  useGetCommunitiesQuery,
  useGetCommunityQuery,
} from 'services/communityManagement';

import CommunityTab from './components/communityTab';
import IndexationTab from './components/indexationTab';
import dummyComminityData from './dummyCommunityData.json';
import { StyledHeaderBlock, StyledHeaderButton, StyledWrapper } from './styles';

const Pricing = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userRole = getAppRole();
  const handleNavigateToTaxesPrices = () => navigate(AppRoutes.TaxPrices);

  const isAdmin = useMemo(() => userRole === USER_ROLES.ADMIN, [userRole]);
  const isProducer = useMemo(
    () => userRole === USER_ROLES.PRODUCER,
    [userRole],
  );

  const getCommunityDataByUserRole = useMemo(() => {
    if (isAdmin) {
      return useGetCommunitiesQuery;
    }
    if (isProducer) {
      return () => ({ data: [dummyComminityData], isLoading: false });
    }
    return useGetCommunityQuery;
  }, [isAdmin, isProducer]);

  const { data: communitiesData, isLoading } = getCommunityDataByUserRole();

  const communities = useMemo(
    () => (isLoading ? [null] : communitiesData),
    [isLoading, communitiesData],
  );

  const tabs = useMemo(() => {
    if (communities && communities.length > 0) {
      if (isProducer) {
        return [
          {
            label: t('consumer_prices'),
            key: 'consumer_prices',
            children: (
              <CommunityTab
                isLoadingCommunity={isLoading}
                community={communities[0] as any}
              />
            ),
          },
          {
            label: t('oyo_coefficients'),
            key: 'oyo_coefficients',
            children: <TaxPrices />,
          },
          {
            label: t('indexation'),
            key: 'indexation',
            children: <IndexationTab />,
          },
        ];
      }
      return communities?.map((community: any) => ({
        label: isLoading
          ? t('community').toUpperCase()
          : community?.communityName,
        key: community?.id || uuidv4(),
        children: (
          <CommunityTab community={community} isLoadingCommunity={isLoading} />
        ),
      }));
    }
    return [];
  }, [communities, isProducer, t, isLoading]);

  return (
    <StyledWrapper>
      <StyledHeaderBlock isAdmin={isAdmin}>
        <Container>
          <Flex align="center" justify="space-between">
            <StyledHeader>{t('pricing_panel')}</StyledHeader>
            {isAdmin && (
              <StyledHeaderButton
                onClick={handleNavigateToTaxesPrices}
                size="large"
              >
                {t('oyo_coefficients')}
              </StyledHeaderButton>
            )}
          </Flex>
        </Container>
      </StyledHeaderBlock>
      {Array.isArray(communities) ? (
        <TabsComponent backgroundColor={COLORS.BgContainer} items={tabs} />
      ) : (
        <CommunityTab isLoadingCommunity={isLoading} community={communities} />
      )}
    </StyledWrapper>
  );
};

export default Pricing;
