import { FC } from 'react';

import { getFormattedInfoChartValue } from 'components/communities/utils';
import { SkeletonLine } from 'components/shared/Skeleton';
import { formatNumberWithSpace } from '../ElectricityConsumption/utils';
import {
  StyledInfoBlock,
  StyledInfoPower,
  StyledInfoRow,
  StyledInfoTitle,
  StyledInfoUnit,
} from './styles';

interface InfoItem {
  title: string;
  color: string;
  unit: string;
}

interface ChartInfoProps {
  data: number[];
  infoItems: InfoItem[];
  fullWidth?: boolean;
  rowWidth?: string;
  isLoading?: boolean;
}

interface ChartInfoItemProps {
  borderColor: string;
  title: string;
  amount?: number | string;
  unit: string;
  fullWidth?: boolean;
  isLoading?: boolean;
}

const ChartInfoItem: FC<ChartInfoItemProps> = ({
  borderColor,
  title,
  amount,
  unit,
  fullWidth,
  isLoading,
}) => {
  if (!title) {
    return <></>;
  }
  return (
    <StyledInfoBlock fullWidth={fullWidth} borderColor={borderColor}>
      <StyledInfoTitle>{title}</StyledInfoTitle>
      {isLoading ? (
        <SkeletonLine active width={146} height={30} />
      ) : (
        <StyledInfoPower>
          {formatNumberWithSpace(amount)}{' '}
          <StyledInfoUnit>{unit}</StyledInfoUnit>
        </StyledInfoPower>
      )}
    </StyledInfoBlock>
  );
};

const ChartInfo: FC<ChartInfoProps> = ({
  data,
  infoItems,
  fullWidth,
  rowWidth,
  isLoading,
}) => (
  <StyledInfoRow width={rowWidth}>
    {infoItems.map((infoItem, idx) => (
      <ChartInfoItem
        fullWidth={fullWidth}
        key={idx}
        title={infoItem.title}
        borderColor={infoItem.color}
        amount={getFormattedInfoChartValue(data?.[idx], data, true)}
        unit={infoItem.unit}
        isLoading={isLoading}
      />
    ))}
  </StyledInfoRow>
);

export default ChartInfo;
