import { styled } from 'styled-components';

import { Select } from 'antd';

export const StyledSorterSelect = styled(Select)`
  min-width: 220px;
  max-width: 260px;
  margin-left: 3px;
  flex-shrink: 0;
  .ant-select-selector {
    padding: 0px 11px 0px 65px !important;
  }

  .ant-select-selection-item {
    font-weight: 600;
    color: rgba(0, 0, 0, 0.88);
  }
`;

export const StyledSorterSuffix = styled.div`
  margin-right: -65px;
  z-index: 1000;
  flex-shrink: 0;
`;
