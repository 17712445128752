import Lottie from 'lottie-react';

import loader from './loader.json';

const Loader = ({ size = 45 }: { size?: number }) => {
  return (
    <Lottie
      animationData={loader}
      loop
      autoplay
      style={{
        height: size,
        width: size,
      }}
    />
  );
};

export default Loader;
