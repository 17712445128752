import { TFunction } from 'i18next';
import { SkeletonLine } from 'components/shared/Skeleton';
import {
  ConnectionIcon,
  LocationIcon,
  ThunderboltIcon,
  UserIcon,
} from 'components/communities/Charts/SummaryView/components/Title/styles';

export const communityInfoItems = (t: TFunction) => [
  {
    subtitle: t('consumers'),
    value: 'consumers',
    icon: <UserIcon />,
    loadingSkeleton: <SkeletonLine active width={16} height={18} />,
  },
  {
    subtitle: t('producers'),
    value: 'producers',
    icon: <ConnectionIcon />,
    loadingSkeleton: <SkeletonLine active width={16} height={18} />,
  },
  {
    subtitle: t('pv_power'),
    value: 'pvPower',
    icon: <ThunderboltIcon />,
    loadingSkeleton: <SkeletonLine active width={34} height={18} />,
  },
  {
    subtitle: '',
    value: 'location',
    icon: <LocationIcon />,
    loadingSkeleton: <SkeletonLine active width={110} height={18} />,
  },
];
