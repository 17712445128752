import { styled } from 'styled-components';

import { COLORS } from 'constants/colors';

export const StyledWrapper = styled.div`
  min-width: 138px;
  min-height: 60px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
`;

export const StyledDate = styled.div`
  color: ${COLORS.TextSecondary};
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 10px;
`;

export const StyledRow = styled.div`
  color: ${COLORS.TextBase};
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  margin-bottom: 10px;
  row-gap: 20px;
`;

export const StyledAmount = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
`;

export const StyledType = styled.span`
  color: ${COLORS.TextSecondary};
  font-size: 12px;
  font-weight: 600;
`;
