import { v4 as uuidv4 } from 'uuid';

import { useTranslation } from 'react-i18next';

import { IDBTaxPrices, ITaxPrices } from 'services/taxPrices.types';

import AccisePrices from './components/AccisePrices';
import DiscountedAccisePrices from './components/DiscountedAccisePrices';
import Indexation from './components/Indexation';
import PrimePrices from './components/PrimePrices';
import TurpePrices from './components/TurpePrices';
import { BlockContainer, Wrapper } from './styles';
import { PriceType } from './types';

type PriceTypeKey =
  | 'index'
  | 'accisePerMWh'
  | 'turpePerMWh'
  | 'primePerMWh'
  | 'discountedAccisePerMWh';

function filterByType(data: ITaxPrices, priceTypeKey: PriceTypeKey) {
  const filterArray = (arr: IDBTaxPrices[], priceTypeKey: PriceTypeKey) =>
    arr.filter((obj) => obj.hasOwnProperty(priceTypeKey));

  return {
    current: filterArray(data?.current, priceTypeKey),
    upcoming: filterArray(data?.upcoming, priceTypeKey),
    historical: filterArray(data?.historical, priceTypeKey),
  };
}

export const useTabsConfig = (data: ITaxPrices, isLoading?: boolean) => {
  const { t } = useTranslation();

  return [
    {
      label: t('indexation').toUpperCase(),
      key: uuidv4(),
      children: (
        <Indexation {...filterByType(data, 'index')} isLoading={isLoading} />
      ),
    },
    {
      label: t('tax_price', { priceType: PriceType.TURPE }).toUpperCase(),
      key: uuidv4(),
      children: <TurpePrices {...filterByType(data, 'turpePerMWh')} />,
      disabled: isLoading,
    },
    {
      label: t('tax_price', { priceType: PriceType.ACCISE }).toUpperCase(),
      key: uuidv4(),
      children: (
        <BlockContainer>
          <Wrapper>
            <AccisePrices {...filterByType(data, 'accisePerMWh')} />
            <DiscountedAccisePrices
              {...filterByType(data, 'discountedAccisePerMWh')}
            />
          </Wrapper>
        </BlockContainer>
      ),
      disabled: isLoading,
    },
    {
      label: t('tax_price', { priceType: PriceType.PRIME }).toUpperCase(),
      key: uuidv4(),
      children: <PrimePrices {...filterByType(data, 'primePerMWh')} />,
      disabled: isLoading,
    },
  ];
};
