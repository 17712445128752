import { styled } from 'styled-components';

import { COLORS } from 'constants/colors';

export const CardBody = styled.div`
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
`;

export const CommunityDataRow = styled.div`
  display: flex;
  column-gap: 17px;
  padding: 0px 10px;
`;

export const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 11px;
  background-color: ${COLORS.BgLayout};
  width: 100%;
  border-radius: 2px;
  box-sizing: border-box;
  margin-bottom: 16px;
`;

export const InfoItemRow = styled.div`
  display: flex;
  column-gap: 6px;
  align-items: center;
`;

export const InfoItemText = styled.div`
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.65);
`;

export const InfoItemAmount = styled.div`
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
`;

export const Title = styled.span`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: ${COLORS.TextSecondary};
`;

export const Amount = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
`;

export const CommunityNameWrapper = styled.span`
  margin-left: 4px;
`;
