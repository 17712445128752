import { styled } from 'styled-components';

import {
  CheckCircleTwoTone,
  ExclamationCircleTwoTone,
  MailTwoTone,
} from '@ant-design/icons';

import { COLORS } from 'constants/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  text-align: center;
`;

export const SuccessIcon = styled(CheckCircleTwoTone).attrs(() => ({
  twoToneColor: COLORS.Green6,
}))`
  font-size: 56px;
`;

export const MailIcon = styled(MailTwoTone).attrs(() => ({
  twoToneColor: COLORS.Green6,
}))`
  font-size: 56px;
`;

export const ExclamationIcon = styled(ExclamationCircleTwoTone).attrs(() => ({
  twoToneColor: COLORS.Orange6,
}))`
  font-size: 56px;
`;
