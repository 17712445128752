import qs from 'qs';

import { parseCSVFileNameFromContentDisposition } from 'components/communities/Dashboard/utils';
import { API_URLS, REQUEST_METHOD } from 'constants/global';

import { LOCAL_STORAGE_KEYS } from 'utils/local-storage';

const getAuthorizationHeader = () => ({
  'Content-Type': 'text/csv',
  Authorization: `Bearer ${localStorage.getItem(
    LOCAL_STORAGE_KEYS.AccessToken,
  )}`,
});

export const getCsvFinancialReport = async ({
  prmNumber,
  dates,
  userId,
}: any) => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/${
    API_URLS.GET_FINANCIAL_REPORTS
  }?${qs.stringify({
    ...dates,
    prmNumber,
    userId,
  })}`;

  try {
    const response = await fetch(url, {
      method: REQUEST_METHOD.GET,
      headers: getAuthorizationHeader(),
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch: ${response.statusText}`);
    }
    const contentDisposition = response.headers.get('Content-Disposition');

    const filename = parseCSVFileNameFromContentDisposition(
      contentDisposition!,
    );

    return { data: await response.text(), fileName: filename };
  } catch (error) {
    console.error('Error fetching CSV energy report:', error);
    throw error;
  }
};
