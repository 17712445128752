import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import ElectricityConsumption from 'components/communities/Charts/ElectricityConsumption';
import {
  LABELS,
  mapElectricityConsumptionData,
} from 'components/communities/MyCommunity/constants/ElectricityConsumption';
import { getFormattedInfoChartValue } from 'components/communities/utils';
import {
  useGetElectricityProductionQuery,
  useGetElectricityTotalQuery,
} from 'services/dashboardManagment';
import { useGetMemberByIdQuery } from 'services/pmoMemberDashboardManagment';
import { ILabelMap } from 'services/types';

import { useAppSelector } from 'utils/hooks';
import { getDateRange } from 'utils/url/getDateRange';

import {
  areaColors,
  infoItems,
  legendItems,
  lineColors,
} from '../../constants';

const EnergyDataChart = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const period = useAppSelector((state) => state.dashboardPeriod.period);

  const { data: producer } = useGetMemberByIdQuery(id);
  const { data: electricityProductionData } = useGetElectricityProductionQuery({
    ...getDateRange(period),
    userId: producer?._id,
  });
  const { data: electricityTotalData } = useGetElectricityTotalQuery({
    ...getDateRange(period),
    userId: producer?._id,
  });
  const electricityConsumptionInfoDataArray = [
    electricityTotalData?.production,
    electricityTotalData?.selfConsumption,
    electricityTotalData?.overproduction,
  ];
  const electricityProductionDataMapped = useMemo(() => {
    const labelsMap: ILabelMap = {
      totalProduction: LABELS.TOTAL_PRODUCTION,
      selfConsumption: LABELS.SELF_CONSUMPTION,
      overproduction: LABELS.OVERPRODUCTION,
    };

    return mapElectricityConsumptionData(
      electricityProductionData,
      period,
      labelsMap,
      t,
    );
  }, [electricityProductionData, period]);

  return (
    <ElectricityConsumption
      title={t('electricity_production')}
      legendItems={legendItems(t)}
      areaColors={areaColors}
      lineColors={lineColors}
      infoItems={infoItems(t)}
      electricityConsumption={electricityProductionDataMapped}
      total={getFormattedInfoChartValue(
        electricityTotalData?.production,
        electricityConsumptionInfoDataArray,
      )}
      selfConsumption={getFormattedInfoChartValue(
        electricityTotalData?.selfConsumption,
        electricityConsumptionInfoDataArray,
      )}
      gridConsumption={getFormattedInfoChartValue(
        electricityTotalData?.overproduction,
        electricityConsumptionInfoDataArray,
      )}
    />
  );
};

export default React.memo(EnergyDataChart);
