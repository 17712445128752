import { TFunction } from 'i18next';

import { COLORS } from 'constants/colors';
import { CONSUMER_INVOICE_STATUSES } from 'constants/types';

export const types = [
  CONSUMER_INVOICE_STATUSES.OVERDUE,
  CONSUMER_INVOICE_STATUSES.PAID,
  CONSUMER_INVOICE_STATUSES.ISSUED,
];

export const infoItems = (t: TFunction) => [
  {
    title: t('paid_amount').toString(),
    color: COLORS.Green6,
    unit: '€',
  },
  {
    title: t('overdue_amount').toString(),
    color: COLORS.ErrorBorder,
    unit: '€',
  },
  {
    title: t('expected_amount').toString(),
    color: COLORS.Green4,
    unit: '€',
  },
];

export const legendItems = (t: TFunction) => [
  {
    value: 'paid_amount_prm',
    name: t('paid_amount'),
    marker: { symbol: 'square', style: { fill: COLORS.Green6 } },
  },
  {
    value: 'overdue_amount_prm',
    name: t('overdue_amount'),
    marker: { symbol: 'square', style: { fill: COLORS.ErrorBorder } },
  },
  {
    value: 'overdue_amount_prm',
    name: t('expected_amount'),
    marker: { symbol: 'square', style: { fill: COLORS.Green4 } },
  },
];

export const somePrmDummyData = (t: TFunction) => [
  {
    date: t('january'),
    value: 123,
    type: t('paid_amount_prms'),
  },
  {
    date: t('january'),
    value: 200,
    type: t('overdue_amount_prm'),
  },
  {
    date: t('february'),
    value: 321,
    type: t('paid_amount_prms'),
  },
  {
    date: t('february'),
    value: 21,
    type: t('overdue_amount_prm'),
  },
  {
    date: t('march'),
    value: 456,
    type: t('paid_amount_prms'),
  },
  {
    date: t('march'),
    value: 556,
    type: t('overdue_amount_prm'),
  },
  {
    date: t('april'),
    value: 345,
    type: t('paid_amount_prms'),
  },
  {
    date: t('april'),
    value: 445,
    type: t('overdue_amount_prm'),
  },
  {
    date: t('may'),
    value: 789,
    type: t('paid_amount_prms'),
  },
  {
    date: t('may'),
    value: 889,
    type: t('overdue_amount_prm'),
  },
  {
    date: t('june'),
    value: 567,
    type: t('paid_amount_prms'),
  },
  {
    date: t('june'),
    value: 667,
    type: t('overdue_amount_prm'),
  },
  {
    date: t('july'),
    value: 345,
    type: t('paid_amount_prms'),
  },
  {
    date: t('july'),
    value: 445,
    type: t('overdue_amount_prm'),
  },
  {
    date: t('august'),
    value: 876,
    type: t('paid_amount_prms'),
  },
  {
    date: t('august'),
    value: 976,
    type: t('overdue_amount_prm'),
  },
  {
    date: t('september'),
    value: 234,
    type: t('paid_amount_prms'),
  },
  {
    date: t('september'),
    value: 334,
    type: t('overdue_amount_prm'),
  },
  {
    date: t('october'),
    value: 123,
    type: t('paid_amount_prms'),
  },
  {
    date: t('october'),
    value: 0,
    type: t('overdue_amount_prm'),
  },
  {
    date: t('november'),
    value: 345,
    type: t('paid_amount_prms'),
  },
  {
    date: t('november'),
    value: 0,
    type: t('overdue_amount_prm'),
  },
  {
    date: t('december'),
    value: 435,
    type: t('paid_amount_prms'),
  },
  {
    date: t('december'),
    value: 0,
    type: t('overdue_amount_prm'),
  },
];
