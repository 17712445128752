import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { DetailCardRow } from '../../styles';
import {
  DetailCardBlock,
  DetailCardLabel,
  DetailCardTitle,
  DetailCardValue,
} from './styles';
import { SkeletonLine } from 'components/shared/Skeleton';

interface DetailCardProps {
  isLoading?: boolean;
  isEditMode?: boolean;
  title: string;
  labelWidth?: string;
  valueWidth?: string;
  labels?: Record<string, string>;
  values?: Record<string, any>;
}
const SKELETON_LINE_HEIGHT = 20;

const DetailCard: FC<DetailCardProps> = ({
  isLoading,
  isEditMode,
  title,
  labelWidth,
  valueWidth,
  labels,
  values,
}) => {
  const { t } = useTranslation();

  if (isEditMode) return null;

  const renderForthValue = isLoading ? (
    <SkeletonLine active size="large" height={SKELETON_LINE_HEIGHT} />
  ) : (
    values?.fourth
  );

  return (
    <DetailCardBlock>
      <DetailCardTitle>{title}</DetailCardTitle>
      <DetailCardRow>
        <DetailCardLabel width={labelWidth}>
          {labels && t(labels.first)}
        </DetailCardLabel>
        <DetailCardValue width={valueWidth}>
          {isLoading ? (
            <SkeletonLine active size="large" height={SKELETON_LINE_HEIGHT} />
          ) : (
            values?.first
          )}
        </DetailCardValue>
      </DetailCardRow>
      <DetailCardRow>
        <DetailCardLabel width={labelWidth}>
          {' '}
          {labels && t(labels.second)}
        </DetailCardLabel>
        <DetailCardValue width={valueWidth}>
          {isLoading ? (
            <SkeletonLine active size="large" height={SKELETON_LINE_HEIGHT} />
          ) : (
            values?.second
          )}
        </DetailCardValue>
      </DetailCardRow>
      <DetailCardRow>
        <DetailCardLabel width={labelWidth}>
          {' '}
          {labels && t(labels.third)}
        </DetailCardLabel>
        <DetailCardValue width={valueWidth}>
          {isLoading ? (
            <SkeletonLine active size="large" height={SKELETON_LINE_HEIGHT} />
          ) : (
            values?.third
          )}
        </DetailCardValue>
      </DetailCardRow>
      <DetailCardRow>
        <DetailCardLabel width={labelWidth}>
          {labels && t(labels.fourth)}
        </DetailCardLabel>
        <DetailCardValue width={valueWidth}>
          {labels?.fourth ? renderForthValue : ''}
        </DetailCardValue>
      </DetailCardRow>
    </DetailCardBlock>
  );
};

export default DetailCard;
