import { FC } from 'react';
import { Header } from 'components/shared/Text';
import { SkeletonLine } from 'components/shared/Skeleton';
import { StyledSkeletonWrapper } from '../../styles';

interface HeaderWithSkeletonProps {
  memberName: string;
  isLoading: boolean;
}

export const HeaderWithSkeleton: FC<HeaderWithSkeletonProps> = ({
  memberName,
  isLoading,
}) => {
  return isLoading ? (
    <StyledSkeletonWrapper>
      <SkeletonLine active height={34} size="large" />
    </StyledSkeletonWrapper>
  ) : (
    <Header>{memberName}</Header>
  );
};

export default HeaderWithSkeleton;
