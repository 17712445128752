import React, { CSSProperties, cloneElement } from 'react';

import CustomTooltip from 'components/shared/Tooltip';

import { ErrorConfirmPasswordIcon } from '../PasswordForm/styles';
import { StyledInputError, StyledInputGroup, StyledLabel } from './styles';

type InputGroupProps = {
  children: JSX.Element;
  error?: string;
  label?: string;
  disabled?: boolean;
  isShowError?: boolean;
  tooltip?: JSX.Element | string;
  errorCentered?: boolean;
  customStyles?: CSSProperties;
  customLabelStyles?: CSSProperties;
};

export const InputGroup: React.FC<InputGroupProps> = ({
  children,
  error,
  label,
  tooltip,
  errorCentered = false,
  isShowError = true,
  customStyles,
  customLabelStyles,
}) => (
  <StyledInputGroup style={customStyles}>
    <StyledLabel style={customLabelStyles}>
      {label}
      {label && tooltip && <CustomTooltip tooltip={tooltip} />}
    </StyledLabel>

    {cloneElement(children)}

    {isShowError && (
      <StyledInputError errorCentered={errorCentered}>
        {error ? (
          <>
            <ErrorConfirmPasswordIcon />
            {error}
          </>
        ) : (
          '\u00A0'
        )}
      </StyledInputError>
    )}
  </StyledInputGroup>
);
