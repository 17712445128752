import styled from 'styled-components';

import { DatePicker, Select } from 'antd';

import { TextRegular } from 'components/shared/Text';
import { COLORS } from 'constants/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 724px;
  padding: 16px 12px 0 16px;
  background: ${COLORS.BgLayout};
`;

export const DatePickerStyled = styled(DatePicker)`
  width: 285px;
  border-width: 1px;
`;

export const DashWrapper = styled(TextRegular)`
  padding-bottom: 5px;
  margin-left: 8px;
  margin-right: 8px;
`;

export const Title = styled(TextRegular)`
  margin-right: 20px;
`;

export const Line = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 24px;
`;

export const LabelContainer = styled.div`
  min-width: 90px;
  padding-bottom: 5px;
`;

export const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 24px;
`;

export const Dropdown = styled(Select)`
  width: 264px;
`;
