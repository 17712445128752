import React from 'react';

const Icon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="38"
      viewBox="0 0 36 38"
      fill="none"
    >
      <path
        d="M0.703125 16.6133L3.51562 9.58203H32.4844L35.2969 16.6133H0.703125Z"
        fill="#B7EB8F"
      />
      <path
        d="M25.875 16.6133L22.0988 9.27062C21.309 7.73492 19.7269 6.76953 18 6.76953C16.2731 6.76953 14.691 7.73492 13.9012 9.27062L10.125 16.6133H2.10938V33.4883H33.8906V16.6133H25.875Z"
        fill="#FFF1B8"
      />
      <path
        d="M35.2969 33.4883H0.703125V36.3008H35.2969V33.4883Z"
        fill="#BAE0FF"
      />
      <path
        d="M31.0781 19.4258H27.5625V22.2383H31.0781V19.4258Z"
        fill="#C8E7F9"
      />
      <path
        d="M24.0469 19.4258H19.8281V22.2383H24.0469V19.4258Z"
        fill="#C8E7F9"
      />
      <path
        d="M16.1719 19.4258H11.9531V22.2383H16.1719V19.4258Z"
        fill="#C8E7F9"
      />
      <path
        d="M8.4375 19.4258H4.92188V22.2383H8.4375V19.4258Z"
        fill="#C8E7F9"
      />
      <path
        d="M31.0781 25.0508H27.5625V27.8633H31.0781V25.0508Z"
        fill="#C8E7F9"
      />
      <path
        d="M8.4375 25.0508H4.92188V27.8633H8.4375V25.0508Z"
        fill="#C8E7F9"
      />
      <path d="M22.2188 1.70703H18V4.51953H22.2188V1.70703Z" fill="#B7EB8F" />
      <path d="M18 25.0508H12.375V33.4883H18V25.0508Z" fill="#AD6800" />
      <path d="M23.625 25.0508H18V33.4883H23.625V25.0508Z" fill="#AD6800" />
      <path
        d="M25.0312 33.4883H10.9688V36.3008H25.0312V33.4883Z"
        fill="black"
        fillOpacity="0.06"
      />
      <path
        d="M18 16.8945C19.5533 16.8945 20.8125 15.6353 20.8125 14.082C20.8125 12.5287 19.5533 11.2695 18 11.2695C16.4467 11.2695 15.1875 12.5287 15.1875 14.082C15.1875 15.6353 16.4467 16.8945 18 16.8945Z"
        fill="#F0F0F0"
      />
      <path
        d="M9.14062 29.9727C8.75243 29.9727 8.4375 30.2884 8.4375 30.6758C8.4375 31.0639 8.75243 31.3789 9.14062 31.3789C9.52805 31.3789 9.84375 31.0639 9.84375 30.6758C9.84375 30.2884 9.52805 29.9727 9.14062 29.9727Z"
        fill="black"
        fillOpacity="0.88"
      />
      <path
        d="M26.8594 31.3789C27.2468 31.3789 27.5625 31.0639 27.5625 30.6758C27.5625 30.2884 27.2468 29.9727 26.8594 29.9727C26.472 29.9727 26.1562 30.2884 26.1562 30.6758C26.1562 31.0639 26.472 31.3789 26.8594 31.3789Z"
        fill="black"
        fillOpacity="0.88"
      />
      <path
        d="M35.2969 32.7852H34.5938V17.3164H35.2969C35.5302 17.3164 35.7483 17.2007 35.8791 17.0075C36.0098 16.8142 36.0364 16.5688 35.9497 16.3521L33.1372 9.32089C33.0304 9.05391 32.7719 8.87891 32.4844 8.87891H22.6866C21.8724 7.3513 20.3909 6.33542 18.7031 6.11295V5.22266H22.2188C22.607 5.22266 22.9219 4.9078 22.9219 4.51953V1.70703C22.9219 1.31877 22.607 1.00391 22.2188 1.00391H18C17.6117 1.00391 17.2969 1.31877 17.2969 1.70703V6.11295C15.6092 6.33542 14.1276 7.3513 13.3134 8.87891H3.51562C3.22812 8.87891 2.96958 9.05391 2.86277 9.32089L0.0502734 16.3521C-0.0363516 16.5688 -0.00984375 16.8142 0.120938 17.0075C0.251719 17.2007 0.469828 17.3164 0.703125 17.3164H1.40625V32.7852H0.703125C0.314859 32.7852 0 33.1 0 33.4883V36.3008C0 36.689 0.314859 37.0039 0.703125 37.0039H35.2969C35.6851 37.0039 36 36.689 36 36.3008V33.4883C36 33.1 35.6852 32.7852 35.2969 32.7852ZM18.7031 2.41016H21.5156V3.81641H18.7031V2.41016ZM34.2584 15.9102H26.3041L23.4112 10.2852H32.0084L34.2584 15.9102ZM3.99171 10.2852H12.5889L9.69595 15.9102H1.74171L3.99171 10.2852ZM10.2656 35.5977H1.40625V34.1914H10.2656V35.5977ZM24.3281 35.5977H11.6719V34.1914H24.3281V35.5977ZM13.0781 32.7852V25.7539H17.2969V32.7852H13.0781ZM18.7031 32.7852V25.7539H22.9219V32.7852H18.7031ZM23.625 24.3477H12.375C11.9867 24.3477 11.6719 24.6625 11.6719 25.0508V32.7852H2.8125V31.3789H6.32812C6.71555 31.3789 7.03125 31.0639 7.03125 30.6758C7.03125 30.2884 6.71555 29.9727 6.32812 29.9727H2.8125V17.3164H10.125C10.3885 17.3164 10.6298 17.1691 10.7503 16.9348L14.5265 9.59216C15.1988 8.28484 16.5298 7.47266 18 7.47266C19.4702 7.47266 20.8012 8.28484 21.4735 9.59223L25.2497 16.9349C25.3702 17.1692 25.6115 17.3165 25.875 17.3165H33.1875V29.9727H29.6719C29.2845 29.9727 28.9688 30.2884 28.9688 30.6759C28.9688 31.064 29.2845 31.379 29.6719 31.379H33.1875V32.7852H24.3281V25.0508C24.3281 24.6625 24.0133 24.3477 23.625 24.3477ZM34.5938 35.5977H25.7344V34.1914H34.5938V35.5977Z"
        fill="black"
        fillOpacity="0.88"
      />
      <path
        d="M27.5625 22.9414H31.0781C31.4664 22.9414 31.7812 22.6265 31.7812 22.2383V19.4258C31.7812 19.0375 31.4664 18.7227 31.0781 18.7227H27.5625C27.1742 18.7227 26.8594 19.0375 26.8594 19.4258V22.2383C26.8594 22.6266 27.1742 22.9414 27.5625 22.9414ZM28.2656 20.1289H30.375V21.5352H28.2656V20.1289Z"
        fill="black"
      />
      <path
        d="M24.75 22.2383V19.4258C24.75 19.0375 24.4351 18.7227 24.0469 18.7227H19.8281C19.4399 18.7227 19.125 19.0375 19.125 19.4258V22.2383C19.125 22.6265 19.4399 22.9414 19.8281 22.9414H24.0469C24.4352 22.9414 24.75 22.6266 24.75 22.2383ZM23.3438 21.5352H20.5312V20.1289H23.3438V21.5352Z"
        fill="black"
      />
      <path
        d="M16.1719 18.7227H11.9531C11.5649 18.7227 11.25 19.0375 11.25 19.4258V22.2383C11.25 22.6265 11.5649 22.9414 11.9531 22.9414H16.1719C16.5601 22.9414 16.875 22.6265 16.875 22.2383V19.4258C16.875 19.0375 16.5602 18.7227 16.1719 18.7227ZM15.4688 21.5352H12.6562V20.1289H15.4688V21.5352Z"
        fill="black"
      />
      <path
        d="M4.92188 22.9414H8.4375C8.82577 22.9414 9.14062 22.6265 9.14062 22.2383V19.4258C9.14062 19.0375 8.82577 18.7227 8.4375 18.7227H4.92188C4.53361 18.7227 4.21875 19.0375 4.21875 19.4258V22.2383C4.21875 22.6266 4.53361 22.9414 4.92188 22.9414ZM5.625 20.1289H7.73438V21.5352H5.625V20.1289Z"
        fill="black"
      />
      <path
        d="M27.5625 28.5664H31.0781C31.4664 28.5664 31.7812 28.2515 31.7812 27.8633V25.0508C31.7812 24.6625 31.4664 24.3477 31.0781 24.3477H27.5625C27.1742 24.3477 26.8594 24.6625 26.8594 25.0508V27.8633C26.8594 28.2516 27.1742 28.5664 27.5625 28.5664ZM28.2656 25.7539H30.375V27.1602H28.2656V25.7539Z"
        fill="black"
      />
      <path
        d="M9.14062 27.8633V25.0508C9.14062 24.6625 8.82577 24.3477 8.4375 24.3477H4.92188C4.53361 24.3477 4.21875 24.6625 4.21875 25.0508V27.8633C4.21875 28.2515 4.53361 28.5664 4.92188 28.5664H8.4375C8.82584 28.5664 9.14062 28.2516 9.14062 27.8633ZM7.73438 27.1602H5.625V25.7539H7.73438V27.1602Z"
        fill="black"
      />
      <path
        d="M4.80132 14.4539C4.88696 14.4881 4.97534 14.5044 5.06224 14.5044C5.34124 14.5044 5.60534 14.3371 5.71531 14.0622L6.27781 12.6559C6.42202 12.2953 6.24666 11.8862 5.8861 11.7419C5.5256 11.5976 5.11638 11.773 4.9721 12.1336L4.4096 13.5399C4.26539 13.9004 4.44075 14.3097 4.80132 14.4539Z"
        fill="black"
      />
      <path
        d="M8.03569 14.4539C8.12133 14.4881 8.20971 14.5044 8.29662 14.5044C8.57562 14.5044 8.83971 14.3371 8.94968 14.0622L9.51218 12.6559C9.65639 12.2953 9.48103 11.8862 9.12047 11.7419C8.76005 11.5976 8.35076 11.773 8.20648 12.1336L7.64398 13.5399C7.49977 13.9004 7.67513 14.3097 8.03569 14.4539Z"
        fill="black"
      />
      <path
        d="M30.2846 14.0621C30.3946 14.337 30.6586 14.5043 30.9377 14.5043C31.0246 14.5043 31.113 14.488 31.1986 14.4538C31.5592 14.3095 31.7345 13.9004 31.5903 13.5398L31.0278 12.1335C30.8835 11.7729 30.4742 11.5978 30.1138 11.7418C29.7533 11.8861 29.5779 12.2953 29.7221 12.6558L30.2846 14.0621Z"
        fill="black"
      />
      <path
        d="M27.0502 14.0621C27.1602 14.337 27.4242 14.5043 27.7033 14.5043C27.7902 14.5043 27.8786 14.488 27.9642 14.4538C28.3248 14.3095 28.5001 13.9004 28.3559 13.5398L27.7934 12.1335C27.6492 11.7729 27.2399 11.5978 26.8794 11.7418C26.5189 11.8861 26.3435 12.2953 26.4877 12.6558L27.0502 14.0621Z"
        fill="black"
      />
      <path
        d="M21.5156 14.082C21.5156 12.1435 19.9385 10.5664 18 10.5664C16.0615 10.5664 14.4844 12.1435 14.4844 14.082C14.4844 16.0205 16.0615 17.5977 18 17.5977C19.9385 17.5977 21.5156 16.0206 21.5156 14.082ZM18 11.9727C18.3164 11.9727 18.6164 12.0431 18.8861 12.1685L17.9835 13.0711L17.0912 12.1788C17.3666 12.0468 17.6748 11.9727 18 11.9727ZM18 16.1914C16.8369 16.1914 15.8906 15.2451 15.8906 14.082C15.8906 13.7568 15.9647 13.4486 16.0968 13.1732L17.4863 14.5627C17.6236 14.6999 17.8035 14.7686 17.9835 14.7686C18.1634 14.7686 18.3433 14.6999 18.4807 14.5627L19.8922 13.1511C20.031 13.4321 20.1094 13.748 20.1094 14.082C20.1094 15.2451 19.1631 16.1914 18 16.1914Z"
        fill="black"
      />
      <path
        d="M15.1875 30.3242C15.5758 30.3242 15.8906 30.0094 15.8906 29.6211C15.8906 29.2328 15.5758 28.918 15.1875 28.918C14.7992 28.918 14.4844 29.2328 14.4844 29.6211C14.4844 30.0094 14.7992 30.3242 15.1875 30.3242Z"
        fill="black"
      />
      <path
        d="M20.8125 30.3242C21.2008 30.3242 21.5156 30.0094 21.5156 29.6211C21.5156 29.2328 21.2008 28.918 20.8125 28.918C20.4242 28.918 20.1094 29.2328 20.1094 29.6211C20.1094 30.0094 20.4242 30.3242 20.8125 30.3242Z"
        fill="black"
      />
    </svg>
  );
};

export default Icon;
