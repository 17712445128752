import { styled } from 'styled-components';

import { COLORS } from 'constants/colors';

export const WidgetRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 5px;
  margin-top: 19px;
`;

export const WidgetAmount = styled.span`
  font-size: 30px;
  font-weight: 600;
  line-height: 38px;
`;

export const WidgetAmountType = styled.span`
  color: ${COLORS.TextSecondary};
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
`;

export const WidgetImage = styled.img`
  width: 50px;
  height: 50px;
`;
