import React from 'react';
import { useTranslation } from 'react-i18next';

import { useFormikContext } from 'formik';

import { StyledForm } from 'components/auth/SignIn/styles';
import { BtnPrimary, BtnSecondary } from 'components/shared/Button';
import Checkbox from 'components/shared/Checkbox';
import FormikInput from 'components/shared/Input/FormikInput';
import PhoneNumberInput from 'components/shared/Input/PhoneNumberInput';
import { InputGroup } from 'components/shared/InputGroup';
import { COLORS } from 'constants/colors';

import { initialValues } from '../constants';
import AddressForm from './AddressForm';
import FullNameForm from './FullNameForm';
import { StyledCheckboxesWrapper, StyledPMOSettingInstruction } from './styles';

const currentFields = [
  'sirenNumber',
  'pmoOrgName',
  'pmo.firstName',
  'pmo.lastName',
  'pmo.email',
  'pmo.contactNumber',
  'pmo.address.city',
  'pmo.address.postalCode',
  'pmo.address.street',
];

interface AddPMOProps {
  onConfirmForm: (isValid: boolean) => void;
  validateFields: (fields: string[]) => Promise<boolean>;
  onBackButtonClick: () => void;
  areValidFields: (fields: string[]) => boolean;
  hasToBeValidated: boolean;
}

const AddPMO: React.FC<AddPMOProps> = ({
  onConfirmForm,
  onBackButtonClick,
  areValidFields,
  validateFields,
  hasToBeValidated,
}) => {
  const { t } = useTranslation();
  const { errors, values, setFieldValue } =
    useFormikContext<typeof initialValues>();
  const onConfirmClick = async () => {
    const isValid = await validateFields(currentFields);
    onConfirmForm(isValid && values.enedisAgreed && values.supportAgreed);
  };

  const isFieldDataValid = areValidFields(currentFields);

  return (
    <StyledForm>
      <InputGroup
        label={t('pmo_organization_name').toString()}
        error={errors.pmoOrgName}
      >
        <FormikInput
          fieldName="pmoOrgName"
          placeholder={t('organization_name').toString()}
        />
      </InputGroup>
      <InputGroup
        label={t('serial_number').toString()}
        error={errors.sirenNumber}
      >
        <FormikInput
          fieldName="sirenNumber"
          placeholder="Ex. 453 983 245"
          type="number"
        />
      </InputGroup>

      <FullNameForm />

      <InputGroup
        label={t('representative_email_label').toString()}
        error={errors.pmo?.email}
      >
        <FormikInput fieldName="pmo.email" placeholder="mail@mail.com" />
      </InputGroup>

      <InputGroup
        label={t('representative_phone_number_label').toString()}
        error={errors.pmo?.contactNumber}
      >
        <PhoneNumberInput
          hasError={Boolean(errors.pmo?.contactNumber)}
          phone={values.pmo.contactNumber}
          onChange={(contactNumber) =>
            setFieldValue('pmo.contactNumber', contactNumber)
          }
        />
      </InputGroup>

      <AddressForm />

      <StyledCheckboxesWrapper>
        <Checkbox
          name="enedisAgreed"
          isError={hasToBeValidated && !values.enedisAgreed}
          label={t('collective_self_consumption')}
        />
        <Checkbox
          name="supportAgreed"
          isError={hasToBeValidated && !values.supportAgreed}
          label={t('support_agreement_is_signed')}
        />
      </StyledCheckboxesWrapper>

      <StyledPMOSettingInstruction>
        {t('pmo_settings_instruction')}
      </StyledPMOSettingInstruction>
      <BtnPrimary
        onClick={onConfirmClick}
        isDisabled={
          hasToBeValidated &&
          (!isFieldDataValid || !values.enedisAgreed || !values.supportAgreed)
        }
      >
        {t('proceed')}
      </BtnPrimary>
      <BtnSecondary color={COLORS.TextBase} onClick={onBackButtonClick}>
        {t('back')}
      </BtnSecondary>
    </StyledForm>
  );
};

export default AddPMO;
