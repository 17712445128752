import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { SummaryViewRow } from 'components/communities/styles';
import WidgetEmissions from 'components/shared/WidgetEmissions';
import { COLORS } from 'constants/colors';
import { PERIOD } from 'constants/types';
import {
  useGetActualCostSavedQuery,
  useGetConsumerActualEmissionPreventedQuery,
  useGetConsumerSelfConsumptionRatioQuery,
  useGetConsumerSelfProductionRatioQuery,
} from 'services/dashboardManagment';

import { getDateRange } from 'utils/url/getDateRange';

import WidgetRatio from '../components/WidgetRatio';
import WidgetCostSaved from './components/WidgetCostSaved';

const SelfProductionChartColors = [COLORS.Green5, COLORS.Orange5];
const SelfConsumptionChartColors = [COLORS.Green5, '#D4D5D6' as COLORS];

interface IConsumerSummaryView {
  period: PERIOD;
  userId?: string;
}

const ConsumerSummaryView = ({ period, userId }: IConsumerSummaryView) => {
  const { t } = useTranslation();
  const { id } = useParams();

  const { data: costSavedData, isLoading: isLoadingActualCostSaved } =
    useGetActualCostSavedQuery({
      ...getDateRange(period),
      userId: id,
    });
  const {
    data: selfProductionRatioPercent,
    isLoading: isLoadingSelfProductionRatio,
  } = useGetConsumerSelfProductionRatioQuery({
    ...getDateRange(period),
    userId,
  });
  const {
    data: selfConsumptionRatioData,
    isLoading: isLoadingSelfConsuptionRatio,
  } = useGetConsumerSelfConsumptionRatioQuery({
    ...getDateRange(period),
    userId: id,
  });
  const selfConsumptionRatioPercent = selfConsumptionRatioData?.ratio;
  const {
    data: actualEmissionPreventedData,
    isLoading: isLoadingActualEmissionPrevented,
  } = useGetConsumerActualEmissionPreventedQuery({
    ...getDateRange(period),
    userId: id,
  });

  return (
    <SummaryViewRow>
      <WidgetRatio
        title={t('self_production_ratio').toString()}
        tooltipText={t('self_production_ratio_tooltip')}
        firstEnergyTitle={t('self_produced_energy')}
        secondEnergyTitle={t('grid_energy')}
        percent={selfProductionRatioPercent}
        chartColors={SelfProductionChartColors}
        isLoading={isLoadingSelfProductionRatio}
      />
      <WidgetCostSaved
        isLoading={isLoadingActualCostSaved}
        costSavedAmount={costSavedData?.actualCostSaved}
      />
      <WidgetEmissions
        isLoading={isLoadingActualEmissionPrevented}
        amount={actualEmissionPreventedData?.actualEmissionPrevented}
        subTitle={t('for_each_kWh_spent')}
        tooltipContext={t('consumer_emissions_prevented_tooltip')}
      />
      <WidgetRatio
        title={t('self_consumption_ratio').toString()}
        tooltipText={t('self_consumption_ratio_tooltip')}
        firstEnergyTitle={t('self_consumed_energy').toString()}
        secondEnergyTitle={t('production_left')}
        percent={selfConsumptionRatioPercent}
        chartColors={SelfConsumptionChartColors}
        isLoading={isLoadingSelfConsuptionRatio}
      />
    </SummaryViewRow>
  );
};

export default ConsumerSummaryView;
