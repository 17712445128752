import { checkValidPhoneNumber, getNestedObjectValue } from 'utils';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FormikProvider, useFormik } from 'formik';

import {
  StyledHeader,
  StyledTab,
  StyledTabsWrapper,
  StyledWrapper,
} from 'components/communities/CreateCommunity/styles';
import ActionInfoTab from 'components/shared/ActionInfoTab';
import Container from 'components/shared/Container';
import FullScreenLoader from 'components/shared/Loader/FullScreenLoader';
import Stepper from 'components/shared/Stepper/Stepper';
import { CREATE_MEMBER_STEPS, CREATE_STEPS } from 'constants/global';
import AppRoutes from 'constants/routes';
import { useCreateConsumerMutation } from 'services/membersManagement';

import { useAppDispatch, useAppSelector } from 'utils/hooks';
import { getConsumerSchema } from 'utils/validation/schemas';

import ConsumerDetails from './ConsumerDetails';
import ConsumerType from './ConsumerType';
import { ConsumerType as ConsumerTypeEnums } from './ConsumerType/constants';
import ContactInfo from './ContactInfo';
import { initialValues } from './constants';
import { setConsumerTypes } from './slice';

const CreateConsumer: React.FC = () => {
  const [activeStep, setActiveStep] = useState(CREATE_STEPS.FIRST);
  const [hasToBeValidated, setHasToBeValidated] = useState(false);
  const currentConsumerType = useAppSelector(
    (state) => state.consumer.consumerType,
  );
  const dispatch = useAppDispatch();
  const isPrivateHouse = currentConsumerType === ConsumerTypeEnums.privateHouse;

  const { t } = useTranslation();
  const [createConsumer, { isError, isSuccess, isLoading }] =
    useCreateConsumerMutation();

  const setupConsumerSchema = getConsumerSchema(t, isPrivateHouse);
  const formik = useFormik({
    initialValues,
    validationSchema: setupConsumerSchema,
    validateOnChange: hasToBeValidated,
    validateOnBlur: false,
    onSubmit: async (values: typeof initialValues) => {
      const transformedValues = JSON.parse(JSON.stringify(values));

      transformedValues.contactNumber = checkValidPhoneNumber(
        transformedValues.additionalInfo.legalPerson.contactNumber,
      );
      transformedValues.additionalInfo.billingPerson.contactNumber =
        checkValidPhoneNumber(
          transformedValues.additionalInfo.billingPerson.contactNumber,
        );
      transformedValues.additionalInfo.legalPerson.contactNumber =
        checkValidPhoneNumber(
          transformedValues.additionalInfo.legalPerson.contactNumber,
        );

      formik.setSubmitting(true);

      await formik.validateForm();
      if (formik.isValid) {
        try {
          await createConsumer(transformedValues).unwrap();
          dispatch(setConsumerTypes(''));
        } catch (err) {
          console.error(err); // TODO add error handling
        }
      }
    },
  });

  const validateFields = async (fields: string[]): Promise<boolean> => {
    const errors = await formik.validateForm();
    const isValid = fields.every(
      (field) => !getNestedObjectValue(errors, field),
    );
    if (isValid) formik.setErrors({});

    return isValid;
  };

  const areValidFields = (fields: string[]) => {
    return fields
      .map((field) => formik.getFieldMeta(field))
      .every((fieldMeta) => {
        return fieldMeta && !fieldMeta.error;
      });
  };

  const handleConfirm = (isValid: boolean) => {
    if (isValid) {
      setActiveStep((prev) => prev + 1);
      setHasToBeValidated(false);
    } else {
      setHasToBeValidated(true);
    }
  };

  const handleBackButtonClick = () => {
    setActiveStep((prev) => prev - 1);
  };

  const handleSuccessClick = () => {
    setActiveStep(CREATE_STEPS.FIRST);
    formik.resetForm();
  };

  return (
    <Container>
      <StyledWrapper>
        {isLoading && <FullScreenLoader />}
        {!isSuccess ? (
          <>
            <StyledHeader>{t('add_new_consumer')}</StyledHeader>
            <Stepper steps={CREATE_MEMBER_STEPS} activeStep={activeStep} />
            <FormikProvider value={formik}>
              <StyledTabsWrapper>
                <StyledTab active={activeStep === CREATE_STEPS.FIRST}>
                  <ConsumerType
                    setNextStep={() => setActiveStep((prev) => prev + 1)}
                  />
                </StyledTab>

                <StyledTab active={activeStep === CREATE_STEPS.SECOND}>
                  <ConsumerDetails
                    hasToBeValidated={hasToBeValidated}
                    areValidFields={areValidFields}
                    onBackButtonClick={handleBackButtonClick}
                    onConfirmForm={handleConfirm}
                    validateFields={validateFields}
                  />
                </StyledTab>

                <StyledTab active={activeStep === CREATE_STEPS.THIRD}>
                  <ContactInfo
                    isError={isError}
                    hasToBeValidated={hasToBeValidated}
                    validateFields={validateFields}
                    onBackButtonClick={handleBackButtonClick}
                    setHasToBeValidated={setHasToBeValidated}
                  />
                </StyledTab>
              </StyledTabsWrapper>
            </FormikProvider>
          </>
        ) : (
          <ActionInfoTab
            title="new_consumer_added"
            text="new_consumer_has_been_successfully_added_to_the_community"
            handleClick={handleSuccessClick}
            buttonText="back_to_members"
            redirectOnClick={AppRoutes.Members}
          />
        )}
      </StyledWrapper>
    </Container>
  );
};

export default CreateConsumer;
