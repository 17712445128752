import React, { FC, useMemo } from 'react';

import { LegendText } from 'components/shared/Text/LegendText';
import { EnergyTypes } from 'constants/energy-types';
import { SkeletonLine } from 'components/shared/Skeleton';
import { ChartDataType, InfoItemsType } from '../types';
import {
  SelfConsumptionBlock,
  SelfConsumptionItem,
  SelfConsumptionItemAmount,
  SelfConsumptionItemPercent,
  SelfConsumptionItemTitle,
  SelfConsumptionItems,
  SelfConsumptionLegendItem,
} from './styles';

interface SelfConsumptionInfoProps {
  data?: ChartDataType[];
  infoItems: InfoItemsType;
  hoveredType?: EnergyTypes;
  isLoading?: boolean;
}

interface SelfConsumptionInfoItemProps {
  type?: string;
  color: string;
  title: string;
  percent?: number | string | null;
  amount?: number;
  hoveredType?: EnergyTypes;
  isLoading?: boolean;
}

const SelfConsumptionInfoItem: FC<SelfConsumptionInfoItemProps> = ({
  type,
  color,
  title,
  percent,
  amount,
  hoveredType,
  isLoading,
}) => {
  return percent === null || percent ? (
    <SelfConsumptionItem highlighted={hoveredType === type}>
      <SelfConsumptionItemTitle>
        <SelfConsumptionLegendItem color={color}> </SelfConsumptionLegendItem>
        <LegendText bold={hoveredType === type}>{title}</LegendText>
        {amount ? ` (${amount})` : ''}
      </SelfConsumptionItemTitle>
      {isLoading ? (
        <SkeletonLine active width={42} height={30} />
      ) : (
        <SelfConsumptionItemAmount>
          {percent !== null ? percent : 'N/A'}
          <SelfConsumptionItemPercent> %</SelfConsumptionItemPercent>
        </SelfConsumptionItemAmount>
      )}
    </SelfConsumptionItem>
  ) : (
    <></>
  );
};

const SelfConsumptionInfo: FC<SelfConsumptionInfoProps> = ({
  hoveredType,
  data,
  infoItems,
  isLoading,
}) => {
  const loadingDummyData = Object.keys(infoItems).map((infoKey: string) => ({
    type: infoKey,
    isLoading: true,
    value: null,
    amount: undefined,
  }));
  const dataItems = useMemo(
    () => (isLoading ? loadingDummyData : data),
    [data, isLoading, loadingDummyData],
  );

  return (
    <SelfConsumptionBlock>
      <SelfConsumptionItems>
        {dataItems?.map((item: any) => (
          <SelfConsumptionInfoItem
            key={item.type}
            type={item.type}
            color={infoItems[item?.type || '']?.color}
            title={infoItems[item?.type || '']?.title}
            percent={item?.value}
            amount={item?.amount}
            hoveredType={hoveredType}
            isLoading={item?.isLoading}
          />
        ))}
      </SelfConsumptionItems>
    </SelfConsumptionBlock>
  );
};

export default SelfConsumptionInfo;
