import { styled } from 'styled-components';

import { DatePicker } from 'antd';

import { CloseCircleFilled, EditTwoTone } from '@ant-design/icons';

import { COLORS } from 'constants/colors';

export const StyledTopFormBlock = styled.div`
  background: ${COLORS.BgLayout};
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  padding: 24px 12px;
`;

export const StyledBottomFormBlock = styled.div`
  display: flex;
  align-items: end;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
  margin-top: 24px;
  align-items: flex-end;
`;

export const StyledBtnContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 12px;
  gap: 8px;
`;

export const StyledDatePicker = styled(DatePicker)<any>`
  width: 264px;
  border-width: 1px;
  height: 32px;
`;

export const StyledTopFormRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
`;

export const StyledTopFormCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 20px;
`;

export const customInputGroupStyles = {
  flexDirection: 'row',
  alignItems: 'center',
  textTransform: 'capitalize',
  justifyContent: 'space-between',
};

export const customInputLabelStyles = {
  minWidth: '114px',
};

export const ErrorIcon = styled(CloseCircleFilled)`
  font-size: 12px;
  color: ${COLORS.ErrorBase};
  margin-right: 4px;
`;
export const ErrorText = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  color: ${COLORS.ErrorBase};
`;

export const ErrorContainer = styled.div`
  min-height: 20px;
`;

export const LabelWrapper = styled.div`
  margin-bottom: 6px;
`;

export const EditIcon = styled(EditTwoTone).attrs(() => ({
  twoToneColor: COLORS.Green6,
}))``;

export const StyledDateTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${COLORS.Grey4};
  line-height: 1;
  width: 114px;
`;
