import { Page } from 'react-pdf';

import styled from 'styled-components';

import { Modal } from 'antd';

export const StyledPdfModal = styled(Modal)`
  z-index: 10000000000;

  .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
    width: 100%;
    height: 100%;
  }

  .ant-modal-close-x {
    display: none;
  }
`;

export const StyledPdfPage = styled(Page)`
  .react-pdf__Page__textContent {
    display: none;
  }

  .react-pdf__Page__annotations {
    display: none;
  }

  margin-bottom: 40px;
`;

export const StyledPdfWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
