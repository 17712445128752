import * as yup from 'yup';
import { TFunction } from 'i18next';

export const contactFormSchema = (t: TFunction) =>
  yup.object().shape({
    email: yup.string().required(t('field_is_required').toString()),
    name: yup.string().required(t('field_is_required').toString()),
    company: yup.string(),
    jobTitle: yup.string(),
    message: yup.string().required(t('field_is_required').toString()),
  });
