import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useFormikContext } from 'formik';

import { StyledForm } from 'components/auth/SignIn/styles';
import BillingInfo from 'components/members/createConsumer/ContactInfo/BillingInfo';
import { BtnPrimary, BtnSecondary } from 'components/shared/Button';
import { MarginTop24px, MarginTop40px } from 'components/shared/Margin';
import { COLORS } from 'constants/colors';

import { initialValues } from '../constants';
import BillingAddressForm from './BillingAddressForm';
import LegalPerson from './LegalPerson';

const BillingInfoValues = {
  firstName: 'additionalInfo.billingPerson.firstName',
  lastName: 'additionalInfo.billingPerson.lastName',
  middleName: 'additionalInfo.billingPerson.middleName',
  email: 'additionalInfo.billingPerson.email',
  phoneNumber: 'additionalInfo.billingPerson.contactNumber',
};

interface LegalDataProps {
  onConfirmForm: (valid: boolean) => void;
  onBackButtonClick: () => void;
  areValidFields: (fields: string[]) => boolean;
  validateFields: (fields: string[]) => Promise<boolean>;
  hasToBeValidated: boolean;
}

const currentFields = [
  'additionalInfo.legalPerson.firstName',
  'additionalInfo.legalPerson.lastName',
  'additionalInfo.legalPerson.position',
  'additionalInfo.legalPerson.email',
  'additionalInfo.legalPerson.contactNumber',
  'additionalInfo.billingPerson.email',
  'additionalInfo.billingPerson.contactNumber',
  'additionalInfo.billingPerson.firstName',
  'additionalInfo.billingPerson.lastName',
];

const LegalData: FC<LegalDataProps> = ({
  onConfirmForm,
  onBackButtonClick,
  hasToBeValidated,
  areValidFields,
  validateFields,
}) => {
  const [isSameAddress, setIsSameAddress] = useState(false);
  const { t } = useTranslation();
  const { values, setSubmitting, setFieldValue } =
    useFormikContext<typeof initialValues>();

  useEffect(() => {
    async function setSameBillingAddress() {
      if (isSameAddress) {
        await setFieldValue(
          'additionalInfo.billingAddress.street',
          values.address.street,
        );
        await setFieldValue(
          'additionalInfo.billingAddress.postalCode',
          values.address.postalCode,
        );
        await setFieldValue(
          'additionalInfo.billingAddress.building',
          values.address.building,
        );
        await setFieldValue(
          'additionalInfo.billingAddress.city',
          values.address.city,
        );
      }
    }

    setSameBillingAddress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSameAddress]);

  const isFieldDataValid = areValidFields(currentFields);

  const onConfirmClick = async () => {
    setSubmitting(true);
    const isValid = await validateFields(currentFields);
    if (isValid) {
      await setFieldValue('email', values.additionalInfo.legalPerson.email);
      await setFieldValue(
        'contactNumber',
        values.additionalInfo.legalPerson.contactNumber,
      );
    }
    onConfirmForm(isValid);
    setSubmitting(false);
  };

  return (
    <StyledForm>
      <LegalPerson />

      <BillingInfo values={BillingInfoValues} />

      <BillingAddressForm
        setSameAddress={setIsSameAddress}
        sameAddress={isSameAddress}
      />
      <MarginTop40px />
      <BtnPrimary
        onClick={onConfirmClick}
        isDisabled={hasToBeValidated && !isFieldDataValid}
      >
        {t('proceed')}
      </BtnPrimary>
      <MarginTop24px />

      <BtnSecondary color={COLORS.TextBase} onClick={onBackButtonClick}>
        {t('back')}
      </BtnSecondary>
    </StyledForm>
  );
};

export default LegalData;
