import { FC } from 'react';

import { COLORS } from 'constants/colors';

import ArrowImage from './ArrowImage';
import {
  StyledPercent,
  StyledPercentImageRotate,
  StyledPercentRow,
} from './styles';

interface DirectionPercentProps {
  percent: number;
}

const DirectionPercent: FC<DirectionPercentProps> = ({ percent }) => {
  const isNegative = Math.sign(percent) === -1;
  const color = isNegative ? COLORS.ErrorBase : COLORS.Green5;

  return (
    <StyledPercentRow>
      <StyledPercentImageRotate rotate={isNegative ? true : false}>
        <ArrowImage color={color} />
      </StyledPercentImageRotate>
      <StyledPercent color={color}>{percent}%</StyledPercent>
    </StyledPercentRow>
  );
};

export default DirectionPercent;
