import { styled } from 'styled-components';

import { COLORS } from 'constants/colors';

export const Header = styled.h1`
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  margin: 0;
`;

export const SubHeader = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: center;
  color: ${COLORS.Grey5};
`;

export const TextRegular = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  color: ${({ color }) => (color ? color : COLORS.Grey4)};
`;

export const TextBold = styled(TextRegular)`
  font-weight: 600;
  color: ${({ color }) => (color ? color : COLORS.TextBase)};
`;
