import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { useFormikContext } from 'formik';

import { BtnPrimary, BtnSecondary } from 'components/shared/Button';
import InputError from 'components/shared/InputGroup/InputError';
import { MarginTop24px } from 'components/shared/Margin';
import { COLORS } from 'constants/colors';
import AppRoutes from 'constants/routes';

import { useAppDispatch, useAppSelector } from 'utils/hooks';

import { initialValues } from '../constants';
import { setConsumerTypes } from '../slice';
import ConsumerTypeItem from './ConsumerTypeItem';
import { useConsumerItemType } from './constants';
import {
  StyledConsumerTabWrapper,
  StyledConsumerTitle,
  StyledConsumerTypes,
} from './styles';

interface ConsumerTypeProps {
  setNextStep: () => void;
}

const ConsumerType: React.FC<ConsumerTypeProps> = ({ setNextStep }) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const { consumerType } = useAppSelector((state) => state.consumer);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const CONSUMER_ITEM_TYPE = useConsumerItemType();

  const { setFieldValue } = useFormikContext<typeof initialValues>();

  useEffect(() => {
    dispatch(setConsumerTypes(''));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const onCancelClick = () => {
    dispatch(setConsumerTypes(''));
    navigate(AppRoutes.Members);
  };

  const onConfirmClick = () => {
    if (!consumerType) {
      setIsDisabled(true);
    } else {
      setNextStep();
    }
  };

  const handleTypeClick = async (itemKey: string) => {
    dispatch(setConsumerTypes(itemKey));
    await setFieldValue('additionalInfo.type', itemKey);
    setIsDisabled(false);
  };

  return (
    <StyledConsumerTabWrapper>
      <StyledConsumerTitle isUppercase={true}>
        {t('choose_consumer_type')}
      </StyledConsumerTitle>

      <StyledConsumerTypes>
        {CONSUMER_ITEM_TYPE.map((itemType) => (
          <ConsumerTypeItem
            key={itemType.title}
            item={itemType}
            isActive={consumerType === itemType.key}
            handleTypeClick={handleTypeClick}
          />
        ))}
      </StyledConsumerTypes>

      <InputError
        error={
          isDisabled ? t('please_choose_the_consumer_type_to_proceed') : ''
        }
      />
      <MarginTop24px />
      <BtnPrimary
        isDisabled={isDisabled && !consumerType}
        onClick={onConfirmClick}
      >
        {t('proceed')}
      </BtnPrimary>
      <MarginTop24px />
      <BtnSecondary color={COLORS.TextBase} onClick={onCancelClick}>
        {t('cancel')}
      </BtnSecondary>
    </StyledConsumerTabWrapper>
  );
};

export default ConsumerType;
