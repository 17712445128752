import React from 'react';
import { useTranslation } from 'react-i18next';

import { ConsumerItem } from '../constants';
import {
  StyledItemWrapper,
  StyledTypeText,
  StyledTypeTitle,
  StyledTypesItem,
} from './styles';

type ConsumerTypeItemProps = {
  item: ConsumerItem;
  isActive: boolean;
  handleTypeClick: (type: string) => void;
};

const ConsumerTypeItem: React.FC<ConsumerTypeItemProps> = ({
  item: { description, disableIcon: DisableIcon, icon: Icon, title, key },
  isActive,
  handleTypeClick,
}) => {
  const { t } = useTranslation();
  return (
    <StyledItemWrapper active={isActive} onClick={() => handleTypeClick(key)}>
      <StyledTypesItem>
        <StyledTypeTitle active={isActive}>{t(title)}</StyledTypeTitle>
      </StyledTypesItem>
      <StyledTypesItem>{isActive ? <Icon /> : <DisableIcon />}</StyledTypesItem>
      <StyledTypesItem>
        <StyledTypeText>{description}</StyledTypeText>
      </StyledTypesItem>
    </StyledItemWrapper>
  );
};

export default ConsumerTypeItem;
