import * as yup from 'yup';

import { TFunction } from 'i18next';

export const prmPriceSchema = (t: TFunction) =>
  yup.object().shape({
    communityId: yup.string().required(),
    consumerId: yup.string().required(),
    prmId: yup.string().required(),
    indexation: yup.string().required(),
    cppa: yup.number().required(),
    startDate: yup.date().required().min(new Date()),
    endDate: yup
      .date()
      .required()
      .min(yup.ref('startDate'), t('end_date_after_start_date').toString()),
    RH: yup.number().required(),
    HS_HH: yup.number().required(),
    HS_LH: yup.number().required(),
    LS_HH: yup.number().required(),
    LS_LH: yup.number().required(),
  });
