import { mapDate, mapPeriodToValue } from 'utils';

import { TFunction } from 'i18next';

import { COLORS } from 'constants/colors';
import { PERIOD } from 'constants/types';
import { ILabelMap } from 'services/types';

export enum LABELS {
  TOTAL_PRODUCTION = 'TOTAL_PRODUCTION',
  TOTAL_CONSUMPTION = 'TOTAL_CONSUMPTION',
  SELF_CONSUMPTION = 'SELF-CONSUMPTION',
  GRID_CONSUMPTION = 'GRID_CONSUMPTION',
  OVERPRODUCTION = 'OVERPRODUCTION',
}

interface IElectricityConsumptionItem {
  category: LABELS;
  date: string;
  value: number;
  tooltipData?: any;
}
const defaultLabelsMap: ILabelMap = {
  totalConsumption: LABELS.TOTAL_CONSUMPTION,
  selfConsumption: LABELS.SELF_CONSUMPTION,
  gridConsumption: LABELS.GRID_CONSUMPTION,
  overproduction: LABELS.OVERPRODUCTION,
};

export function mapElectricityConsumptionData(
  data: any,
  period: PERIOD,
  labelsMap: ILabelMap = defaultLabelsMap,
  t: TFunction,
) {
  const result: Array<IElectricityConsumptionItem> = [];
  const value = mapPeriodToValue(period);

  data?.forEach((item: any) => {
    (Object.keys(labelsMap) as Array<keyof ILabelMap>).forEach((label) => {
      const sevenDaysDate = `${item.dayOfMonth} ${mapDate(
        item[value],
        period,
        t,
      )
        .toUpperCase()
        .slice(0, 2)}`;

      result.push({
        category: labelsMap[label] as LABELS,
        date:
          period === PERIOD.LAST_SEVEN_DAYS
            ? sevenDaysDate
            : mapDate(item[value], period, t),
        value:
          labelsMap[label] === LABELS.OVERPRODUCTION
            ? -Math.abs(item[label])
            : item[label],
        tooltipData: {
          ...item,
        },
      });
    });
  });

  return result;
}

export const legendItems = (t: TFunction) => [
  {
    value: LABELS.OVERPRODUCTION,
    name: t('overproduction'),
    marker: { symbol: 'square', style: { fill: COLORS.TextTertiary } },
  },
  {
    value: LABELS.GRID_CONSUMPTION,
    name: t('grid_consumption'),
    marker: { symbol: 'square', style: { fill: COLORS.Orange6 } },
  },
  {
    value: LABELS.SELF_CONSUMPTION,
    name: t('self_consumption'),
    marker: { symbol: 'square', style: { fill: COLORS.Green6 } },
  },
  {
    value: LABELS.TOTAL_CONSUMPTION,
    name: t('total_consumption'),
    marker: { symbol: 'square', style: { fill: COLORS.Yellow5 } },
  },
];

export const infoItems = (t: TFunction) => [
  {
    title: t('total_consumption').toString(),
    color: COLORS.Yellow5,
  },
  {
    title: t('self_consumption').toString(),
    color: COLORS.Green6,
  },
  {
    title: t('grid_consumption').toString(),
    color: COLORS.Orange5,
  },
  {
    title: t('overproduction').toString(),
    color: COLORS.TextTertiary,
  },
];

export const lineColors = [
  COLORS.Yellow5,
  COLORS.Green6,
  COLORS.Orange5,
  COLORS.TextTertiary,
];

export const areaColors = ['#FEF08A70', '#BBF7D050', '#FED7AA40', '#F5F5F5'];
