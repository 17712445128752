import styled from 'styled-components';

import { COLORS } from 'constants/colors';

export const Container = styled.div`
  position: relative;
`;

export const Suggestions = styled.div`
  box-sizing: border-box;
`;

export const SuggestionsList = styled.ul`
  background-color: ${COLORS.BgContainer};
  border-radius: 6px;
  border: 1px solid ${COLORS.InputBorder};
  font-size: 15px;
  left: 0;
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1000;
  min-width: 200px;

  &:hover {
    cursor: pointer;
  }
`;

export const SuggestionsListItem = styled.li`
  padding: 8px 12px;

  &:hover {
    background-color: ${COLORS.BgLayout};
    cursor: pointer;
  }

  &:first-child {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  &:last-child {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
`;
