import styled from 'styled-components';

import { SubHeader, TextRegular } from 'components/shared/Text';
import { COLORS } from 'constants/colors';

export const StyledItemWrapper = styled.div<{ active: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: 10px 12px;
  border-radius: 8px;
  align-items: center;
  background-color: ${COLORS.BgContainer};
  border: 1px solid ${(props) => (props.active ? COLORS.Green8 : COLORS.Grey1)};
`;

export const StyledTypesItem = styled.div`
  flex: 1;
  &:nth-child(2) {
    flex: 0;
  }
`;

export const StyledTypeTitle = styled(SubHeader)<{ active: boolean }>`
  text-align: start;
  color: ${(props) => (props.active ? COLORS.Green8 : COLORS.TextBase)};
  font-weight: ${(props) => (props.active ? 600 : 400)};
  margin: 0;
`;

export const StyledTypeText = styled(TextRegular)`
  display: block;
  text-align: end;
  margin: 0;
`;
