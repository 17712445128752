import { getAppRole } from 'utils';
import { v4 as uuidv4 } from 'uuid';

import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PlusOutlines from 'components/communities/Communities/img/PlusOutlined.svg';
import { BtnWrapper } from 'components/communities/Communities/styles';
import { BtnPrimary } from 'components/shared/Button';
import Image from 'components/shared/Image';
import { USER_ROLES } from 'constants/types';
import { IDBTaxPrices, ITaxPrices } from 'services/taxPrices.types';

import { BlockHeader, Title } from '../../styles';
import { PriceType, PriceVariant } from '../../types';
import EmptyPrices from '../EmptyPrices';
import ModifyPrices from '../ModifyPrices';
import AcciseTable from './components/AcciseTable';

const AccisePrices = (data: ITaxPrices) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editTaxes, setEditTaxes] = useState<IDBTaxPrices | undefined>(
    undefined,
  );

  const { t } = useTranslation();
  const { current, upcoming, historical } = data;

  const handleOnEdit = (taxPrice: IDBTaxPrices) => {
    setIsModalVisible(true);
    setEditTaxes(taxPrice);
  };

  const handleOnModalVisibleChange = (isModalVisible: boolean) => {
    setIsModalVisible(isModalVisible);
    setEditTaxes(undefined);
  };
  const userRole = getAppRole();

  const isProducer = useMemo(
    () => userRole === USER_ROLES.PRODUCER,
    [userRole],
  );

  const renderTable = (
    prices: IDBTaxPrices[],
    priceVariant: PriceVariant,
    hasEdit = false,
  ) => {
    if (priceVariant === PriceVariant.HISTORICAL) {
      return (
        <AcciseTable
          key={uuidv4()}
          priceVariant={priceVariant}
          taxPrices={prices[0]}
          onEdit={hasEdit ? () => handleOnEdit(prices[0]) : undefined}
        />
      );
    }

    return prices.map((taxPrice) => (
      <AcciseTable
        key={uuidv4()}
        priceVariant={priceVariant}
        taxPrices={taxPrice}
        onEdit={hasEdit ? () => handleOnEdit(taxPrice) : undefined}
      />
    ));
  };

  const renderPrices = (
    prices: IDBTaxPrices[],
    priceVariant: PriceVariant,
    hasEdit = false,
  ) => {
    if (!prices?.length) return null;

    return renderTable(prices, priceVariant, hasEdit);
  };

  return (
    <>
      <BlockHeader>
        <Title>{t('tax_price', { priceType: PriceType.ACCISE })}</Title>
        {!isProducer && (
          <BtnWrapper>
            <BtnPrimary onClick={() => setIsModalVisible(true)}>
              <Image src={PlusOutlines} alt="PlusIcon" margin="0 5px 0 0" />
              {t('add_new_tax_prices', { priceType: PriceType.ACCISE })}
            </BtnPrimary>
          </BtnWrapper>
        )}
      </BlockHeader>
      {renderPrices(current, PriceVariant.CURRENT)}
      {renderPrices(upcoming, PriceVariant.UPCOMMING, true)}
      {renderPrices(historical, PriceVariant.HISTORICAL)}
      <ModifyPrices
        isModalVisible={isModalVisible}
        priceType={PriceType.ACCISE}
        taxPrices={editTaxes}
        onModalVisibleChange={handleOnModalVisibleChange}
      />
      <EmptyPrices
        {...data}
        priceType={PriceType.ACCISE}
        priceKey="accisePerMWh"
      />
    </>
  );
};

export default AccisePrices;
