import styled from 'styled-components';

import { TextBold } from 'components/shared/Text';
import { COLORS } from 'constants/colors';

const Value = styled(TextBold)`
  font-size: 14px;
`;

const Percent = styled(TextBold)`
  margin-left: 3px;
  color: ${COLORS.Grey4};
`;

interface IEnergyValue {
  value?: number;
}

const EnergyValue = ({ value }: IEnergyValue) => {
  const renderValue = value ? value : 'N/A';

  return (
    <>
      <Value>{renderValue}</Value>
      <Percent>%</Percent>
    </>
  );
};

export default EnergyValue;
