import { TFunction } from 'i18next';

export const producerDropdownItems = (t: TFunction) => [
  {
    label: t('all_earnings'),
    key: 'all_earnings',
  },
  {
    label: t('earned_from_community'),
    key: 'earned_from_community',
  },
  {
    label: t('earned_tax_refund'),
    key: 'earned_tax_refund',
  },
];
