import { ReactNode } from 'react';

import { SummaryItemDescription, SummaryItemValue } from '../../styles';

interface ITitleItem {
  value: string | number | ReactNode;
  children: ReactNode;
  preffixDescription?: string;
  suffixDescription?: string;
}

const TitleItem = ({
  value,
  children,
  preffixDescription,
  suffixDescription,
}: ITitleItem) => (
  <>
    {children}
    <SummaryItemDescription prefix>{preffixDescription}</SummaryItemDescription>
    <SummaryItemValue>{value}</SummaryItemValue>
    <SummaryItemDescription>{suffixDescription}</SummaryItemDescription>
  </>
);

export default TitleItem;
