import { styled } from 'styled-components';

import { MailTwoTone } from '@ant-design/icons';

import { COLORS } from 'constants/colors';

export const StyledProfileHeader = styled.div`
  padding: 32px;
  box-sizing: border-box;
  margin: 0 auto;
  width: 1440px;
`;

export const StyledProfileRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledProfileTitle = styled.div`
  font-size: 30px;
  font-weight: 600;
  line-height: 1;
`;

export const MailIcon = styled(MailTwoTone).attrs(() => ({
  twoToneColor: COLORS.Green6,
}))`
  margin-right: 5px;
`;

export const StyledMail = styled.span`
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  display: flex;
  align-items: center;
`;
