import Lottie from 'lottie-react';

import { useState } from 'react';

import loader from './loader.json';
import { CustomModal, StyledLoader } from './styles';

const FullScreenLoader = () => {
  const [isModalOpen, setIsModalOpen] = useState(true);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  return (
    <CustomModal
      open={isModalOpen}
      onOk={handleOk}
      zIndex={1000000}
      footer={[<></>]}
      closable={false}
    >
      <StyledLoader>
        <Lottie animationData={loader} loop autoplay />
      </StyledLoader>
    </CustomModal>
  );
};

export default FullScreenLoader;
