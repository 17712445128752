import { ChangeEvent } from 'react';

import { useFormikContext } from 'formik';

import { FieldStyled } from 'components/pricing/components/TaxPrices/styles';

import { Cell } from '../PriceRow/styles';

type ICompanyTypeItem = {
  property: 'ht' | 'vat' | 'ttc';
  path: 'private' | 'medium' | 'large';
};

const PriceRowLine = ({ path, property }: ICompanyTypeItem) => {
  const { values, errors, setFieldValue, setErrors } = useFormikContext();

  const isDisabled = property === 'vat' || property === 'ttc';
  const err = errors as any;
  const vals = values as any;

  const isError = !isDisabled && !!err?.accisePerMWh?.[path]?.[property];
  const value = vals?.accisePerMWh?.[path]?.[property];
  const placeholder = isDisabled ? '0.00' : '32.07';

  const handleOnChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const ht = Number(event.target.value) as any;
    const vat = parseFloat(((ht / 100) * 20).toFixed(2));
    const ttc = parseFloat((ht * 1.2).toFixed(2));

    await setFieldValue(`accisePerMWh.${path}.ht`, ht);
    await setFieldValue(`accisePerMWh.${path}.vat`, vat);
    await setFieldValue(`accisePerMWh.${path}.ttc`, ttc);

    if (path === 'medium') {
      await setFieldValue(`accisePerMWh.small.ht`, ht);
      await setFieldValue(`accisePerMWh.small.vat`, vat);
      await setFieldValue(`accisePerMWh.small.ttc`, ttc);
    }
  };

  const handleOnFocus = () => {
    setErrors({});
  };

  return (
    <Cell>
      <FieldStyled
        error={isError ? 'error' : undefined}
        type="number"
        disabled={isDisabled}
        value={value || ''}
        name={`accisePerMWh.${path}.${property}`}
        placeholder={placeholder}
        onFocus={handleOnFocus}
        onChange={handleOnChange}
      />
    </Cell>
  );
};

export default PriceRowLine;
