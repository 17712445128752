import { useMemo } from 'react';
import { USER_ROLES } from 'constants/types';
import ConsumerSettingTab from '../ConsumerDashboard/components/SettingsTab';
import ProducerSettingsTab from '../ProducerDashboard/components/SettingsTab';

export const useSettingsByRole = (userRole: USER_ROLES) => {
  const settingsConfig = useMemo(() => {
    switch (userRole) {
      case USER_ROLES.CONSUMER: {
        return <ConsumerSettingTab />;
      }
      case USER_ROLES.PRODUCER: {
        return <ProducerSettingsTab />;
      }
      default:
        return null;
    }
  }, [userRole]);

  return { settingsConfig };
};
