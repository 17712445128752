import dayjs from 'dayjs';
import { getAppRole } from 'utils';

import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { BtnPrimary } from 'components/shared/Button';
import { USER_ROLES } from 'constants/types';

import { IFormInitialData } from '../index';
import ActualPrices from './ActualPrices';
import HistoricalPrices from './HistoricalPrices';
import UpcomingPrices from './UpcomingPrices';
import {
  StyledNoPricesContainer,
  StyledNoPricesText,
  StyledPricesWrapper,
  customAddPriceBtnStyles,
} from './styles';
import isBetween from 'dayjs/plugin/isBetween';

interface PrmPricesProps {
  prmPrices?: any;
  isHistoricalPricesShown: boolean;
  openFormModal: (data: IFormInitialData) => void;
  communityId: string;
  consumerId: string;
  prmId: string;
}

dayjs.extend(isBetween);

const PrmPrices: FC<PrmPricesProps> = ({
  prmPrices,
  isHistoricalPricesShown,
  openFormModal,
  communityId,
  consumerId,
  prmId,
}) => {
  const { t } = useTranslation();
  const userRole = getAppRole();

  const { actualPrice, upcomingPrices, historicalPrices } = useMemo(() => {
    const currentDate = dayjs();
    if (!prmPrices)
      return { actualPrices: [], upcomingPrices: [], historicalPrices: [] };
    let actual: any = null;
    let historical: any = {};
    let upcoming: any = {};
    return prmPrices?.reduce(
      (acc: any, prmPrice: any) => {
        prmPrice.periods.forEach((period: any) => {
          const startDate = dayjs(period.startDate);
          const endDate = dayjs(period.endDate);
          if (currentDate.isBefore(startDate)) {
            // in this case upcoming price can be edited
            if (prmPrice.periods.length === 1) {
              upcoming = {
                ...upcoming,
                [prmPrice.cppa]: {
                  startDate: prmPrice.startDate,
                  endDate: prmPrice.endDate,
                  indexation: prmPrice.indexation,
                  communityId: prmPrice.community,
                  consumerId: prmPrice.prm.user?._id,
                  prmId: prmPrice.prm._id,
                  prmPriceId: prmPrice._id,
                  periods: [period],
                },
              };
            } else {
              const updatedPeriods = upcoming[prmPrice.cppa]
                ? [...upcoming[prmPrice.cppa].periods, period]
                : [period];
              upcoming = {
                ...upcoming,
                [prmPrice.cppa]: {
                  startDate: prmPrice.startDate,
                  endDate: prmPrice.endDate,
                  indexation: prmPrice.indexation,
                  periods: updatedPeriods,
                },
              };
            }
          }
          if (currentDate.isBetween(startDate, endDate)) {
            actual = period;
          }
          if (currentDate.isAfter(endDate)) {
            if (historical[prmPrice.cppa]) {
              historical[prmPrice.cppa].periods.push(period);
            } else {
              historical = {
                ...historical,
                ...{
                  [prmPrice.cppa]: {
                    startDate: prmPrice.startDate,
                    endDate: prmPrice.endDate,
                    periods: [period],
                  },
                },
              };
            }
          }
        });

        return {
          actualPrice: actual,
          upcomingPrices: { ...acc.upcomingPrices, ...upcoming },
          historicalPrices: { ...acc.historicalPrices, ...historical },
        };
      },
      { actualPrice: [], upcomingPrices: [], historicalPrices: [] },
    );
  }, [prmPrices]);

  const noPrices = useMemo(
    () =>
      !actualPrice &&
      !Object.keys(upcomingPrices).length &&
      !Object.keys(historicalPrices).length,
    [actualPrice, upcomingPrices, historicalPrices],
  );

  const isAdmin = useMemo(() => userRole === USER_ROLES.ADMIN, [userRole]);

  return (
    <StyledPricesWrapper>
      {actualPrice ? <ActualPrices period={actualPrice} /> : null}
      {upcomingPrices && Object.keys(upcomingPrices).length ? (
        <UpcomingPrices prmPrices={upcomingPrices} onEdit={openFormModal} />
      ) : null}
      <HistoricalPrices
        prmPrices={historicalPrices}
        isShown={
          isHistoricalPricesShown &&
          historicalPrices &&
          Object.keys(historicalPrices).length
        }
      />
      {noPrices && (
        <StyledNoPricesContainer>
          <StyledNoPricesText>{t('no_prices')}</StyledNoPricesText>
          {isAdmin && (
            <BtnPrimary
              onClick={() =>
                openFormModal({
                  communityId,
                  consumerId,
                  prmId,
                })
              }
              additionalStyles={customAddPriceBtnStyles}
            >
              {t('add_new_prices')}
            </BtnPrimary>
          )}
        </StyledNoPricesContainer>
      )}
    </StyledPricesWrapper>
  );
};

export default PrmPrices;
