import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Popover } from 'antd';

import { StyledReportSelectRow } from 'components/communities/components/CreateReportWithSelectCheckboxes/styles';

import { useAppDispatch, useAppSelector } from 'utils/hooks';

import { ArrowDownIcon, ArrowUpIcon } from '../CustomSelect/styles';
import DatePickerContent from './components/DatePickerContent';
import { predefinedRanges } from './constants';
import { setDates as setReportDatePickerDates } from './slice';
import { StyledInput, StyledLabel } from './styles';
import './styles.css';
import { DATE_PICKER_STATE } from './types';

interface CustomDatePickerProps {
  type?: 'month' | 'date';
}

const CustomDatePicker: FC<CustomDatePickerProps> = ({ type = 'month' }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const pathname = useLocation();
  const dispatch = useAppDispatch();
  const [pickerType, setPickerType] = useState<'month' | 'date'>(type);
  const [wasDatePickerOpened, setWasDatePickerOpened] = useState(false);
  const reportSelectedDate = useAppSelector(
    (store) => store.reportDatePicker.dates,
  );
  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  //temprary solution
  useEffect(() => {
    const popup = document.querySelector('.ant-picker-dropdown') as HTMLElement;
    if (popup) {
      popup.style.opacity = open ? '1' : '0';
      popup.style.transition = wasDatePickerOpened
        ? 'opacity .7s ease'
        : 'opacity .01s ease';
      popup.style.zIndex = open ? '10000' : '-1';
      if (!wasDatePickerOpened) setWasDatePickerOpened(true);
    }
  }, [open, wasDatePickerOpened]);

  useEffect(() => {
    dispatch(
      setReportDatePickerDates({
        ...predefinedRanges[DATE_PICKER_STATE.CURRENT_YEAR],
        label: DATE_PICKER_STATE.CURRENT_YEAR,
      }),
    );
  }, [pathname]);

  return (
    <>
      <StyledLabel>{t('report_period')}</StyledLabel>
      <Popover
        open={open}
        onOpenChange={handleOpenChange}
        trigger="click"
        overlayClassName="datepicker-popover"
        placement="bottomLeft"
        arrow={false}
        content={<DatePickerContent setOpen={setOpen} type={pickerType} />}
      >
        <StyledReportSelectRow>
          <StyledInput>
            {t(reportSelectedDate.label || '')}
            {open ? <ArrowUpIcon /> : <ArrowDownIcon />}
          </StyledInput>
        </StyledReportSelectRow>
      </Popover>
    </>
  );
};

export default CustomDatePicker;
