import { createSlice } from '@reduxjs/toolkit';

import { PERIOD } from 'constants/types';

interface DashboardPeriodState {
  period: PERIOD;
}

const initialState: DashboardPeriodState = {
  period: '' as PERIOD,
};

export const DashboardPeriodSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setPeriod: (state, action) => {
      state.period = action.payload;
    },
  },
});

export const { setPeriod } = DashboardPeriodSlice.actions;

export default DashboardPeriodSlice.reducer;
