import { Trans } from 'react-i18next';

import { styled } from 'styled-components';

import { TextRegular } from 'components/shared/Text';
import { COLORS } from 'constants/colors';

const Text = styled(TextRegular)`
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  padding: 0 10px;
`;

const LinkInline = styled.a`
  display: inline;
  color: ${COLORS.Grey5};
  font-weight: 600;
  text-decoration: none;
`;

const TermsAndConditions = () => (
  <Text>
    <Trans
      i18nKey="by_continuing_oyo_terms_and_data_processing_agreement"
      components={{ a: <LinkInline /> }}
    />
  </Text>
);

export default TermsAndConditions;
