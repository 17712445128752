import { SUPPORTED_LANGUAGES } from 'constants/global';

import EnFlag from './icons/FlagEnglish.svg';
import FrFlag from './icons/FlagFrance.svg';
import { StyledFlag } from './styles';

interface FlagComponentProps {
  country: string;
}

const FlagComponent: React.FC<FlagComponentProps> = ({ country }) => {
  const flagMap: { [key: string]: JSX.Element } = {
    [SUPPORTED_LANGUAGES.English]: (
      <img src={EnFlag} alt={SUPPORTED_LANGUAGES.English} />
    ),
    [SUPPORTED_LANGUAGES.Français]: (
      <img src={FrFlag} alt={SUPPORTED_LANGUAGES.Français} />
    ),
  };

  return <StyledFlag>{flagMap[country]}</StyledFlag>;
};

export default FlagComponent;
