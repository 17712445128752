import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  CollectivityIcon,
  CollectivityInactiveIcon,
  HouseIcon,
  HouseInactiveIcon,
  LargeCompanyIcon,
  LargeCompanyInactiveIcon,
  MediumCompanyIcon,
  MediumCompanyInactiveIcon,
  SmallCompanyIcon,
  SmallCompanyInactiveIcon,
} from './Icons';

export enum ConsumerType {
  privateHouse = 'private',
  smallCompany = 'small',
  mediumCompany = 'medium',
  largeCompany = 'large',
  collectivity = 'collectivity',
}

export type ConsumerItem = {
  title: string;
  description: string;
  disableIcon: React.FC;
  icon: React.FC;
  key: ConsumerType;
};

export const useConsumerItemType = () => {
  const { t } = useTranslation();

  return [
    {
      title: 'private_house',
      icon: HouseIcon,
      disableIcon: HouseInactiveIcon,
      description: t('up_to_36'),
      key: ConsumerType.privateHouse,
    },
    {
      title: 'small_company',
      icon: SmallCompanyIcon,
      disableIcon: SmallCompanyInactiveIcon,
      description: t('up_to_36'),
      key: ConsumerType.smallCompany,
    },
    {
      title: 'medium_company',
      icon: MediumCompanyIcon,
      disableIcon: MediumCompanyInactiveIcon,
      description: '36 KVA - 250 KVA',
      key: ConsumerType.mediumCompany,
    },
    {
      title: 'large_company',
      icon: LargeCompanyIcon,
      disableIcon: LargeCompanyInactiveIcon,
      description: t('more_than_250'),
      key: ConsumerType.largeCompany,
    },
    {
      title: 'collectivity',
      icon: CollectivityIcon,
      disableIcon: CollectivityInactiveIcon,
      description: t('up_to_250'),
      key: ConsumerType.collectivity,
    },
  ];
};
