import { useTranslation } from 'react-i18next';

import CommunityFollowUp from '../../../../../components/CommunityFollowUp';
import ProductionTableWrapper from './components/ProductionTableWrapper';

export const useTabsConfig = () => {
  const { t } = useTranslation();

  return [
    {
      label: t('energy_production_follow_up').toUpperCase(),
      key: '1',
      children: <ProductionTableWrapper />,
    },
    {
      label: t('energy_consumption_follow_up').toUpperCase(),
      key: '2',
      children: <CommunityFollowUp />,
    },
  ];
};
