import { getAppRole } from 'utils';

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import FinancialFollowup from 'components/communities/Charts/FinancialFollowup';
import { FinancialDataTypes } from 'components/communities/Dashboard/types';
import { mapFinancialData } from 'components/communities/Dashboard/utils';
import { formatFinancialDataWithEmptyTypes } from 'components/communities/utils';
import { USER_ROLES } from 'constants/types';
import { useGetFinancialDataQuery } from 'services/dashboardManagment';

import { useAppSelector } from 'utils/hooks';
import { getDateRange } from 'utils/url/getDateRange';

import { producerDropdownItems } from '../../constants';
import { someEarningsDummyData } from './constants';
import {
  getFinancialChartInfoDataByType,
  getFinancialDataTotalByType,
  parseFinancialDataByType,
} from './utils';

const FinancialFollowUpChartWrapper = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const period = useAppSelector((state: any) => state.dashboardPeriod.period);
  const role = getAppRole();
  const [selectedType, setSelectedType] = useState(
    role === USER_ROLES.PMO_ADMIN
      ? FinancialDataTypes.EARNED_FROM_COMMUNITY
      : FinancialDataTypes.ALL_EARNINGS,
  );

  const { data: financialData } = useGetFinancialDataQuery({
    ...getDateRange(period),
    userId: id,
  });
  const chartData = parseFinancialDataByType(financialData, selectedType);

  const infoData = getFinancialChartInfoDataByType(selectedType, t);
  const financialDataMapped =
    chartData && chartData.length > 0
      ? formatFinancialDataWithEmptyTypes(
          mapFinancialData(chartData, t),
          infoData?.types || [],
        )
      : someEarningsDummyData(t);

  return (
    <FinancialFollowup
      withSelect={role !== USER_ROLES.PMO_ADMIN}
      selectItems={producerDropdownItems(t)}
      setSelectValue={setSelectedType}
      selectValue={selectedType}
      infoItems={getFinancialChartInfoDataByType(selectedType, t)?.infoItems}
      infoData={getFinancialDataTotalByType(chartData, selectedType)}
      legendItems={
        getFinancialChartInfoDataByType(selectedType, t)?.legendItems
      }
      data={financialDataMapped}
      hasData={chartData?.length > 0}
    />
  );
};

export default FinancialFollowUpChartWrapper;
