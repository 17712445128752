import { CreateCommunityPayload } from 'services/types';

export const initialValues: CreateCommunityPayload = {
  name: '',
  address: {
    city: '',
    country: 'France',
    postalCode: '',
    location: {
      coordinates: [null, null],
    },
  },
  pmoOrgName: '',
  sirenNumber: null,
  pmo: {
    firstName: '',
    lastName: '',
    middleName: '',
    email: '',
    contactNumber: null,
    address: {
      city: '',
      country: 'France',
      postalCode: '',
      street: '',
      building: '',
    },
  },
  enedisAgreed: false,
  supportAgreed: false,
  maxConsumers: null,
  maxProducers: null,
  maxDistance: null,
  maxPower: null,
  agreementId: '',
};
