import { getAppRole } from 'utils';

import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Dropdown, MenuProps } from 'antd';

import PlusOutlines from 'components/communities/Communities/img/PlusOutlined.svg';
import DropdownArrowIcon from 'components/members/createConsumer/img/dropDownArrow.svg';
import Image from 'components/shared/Image';
import { USER_ROLES } from 'constants/global';
import AppRoutes from 'constants/routes';

import MembersTable from './MembersTable';
import {
  StyledDropdownArrow,
  StyledDropdownButton,
  StyledHeader,
  StyledHeaderWrapper,
  StyledMemberContainer,
  StyledContainer,
} from './styles';

const Members: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [dropDownVisible, setDropDownVisible] = useState(false);
  const role = getAppRole();
  const handleVisibleChange = (flag: boolean) => {
    setDropDownVisible(flag);
  };

  const onClick: MenuProps['onClick'] = ({ key }) => {
    navigate(key);
  };

  const items: MenuProps['items'] = [
    { label: t('consumer'), key: AppRoutes.AddNewConsumer },
    { label: t('producer'), key: AppRoutes.AddNewProducer },
  ];

  return (
    <StyledContainer offset={32}>
      <StyledMemberContainer direction="vertical">
        <StyledHeaderWrapper size={50}>
          <StyledHeader>{t('members')}</StyledHeader>
          {role !== USER_ROLES.OYO_ADMIN ? (
            <>
              <Dropdown
                open={dropDownVisible}
                onOpenChange={handleVisibleChange}
                trigger={['click']}
                menu={{ items, onClick }}
                placement="bottom"
                overlayStyle={{ width: '223px' }}
              >
                <StyledDropdownButton>
                  <Image src={PlusOutlines} alt="PlusIcon" margin="0 5px 0 0" />
                  {t('add_new_member')}
                  <StyledDropdownArrow
                    src={DropdownArrowIcon}
                    isVisible={dropDownVisible}
                    alt="DropwdownArrowIcon"
                    margin="0 5px 0 0"
                  />
                </StyledDropdownButton>
              </Dropdown>
            </>
          ) : (
            <></>
          )}
        </StyledHeaderWrapper>
        <MembersTable />
      </StyledMemberContainer>
    </StyledContainer>
  );
};

export default Members;
