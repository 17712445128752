import { DateTime } from 'luxon';

export enum PriceVariant {
  CURRENT = 'CURRENT',
  UPCOMMING = 'UPCOMMING',
  HISTORICAL = 'HISTORICAL',
}

export enum PriceType {
  TURPE = 'TURPE',
  ACCISE = 'ACCISE',
  DISCOUNTED_ACCISE = 'DISCOUNTED_ACCISE',
  PRIME = 'PRIME',
  INDEX = 'INDEX',
}

export type IDateRange = {
  start: DateTime;
  end: DateTime;
};
