import { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { Input } from 'antd';

import { StyledSearchIcon, StyledSearchWrapper } from './styles';

interface ConsumerSearchProps {
  setSearchValue: Dispatch<SetStateAction<string>>;
}

const ConsumerSearch: FC<ConsumerSearchProps> = ({ setSearchValue }) => {
  const { t } = useTranslation();

  return (
    <StyledSearchWrapper>
      <Input
        onChange={(e) => setSearchValue(e.target.value)}
        placeholder={t('search_or_start_typing_consumer_name').toString()}
        prefix={<StyledSearchIcon />}
        disabled={false}
      />
    </StyledSearchWrapper>
  );
};

export default ConsumerSearch;
