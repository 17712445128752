import styled from 'styled-components';

import { Select } from 'antd';

export const StyledPowerOfProductionWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 16px;
  flex: 13;
`;

export const StyledInputWrapper = styled.div`
  flex: 10;
`;

export const StyledDropDownWrapper = styled.div`
  flex: 3;
  height: 40px;
`;

export const StyledSelect = styled(Select)`
  width: 100%;
  height: 100%;
  & .ant-select-selector {
    height: 40px !important;
  }
  & .ant-select-selection-item {
    line-height: 40px !important;
  }
`;
