import { ReactNode } from 'react';

import {
  TableTitle,
  TableTitleText,
} from 'components/pricing/components/TaxPrices/styles';
import { PriceVariant } from 'components/pricing/components/TaxPrices/types';

import TableHeader from '../TableHeader';
import { Table } from '../styles';

type IDiscountedAcciseTable = {
  hasEdit?: boolean;
  priceVariant: PriceVariant;
  headerText: string;
  children: ReactNode;
};

const DiscountedAcciseTable = ({
  hasEdit,
  priceVariant,
  headerText,
  children,
}: IDiscountedAcciseTable) => (
  <>
    <TableTitle type={priceVariant}>
      <TableTitleText>{headerText}</TableTitleText>
    </TableTitle>
    <Table>
      <TableHeader hasEdit={hasEdit} priceVariant={priceVariant} />
      {children}
    </Table>
  </>
);

export default DiscountedAcciseTable;
