import { FC } from 'react';

import ChartInfo from 'components/communities/Charts/ChartInfo';
import { formatNumberWithSpace } from 'components/communities/Charts/ElectricityConsumption/utils';
import CostEarnedSvg from 'components/communities/Charts/img/CostSaving.svg';
import { SkeletonLine } from 'components/shared/Skeleton';
import {
  InfoBlock,
  InfoCostEarnedImage,
  InfoCostEarnedRow,
  InfoItemAmount,
  InfoItemEuro,
  StyledInfoItemsBlock,
} from './styles';

interface CostEarnedInfoProps {
  data: number[];
  infoItems: any;
  totalCostEarned?: number;
  isLoading?: boolean;
}

const CostEarnedInfo: FC<CostEarnedInfoProps> = ({
  data,
  infoItems,
  isLoading,
}) => {
  const totalCostEarned = data?.some((item) => item)
    ? (data?.[0] + data?.[1]).toFixed(2)
    : 'N/A';

  return (
    <InfoBlock>
      <InfoCostEarnedRow>
        <InfoCostEarnedImage src={CostEarnedSvg} />
        {isLoading ? (
          <SkeletonLine active width={60} height={30} />
        ) : (
          <InfoItemAmount>
            {formatNumberWithSpace(totalCostEarned)}
            <InfoItemEuro> €</InfoItemEuro>
          </InfoItemAmount>
        )}
      </InfoCostEarnedRow>
      <StyledInfoItemsBlock>
        <ChartInfo
          isLoading={isLoading}
          data={data}
          infoItems={infoItems}
          rowWidth="100%"
        />
      </StyledInfoItemsBlock>
    </InfoBlock>
  );
};

export default CostEarnedInfo;
