import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useFormikContext } from 'formik';

import { Tag } from 'antd';

import { TFunction } from 'i18next';

import { BtnPrimary, BtnSecondary } from 'components/shared/Button';
import FormikInput from 'components/shared/Input/FormikInput';
import { InputGroup } from 'components/shared/InputGroup';
import { TextRegular } from 'components/shared/Text';
import CustomTooltip from 'components/shared/Tooltip';
import { COLORS } from 'constants/colors';
import { useUpdateUserPrmMutation } from 'services/prmManagement';
import { USER_STATUSES } from 'services/types';

import { StatusCardPendingMessage } from '../../../../../Profile/components/Card/styles';
import { EditIcon } from '../../../ProfileDataTab/styles';
import { initialValues } from '../../constants';
import { PrmType } from '../PrmBody/types';
import {
  StyledDisabledBtn,
  StyledDisabledBtnLabel,
  StyledDisabledBtnWrapper,
  StyledGivenNameWrapper,
  StyledPrmButtonsRow,
  StyledPrmCancelButton,
  StyledPrmEditButton,
  StyledPrmHeader,
  StyledPrmRow,
  StyledPrmSaveButton,
  StyledPrmTitle,
  StyledPrmWrapper,
} from './styles';

interface PrmNumberProps {
  index: number;
}

export const getPrmStatusLayout = (type: string, t: TFunction) => {
  switch (type) {
    case USER_STATUSES.ACTIVE:
      return (
        <>
          <Tag color="success">{t('active')}</Tag>
        </>
      );
    case USER_STATUSES.PENDING:
      return (
        <>
          <Tag color="warning">{t('pending')}</Tag>
          <CustomTooltip
            tooltip={<TextRegular>{t('awaiting_enedis_registry')}</TextRegular>}
          />
          <StatusCardPendingMessage>
            {t('awaiting_enedis_registry')}
          </StatusCardPendingMessage>
        </>
      );
    case USER_STATUSES.CONFLICT:
      return (
        <>
          <Tag color="error">{t('in_conflict')}</Tag>
          <CustomTooltip
            tooltip={<TextRegular>{t('invalid_prm_number')}</TextRegular>}
          />
          <StatusCardPendingMessage>
            {t('invalid_prm_number')}
          </StatusCardPendingMessage>
        </>
      );
  }
};

const PrmNumber: FC<PrmNumberProps> = ({ index }) => {
  const { t } = useTranslation();
  const [updateUserPrm, { isError, isSuccess }] = useUpdateUserPrmMutation();
  const { values, setFieldValue } = useFormikContext<typeof initialValues>();
  const [initialPrmValues, setInitialPrmValues] = useState<PrmType | null>(
    null,
  );
  const [editablePrmIndex, setEditablePrmIndex] = useState<null | number>(null);
  const [isEdited, setIsEdited] = useState(false);

  useEffect(() => {
    if (!values.prms[index].id) return;

    if (!initialPrmValues?.id) {
      // set initial prm values when got them for the first time
      setInitialPrmValues(values.prms[index]);
      return;
    } else {
      setIsEdited(
        JSON.stringify(values.prms[index]) !== JSON.stringify(initialPrmValues),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.prms[index]]);

  useEffect(() => {
    if (isSuccess) {
      setInitialPrmValues(values.prms[index]);
      setEditablePrmIndex(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isError]);

  const onEditPrmNumber = () => {
    setEditablePrmIndex(index);
  };

  const onCancelEdit = () => {
    setFieldValue(`prms.${index}`, initialPrmValues);
    setEditablePrmIndex(null);
  };

  const onConfirmEdit = async () => {
    const prms: any = [...values.prms];

    await updateUserPrm(prms[index]);
  };

  return (
    <StyledPrmWrapper
      pointerEvents={editablePrmIndex === index ? 'visible' : 'none'}
    >
      <StyledPrmHeader>
        <StyledPrmTitle>{index + 1} PRM</StyledPrmTitle>
        <StyledPrmEditButton onClick={onEditPrmNumber}>
          <EditIcon /> {t('edit')}
        </StyledPrmEditButton>
      </StyledPrmHeader>
      <StyledPrmRow>
        <StyledDisabledBtnWrapper>
          <StyledDisabledBtnLabel>{t('prm_number')}</StyledDisabledBtnLabel>
          <StyledDisabledBtn>
            {values.prms[index].prmValue}
            {getPrmStatusLayout(values.prms[index].status, t)}
          </StyledDisabledBtn>
        </StyledDisabledBtnWrapper>
        <StyledGivenNameWrapper>
          <InputGroup
            error={undefined}
            label={`${t('given_name')} (${t('optional')})`}
          >
            <FormikInput
              fieldName={`prms.${index}.name`}
              placeholder="Ex. Marc Department"
              type="text"
            />
          </InputGroup>
        </StyledGivenNameWrapper>
      </StyledPrmRow>
      {editablePrmIndex === index && (
        <StyledPrmButtonsRow>
          <StyledPrmCancelButton>
            <BtnSecondary color={COLORS.TextBase} onClick={onCancelEdit}>
              {t('cancel')}
            </BtnSecondary>
          </StyledPrmCancelButton>
          <StyledPrmSaveButton>
            <BtnPrimary isDisabled={!isEdited} onClick={onConfirmEdit}>
              {t('save_changes')}
            </BtnPrimary>
          </StyledPrmSaveButton>
        </StyledPrmButtonsRow>
      )}
    </StyledPrmWrapper>
  );
};

export default PrmNumber;
