import { styled } from 'styled-components';

import { COLORS } from 'constants/colors';

export const WidgetTitle = styled.span`
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
`;

export const WidgetAmount = styled.div`
  text-align: right;
  color: rgba(0, 0, 0, 0.88);
  font-size: 20px;
  font-weight: 600;
  line-height: 38px;
`;

export const WidgetCostAmount = styled(WidgetAmount)`
  font-size: 30px;
`;

export const WidgetHeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const WidgetSubtitle = styled.div<{ fontSize: string }>`
  text-align: right;
  color: ${COLORS.TextSecondary};
  font-size: ${(props) => props.fontSize};
  font-weight: 600;
  line-height: 24px;
  min-height: 24px;
  text-wrap: none;
`;

export const StyledGreenSubtitle = styled.span`
  color: ${COLORS.Green8};
  cursor: pointer;
  text-wrap: wrap;
`;

export const WidgetRow = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
`;

export const WidgetBlock = styled.div``;

export const WidgetSkeletonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  row-gap: 4px;
`;
