import { getFormattedUserName } from 'utils';

import { SorterTypes } from 'components/pricing/types';
import { CONSUMER_TYPE, IDBUser } from 'constants/types';

export const searchConsumerByName = (
  consumers: IDBUser[],
  searchValue: string,
) => {
  return consumers.filter((consumer: any) =>
    getFormattedUserName(consumer)
      .toLowerCase()
      .includes(searchValue.toLowerCase()),
  );
};

export const sortConsumersByType = (
  consumers: IDBUser[],
  type: SorterTypes,
) => {
  if (!consumers) return [];
  const orderByConsumerType = [
    CONSUMER_TYPE.LARGE,
    CONSUMER_TYPE.MEDIUM,
    CONSUMER_TYPE.SMALL,
    CONSUMER_TYPE.PRIVATE,
  ];

  switch (type) {
    case SorterTypes.NUMBER_OF_PRMS:
      return consumers.sort(
        (a, b) =>
          (a?.additionalInfo.prms as string[])?.length -
          (b?.additionalInfo.prms as string[])?.length,
      );
    case SorterTypes.CONSUMER_NAME:
      return consumers.sort((a, b) =>
        getFormattedUserName(a).localeCompare(getFormattedUserName(b)),
      );
    case SorterTypes.CONSUMER_TYPE:
      return consumers.sort((a, b) => {
        let comparison =
          orderByConsumerType.indexOf(a?.additionalInfo.type as CONSUMER_TYPE) -
          orderByConsumerType.indexOf(b?.additionalInfo.type as CONSUMER_TYPE);
        if (comparison === 0) {
          // same type, sort by name
          comparison = getFormattedUserName(a).localeCompare(
            getFormattedUserName(b),
          );
        }
        return comparison;
      });
    case SorterTypes.CONTRACT_END_DATE:
      // Assuming 'contractEndDate' is the key to be sorted by.
      return consumers;
  }
};
