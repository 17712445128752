import type { TFunction } from 'i18next';

import { COLORS } from 'constants/colors';

export const legendItems = (t: TFunction) => [
  {
    value: 'TOTAL_CONSUMPTION',
    name: t('total_consumption'),
    marker: { symbol: 'square', style: { fill: COLORS.Yellow5 } },
  },
  {
    value: 'GRID_CONSUMPTION',
    name: t('grid_consumption'),
    marker: { symbol: 'square', style: { fill: COLORS.Orange5 } },
  },
  {
    value: 'SELF-CONSUMPTION',
    name: t('self_consumption'),
    marker: { symbol: 'square', style: { fill: COLORS.Green6 } },
  },
];

export const infoItems = (t: TFunction) => [
  {
    title: t('total_consumption').toString(),
    color: COLORS.Yellow5,
  },
  {
    title: t('self_consumption').toString(),
    color: COLORS.Green6,
  },
  {
    title: t('grid_consumption').toString(),
    color: COLORS.Orange5,
  },
];

export const lineColors = [COLORS.Yellow5, COLORS.Green6, COLORS.Orange5];

export const areaColors = ['#FEF08A70', '#BBF7D050', '#FED7AA40'];
