import styled from 'styled-components';

import { COLORS } from 'constants/colors';

export const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 64px repeat(5, 1fr);
  border-bottom: 1px solid ${COLORS.ColorSplit};
`;

export const Header = styled.div`
  grid-column: 1 / span 1;
  grid-row: 1 / span 3;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  padding-left: 8px;
`;

export const Cell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;

  &:not(:nth-last-child(-n + 6)) {
    border-bottom: 1px solid ${COLORS.ColorSplit};
  }
`;

export const CellWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
