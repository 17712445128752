import ConsumerFinancialData from 'components/communities/Dashboard/components/ConsumerDashboard/components/FinancialDataTab';
import ProducerFinancialData from 'components/communities/Dashboard/components/ProducerDashboard/components/FinancialDataTab';
import { USER_ROLES } from 'constants/types';

const FinancialDataTab = ({ role }: { role: string | null }) => {
  return role === USER_ROLES.PRODUCER ? (
    <ProducerFinancialData />
  ) : (
    <ConsumerFinancialData />
  );
};

export default FinancialDataTab;
