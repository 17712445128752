import { FC, useState } from 'react';

import {
  CardDeliveredBody,
  CardHeader,
  CardMedium,
  ChartTitle,
} from 'components/communities/styles';
import { EnergyTypes } from 'constants/energy-types';

import PieChart from './components/PieChart';
import SelfConsumptionInfo from './components/SelfConsumptionInfo';
import { ChartDataType, InfoItemsType } from './components/types';

interface SelfConsumptionProps {
  title: string;
  chartData?: ChartDataType[];
  infoItems: InfoItemsType;
  getPieColor: (type: string) => void;
  isLoading?: boolean;
}

const SelfConsumption: FC<SelfConsumptionProps> = ({
  title,
  chartData,
  infoItems,
  getPieColor,
  isLoading,
}) => {
  const [hoveredType, setHoveredType] = useState(EnergyTypes.NONE);
  const handleOnHover = ({ type, isEmpty }: any) => {
    if (isEmpty) return;
    setHoveredType(type);
  };

  const selfConsumptionInfoData = chartData?.map((item) => {
    return {
      ...item,
      value: item?.isEmpty ? 'N/A' : item.value,
    };
  });

  return (
    <CardMedium height={367}>
      <CardHeader>
        <ChartTitle>{title}</ChartTitle>
      </CardHeader>
      <CardDeliveredBody verticalDirection="center">
        <PieChart
          getPieColor={getPieColor}
          data={chartData}
          handleOnHover={handleOnHover}
          handleOnBlur={() => setHoveredType(EnergyTypes.NONE)}
          isLoading={isLoading}
        />
        <SelfConsumptionInfo
          hoveredType={hoveredType}
          infoItems={infoItems}
          data={selfConsumptionInfoData}
          isLoading={isLoading}
        />
      </CardDeliveredBody>
    </CardMedium>
  );
};

export default SelfConsumption;
