import qs from 'qs';

import { createApi } from '@reduxjs/toolkit/query/react';

import { API_URLS, REQUEST_METHOD } from 'constants/global';

import { baseQuery } from './baseQuery';
import { DateRangeWithCommunityId } from './types';

export const pmoDashboard = createApi({
  reducerPath: 'pmoDashboard',
  baseQuery,
  endpoints: (builder) => ({
    getCommunitySelfProductionRatio: builder.query<
      any,
      DateRangeWithCommunityId
    >({
      query: (params) => ({
        url: `${API_URLS.GET_COMMUNITY_SELF_PRODUCTION_RATIO}?${qs.stringify(
          params,
        )}`,
        method: REQUEST_METHOD.GET,
      }),
    }),
    getCommunitySelfConsumptionRatio: builder.query<
      any,
      DateRangeWithCommunityId
    >({
      query: (params) => ({
        url: `${API_URLS.GET_COMMUNITY_SELF_CONSUMPTION_RATIO}?${qs.stringify(
          params,
        )}`,
        method: REQUEST_METHOD.GET,
      }),
    }),
    getCommunityElectricityTotal: builder.query<any, DateRangeWithCommunityId>({
      query: (params) => ({
        url: `${API_URLS.GET_COMMUNITY_ELECTRICITY_TOTAL}?${qs.stringify(
          params,
        )}`,
        method: REQUEST_METHOD.GET,
      }),
    }),
  }),
  tagTypes: ['pmoDashboard'],
});

export const {
  useGetCommunitySelfProductionRatioQuery,
  useGetCommunitySelfConsumptionRatioQuery,
  useGetCommunityElectricityTotalQuery,
} = pmoDashboard;
