import styled from 'styled-components';

import { COLORS } from 'constants/colors';

export const Card = styled.div<{ isEmpty?: boolean; fullWidth?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  max-width: 1376px;
  min-height: 316px;
  background-color: ${({ isEmpty }) =>
    isEmpty ? COLORS.Grey2 : COLORS.BgContainer};

  border-radius: 6px;
  border: ${({ isEmpty }) =>
    isEmpty ? 'none' : '1px solid rgba(0, 0, 0, 0.06)'};

  overflow-wrap: break-word;
  overflow: hidden;
  cursor: pointer;
  margin-bottom: 48px;
  ${({ fullWidth }) => (fullWidth ? 'width: 100%;' : '')}
`;

export const StyledTabs = styled.div`
  box-sizing: border-box;
  background-color: ${COLORS.BgLayout};
`;
