import styled from 'styled-components';

import { Form } from 'formik';
import { ScreenLarge } from 'constants/screen-breakpoints';
import { TextBold } from 'components/shared/Text';
import { COLORS } from 'constants/colors';

export const FormWrapper = styled(Form)`
  width: 100%;
`;

export const FormContainer = styled.div<{ childCount: number }>`
  display: grid;
  grid-template-columns: repeat(${({ childCount }) => childCount}, 1fr);
  gap: 24px;
  ${ScreenLarge`
    grid-template-columns: repeat(2, 49%);
  `}
`;

export const BlockContainer = styled.div`
  padding: 12px 16px;
  background: ${COLORS.BgLayout};
  box-sizing: border-box;
`;

export const BlockFieldContainer = styled.div`
  margin-bottom: 8px;
`;

export const TitleWrapper = styled.div`
  padding-bottom: 16px;
`;

export const Title = styled(TextBold)`
  font-size: 16px;
  line-height: 24px;
`;

export const BtnContainer = styled.section`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 24px 0;
  gap: 8px;
`;
