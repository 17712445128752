import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  CardHeader,
  CardMedium,
  ChartTitle,
} from 'components/communities/styles';
import { useGetCommunityDataQuery } from 'services/dashboardManagment';

import { useAppSelector } from 'utils/hooks';
import { getDateRange } from 'utils/url/getDateRange';

import { emptyCommunityData } from '../../dummyData/CommunityData';
import CommunityDataChart from './components/CommunityDataChart';
import CommunityDataChartInfo from './components/CommunityDataChartInfo';
import CommunityDataInfo from './components/CommunityDataInfo';
import { chartInfoLabels } from './constants';
import { CardBody, CommunityDataRow } from './styles';

const CHART_KEYS = [
  'gridConsumption',
  'totalProduction',
  'selfConsumption',
  'overproduction',
];

const ProducerCommunityData = ({ userId }: any) => {
  const { t } = useTranslation();
  const period = useAppSelector((state) => state.dashboardPeriod.period);
  const { data: communityData, isLoading: isLoadingCommunityData } =
    useGetCommunityDataQuery({
      ...getDateRange(period),
      userId,
    });

  const hasCommunityData =
    communityData && CHART_KEYS.every((key) => Boolean(communityData[key]));

  const chartData = useMemo(() => {
    if (hasCommunityData) {
      return Object.keys(communityData)
        .filter((item) => CHART_KEYS.includes(item))
        .map((item, index) => ({
          date: index,
          value: communityData[item],
          type: item,
        }));
    }
    return emptyCommunityData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityData]);

  return (
    <>
      <CardMedium height={366}>
        <CardHeader>
          <ChartTitle>{`${t('community_data')} ${
            communityData?.communityName || ''
          } `}</ChartTitle>
        </CardHeader>
        <CardBody>
          <CommunityDataInfo
            isLoading={isLoadingCommunityData}
            data={communityData}
          />
          <CommunityDataRow>
            <CommunityDataChartInfo
              selfConsumption={communityData?.selfConsumption}
              gridConsumption={communityData?.gridConsumption}
              overProduction={communityData?.overproduction}
              totalProduction={communityData?.totalProduction}
              labels={chartInfoLabels}
              isLoading={isLoadingCommunityData}
            />
            <CommunityDataChart
              hasData={hasCommunityData}
              chartData={chartData}
              isLoading={isLoadingCommunityData}
            />
          </CommunityDataRow>
        </CardBody>
      </CardMedium>
    </>
  );
};

export default ProducerCommunityData;
