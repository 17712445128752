export enum DATE_PICKER_STATE {
  LAST_MONTH = 'last_month',
  LAST_THREE_MONTH = 'last_three_month',
  LAST_SIX_MONTH = 'last_six_month',
  CURRENT_YEAR = 'current_year',
  LAST_YEAR = 'last_year',
  CUSTOM = 'custom',
}

export type DatePickerType = {
  start: Date | null | string;
  end: Date | null | string;
  label?: string;
};
