import ProductionFollowUpTable from 'components/communities/components/ProductionFollowUpTable';
import { useGetCommunityQuery } from 'services/communityManagement';
import { useGetProductionEnergyQuery } from 'services/reportManagment';

const ProductionFollowUpWrapper = () => {
  const { data: community } = useGetCommunityQuery();
  const {
    data: tableData,
    isSuccess,
    isLoading,
  } = useGetProductionEnergyQuery({
    userId: community?.pmoId,
  });
  return (
    <ProductionFollowUpTable
      tableData={tableData}
      isSuccess={isSuccess}
      isLoading={isLoading}
    />
  );
};

export default ProductionFollowUpWrapper;
