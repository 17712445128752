import styled from 'styled-components';

import { COLORS } from 'constants/colors';

export const Table = styled.div`
  display: grid;
  grid-template-rows: 68px;
  background: ${COLORS.BgContainer};
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 463px 1fr;
  border-bottom: 1px solid ${COLORS.ColorSplit};
`;

export const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  padding-left: 8px;
`;

export const Cell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 8px;
`;
