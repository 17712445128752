import React, { ReactNode, useEffect, useState } from 'react';

import styled from 'styled-components';

const Wrapper = styled.div<{ hidden: boolean }>`
  transition: transform 0.3s ease;
  background: transparent;
  transform: ${({ hidden }) => (hidden ? 'translateY(-100%)' : '')};
`;

interface IHideOnScroll {
  children: ReactNode;
}

const SCROLL_DOWN_AMOUNT = 50;

const HideOnScroll = ({ children }: IHideOnScroll) => {
  const [isHidden, setIsHidden] = useState(false);

  const handleScroll = () => {
    // Set 'isHidden' to true when scrolling down past 50px
    setIsHidden(window.scrollY > SCROLL_DOWN_AMOUNT);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return <Wrapper hidden={isHidden}>{children}</Wrapper>;
};

export default HideOnScroll;
