import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import {
  Container,
  HeaderContainer,
  HeaderRow,
  HeaderRowItem,
  PageHeader,
  Wrapper,
} from 'components/communities/styles';
import PeriodPicker from 'components/shared/PeriodPicker';
import TabsComponent from 'components/shared/Tabs';
import { Header } from 'components/shared/Text';
import { COLORS } from 'constants/colors';
import { CHART_TYPE } from 'constants/global';
import AppRoutes from 'constants/routes';
import { CustomSelectItem } from 'constants/types';

import { capitalizeAllWords } from 'utils/index';

import ProducerCreateReport from '../../../FinancialData/components/CreateReport/ProducerCreateReport';
import FinancialFollowUpChartWrapper from './components/FinancialFollowUpChartWrapper';
import { StyledTabs } from './styles';
import { useTabsConfig } from './useTabsConfig';
import { getCsvCommunityDataReport, getCsvTaxRefundDataReport } from './utils';

const ProducerFinancialData = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const tabsConfig = useTabsConfig();
  const [reportDataTypeValue, setReportDataTypeValue] =
    useState<CustomSelectItem>({
      label: 'community_data',
      key: 'community_data',
    });

  const isHeaderVisible = !pathname.includes(AppRoutes.Members);

  return (
    <Wrapper paddingTop={isHeaderVisible ? '32px' : '0'}>
      {isHeaderVisible && (
        <PageHeader background={COLORS.BgLayout}>
          <HeaderContainer>
            <HeaderRow>
              <HeaderRowItem>
                <Header>{capitalizeAllWords(t('financial_data'))}</Header>
              </HeaderRowItem>
              <HeaderRowItem position="flex-end">
                <PeriodPicker chartType={CHART_TYPE.FINANCIAL_DATA} />
              </HeaderRowItem>
            </HeaderRow>
          </HeaderContainer>
        </PageHeader>
      )}
      <Container>
        <FinancialFollowUpChartWrapper />
        <ProducerCreateReport
          setDataTypeValue={setReportDataTypeValue}
          dataTypeValue={reportDataTypeValue}
          datePickerType={'month'}
          fetchCsvFile={
            reportDataTypeValue.key === 'community_data'
              ? getCsvCommunityDataReport
              : getCsvTaxRefundDataReport
          }
        />
        <HeaderRow>
          <HeaderRowItem>
            <Header>{capitalizeAllWords(t('invoice_history'))}</Header>
          </HeaderRowItem>
        </HeaderRow>
        <StyledTabs>
          <TabsComponent backgroundColor={COLORS.BgLayout} items={tabsConfig} />
        </StyledTabs>
      </Container>
    </Wrapper>
  );
};

export default ProducerFinancialData;
