import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  Container,
  ExclamationIcon,
} from 'components/auth/CreatePassword/components/styles';
import { BtnPrimary, BtnSecondary } from 'components/shared/Button';
import { MarginTop40px } from 'components/shared/Margin';
import { Header, TextRegular } from 'components/shared/Text';
import AppRoutes from 'constants/routes';

const ForgotPasswordUnsuccessful: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleOnNavigateToSignIn = () => navigate(AppRoutes.SignIn);
  const handleOnNavigateToForgotPassword = () =>
    navigate(AppRoutes.ForgotPassword);

  return (
    <Container>
      <ExclamationIcon />
      <Header>{t('password_reset_error')}</Header>
      <TextRegular>{t('try_again_reset_password')}</TextRegular>
      <MarginTop40px />
      <BtnPrimary onClick={handleOnNavigateToSignIn}>
        {t('back_to_login')}
      </BtnPrimary>
      <BtnSecondary onClick={handleOnNavigateToForgotPassword}>
        {t('back_to_forgot_password')}
      </BtnSecondary>
    </Container>
  );
};

export default ForgotPasswordUnsuccessful;
