import type { TFunction } from 'i18next';

import { COLORS } from 'constants/colors';
import {
  CONSUMER_INVOICE_STATUSES,
  PRODUCER_INVOICE_STATUSES,
} from 'constants/types';

export const selfConsumedInfoItemsByHours = (t: TFunction) => ({
  HS_HH: { title: t('high_season_high_hours'), color: COLORS.Yellow4 },
  HS_LH: { title: t('high_season_low_hours'), color: COLORS.Yellow5 },
  LS_HH: { title: t('low_season_high_hours'), color: COLORS.Gold5 },
  LS_LH: { title: t('low_season_low_hours'), color: COLORS.Gold6 },
  RH: { title: t('rush_hours'), color: COLORS.Gold7 },
});

export const selfConsumedInfoItemsByConsumerType = (t: TFunction) => ({
  private: { title: t('private_houses'), color: COLORS.Green3 },
  small: { title: t('small_companies'), color: COLORS.Green5 },
  medium: { title: t('medium_companies'), color: COLORS.Green6 },
  large: { title: t('large_companies'), color: COLORS.Green7 },
  collectivities: { title: t('collectivities'), color: COLORS.Green8 },
});

export const selfConsumedEmptyStateByHours = [
  {
    value: 0,
    type: 'HS_HH',
    isEmpty: true,
  },
  {
    value: 0,
    type: 'HS_LH',
    isEmpty: true,
  },
  {
    value: 0,
    type: 'LS_HH',
    isEmpty: true,
  },
  {
    value: 0,
    type: 'LS_LH',
    isEmpty: true,
  },
  {
    value: 0,
    type: 'RH',
    isEmpty: true,
  },
];

export const selfConsumedEmptyStateByConsumerType = [
  {
    value: 0,
    type: 'private',
    isEmpty: true,
  },
  {
    value: 0,
    type: 'small',
    isEmpty: true,
  },
  {
    value: 0,
    type: 'medium',
    isEmpty: true,
  },
  {
    value: 0,
    type: 'large',
    isEmpty: true,
  },
  {
    value: 0,
    type: 'collectivities',
    isEmpty: true,
  },
];
export const MAX_PAGINATION_PAGE_SIZE = 10;

export const consumerInvoiceStatusFilters = (t: TFunction) => [
  {
    text: t('paid'),
    value: CONSUMER_INVOICE_STATUSES.PAID,
  },
  {
    text: t('overdue'),
    value: CONSUMER_INVOICE_STATUSES.OVERDUE,
  },
  {
    text: t('payment_error'),
    value: CONSUMER_INVOICE_STATUSES.PAYMENT_ERROR,
  },
  {
    text: t('issued'),
    value: CONSUMER_INVOICE_STATUSES.ISSUED,
  },
  {
    text: t('awaiting_payment'),
    value: CONSUMER_INVOICE_STATUSES.AWAITING_PAYMENT,
  },
];

export const labelsByType: Record<string, string> = {
  [CONSUMER_INVOICE_STATUSES.PAID]: 'paid_amount',
  [CONSUMER_INVOICE_STATUSES.OVERDUE]: 'overdue_amount',
  [CONSUMER_INVOICE_STATUSES.AWAITING_PAYMENT]: 'expected_amount',
  [CONSUMER_INVOICE_STATUSES.PAYMENT_ERROR]: 'expected_amount',
  [CONSUMER_INVOICE_STATUSES.ISSUED]: 'expected_amount',
  [CONSUMER_INVOICE_STATUSES.PAYMENT_IN_PROGRESS]: 'expected_amount',
};

export const mapProducerInvoiceStatus: Record<string, string> = {
  [PRODUCER_INVOICE_STATUSES.PAID]: 'paid_amount',
  [PRODUCER_INVOICE_STATUSES.OVERDUE]: 'overdue_amount',
  [PRODUCER_INVOICE_STATUSES.ISSUED]: 'expected_amount',
  [PRODUCER_INVOICE_STATUSES.AWAITING_PAYMENT]: 'expected_amount',
  [PRODUCER_INVOICE_STATUSES.PAYMENT_ERROR]: 'expected_amount',
};

export const fullYearChartData = (t: TFunction) => [
  {
    date: t('january'),
  },
  {
    date: t('february'),
  },
  {
    date: t('march'),
  },
  {
    date: t('april'),
  },
  {
    date: t('may'),
  },
  {
    date: t('june'),
  },
  {
    date: t('july'),
  },
  {
    date: t('august'),
  },
  {
    date: t('september'),
  },
  {
    date: t('october'),
  },
  {
    date: t('november'),
  },
  {
    date: t('december'),
  },
];

export const fullMonthChartData = [
  {
    date: 1,
  },
  {
    date: 2,
  },
  {
    date: 3,
  },
  {
    date: 4,
  },
  {
    date: 5,
  },
  {
    date: 6,
  },
  {
    date: 7,
  },
  {
    date: 8,
  },
  {
    date: 9,
  },
  {
    date: 10,
  },
  {
    date: 11,
  },
  {
    date: 12,
  },

  {
    date: 13,
  },
  {
    date: 14,
  },
  {
    date: 15,
  },
  {
    date: 16,
  },
  {
    date: 17,
  },
  {
    date: 18,
  },
  {
    date: 19,
  },
  {
    date: 20,
  },
  {
    date: 21,
  },
  {
    date: 22,
  },
  {
    date: 23,
  },
  {
    date: 24,
  },
  {
    date: 25,
  },
  {
    date: 26,
  },
  {
    date: 27,
  },
  {
    date: 28,
  },
  {
    date: 29,
  },
  {
    date: 30,
  },
];

export const SEVEN_DAYS_TICKS = 8;
export const MONTH_TICKS = 31;
export const LAST_INDEX_OF_SEVEN_DAYS_PERIOD = 7;
