export enum EnergyTypes {
  NONE = 'NONE',
  SELF_ENERGY = 'SELF_ENERGY',
  GRID_ENERGY = 'GRID_ENERGY',
  SELF_CONSUMPTION = 'SELF_CONSUMPTION',
  SELF_CONSUMED = 'SELF_CONSUMED',
  COMMUNITY_SELF_CONSUMPTION = 'COMMUNITY_SELF_CONSUMPTION',
  MY_SELF_CONSUMPTION = 'MY_SELF_CONSUMPTION',
  GRID_CONSUMPTION = 'GRID_CONSUMPTION',
  TOTAL_PRODUCTION = 'TOTAL_PRODUCTION',
  OVERPRODUCTION = 'OVERPRODUCTION',
  SELF_CONSUMPTION_RATIO = 'SELF_CONSUMPTION_RATIO',
  MY_SELF_CONSUMPTION_RATIO = 'MY_SELF_CONSUMPTION_RATIO',
  GRID_CONSUMPTION_RATIO = 'GRID_CONSUMPTION_RATIO',
  RH = 'RH',
  HS_LH = 'HS_LH',
  HS_HH = 'HS_HH',
}

export const mapChartKeys = (type: string) => {
  switch (type) {
    case 'mySelfConsumptionRatio': {
      return EnergyTypes.MY_SELF_CONSUMPTION_RATIO;
    }

    case 'gridConsumptionRatio': {
      return EnergyTypes.GRID_CONSUMPTION_RATIO;
    }

    case 'selfConsumptionRatio': {
      return EnergyTypes.SELF_CONSUMPTION_RATIO;
    }

    case 'gridConsumption': {
      return EnergyTypes.GRID_CONSUMPTION;
    }

    case 'totalProduction': {
      return EnergyTypes.TOTAL_PRODUCTION;
    }

    case 'selfConsumption': {
      return EnergyTypes.SELF_CONSUMPTION;
    }

    case 'overproduction': {
      return EnergyTypes.OVERPRODUCTION;
    }

    default: {
      return EnergyTypes.NONE;
    }
  }
};
