import { getAppRole } from 'utils';

import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';

import DynamicEditForm from 'components/communities/components/DynamicEditForm';
import Loader from 'components/shared/Loader';
import ModalMessage from 'components/shared/ModalMessage';
import Popover from 'components/shared/Popover';
import { USER_ROLES } from 'constants/global';
import { useUpdateMemberMutation } from 'services/membersManagement';
import { IMember, USER_STATUSES } from 'services/types';
import { useLazyResendInviteEmailQuery } from 'services/userManagement';
import { editMemberSchema } from 'utils/validation/schemas';
import DetailCard from 'components/communities/Dashboard/components/Profile/components/Card';
import {
  EditIcon,
  StyledBlockBody,
  StyledBlockHeader,
  StyledBlockTitle,
  StyledBlockWrapper,
  StyledEditButton,
  StyledResendInvitationLink,
} from '../../styles';
import { legalRepresentativeLabels, producerLabels } from './constants';
import { useUserEditFormData } from './useUserEditFormData';

interface ProfileDataProps {
  member: IMember | undefined;
  refetchMember: () => void;
}

const ProfileData: FC<ProfileDataProps> = ({ member, refetchMember }) => {
  const { t } = useTranslation();
  const [isEditMode, setEditMode] = useState(false);
  const [userProfile, setUserProfile] = useState(member);
  const [modalOptions, setModalOptions] = useState<{
    title: string;
    subTitle?: string;
  } | null>(null);

  const [updateMember, { isLoading, data: memberData }] =
    useUpdateMemberMutation();

  const [resendInvitation, { isLoading: isLoadingResendInvitation }] =
    useLazyResendInviteEmailQuery();

  const authUserRole = getAppRole();
  const isAdminOrPMO = useMemo(
    () =>
      authUserRole === USER_ROLES.OYO_ADMIN || authUserRole === USER_ROLES.PMO,
    [getAppRole],
  );

  const isModalOpen = useMemo(() => !!modalOptions, [modalOptions]);

  const openInfoModal = useCallback((title: string, subTitle?: string) => {
    setModalOptions({ title, subTitle });
  }, []);

  const closeInfoModal = useCallback(() => {
    setModalOptions(null);
  }, []);

  const resendInvitationEmail = useCallback(async () => {
    await resendInvitation({ userId: member?._id as string });
    openInfoModal(t('invitation_email_sent'));
  }, [member]);

  const name = `${member?.firstName} ${member?.middleName} ${member?.lastName}`;
  const siretNumber = member?.additionalInfo.siretNumber;
  const location = member?.address.street;
  const legalPerson = member?.additionalInfo.legalPerson;
  const coordinates = member?.address.location.coordinates;
  const legalPersonName = `${legalPerson?.firstName} ${legalPerson?.middleName} ${legalPerson?.lastName}`;
  const personCoordinates = coordinates
    ? coordinates[0] + ', ' + coordinates[1]
    : null;

  const legalEmail = useMemo(() => {
    if (member?.status === USER_STATUSES.PENDING) {
      return (
        <Flex style={{ alignItems: 'baseline', flexWrap: 'wrap' }}>
          <span style={{ marginRight: '4px' }}>{legalPerson?.email}</span>
          <Popover
            children={<ClockCircleOutlined />}
            context={t('pending_invitation')}
          />
          <StyledResendInvitationLink
            type="link"
            onClick={resendInvitationEmail}
          >
            {t('resend_invite')}
          </StyledResendInvitationLink>
        </Flex>
      );
    } else {
      return legalPerson?.email;
    }
  }, [member, legalPerson, t]);

  const userEditFormData = useUserEditFormData({
    isAdminOrPMO,
    personCoordinates,
    name,
    siretNumber,
    location,
    legalPersonName,
    legalPerson,
  });

  const handleOnEdit = useCallback(() => {
    if (userProfile?.role === USER_ROLES.PRODUCER) {
      setUserProfile(member);
      setEditMode(true);
    }
  }, [userProfile, member]);

  const handleOnSave = async (values: IMember) => {
    setEditMode(false);
    const updatedCoordinates =
      values.address.location.coordinates &&
      values.address.location.coordinates.length > 2
        ? (values.address.location.coordinates as any)
            .split(',')
            .map((coord: string) => parseFloat(coord))
        : values.address.location.coordinates;
    const updatedValues = {
      ...values,
      address: {
        ...values.address,
        location: {
          ...values.address.location,
          coordinates: updatedCoordinates,
        },
      },
    };
    await updateMember(updatedValues);
    if (
      updatedValues.additionalInfo.legalPerson.email !==
      userProfile?.additionalInfo.legalPerson.email
    ) {
      openInfoModal(
        t('legal_person_email_updated'),
        t('invitation_email_was_sent', { email: legalPerson?.email }) as string,
      );
    }
    refetchMember();
  };

  useEffect(() => {
    setUserProfile(memberData);
  }, [memberData]);

  useEffect(() => {
    setUserProfile(member);
  }, [member]);

  return (
    <StyledBlockWrapper>
      {(isLoading || isLoadingResendInvitation) && <Loader />}
      {isModalOpen && (
        <ModalMessage
          messageType="mail"
          title={modalOptions?.title as string}
          subTitle={modalOptions?.subTitle as string}
          btnText={t('ok')}
          handleBtn={closeInfoModal}
        />
      )}
      <StyledBlockHeader>
        <StyledBlockTitle>{t('profile_data')}</StyledBlockTitle>
        <StyledEditButton
          size="small"
          type="text"
          disabled={isEditMode}
          onClick={handleOnEdit}
        >
          <EditIcon disabled={isEditMode} />
          {t('edit')}
        </StyledEditButton>
      </StyledBlockHeader>
      <StyledBlockBody count={isEditMode ? 1 : 2}>
        <DynamicEditForm
          isEditMode={isEditMode}
          formData={userEditFormData}
          initialValues={userProfile}
          validationSchema={editMemberSchema(t)}
          onClose={() => setEditMode(false)}
          onSave={handleOnSave}
        />
        <DetailCard
          isEditMode={isEditMode}
          labelWidth={'150px'}
          valueWidth={'calc(100% - 150px - 30px)'}
          title={t('producer_data').toString()}
          labels={producerLabels}
          values={{
            first: name,
            second: siretNumber,
            third: location,
            forth: personCoordinates,
          }}
        />
        <DetailCard
          isEditMode={isEditMode}
          labelWidth={'150px'}
          valueWidth={'calc(100% - 150px - 30px)'}
          title={t('legal_representative').toString()}
          labels={legalRepresentativeLabels}
          values={{
            first: legalPersonName,
            second: legalEmail,
            third: legalPerson?.position,
            forth: legalPerson?.contactNumber,
          }}
        />
      </StyledBlockBody>
    </StyledBlockWrapper>
  );
};

export default ProfileData;
