import React from 'react';

const Icon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="36"
      viewBox="0 0 34 36"
      fill="none"
    >
      <path
        d="M31.5182 31.1079H31.3713V6.70029C31.3713 5.50484 30.4003 4.53227 29.2068 4.53227H28.0664V2.96923C28.0664 1.332 26.7355 0 25.0996 0H8.01773C6.38184 0 5.05097 1.332 5.05097 2.96923V4.5322H3.9105C2.71589 4.5322 1.74403 5.50484 1.74403 6.70022V31.1078H1.59905C0.717328 31.1078 0 31.7469 0 32.5324V34.5753C7.03125e-05 35.3609 0.717398 36 1.59912 36H13.4051C13.7934 36 14.1082 35.6852 14.1082 35.2969C14.1082 34.9085 13.7934 34.5938 13.4051 34.5938H1.59912C1.5011 34.5938 1.43423 34.5589 1.40632 34.5362V32.5711C1.43227 32.55 1.4959 32.5141 1.59912 32.5141H31.5183C31.6163 32.5141 31.6832 32.5489 31.7111 32.5716V34.5362C31.6832 34.5589 31.6163 34.5938 31.5183 34.5938H19.7123C19.3239 34.5938 19.0091 34.9085 19.0091 35.2969C19.0091 35.6852 19.3239 36 19.7123 36H31.5182C32.3999 36 33.1173 35.3609 33.1173 34.5754V32.5325C33.1173 31.7469 32.3999 31.1079 31.5182 31.1079ZM14.6995 24.7949H18.4179V31.1079H14.6995V24.7949ZM19.824 27.6012H29.965V31.1079H19.824V27.6012ZM6.45722 2.96923C6.45722 2.10741 7.15725 1.40625 8.01773 1.40625H25.0996C25.9601 1.40625 26.6601 2.10741 26.6601 2.96923V4.5322H6.45722V2.96923ZM3.9105 5.93845H29.2068C29.6249 5.93845 29.965 6.28017 29.965 6.70022V26.1949H19.824V24.614C19.824 23.9384 19.2737 23.3887 18.5973 23.3887H14.5179C13.8426 23.3887 13.2932 23.9384 13.2932 24.614V26.1949H3.15035V6.70029C3.15035 6.28024 3.49137 5.93845 3.9105 5.93845ZM3.15035 27.6012H13.2933V31.1079H3.15035V27.6012Z"
        fill="black"
        fillOpacity="0.25"
      />
      <path
        d="M6.59619 13.8171H10.8169C11.2052 13.8171 11.52 13.5023 11.52 13.114V8.88867C11.52 8.50034 11.2052 8.18555 10.8169 8.18555H6.59619C6.20786 8.18555 5.89307 8.50034 5.89307 8.88867V13.114C5.89307 13.5023 6.20786 13.8171 6.59619 13.8171ZM7.29932 9.5918H10.1138V12.4108H7.29932V9.5918Z"
        fill="black"
        fillOpacity="0.25"
      />
      <path
        d="M14.4482 13.8171H18.6689C19.0572 13.8171 19.372 13.5023 19.372 13.114V8.88867C19.372 8.50034 19.0572 8.18555 18.6689 8.18555H14.4482C14.0599 8.18555 13.7451 8.50034 13.7451 8.88867V13.114C13.7451 13.5023 14.0599 13.8171 14.4482 13.8171ZM15.1514 9.5918H17.9658V12.4108H15.1514V9.5918Z"
        fill="black"
        fillOpacity="0.25"
      />
      <path
        d="M22.3003 13.8171H26.521C26.9093 13.8171 27.2241 13.5023 27.2241 13.114V8.88867C27.2241 8.50034 26.9093 8.18555 26.521 8.18555H22.3003C21.912 8.18555 21.5972 8.50034 21.5972 8.88867V13.114C21.5972 13.5023 21.912 13.8171 22.3003 13.8171ZM23.0034 9.5918H25.8179V12.4108H23.0034V9.5918Z"
        fill="black"
        fillOpacity="0.25"
      />
      <path
        d="M6.59619 21.2634H10.8169C11.2052 21.2634 11.52 20.9486 11.52 20.5603V16.335C11.52 15.9466 11.2052 15.6318 10.8169 15.6318H6.59619C6.20786 15.6318 5.89307 15.9466 5.89307 16.335V20.5603C5.89307 20.9486 6.20786 21.2634 6.59619 21.2634ZM7.29932 17.0381H10.1138V19.8571H7.29932V17.0381Z"
        fill="black"
        fillOpacity="0.25"
      />
      <path
        d="M14.4482 21.2634H18.6689C19.0572 21.2634 19.372 20.9486 19.372 20.5603V16.335C19.372 15.9466 19.0572 15.6318 18.6689 15.6318H14.4482C14.0599 15.6318 13.7451 15.9466 13.7451 16.335V20.5603C13.7451 20.9486 14.0599 21.2634 14.4482 21.2634ZM15.1514 17.0381H17.9658V19.8571H15.1514V17.0381Z"
        fill="black"
        fillOpacity="0.25"
      />
      <path
        d="M22.3003 21.2634H26.521C26.9093 21.2634 27.2241 20.9486 27.2241 20.5603V16.335C27.2241 15.9466 26.9093 15.6318 26.521 15.6318H22.3003C21.912 15.6318 21.5972 15.9466 21.5972 16.335V20.5603C21.5972 20.9486 21.912 21.2634 22.3003 21.2634ZM23.0034 17.0381H25.8179V19.8571H23.0034V17.0381Z"
        fill="black"
        fillOpacity="0.25"
      />
      <path
        d="M16.5586 34.5938C16.1703 34.5938 15.8555 34.9085 15.8555 35.2969C15.8555 35.6852 16.1703 36 16.5586 36H16.5626C16.9509 36 17.2638 35.6852 17.2638 35.2969C17.2638 34.9085 16.9469 34.5938 16.5586 34.5938Z"
        fill="black"
        fillOpacity="0.25"
      />
    </svg>
  );
};

export default Icon;
