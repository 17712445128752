import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Area, G2 } from '@ant-design/plots';

import {
  LAST_INDEX_OF_SEVEN_DAYS_PERIOD,
  MONTH_TICKS,
  SEVEN_DAYS_TICKS,
} from 'components/communities/constants';
import { formatChartDataByFullDate } from 'components/communities/utils';
import { COLORS } from 'constants/colors';
import { PERIOD } from 'constants/types';

import { useAppSelector } from 'utils/hooks';

import { emptyData } from '../../dummyData';
import { AreaChartDataType, LegendItemsType } from '../../types';
import CustomTooltip from '../AreaChartTooltip/index';
import { StyledLineChart } from './styles';
import { SkeletonAreaChartBig } from 'components/shared/Skeleton';
interface AreaChartProps {
  data: AreaChartDataType[] | undefined;
  legendItems: LegendItemsType[];
  lineColors: string[];
  areaColors: string[];
  isLoading?: boolean;
}

const AreaChart: FC<AreaChartProps> = ({
  data,
  legendItems,
  areaColors,
  lineColors,
  isLoading,
}) => {
  const { t } = useTranslation();
  const { registerTheme } = G2;
  const hasData = data && data.length > 0;
  const period = useAppSelector((state) => state.dashboardPeriod.period);

  useEffect(() => {
    registerTheme('electricity-consumption', {
      colors10: areaColors,
    });
  }, [data, areaColors, registerTheme]);

  const config: any = {
    data: hasData ? formatChartDataByFullDate({ data, period, t }) : emptyData,
    xField: 'date',
    yField: 'value',
    seriesField: 'category',
    line: hasData
      ? {
          color: lineColors,
          style: {
            lineWidth: 3,
          },
        }
      : { color: COLORS.Grey3 },
    xAxis: {
      tickCount:
        period === PERIOD.LAST_SEVEN_DAYS ? SEVEN_DAYS_TICKS : MONTH_TICKS,
      label: {
        formatter: (value: number, _: any, index: any) => {
          if (period === PERIOD.LAST_SEVEN_DAYS) {
            if (index === LAST_INDEX_OF_SEVEN_DAYS_PERIOD) return '';
            const splittedValue = value.toString().split(' ');
            //ex: ['17', 'WE', '0:00']
            const valueWithoutHours =
              `${splittedValue[0]}, ${splittedValue[1]}`.replaceAll(',', ' ');
            return valueWithoutHours;
          }

          return value;
        },
      },
    },
    yAxis: {
      label: {
        formatter: (value: number, _: any) => Math.abs(value),
      },
      title: {
        text: 'kW',
        style: {
          fontSize: 12,
          textAlign: 'center',
          fill: '#8C8C8C',
          rotate: -90,
        },
      },
    },
    areaStyle: () => {
      return {
        fill: !hasData && COLORS.BgLayout,
        fillOpacity: 1,
      };
    },
    legend: {
      position: 'bottom',
      offsetY: 8,
      offsetX: 20,
      items: legendItems,
    },
    tooltip: {
      customContent: (datum: string, tooltipData: any) =>
        hasData ? (
          <CustomTooltip period={period} data={tooltipData} />
        ) : undefined,
    },
    theme: 'electricity-consumption',
    isStack: false,
  };

  return (
    <StyledLineChart>
      <Area
        {...config}
        loading={isLoading}
        loadingTemplate={<SkeletonAreaChartBig />}
      />
    </StyledLineChart>
  );
};

export default React.memo(AreaChart);
