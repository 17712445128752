import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { Flex } from 'antd';
import { USER_STATUSES } from 'services/types';
import { HeaderContainer } from 'components/communities/styles';
import { HeaderRow, HeaderRowItem } from 'components/communities/styles';
import PeriodPicker from 'components/shared/PeriodPicker';
import { TextRegular } from 'components/shared/Text';
import { USER_ROLES } from 'constants/global';
import { useGetMemberByIdQuery } from 'services/pmoMemberDashboardManagment';
import { useGetPrmByUserIdQuery } from 'services/prmManagement';
import Popover from 'components/shared/Popover';
import DetailCard from './components/Card';
import StatusCard from './components/Card/StatusCard';
import PmoMemberTitle from './components/Title';
import HeaderWithSkeleton from './components/HeaderWithSkeleton';
import {
  consumerLabels,
  legalRepresentativeLabels,
  producerLabels,
} from './constants';
import { DetailCardsRow, SummaryDetailsBlock } from './styles';
import { SkeletonLine } from 'components/shared/Skeleton';

export const PmoMemberSummaryDetails = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { data: member, isLoading: isLoadingMember } =
    useGetMemberByIdQuery(id);
  const [searchParams] = useSearchParams();
  const { community } = Object.fromEntries(searchParams);

  const { memberName, legalPersonName } = useMemo(() => {
    return {
      memberName: `${member?.firstName} ${member?.middleName} ${member?.lastName}`,
      legalPersonName: `${member?.additionalInfo?.legalPerson?.firstName} ${member?.additionalInfo?.legalPerson?.middleName} ${member?.additionalInfo?.legalPerson?.lastName}`,
    };
  }, [member]);

  const { data: prmData = [] } = useGetPrmByUserIdQuery({
    userId: member?._id,
  });

  const deactivationDate = useMemo(() => {
    if ((member as any)?.deactivatedAt) {
      return dayjs((member as any)?.deactivatedAt)
        .add(3, 'month')
        .format('YYYY-MM-DD');
    }
    return '';
  }, [member]);

  const memberNameValue = useMemo(
    () =>
      isLoadingMember ? <SkeletonLine height={22} block active /> : memberName,
    [isLoadingMember, memberName],
  );
  const siretNumberValue = useMemo(
    () =>
      isLoadingMember ? (
        <SkeletonLine height={22} block active />
      ) : (
        member?.additionalInfo?.siretNumber
      ),
    [isLoadingMember, member],
  );
  const locationValue = useMemo(
    () =>
      isLoadingMember ? (
        <SkeletonLine height={44} block active />
      ) : (
        member?.address.street
      ),
    [isLoadingMember, member],
  );
  const legalPersonNameValue = useMemo(
    () =>
      isLoadingMember ? (
        <SkeletonLine height={22} block active />
      ) : (
        legalPersonName
      ),
    [isLoadingMember, legalPersonName],
  );
  const legalPersonEmailValue = useMemo(
    () =>
      isLoadingMember ? (
        <SkeletonLine height={22} block active />
      ) : (
        member?.additionalInfo?.legalPerson?.email
      ),
    [isLoadingMember, member],
  );
  const legalPersonPositionValue = useMemo(
    () =>
      isLoadingMember ? (
        <SkeletonLine height={22} block active />
      ) : (
        member?.additionalInfo?.legalPerson?.position
      ),
    [isLoadingMember, member],
  );
  const legalPersonContactNumberValue = useMemo(
    () =>
      isLoadingMember ? (
        <SkeletonLine height={22} block active />
      ) : (
        member?.additionalInfo?.legalPerson?.contactNumber
      ),
    [isLoadingMember, member],
  );

  return (
    <>
      <SummaryDetailsBlock>
        <HeaderContainer>
          <HeaderRow>
            <HeaderRowItem>
              <HeaderWithSkeleton
                memberName={memberName}
                isLoading={isLoadingMember}
              />
              {member?.status === USER_STATUSES.DEACTIVATED && (
                <Flex style={{ alignItems: 'center' }}>
                  <span style={{ fontSize: '18', marginLeft: '4px' }}>
                    {' '}
                    ({t('deactivated')})
                  </span>
                  <Popover
                    placement="topRight"
                    overlayStyles={{ zIndex: 999999, width: '350px' }}
                    context={t('consumer_deactivated_profile', {
                      deactivationDate,
                    })}
                  />
                </Flex>
              )}
            </HeaderRowItem>
            <HeaderRowItem position="flex-end">
              <TextRegular>{t('show_data_for')}</TextRegular>
              <PeriodPicker />
            </HeaderRowItem>
          </HeaderRow>
          <PmoMemberTitle
            location={member?.address.city}
            type={member?.additionalInfo?.type || member?.role}
            power={member?.additionalInfo?.power}
            role={member?.role}
            community={community}
            prmNumbers={member?.additionalInfo?.prms?.length}
            isLoading={isLoadingMember}
          />
          <DetailCardsRow>
            <DetailCard
              containerWidth="calc((100% - 48px) / 3)"
              labelWidth={'30%'}
              valueWidth={'calc(100% - 30% - 30px)'}
              labels={
                member?.role === USER_ROLES.PRODUCER
                  ? producerLabels(t)
                  : consumerLabels(t)
              }
              title={
                member?.role === USER_ROLES.CONSUMER
                  ? t('consumer_data').toString()
                  : t('producer_data').toString()
              }
              firstValue={memberNameValue}
              secondValue={siretNumberValue}
              thirdValue={locationValue}
            />
            <DetailCard
              containerWidth="calc((100% - 48px) / 3)"
              labelWidth={'50%'}
              valueWidth={'calc(100% - 50% - 30px)'}
              labels={legalRepresentativeLabels(t)}
              title={t('legal_representative').toString()}
              firstValue={legalPersonNameValue}
              secondValue={legalPersonEmailValue}
              thirdValue={legalPersonPositionValue}
              fourthValue={legalPersonContactNumberValue}
            />
            <StatusCard
              role={member?.role}
              title={t('actions_status').toString()}
              prms={prmData}
              containerWidth="calc((100% - 48px) / 3)"
            />
          </DetailCardsRow>
        </HeaderContainer>
      </SummaryDetailsBlock>
    </>
  );
};

export default PmoMemberSummaryDetails;
