import type { TFunction } from 'i18next';

import { COLORS } from 'constants/colors';
import { CONSUMER_INVOICE_STATUSES, PERIOD } from 'constants/types';

import { fullMonthChartData, fullYearChartData } from './constants';

export const getPieColorByHours = (type: string) => {
  switch (type) {
    case 'HS_HH':
      return COLORS.Yellow4;
    case 'HS_LH':
      return COLORS.Yellow5;
    case 'LS_HH':
      return COLORS.Gold5;
    case 'LS_LH':
      return COLORS.Gold6;
    case 'RH':
      return COLORS.Gold7;
    default:
      return COLORS.TextDescription;
  }
};

export const getPieColorByConsumerType = (type: string) => {
  switch (type) {
    case 'private':
      return COLORS.Green3;
    case 'small':
      return COLORS.Green4;
    case 'medium':
      return COLORS.Green5;
    case 'large':
      return COLORS.Green6;
    case 'collectivities':
      return COLORS.Green7;
    default:
      return COLORS.TextDescription;
  }
};

export const getFormattedInfoChartValue = (
  value: number | undefined,
  dataValues: Array<number | undefined>,
  isRounded: boolean = false,
) => {
  // Directly return 'N/A' if dataValues are all zeros
  if (dataValues?.every((item) => item === 0)) {
    return 'N/A';
  }

  // Return '0' if value is falsy and not all dataValues are zero (handled above)
  if (!value) {
    return '0';
  }

  // If value is truthy, format it based on isRounded flag
  return isRounded ? value.toFixed(2) : value;
};

export const formatFinancialDataWithEmptyTypes = (
  data: any,
  expectedTypes: string[],
  labelsByType?: Record<CONSUMER_INVOICE_STATUSES | string, string>,
) =>
  data
    .filter(({ type }: { type: string }) =>
      labelsByType ? labelsByType?.[type] : expectedTypes.includes(type),
    )
    .map((item: any) => {
      return {
        ...item,
        type: labelsByType ? labelsByType[item.type] : item.type,
      };
    });

export const formatChartDataByFullDate = ({
  data,
  period,
  isFinancial,
  t,
}: {
  data: any;
  period: PERIOD;
  isFinancial?: boolean;
  t: TFunction;
}) => {
  switch (period) {
    case PERIOD.LAST_YEAR:
      return [...data, ...fullYearChartData(t)];
    case PERIOD.CURRENT_YEAR:
      return [...data, ...fullYearChartData(t)];
    case PERIOD.LAST_MONTH:
      if (isFinancial) return [...fullYearChartData(t), ...data];
      return [...data, ...fullMonthChartData];
    case PERIOD.CURRENT_MONTH:
      return [...data, ...fullMonthChartData];
    default:
      return data;
  }
};
