import { styled } from 'styled-components';

import { CloseCircleFilled, EditTwoTone } from '@ant-design/icons';

import { PriceVariant } from 'components/pricing/components/TaxPrices/types';
import { BtnDefault } from 'components/shared/Button';
import FormikField from 'components/shared/Input/FormikField';
import { Header } from 'components/shared/Text';
import { TextBold, TextRegular } from 'components/shared/Text';
import { COLORS } from 'constants/colors';
import {
  MAX_CONTENT_WIDTH,
  ScreenContentMaxSize,
} from 'constants/screen-breakpoints';

export const Container = styled.div<{ isProducer: boolean }>`
  display: flex;
  min-height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 32px;
  ${({ isProducer }) => isProducer && 'padding-top: 0;'}
  background: ${COLORS.BgContainer};
`;

export const BlockContainer = styled.div`
  padding: 48px 32px 0;
  background: ${COLORS.BgLayout};
  height: 100vh;

  display: flex;
  justify-content: center;
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: ${MAX_CONTENT_WIDTH}px;
`;

export const BlockHeader = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: space-between;
`;

export const BlockHeaderWithBottomSpace = styled(BlockHeader)`
  margin-bottom: 32px;
`;

export const Title = styled(TextBold)`
  font-size: 16px;
`;

export const PageTitleWrapper = styled.div`
  width: 100%;
  max-width: ${MAX_CONTENT_WIDTH}px;
  padding: 0 32px;
  box-sizing: content-box;
  ${ScreenContentMaxSize`
    box-sizing: border-box;
  `}
`;

export const PageTitle = styled(Header)`
  display: flex;
  background: ${COLORS.BgContainer};
  margin-bottom: 24px;
`;

export const TableTitle = styled.div<{ type?: PriceVariant }>`
  background: ${(props) =>
    props.type === PriceVariant.HISTORICAL ? COLORS.Grey7 : COLORS.Green1};
  color: ${COLORS.TextSecondary};
  margin-top: 32px;
  padding: 0 8px;
  height: 46px;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TableTitleText = styled(TextRegular)`
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
`;

export const Table = styled.div`
  display: grid;
  grid-template-rows: 68px repeat(3, 1fr);
  background: ${COLORS.BgContainer};
`;

export const HeaderRow = styled.div<{ type?: PriceVariant }>`
  background: ${(props) =>
    props.type === PriceVariant.HISTORICAL ? COLORS.Grey7 : 'transparent'};
  display: grid;
  grid-template-columns: 1fr 64px repeat(5, 1fr);
  border-top: 1px solid ${COLORS.ColorSplit};
  border-bottom: 1px solid ${COLORS.ColorSplit};

  > :last-child {
    border-right: none;
  }
`;

export const HeaderCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  padding: 0 8px;
  margin: 12px 0;

  font-size: 14px;
  font-weight: 600;

  border-right: 1px solid ${COLORS.ColorSplit};
`;

export const HeaderCellLeft = styled(HeaderCell)`
  justify-content: flex-start;
  padding-left: 8px;
`;

export const BtnContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 12px;
  gap: 8px;
`;

export const CancelBtnWrapper = styled.div`
  min-width: 74px;
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const TableWrapper = styled.div`
  margin-top: 24px;
`;

export const ErrorMsg = styled.div`
  min-height: 22px;
  margin-top: 8px;
`;

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const ErrorIcon = styled(CloseCircleFilled)`
  font-size: 12px;
  color: ${COLORS.ErrorBase};
  margin-right: 4px;
`;

export const ErrorText = styled(TextRegular)`
  color: ${COLORS.ErrorBase};
`;

export const FieldStyled = styled(FormikField)<{ error?: 'error' }>`
  width: 86px;
  height: 32px;
  text-align: center;
  border-radius: 8px;
  border: ${({ error }) =>
    error
      ? `1px solid ${COLORS.ErrorBorder}`
      : `1px solid ${COLORS.BgContainerDisabled}`};

  &:focus {
    outline-color: ${COLORS.Green6};
  }

  &::placeholder {
    color: ${COLORS.Grey3};
    opacity: 0.7;
  }
`;

export const EditIcon = styled(EditTwoTone).attrs(() => ({
  twoToneColor: COLORS.Green6,
}))``;

export const Button = styled(BtnDefault)`
  height: 22px;
`;
