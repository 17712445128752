import { styled } from 'styled-components';

import { TextRegular } from 'components/shared/Text';
import { COLORS } from 'constants/colors';

export const Contaner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 158px;
  background: ${COLORS.Grey6};
  margin-top: 32px;
`;

export const Text = styled(TextRegular)`
  font-weight: 600;
`;
