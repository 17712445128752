import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Area } from '@ant-design/plots';

import { emptyData } from 'components/communities/Dashboard/components/ConsumerDashboard/dummyData/RepartitionKey';
import { LAST_INDEX_OF_SEVEN_DAYS_PERIOD } from 'components/communities/constants';
import { formatChartDataByFullDate } from 'components/communities/utils';
import { COLORS } from 'constants/colors';
import { PERIOD } from 'constants/types';

import { useAppSelector } from 'utils/hooks';

import { StyledLineChart } from '../../styles';
import CustomTooltip from './CustomTooltip';
import { SkeletonAreaChartSmall } from 'components/shared/Skeleton';

type AreaChartData = {
  date: string;
  value: number | null;
};

interface AreaChartProps {
  data: AreaChartData[] | undefined;
  isLoading?: boolean;
}

const AreaChart: FC<AreaChartProps> = ({ data, isLoading }) => {
  const { t } = useTranslation();
  const period = useAppSelector((state) => state.dashboardPeriod.period);
  const xLabelCount = new Set(
    formatChartDataByFullDate({ data, period, t })?.map(
      ({ date }: { date: string }) => date,
    ),
  ).size;
  const config: any = {
    data:
      data && data.length > 0
        ? formatChartDataByFullDate({ data, period, t })
        : emptyData,
    xField: 'date',
    yField: 'value',
    xAxis: {
      tickCount: xLabelCount,

      label: {
        offset: 18,
        formatter: (value: number, _: any, index: any) => {
          if (period === PERIOD.LAST_SEVEN_DAYS) {
            if (index === LAST_INDEX_OF_SEVEN_DAYS_PERIOD) return '';
            const splittedValue = value.toString().split(' ');
            //ex: ['17', 'WE', '0:00']
            const valueWithoutHours =
              `${splittedValue[0]}, ${splittedValue[1]}`.replaceAll(',', ' ');
            return valueWithoutHours;
          }

          return value;
        },
      },
      tickLine: {
        style: {
          lineWidth: 1,
        },
        length: 10,
      },
    },
    yAxis: {
      min: 0,
      tickCount: 6,
      label: {
        formatter: (value: number) => `${value}%`,
      },
    },
    line:
      data && data.length > 0
        ? {
            color: COLORS.Green6,
            size: 3,
          }
        : { color: COLORS.Grey3 },
    tooltip: {
      customContent: (datum: string, tooltipData: any) =>
        data && data.length > 0 ? (
          <CustomTooltip period={period} data={tooltipData} />
        ) : undefined,
    },
    areaStyle: () => {
      return {
        fill:
          data && data.length > 0
            ? `l(270) 0:${COLORS.BgElevated} 0.5:${COLORS.Green5} 1:${COLORS.Green5}`
            : `l(270) 0:${COLORS.BgElevated} 0.5:${COLORS.Grey3} 1:${COLORS.Grey1}`,
      };
    },
  };

  return (
    <StyledLineChart>
      <Area
        {...config}
        loading={isLoading}
        loadingTemplate={<SkeletonAreaChartSmall />}
      />
    </StyledLineChart>
  );
};

export default AreaChart;
