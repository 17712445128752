import { styled } from 'styled-components';

import { Table } from 'antd';
import { MAX_CONTENT_WIDTH } from 'constants/screen-breakpoints';
import { COLORS } from 'constants/colors';

export const StyledTabs = styled.div`
  box-sizing: border-box;
  background-color: ${COLORS.BgLayout};
`;

export const StyledTableWrapper = styled.div`
  border-top: 1px solid var(--colorSplit, #aeaeae);
  margin-top: -1px;
  padding-top: 40px;
  width: 100%;
  height: 100%;
`;

export const FollowUpTable = styled(Table)`
  border-color: ${COLORS.ColorSplit};
  background-color: ${COLORS.BgLayout};

  .ant-table {
    border-radius: 0px;
  }

  .ant-table-tbody > tr > td {
    padding: 8px;
    font-weight: 400;
    border-color: ${COLORS.ColorSplit};
  }

  .ant-table-thead > tr > th {
    width: 398px;

    border-radius: 0px;
    padding: 12px 8px;
    background-color: ${COLORS.BgContainer};
    font-weight: normal;
    font-size: 14px;
    cursor: pointer;
    border-color: ${COLORS.ColorSplit};
  }

  .ant-table-tbody > tr > td:nth-child(1) {
    padding: 19px 8px;
    font-weight: normal;
  }

  .ant-table-thead > tr > th {
    font-weight: bold;
  }

  .ant-table-tbody > tr:last-child > td {
    border: none;
  }
`;

export const StyledEnergyTable = styled.div`
  width: 1378px;
`;

export const StyledEmptyDataWrapper = styled.div`
  margin-top: 40px;
  width: 100%;
  max-width: ${MAX_CONTENT_WIDTH}px;
`;
