import dayjs from 'dayjs';

import {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Badge, Dropdown } from 'antd';

import type { TFunction } from 'i18next';

import { INotification } from 'constants/types';

import {
  BellIcon,
  ErrorIcon,
  InfoIcon,
  StyledAvatar,
  StyledNotificationAction,
  StyledNotificationCol,
  StyledNotificationContainer,
  StyledNotificationDate,
  StyledNotificationRow,
  StyledNotificationTitle,
  StyledNotificationType,
  StyledNotificationsBody,
  StyledNotificationsContainer,
  StyledNotificationsEmpty,
  StyledNotificationsHeader,
  StyledNotificationsWrapper,
  SuccessIcon,
  UnreadIcon,
  WarningIcon,
  dropdownOverlayStyle,
  StyledNotificationButtonWrapper,
} from './styles';
import { useNotificationsSocket } from 'utils/hooks/useNotificationsSocket';

export enum NOTIFICATION_TYPE {
  error = 'error',
  warning = 'warning',
  success = 'success',
  info = 'info',
}
const getTypeIcon = (type: NOTIFICATION_TYPE) => {
  switch (type) {
    case 'success':
      return <SuccessIcon />;
    case 'error':
      return <ErrorIcon />;
    case 'warning':
      return <WarningIcon />;
    case 'info':
      return <InfoIcon />;
  }
};
const formatDate = (date: string, t: TFunction) => {
  const currentDate = dayjs();
  const dateObj = dayjs(date);
  const weekDiff = currentDate.diff(dateObj, 'week');
  if (weekDiff) return t('week_ago', { count: weekDiff });
  const dayDiff = currentDate.diff(dateObj, 'day');
  if (dayDiff) return t('day_ago', { count: dayDiff });
  const hourDiff = currentDate.diff(dateObj, 'hour');
  if (hourDiff) return t('hour_ago', { count: hourDiff });
};
const MAX_NOTIFICATION_COUNT = 9;

const Notifications = () => {
  const { notifications, markAsRead } = useNotificationsSocket();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goTo = useCallback(
    (url: string) => {
      setIsMenuOpen(false);
      navigate(url);
    },
    [navigate],
  );
  const count = useMemo(
    () => notifications.filter(({ isRead }: any) => !isRead).length,
    [notifications],
  );

  const renderNotification = useCallback(
    (notification: INotification) => {
      const { _id, text, type, actionLink, createdAt, isRead, params } =
        notification;
      return (
        <StyledNotificationContainer
          onClick={() => {
            if (!isRead) {
              markAsRead(_id);
            }
          }}
          isRead={isRead}
          key={_id}
        >
          <StyledNotificationCol>
            <StyledNotificationType>{getTypeIcon(type)}</StyledNotificationType>
          </StyledNotificationCol>
          <StyledNotificationCol>
            <StyledNotificationRow>
              <StyledNotificationTitle>
                {t(`${text}.title`, { ...params })}
              </StyledNotificationTitle>
            </StyledNotificationRow>
            <StyledNotificationRow>
              {t(`${text}.body`, { ...params })}
            </StyledNotificationRow>
            <StyledNotificationRow>
              <StyledNotificationDate>
                {formatDate(createdAt, t)}
              </StyledNotificationDate>
            </StyledNotificationRow>
            <StyledNotificationButtonWrapper>
              {actionLink && (
                <StyledNotificationAction
                  size="small"
                  onClick={() => goTo(actionLink)}
                >
                  {t(`${text}.actionTitle`)}
                </StyledNotificationAction>
              )}
            </StyledNotificationButtonWrapper>
          </StyledNotificationCol>
          <StyledNotificationCol>
            {!isRead ? <UnreadIcon /> : <div style={{ width: '14px' }} />}
          </StyledNotificationCol>
        </StyledNotificationContainer>
      );
    },
    [goTo, t, markAsRead],
  );
  const renderNotifications = useCallback(
    ({ items }: { items: INotification[] }) => {
      return (
        <StyledNotificationsContainer>
          <StyledNotificationsHeader>
            {t('notifications')}
          </StyledNotificationsHeader>
          <StyledNotificationsBody>
            {items.length ? (
              items.map((item: INotification) => renderNotification(item))
            ) : (
              <StyledNotificationsEmpty>
                {t('no_notifications_yet')}
              </StyledNotificationsEmpty>
            )}
          </StyledNotificationsBody>
        </StyledNotificationsContainer>
      );
    },
    [renderNotification, t],
  );
  const toggleMenu = () => setIsMenuOpen((val) => !val);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        !(event.target as HTMLElement).closest('.dropdown-classss') &&
        !(event.target as HTMLElement).closest('.ant-dropdown')
      ) {
        setIsMenuOpen(false);
      }
    };
    const handleScrollOutside = () => setIsMenuOpen(false);
    window.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('scroll', handleScrollOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('scroll', handleScrollOutside);
    };
  }, []);

  return (
    <StyledNotificationsWrapper>
      <Dropdown
        className="dropdown-classss"
        overlay={renderNotifications({ items: notifications })}
        trigger={['click']}
        open={isMenuOpen}
        placement="bottomLeft"
        overlayStyle={dropdownOverlayStyle as CSSProperties}
      >
        <Badge count={count} overflowCount={MAX_NOTIFICATION_COUNT}>
          <StyledAvatar
            onClick={toggleMenu}
            shape="square"
            icon={<BellIcon />}
          />
        </Badge>
      </Dropdown>
    </StyledNotificationsWrapper>
  );
};

export default Notifications;
