import { useTranslation } from 'react-i18next';

import CommunityData from '../CommunityData';

interface ITabsConfig {
  consumptionTotal: number;
  selfConsumption: number;
  gridConsumption: number;
  overproduction: number;
}
export const useTabsConfig = ({
  consumptionTotal,
  selfConsumption,
  gridConsumption,
  overproduction,
}: ITabsConfig) => {
  const { t } = useTranslation();

  return [
    {
      label: t('community_data').toUpperCase(),
      key: '1',
      children: (
        <CommunityData
          consumptionTotal={consumptionTotal}
          selfConsumption={selfConsumption}
          gridConsumption={gridConsumption}
          overproduction={overproduction}
        />
      ),
    },
  ];
};
