import { styled } from 'styled-components';

import { InfoCircleOutlined } from '@ant-design/icons';

import { COLORS } from 'constants/colors';

export const DetailCardBlock = styled.div`
  box-sizing: border-box;
  height: 203px;
  padding: 12px;
  background-color: ${COLORS.BgLayout};
`;

export const DetailCardTitle = styled.div`
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 12px;
`;

export const DetailCardNoPaymentLabel = styled.div`
  color: ${COLORS.ErrorBorder};
`;

export const DetailCardLabel = styled.div<{ width?: string }>`
  color: ${COLORS.TextSecondary};
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  min-height: 20px;
  width: ${(props) => (props.width ? props.width : '90px')};
  margin-top: 1px;
`;

export const DetailCardValue = styled.div<{ width?: string }>`
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  width: ${({ width }) => (width ? width : 'auto')};
`;

export const StatusCardPendingRow = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-bottom: 12px;
`;

export const StatusCardPendingMessage = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  overflow: hidden;
`;

export const StatusCardInfoIcon = styled(InfoCircleOutlined)`
  color: ${COLORS.TextSecondary};
  margin-right: 4px;
`;
