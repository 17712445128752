import { useTranslation } from 'react-i18next';

import EnergyDataTab from './components/EnergyDataTab';
import FinancialDataTab from './components/FinancialDataTab';

export const useTabsConfig = () => {
  const { t } = useTranslation();

  return [
    {
      label: t('energy_data').toUpperCase(),
      key: '1',
      children: <EnergyDataTab />,
    },
    {
      label: t('financial_data').toUpperCase(),
      key: '2',
      children: <FinancialDataTab />,
    },
  ];
};
