import { DateTime } from 'luxon';

import { TFunction } from 'i18next';

import {
  PriceType,
  PriceVariant,
} from 'components/pricing/components/TaxPrices/types';
import { IDBTaxPrices, ITaxPrices } from 'services/taxPrices.types';

import { getDateRange } from 'utils/date/date-range';

type IRenderTableHeader = {
  priceType: string;
  priceVariant: PriceVariant;
  titleText: string;
  t: TFunction;
};

export const renderTableHeader = ({
  priceType,
  priceVariant,
  titleText,
  t,
}: IRenderTableHeader) => {
  switch (priceVariant) {
    case PriceVariant.UPCOMMING: {
      return `${t('upcomming_prices', { priceType })} ${titleText}`;
    }

    case PriceVariant.HISTORICAL: {
      return `${t('historical_prices')} ${titleText}`;
    }

    default: {
      return titleText;
    }
  }
};

export const extractExistingDateRanges = (
  taxPrices: ITaxPrices,
  priceType: PriceType,
  selectedUserId: string | null,
) =>
  [...taxPrices.current, ...taxPrices.upcoming]
    .filter((taxPrice) => {
      switch (priceType) {
        case PriceType.TURPE: {
          return taxPrice.turpePerMWh !== undefined;
        }

        case PriceType.ACCISE: {
          return taxPrice.accisePerMWh !== undefined;
        }

        case PriceType.DISCOUNTED_ACCISE: {
          return (
            taxPrice.discountedAccisePerMWh !== undefined &&
            selectedUserId === taxPrice.userId
          );
        }

        case PriceType.INDEX: {
          return taxPrice.index !== undefined;
        }

        default: {
          return taxPrice.primePerMWh !== undefined;
        }
      }
    })
    .map((taxPrice) => ({
      start: DateTime.fromISO(taxPrice.startDate, { zone: 'utc' }).minus({
        days: 1,
      }),
      end: DateTime.fromISO(taxPrice.endDate, { zone: 'utc' }).minus({
        days: 1,
      }),
    }));

export interface GroupedTaxPricesByDateRange {
  [key: string]: IDBTaxPrices[];
}

export const groupDateRanges = (
  taxPrices: IDBTaxPrices[],
  priceKey:
    | 'accisePerMWh'
    | 'discountedAccisePerMWh'
    | 'turpePerMWh'
    | 'primePerMWh'
    | 'index',
  communityId?: string,
): GroupedTaxPricesByDateRange =>
  taxPrices
    .filter((taxPrice) => {
      if (priceKey === 'discountedAccisePerMWh' && communityId) {
        return (
          taxPrice[priceKey] !== undefined &&
          taxPrice.discountedAccisePerMWh?.communityId === communityId
        );
      }

      return taxPrice[priceKey] !== undefined;
    })
    .reduce((acc, taxPrice) => {
      const dateRange = getDateRange(taxPrice);

      if (!acc[dateRange]) {
        acc[dateRange] = [];
      }

      acc[dateRange].push(taxPrice);

      return acc;
    }, {} as GroupedTaxPricesByDateRange);
