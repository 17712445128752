import { ChangeEvent } from 'react';

import { useFormikContext } from 'formik';

import { FieldStyled } from 'components/pricing/components/TaxPrices/styles';

import PriceField from '../PriceField';
import { Cell, Row } from './styles';

const PriceRow = () => {
  const { values, errors, setFieldValue, setErrors } = useFormikContext();
  const err = errors as any;
  const vals = values as any;

  const isError = !!err?.discountedAccisePerMWh?.condition;
  const value = vals?.discountedAccisePerMWh?.condition;

  const handleOnFocus = () => {
    setErrors({});
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) =>
    setFieldValue('discountedAccisePerMWh.condition', event.target.value);

  return (
    <Row>
      <Cell>
        <FieldStyled
          error={isError ? 'error' : undefined}
          type="string"
          value={value || ''}
          name="discountedAccisePerMWh.condition"
          placeholder="L. 312-50"
          onFocus={handleOnFocus}
          onChange={handleOnChange}
        />
      </Cell>
      <PriceField property="ht" />
      <PriceField property="vat" />
      <PriceField property="ttc" />
    </Row>
  );
};

export default PriceRow;
