import { COLORS } from 'constants/colors';

export const statusColor: { [key: string]: string } = {
  ACTIVE: 'green',
  PENDING: 'orange',
  DISABLED: 'red',
  CONFLICT: 'red',
  Indeactivation: '#B9B9B9',
  Deactivated: '#B9B9B9',
};
