import styled from 'styled-components';

import { PriceVariant } from 'components/pricing/components/TaxPrices/types';
import { TextRegular as Text } from 'components/shared/Text';
import { COLORS } from 'constants/colors';

export const Table = styled.div`
  display: grid;
  grid-template-rows: 46px;
  background: ${COLORS.BgContainer};
`;

export const Row = styled.div<{ type?: PriceVariant }>`
  background: ${(props) =>
    props.type === PriceVariant.HISTORICAL ? COLORS.Grey6 : 'transparent'};
  display: grid;
  grid-template-columns: 570px 1fr;
  border-bottom: 1px solid ${COLORS.ColorSplit};
`;

export const RowHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  padding-left: 8px;
`;

export const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  padding-left: 8px;
`;

export const Cell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 8px;
`;

export const TextRegular = styled(Text)`
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
`;

export const TextBold = styled(TextRegular)`
  font-weight: 600;
`;
