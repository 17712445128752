import { useTranslation } from 'react-i18next';

import ExpandedWrapper from 'components/hoc/ExpandedWrapper';
import {
  TableTitle,
  TableTitleText,
} from 'components/pricing/components/TaxPrices/styles';
import { renderTableHeader } from 'components/pricing/components/TaxPrices/tax-prices.helper';
import {
  PriceType,
  PriceVariant,
} from 'components/pricing/components/TaxPrices/types';
import { ITaxTable } from 'services/taxPrices.types';

import { getDateRange } from 'utils/date/date-range';

import EditPriceBtn from '../../../EditPriceBtn';
import { Cell, HeaderRow, Row, Table, TextBold } from './styles';

const PrimeTable = ({
  priceVariant,
  taxPrices,
  onEdit = () => {},
}: ITaxTable) => {
  const { t } = useTranslation();
  const isHystoricalTaxPrices = priceVariant === PriceVariant.HISTORICAL;

  const titleText = t('tariffs_valid_date_range', {
    dateRange: getDateRange(taxPrices),
  });

  if (!taxPrices.primePerMWh) {
    return null;
  }

  return (
    <ExpandedWrapper
      isCollapsed={isHystoricalTaxPrices}
      arrowUpText={t('hide_historical_prices')}
      arrowDownText={t('show_historical_prices')}
    >
      <TableTitle type={priceVariant}>
        <TableTitleText>
          {renderTableHeader({
            priceType: PriceType.PRIME,
            priceVariant,
            titleText,
            t,
          })}
        </TableTitleText>
        <EditPriceBtn priceVariant={priceVariant} onClick={onEdit} />
      </TableTitle>
      <Table>
        <Row type={priceVariant}>
          <HeaderRow>
            <TextBold>{t('prime_coeficient')}</TextBold>
          </HeaderRow>
          <Cell>
            <TextBold>{taxPrices.primePerMWh}</TextBold>
          </Cell>
        </Row>
      </Table>
    </ExpandedWrapper>
  );
};

export default PrimeTable;
