import React, { useMemo } from 'react';

import { COLORS } from 'constants/colors';

export enum LOGO_SIZE {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}
const logoSizes: { [K in LOGO_SIZE]: number } = {
  [LOGO_SIZE.Small]: 50,
  [LOGO_SIZE.Medium]: 100,
  [LOGO_SIZE.Large]: 250,
};

type SizeProps = { size: LOGO_SIZE | number; color?: keyof typeof COLORS };

const Logo: React.FC<SizeProps> = ({
  size = LOGO_SIZE.Small,
  color = 'BgContainer',
}) => {
  const sizeValue = useMemo(
    () => (typeof size === 'number' ? size : logoSizes[size]),
    [size],
  );

  return (
    <svg
      width={sizeValue}
      height={(sizeValue * 81) / 164}
      viewBox="0 0 164 81"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M95.1137 50.5119C95.1137 61.2248 86.425 69.8979 75.7121 69.8979C64.9992 69.8979 56.3105 61.2248 56.3105 50.5119C56.3105 39.799 65.0148 31.1259 75.7277 31.1259C86.425 31.1259 95.1137 39.799 95.1137 50.5119ZM75.7277 60.166C80.8817 60.166 84.6655 55.6504 84.6655 50.4963C84.6655 45.4046 80.8662 40.889 75.7277 40.889C70.5737 40.889 66.7899 45.4046 66.7899 50.4963C66.7743 55.6504 70.5737 60.166 75.7277 60.166Z"
        fill={COLORS[color]}
      />
      <path
        d="M115.653 52.3182V68.6367H104.676V52.3182L92.717 32.3093H103.632L110.188 44.2523L116.681 32.3093H127.596L115.653 52.3182Z"
        fill={COLORS[color]}
      />
      <path
        d="M164 50.5119C164 61.2248 155.311 69.8979 144.598 69.8979C133.886 69.8979 125.197 61.2248 125.197 50.5119C125.197 39.799 133.886 31.1259 144.598 31.1259C155.311 31.1259 164 39.799 164 50.5119ZM144.614 60.166C149.768 60.166 153.552 55.6504 153.552 50.4963C153.552 45.4046 149.753 40.889 144.614 40.889C139.46 40.889 135.676 45.4046 135.676 50.4963C135.676 55.6504 139.46 60.166 144.614 60.166Z"
        fill={COLORS[color]}
      />
      <path
        d="M63.2403 78.1973V79.1004C62.633 79.4274 61.9479 79.5987 61.1693 79.5987C60.5309 79.5987 59.9859 79.4742 59.5499 79.2094C59.0984 78.9603 58.7558 78.6022 58.4911 78.1506C58.2264 77.6991 58.1018 77.2163 58.1018 76.6869C58.1018 75.8617 58.3977 75.161 58.9894 74.5848C59.5811 74.0243 60.3129 73.7284 61.1849 73.7284C61.7922 73.7284 62.4461 73.8841 63.178 74.1956V75.0675C62.5084 74.6783 61.8544 74.4914 61.216 74.4914C60.562 74.4914 60.0171 74.6938 59.5811 75.1143C59.1451 75.5347 58.9271 76.0485 58.9271 76.6714C58.9271 77.2942 59.1451 77.808 59.5655 78.2129C59.9859 78.6177 60.5309 78.8202 61.2005 78.8202C61.9012 78.8357 62.5707 78.6177 63.2403 78.1973Z"
        fill={COLORS[color]}
      />
      <path
        d="M72.0688 73.7284C72.9564 73.7284 73.6882 74.0087 74.2799 74.5693C74.8716 75.1298 75.1675 75.8305 75.1675 76.6714C75.1675 77.5122 74.8716 78.2129 74.2799 78.7579C73.6882 79.3029 72.9408 79.5832 72.0377 79.5832C71.1813 79.5832 70.465 79.3029 69.8889 78.7579C69.3128 78.2129 69.0325 77.5122 69.0325 76.6869C69.0325 75.8305 69.3283 75.1298 69.9045 74.5693C70.465 74.0087 71.1969 73.7284 72.0688 73.7284ZM72.1 74.507C71.446 74.507 70.9166 74.7094 70.4806 75.1143C70.0446 75.5191 69.8422 76.0485 69.8422 76.6869C69.8422 77.3098 70.0602 77.8081 70.4806 78.2285C70.901 78.6333 71.4304 78.8357 72.0688 78.8357C72.7072 78.8357 73.2367 78.6333 73.6571 78.2129C74.0775 77.7925 74.2955 77.2786 74.2955 76.6558C74.2955 76.0485 74.0775 75.5347 73.6571 75.1298C73.2522 74.7094 72.7228 74.507 72.1 74.507Z"
        fill={COLORS[color]}
      />
      <path
        d="M85.7404 73.8064H86.5034V79.521H85.6781V75.0832L83.9186 77.2943H83.7629L81.9877 75.0677V79.521H81.1625V73.8064H81.941L83.8407 76.1577L85.7404 73.8064Z"
        fill={COLORS[color]}
      />
      <path
        d="M97.2955 73.8064H98.0585V79.521H97.2488V75.0832L95.4892 77.2943H95.3335L93.5584 75.0832V79.521H92.7332V73.8064H93.5117L95.4114 76.1577L97.2955 73.8064Z"
        fill={COLORS[color]}
      />
      <path
        d="M108.287 73.8064H109.112V77.0452C109.112 77.4812 109.081 77.8082 109.019 78.0417C108.956 78.2597 108.878 78.4466 108.785 78.6023C108.692 78.758 108.583 78.8826 108.442 79.0072C107.991 79.3964 107.399 79.5989 106.667 79.5989C105.92 79.5989 105.328 79.412 104.877 79.0227C104.737 78.8982 104.628 78.7736 104.534 78.6179C104.441 78.4622 104.363 78.2909 104.301 78.0729C104.238 77.8549 104.207 77.5123 104.207 77.0608V73.822H105.032V77.0608C105.032 77.5902 105.095 77.9639 105.219 78.1819C105.344 78.3843 105.531 78.5556 105.78 78.6802C106.029 78.8047 106.325 78.867 106.667 78.867C107.15 78.867 107.555 78.7424 107.851 78.4933C108.006 78.3532 108.131 78.1975 108.193 78.0106C108.271 77.8237 108.302 77.5123 108.302 77.0608V73.8064H108.287Z"
        fill={COLORS[color]}
      />
      <path
        d="M119.811 73.8064H120.59V79.521H119.889L116.074 75.1144V79.521H115.311V73.8064H115.981L119.827 78.2442V73.8064H119.811Z"
        fill={COLORS[color]}
      />
      <path
        d="M126.847 73.8064H127.672V79.521H126.847V73.8064Z"
        fill={COLORS[color]}
      />
      <path
        d="M133.465 73.7903H138.245V74.5222H136.252V79.5205H135.427V74.5222H133.465V73.7903Z"
        fill={COLORS[color]}
      />
      <path
        d="M144.055 73.8064H144.88V79.521H144.055V73.8064Z"
        fill={COLORS[color]}
      />
      <path
        d="M151.171 73.8064H154.41V74.5383H151.996V76.2822H154.332V77.0141H151.996V78.7892H154.488V79.521H151.171V73.8064Z"
        fill={COLORS[color]}
      />
      <path
        d="M161.789 77.1226L161.166 76.7489C160.777 76.5153 160.497 76.2818 160.341 76.0482C160.17 75.8147 160.092 75.5499 160.092 75.2541C160.092 74.8025 160.248 74.4444 160.559 74.1641C160.87 73.8838 161.275 73.7437 161.774 73.7437C162.256 73.7437 162.692 73.8838 163.082 74.1485V75.0828C162.677 74.6935 162.225 74.4911 161.758 74.4911C161.493 74.4911 161.275 74.5534 161.104 74.678C160.933 74.8025 160.839 74.9582 160.839 75.1451C160.839 75.3164 160.902 75.4721 161.026 75.6278C161.151 75.7835 161.353 75.9392 161.633 76.0949L162.256 76.4686C162.957 76.8891 163.3 77.4185 163.3 78.0569C163.3 78.5084 163.144 78.8822 162.832 79.178C162.521 79.4583 162.132 79.614 161.633 79.614C161.073 79.614 160.559 79.4427 160.092 79.1001V78.0569C160.528 78.6174 161.042 78.8977 161.618 78.8977C161.867 78.8977 162.085 78.8199 162.256 78.6797C162.428 78.5396 162.505 78.3527 162.505 78.1503C162.537 77.761 162.287 77.434 161.789 77.1226Z"
        fill={COLORS[color]}
      />
      <path
        d="M16.5178 51.5422V38.9089C16.5178 38.6137 16.8286 38.4428 17.0772 38.5826L28.0789 44.907C28.3275 45.0468 28.3275 45.4198 28.0789 45.5596L17.0772 51.853C16.8286 52.0083 16.5178 51.8219 16.5178 51.5422Z"
        fill="#FAA31B"
      />
      <path
        d="M15.4908 33.9525V21.3192C15.4908 21.024 15.8016 20.8531 16.0503 20.9929L27.0519 27.3173C27.3005 27.4571 27.3005 27.8301 27.0519 27.9699L16.0503 34.2943C15.8016 34.4186 15.4908 34.2477 15.4908 33.9525Z"
        fill="#FAA31B"
      />
      <path
        d="M0 24.924V12.2908C0 11.9955 0.310782 11.8246 0.559407 11.9644L11.561 18.2888C11.8097 18.4287 11.8097 18.8016 11.561 18.9415L0.559407 25.2659C0.326321 25.3902 0 25.2192 0 24.924Z"
        fill="#FAA31B"
      />
      <path
        d="M16.5178 70.1739V57.5407C16.5178 57.2454 16.8286 57.0745 17.0772 57.2143L28.0789 63.5387C28.3275 63.6786 28.3275 64.0515 28.0789 64.1914L17.0772 70.5002C16.8286 70.6401 16.5178 70.4536 16.5178 70.1739Z"
        fill="#FAA31B"
      />
      <path
        d="M29.042 60.8351V48.2019C29.042 47.9066 28.7312 47.7357 28.4825 47.8755L17.4809 54.1844C17.2323 54.3242 17.2323 54.6972 17.4809 54.837L28.4825 61.1614C28.7156 61.3013 29.042 61.1303 29.042 60.8351Z"
        fill="#FAA31B"
      />
      <path
        d="M29.042 42.2496V29.6163C29.042 29.3211 28.7312 29.1502 28.4825 29.29L17.4809 35.6144C17.2323 35.7543 17.2323 36.1272 17.4809 36.267L28.4825 42.5914C28.7156 42.7157 29.042 42.5448 29.042 42.2496Z"
        fill="#FAA31B"
      />
      <path
        d="M13.9394 32.8491V20.2159C13.9394 19.9206 13.6286 19.7497 13.38 19.8896L2.37836 26.214C2.12974 26.3538 2.12974 26.7268 2.37836 26.8666L13.38 33.191C13.6286 33.3153 13.9394 33.1444 13.9394 32.8491Z"
        fill="#FAA31B"
      />
      <path
        d="M29.042 79.4816V66.8483C29.042 66.5531 28.7312 66.3822 28.4825 66.522L17.4809 72.8309C17.2323 72.9707 17.2323 73.3437 17.4809 73.4835L28.4825 79.8079C28.7156 79.9478 29.042 79.7613 29.042 79.4816Z"
        fill="#FAA31B"
      />
      <path
        d="M43.2138 73.1189L32.7261 79.153C32.0576 79.5376 31.2238 79.0528 31.2274 78.2816L31.3753 46.7746C31.3804 45.6916 31.9688 44.6954 32.9148 44.1682L43.3264 38.3656C43.5751 38.2257 43.8859 38.4122 43.8859 38.6919V72.4617C43.9014 72.7724 43.5425 72.9546 43.2138 73.1189Z"
        fill="#39A77F"
      />
      <path
        d="M46.2613 16.4714C46.2147 16.5025 46.1525 16.5491 46.1059 16.5646L41.6462 19.2063C35.3529 22.9356 31.4836 29.6796 31.4836 36.9985V41.6913C31.4836 42.1574 31.9964 42.4682 32.416 42.2196L56.7812 28.0791C57.2474 27.8149 57.6825 27.5197 58.0865 27.1933L58.6614 26.8515C68.2335 21.2885 73.9403 11.0638 73.9403 0L46.2613 16.4714Z"
        fill="url(#paint0_radial_16_7684)"
      />
      <defs>
        <radialGradient
          id="paint0_radial_16_7684"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(74.8209 0.0366915) rotate(136.598) scale(50.8857 50.6848)"
        >
          <stop stopColor="#BCF16F" />
          <stop offset="0.4479" stopColor="#6AD97A" />
          <stop offset="1" stopColor="#38A67E" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default Logo;
