import qs from 'qs';

import { createApi } from '@reduxjs/toolkit/query/react';

import { API_URLS, REQUEST_METHOD } from 'constants/global';

import { baseQuery } from './baseQuery';

export const invoiceApi = createApi({
  reducerPath: 'invoice',
  baseQuery,
  endpoints: (builder) => ({
    createInvoice: builder.mutation<any, { id: string }>({
      query: ({ id }) => ({
        url: `${API_URLS.CREATE_INVOICE(id)}`,
        method: REQUEST_METHOD.POST,
      }),
    }),
    getInvoice: builder.query<any, { prmId?: string; id?: string }>({
      query: ({ id, prmId }) => ({
        url: `${API_URLS.GET_INVOICE}?${qs.stringify({
          prmId,
          id,
        })}`,
        method: REQUEST_METHOD.GET,
      }),
    }),
  }),
  tagTypes: ['invoice'],
});

export const { useGetInvoiceQuery, useCreateInvoiceMutation } = invoiceApi;
