import styled from 'styled-components';

import { Switch } from 'antd';

export const SwitchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-start;
`;

export const StyledSwitch = styled(Switch)`
  margin: 20px 8px 0 0;
`;
