import { styled } from 'styled-components';
import {
  MAX_CONTENT_WIDTH,
  ScreenContentMaxSize,
} from 'constants/screen-breakpoints';
import { COLORS } from 'constants/colors';

export const StyledHeaderWrapper = styled.div`
  width: 100%;
  padding-top: 0;
`;

export const StyledHeaderRow = styled.div`
  background-color: ${COLORS.BgContainer};
  width: 100%;
  padding: 32px 0px 1px 0px;
`;

export const StyledPeriodPicker = styled.div`
  display: flex;
  justify-content: end;
  margin: 0 auto;
  max-width: ${MAX_CONTENT_WIDTH}px;
  padding: 0 32px;
`;

export const StyledContainer = styled.div`
  max-width: ${MAX_CONTENT_WIDTH}px;
  margin: 0 auto;
  padding: 0 32px;
  box-sizing: content-box;
  ${ScreenContentMaxSize`
    box-sizing: border-box;
  `}
`;

export const StyledTabWrapper = styled.div`
  background-color: ${COLORS.BgLayout};
  width: 100%;
  padding: 38px 0px 20px 0px;
`;

export const StyledFinTableHeader = styled.div`
  font-size: 30px;
  font-weight: 600;
  line-height: 38px;
`;
