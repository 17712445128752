import { isValidRole } from 'utils';

import { useTranslation } from 'react-i18next';

import {
  Button,
  EditIcon,
} from 'components/pricing/components/TaxPrices/styles';
import { PriceVariant } from 'components/pricing/components/TaxPrices/types';
import { USER_ROLES } from 'constants/global';

type IEditPriceBtn = {
  priceVariant: PriceVariant;
  onClick?: () => void;
};

const EditPriceBtn = ({ priceVariant, onClick }: IEditPriceBtn) => {
  const { t } = useTranslation();

  const isOyoAdmin = isValidRole(USER_ROLES.OYO_ADMIN);
  const isEditableTaxPrices =
    isOyoAdmin && priceVariant === PriceVariant.UPCOMMING;

  if (isEditableTaxPrices) {
    return (
      <Button size="small" type="text" onClick={onClick}>
        <EditIcon />
        {t('edit')}
      </Button>
    );
  }

  return null;
};

export default EditPriceBtn;
