import { ReactNode, useState } from 'react';
import { useCollapse } from 'react-collapsed';

import {
  ArrowButton,
  ArrowDown,
  ArrowText,
  ArrowUp,
  Container,
} from './styles';

type IExpandedWrapper = {
  isCollapsed: boolean;
  arrowUpText: string;
  arrowDownText: string;
  children: ReactNode;
};

const ExpandedWrapper = ({
  isCollapsed,
  arrowUpText,
  arrowDownText,
  children,
}: IExpandedWrapper) => {
  const [isExpanded, setIsExpanded] = useState(!isCollapsed);
  const { getCollapseProps, getToggleProps } = useCollapse({
    isExpanded,
  });

  return (
    <Container>
      <section {...getCollapseProps()}>{children}</section>
      {isCollapsed && (
        <ArrowButton
          {...getToggleProps({
            onClick: () => setIsExpanded((prevExpanded) => !prevExpanded),
          })}
        >
          {isExpanded ? <ArrowUp /> : <ArrowDown />}
          {isExpanded ? (
            <ArrowText>{arrowUpText}</ArrowText>
          ) : (
            <ArrowText>{arrowDownText}</ArrowText>
          )}
        </ArrowButton>
      )}
    </Container>
  );
};

export default ExpandedWrapper;
