import type { AppDispatch, RootState } from 'store';
import { getAuthUser } from 'utils';

import { useCallback, useEffect, useState } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { SUPPORTED_LANGUAGES } from 'constants/global';
import {
  useGetUserLanguageQuery,
  useSetUserLanguageMutation,
} from 'services/userManagement';

import i18n from 'utils/i18n';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useDebounce = <T>(value: T, delay: number): T => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export const useAppLanguage = () => {
  const [lang, setLang] = useState(SUPPORTED_LANGUAGES.Français);
  const authUser = getAuthUser();
  const [userEmail, setUserEmail] = useState(authUser?.email);
  const {
    data,
    isSuccess: isLangSuccess,
    refetch,
  } = useGetUserLanguageQuery({}, { skip: !userEmail });
  const [setUserLanguage] = useSetUserLanguageMutation();

  const changeLanguage = useCallback(
    (value: string) => {
      setUserLanguage(value);
      i18n.changeLanguage(value);
      setLang(value);
    },
    [i18n],
  );

  const handleSignOut = useCallback(() => {
    setUserEmail(null);
  }, []);

  const handleSignIn = useCallback((email: string) => {
    setUserEmail(email);
  }, []);

  useEffect(() => {
    // initialize lang from server
    if (isLangSuccess) {
      setLang(data.lang || SUPPORTED_LANGUAGES.Français);
      i18n.changeLanguage(data.lang || SUPPORTED_LANGUAGES.Français);
    }
  }, [isLangSuccess, data]);

  useEffect(() => {
    // update lang after user changed
    if (!userEmail) {
      setLang(SUPPORTED_LANGUAGES.Français);
      i18n.changeLanguage(SUPPORTED_LANGUAGES.Français);
    }
    if (userEmail) {
      refetch();
    }
  }, [userEmail]);

  return {
    lang,
    changeLanguage,
    onLogout: handleSignOut,
    onLogin: handleSignIn,
  };
};
