import { useTranslation } from 'react-i18next';

import { InputGroup } from 'components/shared/InputGroup';
import AddressAutocomplete from 'components/shared/AddressAutocomplete';
import { ADDRESS_TYPE } from 'components/shared/AddressAutocomplete/types';
import { IFields } from 'components/members/types';

const AddressField = (props: any) => {
  const { t } = useTranslation();

  return (
    <InputGroup label={t('location') as string} error={props.error}>
      <AddressAutocomplete
        isError={!!props.error}
        placeholder={t('address_street_building_placeholder').toString()}
        types={ADDRESS_TYPE.ADDRESS}
        value={props.value}
        onChange={(value: string) => {
          props.resetError();
          props.setFieldValue('address.street', value);
        }}
        onSelect={(fields: IFields) => {
          props.resetError();
          props.setFieldValue(
            'address.location.coordinates',
            fields.coordinates,
          );
          props.setFieldValue('address.street', fields.address);
          props.setFieldValue('address.postcode', fields.postcode);
          props.setFieldValue('address.city', fields.text);
        }}
      />
    </InputGroup>
  );
};

export default AddressField;
