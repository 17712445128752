import { useTranslation } from 'react-i18next';

import { useFormikContext } from 'formik';

import { StyledCommunityTitle } from 'components/communities/CreateCommunity/setup-community/styles';
import FormikInput from 'components/shared/Input/FormikInput';
import PhoneNumberInput from 'components/shared/Input/PhoneNumberInput';
import { InputGroup } from 'components/shared/InputGroup';

import { useAppSelector } from 'utils/hooks';

import { ConsumerType } from '../../ConsumerType/constants';
import { initialValues } from '../../constants';
import FullNameInput from './FullName';

const PersonData = () => {
  const { values, errors, setFieldValue } =
    useFormikContext<typeof initialValues>();

  const { t } = useTranslation();

  const { consumerType } = useAppSelector((state) => state.consumer);

  return (
    <>
      <StyledCommunityTitle isUppercase={true}>
        {t('legal_person_data')}
      </StyledCommunityTitle>
      {consumerType !== ConsumerType.privateHouse && <FullNameInput />}

      <InputGroup
        label={t('legal_person_email_address').toString()}
        error={errors?.additionalInfo?.legalPerson?.email}
      >
        <FormikInput
          fieldName="additionalInfo.legalPerson.email"
          placeholder="Ex. mail@mail.com"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('email', e.target.value);
          }}
        />
      </InputGroup>
      {consumerType !== ConsumerType.privateHouse && (
        <InputGroup
          label={t('legal_person_position').toString()}
          error={errors?.additionalInfo?.legalPerson?.position}
        >
          <FormikInput
            fieldName="additionalInfo.legalPerson.position"
            placeholder="Ex. Chief executive officer"
          />
        </InputGroup>
      )}

      <InputGroup
        label={t('legal_person_phone_number').toString()}
        error={errors?.additionalInfo?.legalPerson?.contactNumber}
      >
        <PhoneNumberInput
          hasError={Boolean(errors?.additionalInfo?.legalPerson?.contactNumber)}
          phone={values.additionalInfo.legalPerson.contactNumber}
          onChange={(contactNumber) => {
            setFieldValue('contactNumber', contactNumber);
            setFieldValue(
              'additionalInfo.legalPerson.contactNumber',
              contactNumber,
            );
          }}
        />
      </InputGroup>
    </>
  );
};

export default PersonData;
