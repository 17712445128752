import { styled } from 'styled-components';

import { Select } from 'antd';

export const StyledFlag = styled.span`
  font-size: 20px;
  position: relative;
  top: 2px;
  left: 3px;
`;

export const StyledSelect = styled(Select)`
  width: 300px;
`;
