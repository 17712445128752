import { getAppRole } from 'utils';

import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';

import DynamicEditForm from 'components/communities/components/DynamicEditForm';
import Loader from 'components/shared/Loader';
import ModalMessage from 'components/shared/ModalMessage';
import Popover from 'components/shared/Popover';
import { USER_ROLES } from 'constants/global';
import { useUpdateMemberMutation } from 'services/membersManagement';
import { useLazyResendInviteEmailQuery } from 'services/userManagement';
import { IMember, USER_STATUSES } from 'services/types';
import { editConsumerSchema } from 'utils/validation/schemas';
import DetailCard from 'components/communities/Dashboard/components/Profile/components/Card';
import {
  Button,
  EditIcon,
  StyledBlockBody,
  StyledBlockHeader,
  StyledBlockTitle,
  StyledBlockWrapper,
  StyledResendInvitationLink,
} from '../../styles';
import { CONSUMER_TYPE } from 'constants/types';
import {
  getConsumerDataLabelsByCompanyType,
  getLegalRepresentativeLabelsByCompanyType,
} from './utils/getLabelsByCompanyType';
import {
  getConsumerDataValuesByCompanyType,
  getLegalRepresentativeValuesByCompanyType,
} from './utils/getValuesByCompanyType';
import { useUserEditFormData } from './useUserEditFormData';

interface ProfileDataProps {
  member: IMember | undefined;
  refetch: () => void;
  isLoading?: boolean;
}

const ProfileData: FC<ProfileDataProps> = ({
  member,
  refetch,
  isLoading: isLoadingMember,
}) => {
  const { t } = useTranslation();
  const [isEditMode, setEditMode] = useState(false);
  const [userProfile, setUserProfile] = useState(member);
  const [modalOptions, setModalOptions] = useState<{
    title: string;
    subTitle?: string;
  } | null>(null);

  const [resendInvitation, { isLoading: isLoadingResendInvitation }] =
    useLazyResendInviteEmailQuery();

  const authUserRole = getAppRole();
  const isAdminOrPMO = useMemo(
    () =>
      authUserRole === USER_ROLES.OYO_ADMIN || authUserRole === USER_ROLES.PMO,
    [authUserRole],
  );
  const isDiscountedAcciseAvailable =
    isAdminOrPMO && userProfile?.additionalInfo?.type !== CONSUMER_TYPE.PRIVATE;
  const isCompany = userProfile?.additionalInfo?.type !== CONSUMER_TYPE.PRIVATE;

  const [updateMember, { isLoading, data: memberData }] =
    useUpdateMemberMutation();

  useEffect(() => {
    setUserProfile(memberData);
  }, [memberData]);

  useEffect(() => {
    setUserProfile(member);
  }, [member]);

  const name = `${userProfile?.firstName} ${userProfile?.middleName} ${userProfile?.lastName}`;
  const siretNumber = userProfile?.additionalInfo.siretNumber;
  const location = userProfile?.address.street;
  const legalPerson = userProfile?.additionalInfo.legalPerson;
  const legalPersonName = `${legalPerson?.firstName} ${legalPerson?.middleName} ${legalPerson?.lastName}`;
  const hasSpecialTariffs = !!userProfile?.additionalInfo.hasSpecialTariffs;
  const specialTariffsText = hasSpecialTariffs ? t('discounted') : t('regular');
  const consumerType = userProfile?.additionalInfo?.type as CONSUMER_TYPE;

  const isModalOpen = useMemo(() => !!modalOptions, [modalOptions]);

  const openInfoModal = useCallback((title: string, subTitle?: string) => {
    setModalOptions({ title, subTitle });
  }, []);

  const closeInfoModal = useCallback(() => {
    setModalOptions(null);
  }, []);

  const resendInvitationEmail = useCallback(async () => {
    await resendInvitation({ userId: member?._id as string });
    openInfoModal(t('invitation_email_sent'));
  }, [member, openInfoModal, resendInvitation, t]);

  const legalEmail = useMemo(() => {
    if (member?.status === USER_STATUSES.PENDING) {
      return (
        <Flex style={{ alignItems: 'baseline', flexWrap: 'wrap' }}>
          <span style={{ marginRight: '4px' }}>{legalPerson?.email}</span>
          <Popover
            children={<ClockCircleOutlined />}
            context={t('pending_invitation')}
          />
          <StyledResendInvitationLink
            type="link"
            onClick={resendInvitationEmail}
          >
            {t('resend_invite')}
          </StyledResendInvitationLink>
        </Flex>
      );
    } else {
      return legalPerson?.email;
    }
  }, [member, legalPerson, t, resendInvitationEmail]);

  const userEditFormData = useUserEditFormData({
    hasDiscountedAccise: isDiscountedAcciseAvailable,
    hasSpecialTariffs,
    isAdminOrPMO,
    isCompany,
    legalPerson,
    legalPersonName,
    location,
    name,
    siretNumber,
  });

  const handleOnEdit = () => {
    if (userProfile?.role === USER_ROLES.CONSUMER) {
      setUserProfile(member);
      setEditMode(true);
    }
  };
  const handleOnSave = async (values: IMember) => {
    setEditMode(false);
    await updateMember(values);
    if (
      values.additionalInfo.legalPerson.email !==
      userProfile?.additionalInfo.legalPerson.email
    ) {
      openInfoModal(
        t('legal_person_email_updated'),
        t('invitation_email_was_sent', { email: legalPerson?.email }) as string,
      );
    }
    refetch();
  };

  return (
    <StyledBlockWrapper>
      {isLoadingResendInvitation && <Loader />}
      {isModalOpen && (
        <ModalMessage
          messageType="mail"
          title={modalOptions?.title as string}
          subTitle={modalOptions?.subTitle as string}
          btnText={t('ok')}
          handleBtn={closeInfoModal}
        />
      )}
      <StyledBlockHeader>
        <StyledBlockTitle>{t('profile_data')}</StyledBlockTitle>
        <Button
          size="small"
          type="text"
          disabled={isEditMode}
          onClick={handleOnEdit}
        >
          <EditIcon disabled={isEditMode} />
          {t('edit')}
        </Button>
      </StyledBlockHeader>
      <StyledBlockBody count={isEditMode ? 1 : 2}>
        <DynamicEditForm
          isEditMode={isEditMode}
          formData={userEditFormData}
          initialValues={userProfile}
          validationSchema={editConsumerSchema(t, consumerType)}
          onClose={() => setEditMode(false)}
          onSave={handleOnSave}
        />
        <DetailCard
          isEditMode={isEditMode}
          labelWidth={'150px'}
          valueWidth={'calc(100% - 150px - 30px)'}
          title={t('consumer_data').toString()}
          labels={getConsumerDataLabelsByCompanyType({
            hasAccise: isDiscountedAcciseAvailable,
            consumerType,
          })}
          values={getConsumerDataValuesByCompanyType({
            hasAccise: isDiscountedAcciseAvailable,
            consumerType,
            name,
            siretNumber,
            location,
            acciseType: isDiscountedAcciseAvailable ? specialTariffsText : null,
          })}
          isLoading={isLoadingMember}
        />
        <DetailCard
          isEditMode={isEditMode}
          labelWidth={'150px'}
          valueWidth={'calc(100% - 150px - 30px)'}
          title={t('legal_representative').toString()}
          labels={getLegalRepresentativeLabelsByCompanyType({ consumerType })}
          values={getLegalRepresentativeValuesByCompanyType({
            consumerType,
            name: legalPersonName,
            email: legalEmail,
            position: legalPerson?.position,
            contactNumber: legalPerson?.contactNumber,
          })}
          isLoading={isLoadingMember}
        />
      </StyledBlockBody>
    </StyledBlockWrapper>
  );
};

export default ProfileData;
