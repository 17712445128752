import styled from 'styled-components';

import { COLORS } from 'constants/colors';

export const Row = styled.div`
  display: grid;
  grid-template-columns: 199px 263px repeat(2, 132px);
  border-bottom: 1px solid ${COLORS.ColorSplit};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  padding-left: 8px;
`;

export const Cell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
`;

export const CellWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
