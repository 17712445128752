import React from 'react';
import { useTranslation } from 'react-i18next';

import { useFormikContext } from 'formik';

import { StyledAddressWrapper } from 'components/communities/CreateCommunity/add-pmo/AddressForm/styles';
import {
  StyledCity,
  StyledLocationWrapper,
  StyledPostalCode,
} from 'components/communities/CreateCommunity/setup-community/styles';
import { IChangeAddress, IFields } from 'components/members/types';
import AddressAutocomplete from 'components/shared/AddressAutocomplete';
import { ADDRESS_TYPE } from 'components/shared/AddressAutocomplete/types';
import FormikInput from 'components/shared/Input/FormikInput';
import { InputGroup } from 'components/shared/InputGroup';

import { numberOrEmptyStr } from 'utils/forms';

import { initialValues } from '../../constants';

type ProducerAddressFormProps = {
  setCoordinates: (coordinates: number[]) => void;
};
const ProducerAddressForm: React.FC<ProducerAddressFormProps> = ({
  setCoordinates,
}) => {
  const { t } = useTranslation();
  const { values, errors, setFieldValue } =
    useFormikContext<typeof initialValues>();

  const handleOnChangeAddress = async ({
    text,
    address,
    postcode,
    coordinates,
    type,
  }: IChangeAddress) => {
    const isEmptyAddressValue = values.address.street.length === 0;

    await setFieldValue('address.city', text);

    if (postcode) {
      await setFieldValue('address.postalCode', postcode);
    }

    if (coordinates) {
      await setCoordinates(coordinates);
    }

    switch (type) {
      case ADDRESS_TYPE.ADDRESS: {
        await setFieldValue('address.street', address);
        break;
      }

      default: {
        if (isEmptyAddressValue) {
          await setFieldValue('address.street', address);
        }
      }
    }
  };

  const handleOnCityChange = async (value: string) =>
    setFieldValue('address.city', value);
  const handleOnStreetChange = async (value: string) =>
    await setFieldValue('address.street', value);
  const handleOnPostcodeChange = async (value: string) =>
    await setFieldValue('address.postalCode', numberOrEmptyStr(value));

  return (
    <>
      <StyledAddressWrapper>
        <InputGroup label={t('address').toString()} isShowError={false}>
          <AddressAutocomplete
            isError={!!errors.address?.street}
            types={ADDRESS_TYPE.ADDRESS}
            placeholder={t('address_street_building_placeholder').toString()}
            value={values.address.street}
            onChange={handleOnStreetChange}
            onSelect={(fields: IFields) =>
              handleOnChangeAddress({
                ...fields,
                type: ADDRESS_TYPE.ADDRESS,
              })
            }
          />
        </InputGroup>
        <FormikInput
          fieldName="address.building"
          placeholder={t('additional_address_info').toString()}
        />
      </StyledAddressWrapper>
      <InputGroup
        error={
          errors.address?.city ||
          errors.address?.postalCode ||
          errors.address?.street
        }
      >
        <StyledLocationWrapper>
          <StyledPostalCode>
            <InputGroup label={t('postal_code').toString()} isShowError={false}>
              <AddressAutocomplete
                isError={!!errors.address?.postalCode}
                inputType="number"
                placeholder="Ex. 64205"
                value={values.address.postalCode}
                onChange={handleOnPostcodeChange}
                onSelect={(fields: IFields) =>
                  handleOnChangeAddress({
                    ...fields,
                    type: ADDRESS_TYPE.POSTCODE,
                  })
                }
              />
            </InputGroup>
          </StyledPostalCode>
          <StyledCity>
            <InputGroup label={t('city').toString()} isShowError={false}>
              <AddressAutocomplete
                isError={!!errors.address?.city}
                placeholder="Ex. Besançon"
                types={ADDRESS_TYPE.PLACE_REGION_POSTCODE}
                value={values.address.city}
                onChange={handleOnCityChange}
                onSelect={(fields: IFields) =>
                  handleOnChangeAddress({
                    ...fields,
                    type: ADDRESS_TYPE.PLACE_REGION_POSTCODE,
                  })
                }
              />
            </InputGroup>
          </StyledCity>
        </StyledLocationWrapper>
      </InputGroup>
      <InputGroup label={t('country').toString()} isShowError={false}>
        <FormikInput
          fieldName="address.country"
          placeholder={t('country').toString()}
          disabled
        />
      </InputGroup>
    </>
  );
};

export default ProducerAddressForm;
