import React, { CSSProperties } from 'react';

import { COLORS } from 'constants/colors';

import TooltipIcon from './TooltipIcon';
import { StyledTooltipContainer, StyledTooltipWrapper } from './styles';

type CustomTooltipProps = {
  tooltip: JSX.Element | string;
  withoutIcon?: boolean;
  children?: React.ReactNode;
  iconSize?: number;
  containerStyles?: CSSProperties;
  wrapperStyles?: CSSProperties;
};

const CustomTooltip: React.FC<CustomTooltipProps> = ({
  tooltip,
  withoutIcon,
  children,
  iconSize,
  containerStyles,
  wrapperStyles,
}) => (
  <StyledTooltipContainer
    title={tooltip}
    color={COLORS.BgElevated}
    style={containerStyles}
  >
    {!withoutIcon && (
      <StyledTooltipWrapper style={wrapperStyles}>
        <TooltipIcon size={iconSize ?? 14}></TooltipIcon>
      </StyledTooltipWrapper>
    )}
    {children}
  </StyledTooltipContainer>
);

export default CustomTooltip;
