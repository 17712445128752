import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import CardLine from 'components/communities/Communities/components/CardLine';
import {
  Card,
  CardBody,
  CardBodyColumn,
  CardBodyWrapper,
  CardChartContainer,
  CardChartTitle,
  CardHeader,
  CardTitle,
  ChartCardLine,
  ChartCardLineItem,
  ChartWrapper,
  Content,
  EnergyIconOrange,
  EnergyIconYellow,
  LegendText,
  PopoverIcon,
  TextWrapper,
  popoverOverlayStyles,
} from 'components/communities/Communities/styles';
import {
  prepareNextUrl,
  preparePieChartData,
} from 'components/communities/Communities/utils';
import CommunityCardChart from 'components/shared/PieChart';
import Popover from 'components/shared/Popover';
import { TextBold, TextRegular } from 'components/shared/Text';
import { SkeletonLine } from 'components/shared/Skeleton';
import { EnergyTypes } from 'constants/energy-types';
import AppRoutes from 'constants/routes';
import { Community, PERIOD } from 'constants/types';
import { useGetCommunitySelfConsumptionRatioQuery } from 'services/pmoDashboardManagment';
import { getUniqeConsumersById } from 'utils/index';
import { getDateRange } from 'utils/url/getDateRange';
import { StyledTitleSkeleton, StyledOrgNameSkeleton } from './styles';

interface ICommunityCard {
  community: Community;
  isLoading?: boolean;
}

const CommunityCard = ({
  community,
  isLoading: isLoadingCommunity,
}: ICommunityCard) => {
  const [hoveredType, setHoveredType] = useState(EnergyTypes.NONE);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const dateRange = getDateRange(PERIOD.LAST_TWELVE_MONTHS_INCLUDING_CURRENT);

  const {
    data: selfCommunityConsumptionRatio,
    isLoading: isLoadingCommunityConsuptionRatio,
  } = useGetCommunitySelfConsumptionRatioQuery({ communityId: community.id });

  const consumersAmount =
    community && getUniqeConsumersById(community?.consumers).length;
  const producersAmount = useMemo(() => {
    return Object.keys(
      community?.producers.reduce((acc: any, curr) => {
        if (curr.user) {
          acc[curr.user._id] = curr.user;
        }
        return acc;
      }, {}),
    ).length;
  }, [community]);

  const communityLocation = community?.location?.split(',')?.[0];
  const pvPower = `${community?.pvPower} MW`;
  const perimeter = `${community?.perimeter} km`;

  const nextUrl = prepareNextUrl(community.communityName);

  const hasValue = useMemo(
    () =>
      typeof selfCommunityConsumptionRatio === 'number' &&
      !Number.isNaN(selfCommunityConsumptionRatio),
    [selfCommunityConsumptionRatio],
  );
  const selfConsumedValue = useMemo(
    () => (hasValue ? selfCommunityConsumptionRatio : t('n_a')),
    [hasValue, t],
  );
  const gridEnergyValue = useMemo(
    () => (hasValue ? 100 - selfCommunityConsumptionRatio : t('n_a')),
    [hasValue, t],
  );

  return (
    <Card
      key={community.id}
      onClick={() =>
        navigate(
          `${AppRoutes.Communities}/${nextUrl}?name=${community.communityName}`,
          {
            state: { ...community, nextUrl: community.communityName },
          },
        )
      }
    >
      <CardHeader>
        {isLoadingCommunity ? (
          <StyledTitleSkeleton active width={120} height={24} />
        ) : (
          <CardTitle>{community.communityName}</CardTitle>
        )}
      </CardHeader>
      <CardBody>
        <CardBodyWrapper>
          <CardBodyColumn>
            <CardBodyWrapper>
              <CardBodyWrapper size={1}>
                <TextRegular>{t('location')}</TextRegular>
              </CardBodyWrapper>
              <CardBodyWrapper size={1}>
                {isLoadingCommunity ? (
                  <SkeletonLine active width={90} height={21} />
                ) : (
                  <TextWrapper>
                    <TextBold>{communityLocation}</TextBold>
                  </TextWrapper>
                )}
              </CardBodyWrapper>
            </CardBodyWrapper>
            <CardLine
              title={t('consumers')}
              value={
                isLoadingCommunity ? (
                  <SkeletonLine active width={20} height={21} />
                ) : (
                  consumersAmount
                )
              }
            />
            <CardLine
              title={t('producers')}
              value={
                isLoadingCommunity ? (
                  <SkeletonLine active width={20} height={21} />
                ) : (
                  producersAmount
                )
              }
            />
            <CardLine
              title={t('pv_power')}
              value={
                isLoadingCommunity ? (
                  <SkeletonLine active width={38} height={21} />
                ) : (
                  pvPower
                )
              }
            />
            <CardLine
              title={t('perimeter')}
              value={
                isLoadingCommunity ? (
                  <SkeletonLine active width={38} height={21} />
                ) : (
                  perimeter
                )
              }
            />
            <CardLine title={t('pmo')} />
            {isLoadingCommunity ? (
              <StyledOrgNameSkeleton active width={180} height={18} block />
            ) : (
              <TextBold>{community?.pmoOrgName}</TextBold>
            )}
            {isLoadingCommunity ? (
              <SkeletonLine active width={180} height={18} block />
            ) : (
              <TextWrapper width="200px">
                <TextRegular>{community.pmoEmail}</TextRegular>
              </TextWrapper>
            )}
          </CardBodyColumn>
        </CardBodyWrapper>
        <CardBodyWrapper>
          <CardChartContainer>
            <CardChartTitle>
              <TextBold>{t('self_consumption_ratio_ttm')}</TextBold>
              <Popover
                context={t('self_consumption_ratio_tooltip_content')}
                overlayStyles={popoverOverlayStyles}
              >
                <PopoverIcon />
              </Popover>
            </CardChartTitle>
            <ChartWrapper className="community-card">
              <CommunityCardChart
                chartData={preparePieChartData(selfCommunityConsumptionRatio)}
                handleOnHover={setHoveredType}
                handleOnBlur={() => setHoveredType(EnergyTypes.NONE)}
                isLoading={isLoadingCommunityConsuptionRatio}
                skeletonRadius={96}
              />
            </ChartWrapper>
            <Content>
              <ChartCardLine>
                <ChartCardLineItem>
                  <EnergyIconYellow />
                  <LegendText bold={hoveredType === EnergyTypes.SELF_CONSUMED}>
                    {t('self_consumed_energy')}
                  </LegendText>
                </ChartCardLineItem>
                <ChartCardLineItem>
                  {isLoadingCommunityConsuptionRatio ? (
                    <StyledOrgNameSkeleton active width={34} height={14} />
                  ) : (
                    [
                      <TextBold>{selfConsumedValue}</TextBold>,
                      <TextRegular>{' %'}</TextRegular>,
                    ]
                  )}
                </ChartCardLineItem>
              </ChartCardLine>
              <ChartCardLine>
                <ChartCardLineItem>
                  <EnergyIconOrange />
                  <LegendText bold={hoveredType === EnergyTypes.GRID_ENERGY}>
                    {t('grid_energy')}
                  </LegendText>
                </ChartCardLineItem>
                <ChartCardLineItem>
                  {isLoadingCommunityConsuptionRatio ? (
                    <SkeletonLine active width={34} height={14} />
                  ) : (
                    [
                      <TextBold>{gridEnergyValue}</TextBold>,
                      <TextRegular>{' %'}</TextRegular>,
                    ]
                  )}
                </ChartCardLineItem>
              </ChartCardLine>
            </Content>
          </CardChartContainer>
        </CardBodyWrapper>
      </CardBody>
    </Card>
  );
};

export default CommunityCard;
