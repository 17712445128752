import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { FormikValues } from 'formik';

import CreatePasswordUnsuccessful from 'components/auth/CreatePassword/components/CreatePasswordUnsuccessful';
import AuthWrapper from 'components/shared/AuthWrapper';
import PasswordForm from 'components/shared/PasswordForm';
import {
  usePutCreatePasswordMutation,
  useValidateTokenQuery,
} from 'services/userManagement';

import CreatePasswordSuccess from './components/CreatePasswordSuccess';

interface ICreatePassword {
  title: string;
  hasTokenValidation?: boolean;
}

const CreatePassword = ({
  title,
  hasTokenValidation = false,
}: ICreatePassword) => {
  const { t } = useTranslation();
  const { id, token = '' } = useParams();

  const { isError: isErrorValidation } = useValidateTokenQuery(token);
  const [putCreatePassword, { isError, isSuccess }] =
    usePutCreatePasswordMutation();

  if (isSuccess) {
    const headerText = hasTokenValidation
      ? t('password_updated')
      : t('password_created');
    const headerDescription = hasTokenValidation
      ? t('password_changed_success')
      : t('password_set_successfully');
    return (
      <AuthWrapper>
        <CreatePasswordSuccess
          headerText={headerText}
          headerDescription={headerDescription}
        />
      </AuthWrapper>
    );
  }

  if (isErrorValidation && hasTokenValidation) {
    return (
      <AuthWrapper>
        <CreatePasswordUnsuccessful
          headerText={t('link_expired')}
          descriptionText={t('try_again_description')}
          buttonText={t('try_again')}
        />
      </AuthWrapper>
    );
  }

  if (isError) {
    return (
      <AuthWrapper>
        <CreatePasswordUnsuccessful
          headerText={t('password_reset_error')}
          descriptionText={t('try_again_reset_password').toString()}
          buttonText={t('back_to_login')}
        />
      </AuthWrapper>
    );
  }

  const handleOnSubmit = ({ password }: FormikValues) => {
    putCreatePassword({ id, password });
  };

  return (
    <AuthWrapper>
      <PasswordForm
        header={title}
        subHeader={t('set_password').toString()}
        onSubmit={handleOnSubmit}
      />
    </AuthWrapper>
  );
};

export default CreatePassword;
