import { LoginResponse } from 'services/auth';

export enum LOCAL_STORAGE_KEYS {
  AccessToken = 'access_token',
  RefreshToken = 'refresh_token',
  IdToken = 'id_token',
}

export const setAuthTokens = (tokens: LoginResponse): void => {
  if (!tokens) return;

  if (tokens.access_token)
    localStorage.setItem(LOCAL_STORAGE_KEYS.AccessToken, tokens.access_token);
  if (tokens.refresh_token)
    localStorage.setItem(LOCAL_STORAGE_KEYS.RefreshToken, tokens.refresh_token);
  if (tokens.id_token)
    localStorage.setItem(LOCAL_STORAGE_KEYS.IdToken, tokens.id_token);
};

export const removeAuthTokens = (): void => {
  localStorage.removeItem(LOCAL_STORAGE_KEYS.AccessToken);
  localStorage.removeItem(LOCAL_STORAGE_KEYS.RefreshToken);
  localStorage.removeItem(LOCAL_STORAGE_KEYS.IdToken);
};
