import { styled } from 'styled-components';

import { DownOutlined, UpOutlined } from '@ant-design/icons';

import { TextBold, TextRegular } from 'components/shared/Text';
import { COLORS } from 'constants/colors';

export const StlyedTabWrapper = styled.div`
  background: ${COLORS.BgLayout};
  margin-top: 48px;
`;

export const StyledIndexationList = styled.div`
  margin-top: 32px;
`;

export const StyledDataRow = styled.div`
  background: ${COLORS.BgContainer};
  border-bottom: 1px solid ${COLORS.ColorSplit};
  display: flex;
  :first-child {
    width: 60%;
  }
  :last-child {
    width: 40%;
  }
`;

export const StlyedUpcomingTitle = styled.div`
  color: ${COLORS.Green7};
`;

export const StlyedDataRowTitle = styled.div`
  font-weight: 600;
  padding: 12px 8px;
  margin: auto 0;
`;

export const Title = styled(TextBold)`
  font-size: 16px;
`;

export const StyledDivider = styled.div<{ isExpanded: boolean }>`
  margin-top: ${({ isExpanded }) => (isExpanded ? '16px' : '0')};
  padding-top: ${({ isExpanded }) => (isExpanded ? '8px' : '0')};
`;

export const ArrowDown = styled(DownOutlined)`
  font-size: 14px;
  color: ${COLORS.TextBase};
`;

export const ArrowUp = styled(UpOutlined)`
  font-size: 14px;
  color: ${COLORS.TextBase};
`;

export const ArrowButton = styled.div`
  cursor: pointer;
  margin-right: 16px;
`;

export const ArrowText = styled(TextRegular)`
  font-size: 14px;
  font-weight: 600;
  margin-left: 8px;
`;

export const StyledHistoricalIndexationList = styled.div``;

export const StyledHistoricalHeaderRow = styled.div`
  background: ${COLORS.Grey7};
  border-bottom: 1px solid ${COLORS.ColorSplit};
  display: flex;
  :first-child {
    width: 60%;
  }
  :last-child {
    width: 40%;
  }
`;

export const StyledHistoricalDataRow = styled.div`
  background: ${COLORS.Grey6};
  border-bottom: 1px solid ${COLORS.ColorSplit};
  display: flex;
  :first-child {
    width: 60%;
  }
  :last-child {
    width: 40%;
  }
`;
