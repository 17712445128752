type Context = {
  id: string;
  text: string;
};

export interface Suggestion {
  center: number[];
  text: string;
  place_name: string;
  context: Context[];
}

export interface AutocompleteResponse {
  text: string;
  address: string;
  postcode: number | string;
  coordinates?: [number, number];
}

export enum ADDRESS_TYPE {
  NO_TYPE = '',
  PLACE = 'place',
  ADDRESS = 'address',
  POSTCODE = 'postcode',
  PLACE_REGION_POSTCODE = 'place,region,postcode',
}
