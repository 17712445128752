import styled from 'styled-components';
import { ScreenLarge } from 'constants/screen-breakpoints';
import { COLORS } from 'constants/colors';

export const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  gap: 32px;
  align-items: flex-end;
  justify-content: center;
  ${ScreenLarge`
    gap: 24px;  
  `}
`;

export const NavItem = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const NavTitle = styled.div<{ active: boolean; disabled?: boolean }>`
  padding: 10px 0;
  color: ${(props) =>
    props.disabled
      ? 'rgba(0, 0, 0, 0.25)'
      : props.active
        ? COLORS.Green7
        : COLORS.Grey4};
  font-size: 16px;
  font-weight: ${(props) => (props.active ? 600 : 400)};
  text-transform: uppercase;
  justify-content: center;
  cursor: ${(props) => props.disabled && 'not-allowed'};
  white-space: nowrap;
`;

export const Underline = styled.div<{ active: boolean }>`
  width: 100px;
  height: 4px;
  background-color: ${(props) =>
    props.active ? COLORS.Green7 : 'transparent'};
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
`;
