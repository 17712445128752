import { TFunction } from 'i18next';

import { COLORS } from 'constants/colors';

import { TableDataItem } from './types';

export const parseCommunityFollowUpTableData = (data: any, t: TFunction) => {
  const parsedData: TableDataItem[] = [];

  // Iterate over each consumer
  Object.keys(data).forEach((consumerName) => {
    const consumerData = data[consumerName];

    // Extract 'All prms' data for each period
    Object.keys(consumerData).forEach((period) => {
      const allPrmsData = consumerData[period].allPrms;

      // Add 'All prms' data to the result array
      parsedData.push({
        consumerName,
        sorterConsumerName: consumerName,
        sorterPeriod: period,
        prmNumber: t('all_prm'),
        period,
        selfConsumption: allPrmsData.selfConsumption,
        totalConsumption: allPrmsData.totalConsumption,
      });

      // Iterate over each PRM data for the period
      consumerData[period].data.forEach((prm: any, index: number, arr: any) => {
        const isInnerElement = index >= 0 && index !== arr.length - 1; // need this for styling a table
        // Add PRM data to the result array
        parsedData.push({
          consumerName: isInnerElement ? null : '',
          sorterConsumerName: consumerName,
          prmNumber: prm.prmNumber,
          sorterPeriod: period,
          period: '',
          selfConsumption: prm.selfConsumption,
          totalConsumption: prm.totalConsumption,
        });
      });
    });
  });

  const uniquePrmNumberArray = new Set(
    parsedData.map((item) => item.prmNumber),
  );
  const uniquePrmNumbersFilter = Array.from(uniquePrmNumberArray).map(
    (item) => ({
      text: item,
      value: item,
    }),
  );
  return { parsedData, prmNumbersFilters: uniquePrmNumbersFilter };
};

export const sharedOnCell = (record: TableDataItem) => ({
  style: {
    borderColor:
      record.consumerName === null || record.consumerName === 'none'
        ? COLORS.BgLayout
        : COLORS.ColorSplit,
  },
});
