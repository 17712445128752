import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import moment from 'moment/moment';

import { ConfigProvider } from 'antd';
import en_US from 'antd/locale/en_US';
import fr_FR from 'antd/locale/fr_FR';

import { COLORS } from 'constants/colors';

import 'utils/i18n';
import i18n from 'utils/i18n';

import App from './App';
import './index.css';
import { store } from './store';

moment.updateLocale('fr', {
  week: {
    dow: 1,
  },
});

// override default antd text
const updatedFrLocale = {
  ...fr_FR,
  Table: {
    ...fr_FR.Table,
    triggerAsc: 'Cliquez pour trier par ordre croissant',
    triggerDesc: 'Cliquez pour trier par ordre décroissant',
    cancelSort: 'Cliquez pour annuler le tri',
  },
};

const container = document.getElementById('root')!;
const root = createRoot(container);
const locale = i18n.language.includes('fr') ? updatedFrLocale : en_US;

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConfigProvider
        theme={{
          token: {
            fontFamily: `Poppins, sans-serif`,
            colorPrimary: `${COLORS.Green6}`,
          },
          components: {
            Button: {
              colorPrimary: `${COLORS.Green6}`,
            },
            Input: {
              colorPrimary: `${COLORS.Green6}`,
            },
          },
        }}
        locale={locale}
      >
        <App />
      </ConfigProvider>
    </Provider>
  </React.StrictMode>,
);
