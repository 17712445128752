import { TFunction } from 'i18next';

export const producerLabels = (t: TFunction) => ({
  first: t('producer_name'),
  second: t('siret_number'),
  third: t('location'),
});

export const consumerLabels = (t: TFunction) => ({
  first: t('consumer_name'),
  second: t('siret_number'),
  third: t('location'),
});

export const legalRepresentativeLabels = (t: TFunction) => ({
  first: t('legal_person_name'),
  second: t('legal_person_email_address'),
  third: t('legal_person_position'),
  fourth: t('legal_representative_contact_number'),
});
