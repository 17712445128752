import { styled } from 'styled-components';

import { COLORS } from 'constants/colors';

export const ChartContainer = styled.div`
  display: flex;
  flex: 1;
  margin: 24px;
  width: 80%;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;

  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  background: ${COLORS.BgContainer};
  margin-bottom: 20px;
`;

export const CardBody = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: space-between;
  row-gap: 50px;
  align-items: center;
  margin-top: 34px;
  min-height: 442px;
`;
