import * as yup from 'yup';

import { TFunction } from 'i18next';

export const taxPriceSchema = (t: TFunction) =>
  yup.object().shape({
    vat: yup
      .number()
      .required(t('field_is_required').toString())
      .positive(t('value_should_be_positive').toString()),
    ht: yup
      .number()
      .required(t('field_is_required').toString())
      .positive(t('value_should_be_positive').toString()),
    ttc: yup
      .number()
      .required(t('field_is_required').toString())
      .positive(t('value_should_be_positive').toString()),
  });
