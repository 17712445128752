import { TFunction } from 'i18next';

export const someEarningsDummyData = (t: TFunction) => [
  {
    date: t('january'),
    value: 123,
    type: t('earned_tax_refund'),
  },
  {
    date: t('january'),
    value: 200,
    type: t('earned_from_community'),
  },
  {
    date: t('february'),
    value: 321,
    type: t('earned_tax_refund'),
  },
  {
    date: t('february'),
    value: 21,
    type: t('earned_from_community'),
  },
  {
    date: t('march'),
    value: 456,
    type: t('earned_tax_refund'),
  },
  {
    date: t('march'),
    value: 556,
    type: t('earned_from_community'),
  },
  {
    date: t('april'),
    value: 345,
    type: t('earned_tax_refund'),
  },
  {
    date: t('april'),
    value: 445,
    type: t('earned_from_community'),
  },
  {
    date: t('may'),
    value: 789,
    type: t('earned_tax_refund'),
  },
  {
    date: t('may'),
    value: 889,
    type: t('earned_from_community'),
  },
  {
    date: t('june'),
    value: 567,
    type: t('earned_tax_refund'),
  },
  {
    date: t('june'),
    value: 667,
    type: t('earned_from_community'),
  },
  {
    date: t('july'),
    value: 345,
    type: t('earned_tax_refund'),
  },
  {
    date: t('july'),
    value: 445,
    type: t('earned_from_community'),
  },
  {
    date: t('august'),
    value: 876,
    type: t('earned_tax_refund'),
  },
  {
    date: t('august'),
    value: 976,
    type: t('earned_from_community'),
  },
  {
    date: t('september'),
    value: 234,
    type: t('earned_tax_refund'),
  },
  {
    date: t('september'),
    value: 334,
    type: t('earned_from_community'),
  },
  {
    date: t('october'),
    value: 123,
    type: t('earned_tax_refund'),
  },
  {
    date: t('october'),
    value: 0,
    type: t('earned_from_community'),
  },
  {
    date: t('november'),
    value: 345,
    type: t('earned_tax_refund'),
  },
  {
    date: t('november'),
    value: 0,
    type: t('earned_from_community'),
  },
  {
    date: t('december'),
    value: 435,
    type: t('earned_tax_refund'),
  },
  {
    date: t('december'),
    value: 0,
    type: t('earned_from_community'),
  },
];
