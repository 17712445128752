import { styled } from 'styled-components';

import { Button, DatePicker, Divider, Radio } from 'antd';
import { ScreenMedium } from 'constants/screen-breakpoints';
import { COLORS } from 'constants/colors';

import { TextRegular } from '../Text';

const { RangePicker } = DatePicker;

export const StyledWrapper = styled.div`
  width: 800px;
  height: 383px;
  background-color: ${COLORS.BgContainer};
  border-radius: 10px;
`;

export const StyledRow = styled.div`
  display: flex;
`;

export const StyledRadioRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  border-right: 1px solid ${COLORS.ColorSplit};
  padding: 0px 5px;
  margin: 5px 0px;
`;

export const StyledRadio = styled(Radio)`
  padding: 20px 10px;

  .ant-radio-checked .ant-radio-inner:after {
    background-color: ${COLORS.PrimaryBase};
    width: 20px;
    height: 20px;
    margin-block-start: -10px;
    margin-inline-start: -10px;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: ${COLORS.PrimaryBase};
    background-color: ${COLORS.BgLayout};
  }

  .ant-radio-wrapper:hover {
    .ant-radio-inner {
      border-color: ${COLORS.PrimaryBase} !important;
    }
  }
`;

export const StyledDivider = styled(Divider)`
  margin: 0px;
  background-color: ${COLORS.ColorSplit};
`;

export const StyledDatePickersRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px;
  padding-right: 14px;
`;

export const StyledButtonsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  column-gap: 20px;
  margin-top: 5px;
`;

export const StyledCancelButton = styled(Button)`
  border: none;
  color: ${COLORS.Green8};
  min-width: 67px;
  box-shadow: none;
  font-size: 14px;
`;

export const StyledApplyButton = styled(Button)`
  border: none;
  background-color: ${COLORS.Green5};
  box-shadow: none;
  height: 25px;
  font-size: 14px;
  display: flex;
  max-width: 70px;
  align-items: center;
  justify-content: center;
`;

export const StyledDatePickerWrapper = styled.div`
  height: 330px;
`;

export const StyledDatePicker = styled(RangePicker)`
  width: 100%;
  height: 40px;
  background-color: ${COLORS.BgElevated} !important;
  border: none;

  .ant-picker-dropdown {
    display: none !important;
  }

  .ant-picker-dropdown .ant-picker-panel-container {
    box-shadow: 0px !important;
  }

  .ant-picker-footer {
    display: none !important;
  }
`;

export const StyledInput = styled.div`
  box-sizing: border-box;
  font-size: 14px;
  line-height: 22px;
  min-width: 162px;
  padding: 0px 12px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  border: 1px solid #b9b9b9;
  background: ${COLORS.BgContainer};
  transition: all 0.2s ease;
  cursor: pointer;

  &:hover {
    color: ${COLORS.Green7};
    border-color: ${COLORS.Green7};
  }
`;

export const StyledLabel = styled(TextRegular)`
  width: auto;
  text-align: right;
  white-space: nowrap;

  &::first-letter {
    text-transform: uppercase;
  }

  ${ScreenMedium`
    width: auto;
  `}
`;
