import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'antd';

import { StyledDownloadOutlined } from 'components/communities/styles';
import { TextRegular } from 'components/shared/Text';
import CustomTooltip from 'components/shared/Tooltip';

const DownloadAction = () => {
  const { t } = useTranslation();

  return (
    <CustomTooltip
      tooltip={<TextRegular>{t('download_invoice')}</TextRegular>}
      withoutIcon
    >
      <Button size="small" icon={<StyledDownloadOutlined />} />
    </CustomTooltip>
  );
};

export default DownloadAction;
