import parsePhoneNumber from 'libphonenumber-js';
import * as yup from 'yup';

import { SIRET_LENGTH } from 'constants/global';

export const MAX_DISTANCE = 20;
export const MIN_DISTANCE = 2;
export const MAX_POWER = 3;
export const MIN_POWER = 0.5;
export const MIN_SUBSCRIPTION_POWER = 0;
export const MAX_SUBSCRIPTION_POWER = 250;
export const MAX_PRIVE_HOUSE_SUBSCRIPTION_POWER = 36;
export const MIN_MEDIUM_COMPANY_SUBSCRIPTION_POWER = 37;
export const MIN_LARGE_SUBSCRIPTION_POWER = 251;
export const EXACT_PRM_NUMBER_LENGTH = 14;
export const POSTAL_CODE_LENGTH = 5;
export const MIN_MEMBERS = 1;
export const EXACT_VAT_NUMBER_LENGTH = 11;

declare module 'yup' {
  interface NumberSchema {
    phoneNumber(message?: string): NumberSchema;
  }
}

yup.addMethod(yup.number, 'phoneNumber', function () {
  return this.test(
    'phoneNumber',
    'Please enter a valid phone number',
    (value) => {
      if (typeof value === 'undefined' || value === null) {
        return false;
      }

      let phoneNumber = parsePhoneNumber('+' + value);
      if (phoneNumber) {
        return phoneNumber.isValid();
      }
      return false;
    },
  );
});

export const postalCodeValidation = (t: (key: string) => string) =>
  yup
    .number()
    .nullable()
    .required(t('validation.postal_code_required'))
    .test('is-five-digits', t('validation.wrong_format'), (value) => {
      if (value) {
        const numberString = value.toString();
        return numberString.length === POSTAL_CODE_LENGTH && +numberString > 0;
      }
      return false;
    });

export const siretNumberValidation = (t: (key: string) => string) =>
  yup
    .number()
    .required(t('validation.siret_number_required'))
    .test('is-enough-digits', t('validation.wrong_format'), (value) => {
      if (value) {
        const numberString = value.toString();
        return numberString.length === SIRET_LENGTH;
      }
      return false;
    });

export const emailValidation = (
  t: (key: string) => string,
  translationKey: string,
) =>
  yup
    .string()
    .required(t(translationKey))
    .matches(
      /^[a-zA-Z0-9][a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      t('validation.wrong_email_format'),
    );

export const phoneValidation = (
  t: (key: string) => string,
  translationKey: string,
) => yup.number().nullable().phoneNumber().required(t(translationKey));

export const getMinPowerCosnumerTypes = (type: string) => {
  switch (type) {
    case 'private':
      return MIN_SUBSCRIPTION_POWER;
    case 'small':
      return MIN_SUBSCRIPTION_POWER;
    case 'medium':
      return MIN_MEDIUM_COMPANY_SUBSCRIPTION_POWER;
    case 'large':
      return MIN_LARGE_SUBSCRIPTION_POWER;
    case 'collectivity':
      return MIN_SUBSCRIPTION_POWER;
    default:
      return MIN_SUBSCRIPTION_POWER;
  }
};
export const getMaxPowerCosnumerTypes = (type: string) => {
  switch (type) {
    case 'private':
      return MAX_PRIVE_HOUSE_SUBSCRIPTION_POWER;
    case 'small':
      return MAX_PRIVE_HOUSE_SUBSCRIPTION_POWER;
    case 'medium':
      return MAX_SUBSCRIPTION_POWER;
    case 'large':
      return Number.MAX_SAFE_INTEGER;
    case 'collectivity':
      return MAX_SUBSCRIPTION_POWER;
    default:
      return MAX_SUBSCRIPTION_POWER;
  }
};
