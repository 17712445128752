import * as yup from 'yup';

import { TFunction } from 'i18next';

export const gridPricesSchema = (t: TFunction) =>
  yup.object().shape({
    prm: yup.string().required(),
    startDate: yup.date().required(),
    HS_HH: yup.number().required(),
    HS_LH: yup.number().required(),
    LS_HH: yup.number().required(),
    LS_LH: yup.number().required(),
    RH: yup.number().required(),
  });
