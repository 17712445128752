import { getAppRole } from 'utils';

import { USER_ROLES } from 'constants/global';

import ConsumerFinancialData from '../ConsumerDashboard/components/FinancialDataTab';
import ProducerFinancialData from '../ProducerDashboard/components/FinancialDataTab';

const FinancialData = () => {
  const role = getAppRole();

  return (
    <>
      {role === USER_ROLES.CONSUMER ? (
        <ConsumerFinancialData />
      ) : (
        <ProducerFinancialData />
      )}
    </>
  );
};

export default FinancialData;
