import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import AppRoutes from 'constants/routes';
import { checkAuth, useRefreshTokenMutation } from 'services/auth';

import { LOCAL_STORAGE_KEYS, removeAuthTokens } from 'utils/local-storage';

interface PrivateRouteProps {
  children: React.ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [refresh] = useRefreshTokenMutation();

  useEffect(() => {
    if (!navigate || !refresh) return;
    const checkAuthentication = async () => {
      // Get access token from wherever it's stored
      let accessToken = localStorage.getItem(LOCAL_STORAGE_KEYS.AccessToken);

      if (!accessToken) {
        // If there's no access token, we redirect to sign-in page
        navigate(AppRoutes.SignIn);
        return;
      }

      try {
        // Check if the token is valid
        const response = await checkAuth(accessToken);

        if (response.status === 200) {
          setLoading(false);
          return;
        }
      } catch (error: any) {
        // If token is invalid, we request a new one
        const refreshToken = localStorage.getItem(
          LOCAL_STORAGE_KEYS.RefreshToken,
        );

        if (!refreshToken) {
          removeAuthTokens();
          navigate(AppRoutes.SignIn);
          return;
        }

        try {
          const newTokens = await refresh({
            refresh_token: refreshToken,
          }).unwrap();
          const newAccessToken = newTokens.access_token;

          // Store the new access token
          localStorage.setItem(LOCAL_STORAGE_KEYS.AccessToken, newAccessToken);

          // Verify the new token
          const verifyResponse = await checkAuth(newAccessToken);

          if (verifyResponse.status === 200) {
            setLoading(false);
            return;
          }
        } catch (refreshError) {
          removeAuthTokens();
          console.error(refreshError);
          // If refreshing the token also fails, we redirect to the sign-in page
          navigate(AppRoutes.SignIn);
          return;
        }
      }

      setLoading(false);
    };

    checkAuthentication();
  }, [navigate, refresh]);

  // Render a loading indicator while the token verification is in progress
  if (loading) {
    return <div>Loading...</div>;
  }

  return <>{children}</>;
};

export default PrivateRoute;
