import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Space } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';

import {
  fetchAndDownloadPdfFile,
  fetchViewerPdfFile,
} from 'components/communities/Dashboard/utils';
import DownloadAction from 'components/communities/components/DownloadAction';
import ViewAction from 'components/communities/components/ViewAction';
import { MAX_PAGINATION_PAGE_SIZE } from 'components/communities/constants';
import Loader from 'components/shared/Loader';
import PdfViewer from 'components/shared/PdfViewer';
import { TextRegular } from 'components/shared/Text';
import { useGetTaxRefundInvoiceHistoryQuery } from 'services/dashboardManagment';

import { Card } from '../../styles';
import { columns } from './columns';
import { StyledTaxRefundWrapper, TaxRefundInvoiceTable } from './styles';

const TaxRefundInvoicesTab = () => {
  const { t } = useTranslation();
  const { id: userId } = useParams();

  const {
    data: tableData,
    isLoading,
    isSuccess,
  } = useGetTaxRefundInvoiceHistoryQuery({ userId });
  const [pdfFile, setPdfFile] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>(
    {
      current: 1,
      pageSize: 10,
    },
  );

  const handleViewPdfFile = async (
    downloadLink: string,
    dateCreated: string,
  ) => {
    try {
      const url: any | undefined = await fetchViewerPdfFile(
        downloadLink,
        dateCreated,
      );

      if (url) {
        setPdfFile(url);
        setIsModalOpen(true);
      }
      if (!url) {
        setPdfFile('');
        setIsModalOpen(false);
      }
    } catch (error) {
      setIsModalOpen(false);
    }
  };
  const handlePaginationChange = (page: number, pageSize: number) => {
    setTablePagination((prev) => ({ ...prev, current: page, pageSize }));
  };

  const modifiedColumns: ColumnsType<any> = [
    ...columns(t),
    {
      title: t('action').toUpperCase(),
      width: '8%',
      render: ({ downloadLink, dateCreated }) => (
        <Space>
          <div
            onClick={() => fetchAndDownloadPdfFile(downloadLink, dateCreated)}
          >
            <DownloadAction />
          </div>
          <div onClick={() => handleViewPdfFile(downloadLink, dateCreated)}>
            <ViewAction />
          </div>
        </Space>
      ),
    },
  ];

  const hasEnoughDataToShowPagination =
    tableData && tableData?.length > MAX_PAGINATION_PAGE_SIZE;

  return (
    <>
      <StyledTaxRefundWrapper>
        {isLoading ? (
          <Loader />
        ) : isSuccess && tableData && tableData?.length > 0 ? (
          <TaxRefundInvoiceTable
            columns={modifiedColumns}
            dataSource={tableData}
            pagination={
              hasEnoughDataToShowPagination
                ? {
                    ...tablePagination,
                    position: ['bottomCenter'],
                    showSizeChanger: true,
                    onChange: handlePaginationChange,
                    showTotal: (total) =>
                      `${t('total')} ${total} ${t('items')}`,
                  }
                : false
            }
          />
        ) : (
          <Card isEmpty>
            <TextRegular>{t('no_invoices').toUpperCase()}</TextRegular>
          </Card>
        )}
      </StyledTaxRefundWrapper>
      <PdfViewer
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        pdfFile={pdfFile}
      />
    </>
  );
};

export default TaxRefundInvoicesTab;
