import { styled } from 'styled-components';

import { Tooltip } from 'antd';

export const StyledTooltipWrapper = styled.span`
  cursor: pointer;
  pointer-events: auto;
`;

export const StyledTooltipContainer = styled(Tooltip)`
  & .ant-tooltip-inner {
    padding: 12px;
  }
`;
