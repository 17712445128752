import React from 'react';

const Icon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="36"
      viewBox="0 0 32 36"
      fill="none"
    >
      <path
        d="M31.1172 10.2657H30.6953V7.0313C30.6953 6.64294 30.3803 6.32818 29.9922 6.32818H19.1404V4.50005C19.1404 4.17321 18.9152 3.88949 18.5969 3.81533L2.28439 0.0184566C2.07565 -0.0304325 1.85565 0.0190059 1.68784 0.15249C1.51974 0.285974 1.42197 0.488671 1.42197 0.703179V31.7813H0.882812C0.494446 31.7813 0.179688 32.096 0.179688 32.4844V35.2969C0.179688 35.6853 0.494446 36 0.882812 36H31.1172C31.5053 36 31.8203 35.6853 31.8203 35.2969V26.2969C31.8203 25.9086 31.5053 25.5938 31.1172 25.5938C30.7288 25.5938 30.4141 25.9086 30.4141 26.2969V34.5938H16.6328V11.6719H30.4141V19.9688C30.4141 20.3572 30.7288 20.6719 31.1172 20.6719C31.5053 20.6719 31.8203 20.3572 31.8203 19.9688V10.9688C31.8203 10.5804 31.5053 10.2657 31.1172 10.2657ZM2.82794 29.8125H10.2811C10.6694 29.8125 10.9842 29.4978 10.9842 29.1094C10.9842 28.7211 10.6694 28.4063 10.2811 28.4063H2.82794V26.4375H10.2811C10.6694 26.4375 10.9842 26.1228 10.9842 25.7344C10.9842 25.3461 10.6694 25.0313 10.2811 25.0313H2.82794V23.0625H10.2811C10.6694 23.0625 10.9842 22.7478 10.9842 22.3594C10.9842 21.9711 10.6694 21.6563 10.2811 21.6563H2.82794V19.6875H10.2811C10.6694 19.6875 10.9842 19.3728 10.9842 18.9844C10.9842 18.5961 10.6694 18.2813 10.2811 18.2813H2.82794V16.3125H10.2811C10.6694 16.3125 10.9842 15.9978 10.9842 15.6094C10.9842 15.2211 10.6694 14.9063 10.2811 14.9063H2.82794V12.9375H10.2811C10.6694 12.9375 10.9842 12.6228 10.9842 12.2344C10.9842 11.8461 10.6694 11.5313 10.2811 11.5313H2.82794V9.56255H10.2811C10.6694 9.56255 10.9842 9.24779 10.9842 8.85943C10.9842 8.47106 10.6694 8.1563 10.2811 8.1563H2.82794V1.58895L17.7342 5.05843V6.32818H17.0547C16.6663 6.32818 16.3516 6.64294 16.3516 7.0313V10.2657H15.9297C15.5413 10.2657 15.2266 10.5804 15.2266 10.9688V31.7813H2.82794V29.8125ZM1.58594 33.1875H15.2266V34.5938H1.58594V33.1875ZM17.7578 10.2657V7.73443H29.2891V10.2657H17.7578Z"
        fill="black"
        fillOpacity="0.25"
      />
      <path
        d="M20.2891 14.8496C20.2891 14.4615 19.974 14.1465 19.5859 14.1465C19.1976 14.1465 18.8828 14.4615 18.8828 14.8496V16.8184C18.8828 17.2067 19.1976 17.5215 19.5859 17.5215C19.974 17.5215 20.2891 17.2067 20.2891 16.8184V14.8496Z"
        fill="black"
        fillOpacity="0.25"
      />
      <path
        d="M24.2266 14.8496C24.2266 14.4615 23.9115 14.1465 23.5234 14.1465C23.1351 14.1465 22.8203 14.4615 22.8203 14.8496V16.8184C22.8203 17.2067 23.1351 17.5215 23.5234 17.5215C23.9115 17.5215 24.2266 17.2067 24.2266 16.8184V14.8496Z"
        fill="black"
        fillOpacity="0.25"
      />
      <path
        d="M28.1641 14.8496C28.1641 14.4615 27.849 14.1465 27.4609 14.1465C27.0726 14.1465 26.7578 14.4615 26.7578 14.8496V16.8184C26.7578 17.2067 27.0726 17.5215 27.4609 17.5215C27.849 17.5215 28.1641 17.2067 28.1641 16.8184V14.8496Z"
        fill="black"
        fillOpacity="0.25"
      />
      <path
        d="M20.2891 19.7158C20.2891 19.3275 19.974 19.0127 19.5859 19.0127C19.1976 19.0127 18.8828 19.3275 18.8828 19.7158V21.6846C18.8828 22.0727 19.1976 22.3877 19.5859 22.3877C19.974 22.3877 20.2891 22.0727 20.2891 21.6846V19.7158Z"
        fill="black"
        fillOpacity="0.25"
      />
      <path
        d="M24.2266 19.7158C24.2266 19.3275 23.9115 19.0127 23.5234 19.0127C23.1351 19.0127 22.8203 19.3275 22.8203 19.7158V21.6846C22.8203 22.0727 23.1351 22.3877 23.5234 22.3877C23.9115 22.3877 24.2266 22.0727 24.2266 21.6846V19.7158Z"
        fill="black"
        fillOpacity="0.25"
      />
      <path
        d="M28.1641 19.7158C28.1641 19.3275 27.849 19.0127 27.4609 19.0127C27.0726 19.0127 26.7578 19.3275 26.7578 19.7158V21.6846C26.7578 22.0727 27.0726 22.3877 27.4609 22.3877C27.849 22.3877 28.1641 22.0727 28.1641 21.6846V19.7158Z"
        fill="black"
        fillOpacity="0.25"
      />
      <path
        d="M20.2891 24.5811C20.2891 24.1927 19.974 23.8779 19.5859 23.8779C19.1976 23.8779 18.8828 24.1927 18.8828 24.5811V26.5498C18.8828 26.9382 19.1976 27.2529 19.5859 27.2529C19.974 27.2529 20.2891 26.9382 20.2891 26.5498V24.5811Z"
        fill="black"
        fillOpacity="0.25"
      />
      <path
        d="M24.2266 24.5811C24.2266 24.1927 23.9115 23.8779 23.5234 23.8779C23.1351 23.8779 22.8203 24.1927 22.8203 24.5811V26.5498C22.8203 26.9382 23.1351 27.2529 23.5234 27.2529C23.9115 27.2529 24.2266 26.9382 24.2266 26.5498V24.5811Z"
        fill="black"
        fillOpacity="0.25"
      />
      <path
        d="M28.1641 24.5811C28.1641 24.1927 27.849 23.8779 27.4609 23.8779C27.0726 23.8779 26.7578 24.1927 26.7578 24.5811V26.5498C26.7578 26.9382 27.0726 27.2529 27.4609 27.2529C27.849 27.2529 28.1641 26.9382 28.1641 26.5498V24.5811Z"
        fill="black"
        fillOpacity="0.25"
      />
      <path
        d="M19.5859 28.7441C19.1976 28.7441 18.8828 29.0589 18.8828 29.4473V31.416C18.8828 31.8044 19.1976 32.1191 19.5859 32.1191C19.974 32.1191 20.2891 31.8044 20.2891 31.416V29.4473C20.2891 29.0589 19.974 28.7441 19.5859 28.7441Z"
        fill="black"
        fillOpacity="0.25"
      />
      <path
        d="M23.5234 28.7441C23.1351 28.7441 22.8203 29.0589 22.8203 29.4473V31.416C22.8203 31.8044 23.1351 32.1191 23.5234 32.1191C23.9115 32.1191 24.2266 31.8044 24.2266 31.416V29.4473C24.2266 29.0589 23.9115 28.7441 23.5234 28.7441Z"
        fill="black"
        fillOpacity="0.25"
      />
      <path
        d="M27.4609 28.7441C27.0726 28.7441 26.7578 29.0589 26.7578 29.4473V31.416C26.7578 31.8044 27.0726 32.1191 27.4609 32.1191C27.849 32.1191 28.1641 31.8044 28.1641 31.416V29.4473C28.1641 29.0589 27.849 28.7441 27.4609 28.7441Z"
        fill="black"
        fillOpacity="0.25"
      />
      <path
        d="M31.1172 22.4297C30.9321 22.4297 30.7508 22.5049 30.6201 22.6357C30.489 22.7664 30.4141 22.948 30.4141 23.1328C30.4141 23.3177 30.489 23.4992 30.6201 23.6299C30.7508 23.7607 30.9321 23.8359 31.1172 23.8359C31.302 23.8359 31.4833 23.7607 31.614 23.6299C31.7448 23.4992 31.8203 23.3177 31.8203 23.1328C31.8203 22.948 31.7451 22.7664 31.614 22.6357C31.4833 22.5049 31.302 22.4297 31.1172 22.4297Z"
        fill="black"
        fillOpacity="0.25"
      />
    </svg>
  );
};

export default Icon;
