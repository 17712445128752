import { styled } from 'styled-components';

import { COLORS } from 'constants/colors';

export const SelfConsumptionBlock = styled.div`
  display: inline-flex;
  justify-content: center;
  flex: 1;
`;

export const SelfConsumptionItems = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  margin: 12px 0;
`;

export const SelfConsumptionItem = styled.div<{ highlighted?: boolean }>`
  display: flex;
  flex: 1;
  align-items: center;

  height: 46px;
  padding: 12px;

  border-radius: 6px;
  border: ${({ highlighted }) =>
    highlighted ? `1px solid ${COLORS.Border}` : '1px solid transparent'};
  background: ${COLORS.BgContainer};

  box-shadow: ${({ highlighted }) =>
    highlighted
      ? `0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05)`
      : 'none'};
`;

export const SelfConsumptionItemTitle = styled.div`
  display: inline-flex;
  align-items: center;
  width: 170px;
  height: 24px;
`;

export const SelfConsumptionLegendItem = styled.div<{ color: string }>`
  width: 8px;
  height: 24px;
  border-radius: 2px;
  margin-right: 8px;
  background-color: ${(props) => props.color};
`;

export const SelfConsumptionItemAmount = styled.span`
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
`;

export const SelfConsumptionItemPercent = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  color: ${COLORS.TextSecondary};
`;
