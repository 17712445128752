import { RootState } from 'store';

import { createSelector, createSlice } from '@reduxjs/toolkit';

import { taxPricesApi } from 'services/taxPrices.service';
import { ITaxPrices } from 'services/taxPrices.types';

interface TaxPricesState {
  taxPrices: ITaxPrices;
}

const initialState: TaxPricesState = {
  taxPrices: {
    historical: [],
    current: [],
    upcoming: [],
  },
};

export const taxPricesSlice = createSlice({
  name: 'taxPrices',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      taxPricesApi.endpoints.getTaxPrices.matchFulfilled,
      (state, { payload }) => {
        state.taxPrices = payload;
      },
    );
  },
});

export default taxPricesSlice.reducer;

export const selectTaxPricesSlice = (state: RootState) =>
  state.taxPrices.taxPrices;

export const selectTaxPrices = createSelector(
  selectTaxPricesSlice,
  (taxPrices) => taxPrices,
);
