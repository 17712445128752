import { useTranslation } from 'react-i18next';

import { Flex } from 'antd';

import { TFunction } from 'i18next';

import { popoverOverlayStyles } from 'components/communities/Communities/styles';
import Popover from 'components/shared/Popover';
import { PERIOD, USER_ROLES } from 'constants/types';
import { useGetConsumerSelfProductionRatioQuery } from 'services/dashboardManagment';

import { getDateRange } from 'utils/url/getDateRange';

import { StyledSelfProductionWrapper } from '../styles';
import Loader from './components/Loader/Loader';

const SelfProductionRatio = ({
  userId,
  role,
}: {
  userId?: string;
  role?: string;
}) => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetConsumerSelfProductionRatioQuery({
    ...getDateRange(PERIOD.LAST_TWELVE_MONTHS),
    userId,
  });

  return (
    <StyledSelfProductionWrapper>
      {isLoading ? <Loader /> : renderSelfProductionRatioByRole(t, role, data)}
    </StyledSelfProductionWrapper>
  );
};

export default SelfProductionRatio;

const renderSelfProductionRatioByRole = (
  t: TFunction,
  role?: USER_ROLES | string,
  selfProductionRatio?: number,
) => {
  if (!selfProductionRatio) return '-';
  switch (role) {
    case USER_ROLES.CONSUMER:
      return `${selfProductionRatio}%`;
    case USER_ROLES.PRODUCER:
      return (
        <Flex align="center">
          {`${selfProductionRatio}%`}
          <Popover
            context={t('members_table_producer_ratio_tooltip')}
            overlayStyles={popoverOverlayStyles}
          />
        </Flex>
      );
    case USER_ROLES.PMO_ADMIN:
      return (
        <Flex align="center">
          {`${selfProductionRatio}%`}
          <Popover
            context={t('members_table_pmo_ratio_tooltip')}
            overlayStyles={popoverOverlayStyles}
          />
        </Flex>
      );
  }
};
