import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useField, useFormikContext } from 'formik';

import { FormInput } from 'components/shared/Input';
import { InputGroup } from 'components/shared/InputGroup';

import { useDebounce } from 'utils/hooks';

import { initialValues } from '../../constants';
import { BillingInfoValuesType } from './types';

interface BillingFullNameProps {
  values: BillingInfoValuesType;
  label?: string;
}

const BillingFullName: FC<BillingFullNameProps> = ({ values, label }) => {
  const { t } = useTranslation();
  const { setFieldValue } = useFormikContext<typeof initialValues>();
  const [fullName, setFullName] = useState('');
  const [, firstNameMeta] = useField(values.firstName);
  const [, lastNameMeta] = useField(values.lastName);

  const isError = !!(firstNameMeta.error || lastNameMeta.error);

  const handleFullNameChange = useCallback(
    async (fullName: string) => {
      const fullNameArr = fullName.trim().split(' ');

      const firstName = fullNameArr[0] || '';
      const lastName =
        fullNameArr.length > 1 ? fullNameArr[fullNameArr.length - 1] : '';
      const middleName =
        fullNameArr.length > 2
          ? fullNameArr
              .slice(1, fullNameArr.length - 1)
              .join(' ')
              .trim()
          : '';

      await setFieldValue(values.firstName, firstName);
      await setFieldValue(values.lastName, lastName);
      await setFieldValue(values.middleName, middleName);
    },
    [setFieldValue, values.firstName, values.middleName, values.lastName],
  );

  const debouncedFullName = useDebounce(fullName, 100);

  useEffect(() => {
    handleFullNameChange(debouncedFullName);
  }, [debouncedFullName, handleFullNameChange]);

  return (
    <InputGroup
      label={label ? label : t('billing_responsible').toString()}
      error={firstNameMeta.error || lastNameMeta.error}
    >
      <FormInput
        type="text"
        placeholder="Ex. Sabastian Buffa"
        isError={isError}
        onChange={(e) => setFullName(e.target.value)}
      />
    </InputGroup>
  );
};

export default BillingFullName;
