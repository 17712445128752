import { checkValidPhoneNumber, getNestedObjectValue } from 'utils';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FormikProvider, useFormik } from 'formik';

import ActionInfoTab from 'components/shared/ActionInfoTab';
import Container from 'components/shared/Container';
import FullScreenLoader from 'components/shared/Loader/FullScreenLoader';
import Stepper from 'components/shared/Stepper/Stepper';
import { CREATE_COMMUNITY_STEPS, CREATE_STEPS } from 'constants/global';
import AppRoutes from 'constants/routes';
import { useCreateCommunityMutation } from 'services/communityManagement';

import { getCommunitySchema } from 'utils/validation/schemas';

import AddPMO from './add-pmo';
import { initialValues } from './constants';
import Settings from './settings';
import SetupCommunity from './setup-community';
import {
  StyledHeader,
  StyledTab,
  StyledTabsWrapper,
  StyledWrapper,
} from './styles';

const CreateCommunity: React.FC = () => {
  const [activeStep, setActiveStep] = useState(CREATE_STEPS.FIRST);
  const [hasToBeValidated, setHasToBeValidated] = useState(false);
  const { t } = useTranslation();

  const [createCommunity, { isError, isSuccess, isLoading }] =
    useCreateCommunityMutation();

  const setupCommunitySchema = getCommunitySchema(t);
  const formik = useFormik({
    initialValues,
    validationSchema: setupCommunitySchema,
    validateOnChange: hasToBeValidated,
    validateOnBlur: false,
    onSubmit: async (values: typeof initialValues) => {
      const transformedValues = JSON.parse(JSON.stringify(values));
      const stringNumberValue = String(transformedValues.pmo?.contactNumber);
      if (
        transformedValues.pmo?.contactNumber &&
        !stringNumberValue.startsWith('+')
      ) {
        transformedValues.pmo.contactNumber = checkValidPhoneNumber(
          transformedValues.pmo.contactNumber,
        );
      }
      formik.setSubmitting(true);

      await formik.validateForm();
      if (formik.isValid) {
        try {
          await createCommunity(transformedValues).unwrap();
        } catch (err) {
          console.error(err); // TODO add error handling
        }
      }
    },
  });

  const validateFields = async (fields: string[]): Promise<boolean> => {
    const errors = await formik.validateForm();
    const isValid = fields.every(
      (field) => !getNestedObjectValue(errors, field),
    );
    if (isValid) formik.setErrors({});

    return isValid;
  };

  const areValidFields = (fields: string[]) => {
    return fields
      .map((field) => formik.getFieldMeta(field))
      .every((fieldMeta) => {
        return fieldMeta && !fieldMeta.error;
      });
  };

  const handleConfirm = (isValid: boolean) => {
    if (isValid) {
      setActiveStep((prev) => prev + 1);
      setHasToBeValidated(false);
    } else {
      setHasToBeValidated(true);
    }
  };

  const handleBackButtonClick = () => {
    setActiveStep((prev) => prev - 1);
    setHasToBeValidated(true);
  };

  const handleSuccessClick = () => {
    setActiveStep(CREATE_STEPS.FIRST);
    formik.resetForm();
  };

  return (
    <Container offset={1}>
      <StyledWrapper>
        {isLoading && <FullScreenLoader />}
        {!isSuccess ? (
          <>
            <StyledHeader>{t('add_new_community')}</StyledHeader>
            <Stepper steps={CREATE_COMMUNITY_STEPS} activeStep={activeStep} />
            <FormikProvider value={formik}>
              <StyledTabsWrapper>
                <StyledTab active={activeStep === CREATE_STEPS.FIRST}>
                  <SetupCommunity
                    areValidFields={areValidFields}
                    validateFields={validateFields}
                    onConfirmForm={handleConfirm}
                    hasToBeValidated={hasToBeValidated}
                  />
                </StyledTab>

                <StyledTab active={activeStep === CREATE_STEPS.SECOND}>
                  <AddPMO
                    areValidFields={areValidFields}
                    validateFields={validateFields}
                    onConfirmForm={handleConfirm}
                    onBackButtonClick={handleBackButtonClick}
                    hasToBeValidated={hasToBeValidated}
                  />
                </StyledTab>

                <StyledTab active={activeStep === CREATE_STEPS.THIRD}>
                  <Settings
                    errors={formik.errors}
                    isValid={formik.isValid}
                    values={formik.values}
                    areValidFields={areValidFields}
                    validateFields={validateFields}
                    handleSubmit={formik.handleSubmit}
                    onBackButtonClick={handleBackButtonClick}
                    setFieldValue={formik.setFieldValue}
                    setHasToBeValidated={setHasToBeValidated}
                    hasToBeValidated={hasToBeValidated}
                    isError={isError}
                  />
                </StyledTab>
              </StyledTabsWrapper>
            </FormikProvider>
          </>
        ) : (
          <ActionInfoTab
            title="new_community_added"
            text="new_community_has_been_successfully_added"
            handleClick={handleSuccessClick}
            buttonText="back_to_communities"
            redirectOnClick={AppRoutes.Communities}
          />
        )}
      </StyledWrapper>
    </Container>
  );
};

export default CreateCommunity;
