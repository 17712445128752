import { FC, useCallback, useState } from 'react';
import { useCollapse } from 'react-collapsed';
import { useTranslation } from 'react-i18next';

import { Container } from 'components/communities/styles';
import { Community } from 'constants/types';

import {
  ArrowButton,
  ArrowDown,
  ArrowText,
  ArrowUp,
  StlyedDataRowTitle,
  StlyedTabWrapper,
  StlyedUpcomingTitle,
  StyledDataRow,
  StyledDivider,
  StyledHistoricalDataRow,
  StyledHistoricalHeaderRow,
  StyledHistoricalIndexationList,
  StyledIndexationList,
  Title,
} from './styles';

interface IndexationTabProps {
  community?: Community;
}

const IndexationTab: FC<IndexationTabProps> = ({ community }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { t } = useTranslation();
  const { getCollapseProps, getToggleProps } = useCollapse({
    isExpanded,
  });

  const toggleShowHistoricalIndexes = useCallback(
    () => setIsExpanded((val) => !val),
    [],
  );

  return (
    <StlyedTabWrapper>
      <Container>
        <Title>{t('indexation')}</Title>
        <StyledIndexationList>
          <StyledDataRow>
            <StlyedDataRowTitle>{t('indexation_period')}</StlyedDataRowTitle>
            <StlyedDataRowTitle>{t('index')}</StlyedDataRowTitle>
          </StyledDataRow>
          <StyledDataRow>
            <StlyedDataRowTitle>01.01.2024 - 31.12.2024</StlyedDataRowTitle>
            <StlyedDataRowTitle>1,0125</StlyedDataRowTitle>
          </StyledDataRow>
          <StyledDataRow>
            <StlyedDataRowTitle>
              <StlyedUpcomingTitle>
                {t('upcoming_indexation').toUpperCase()}
              </StlyedUpcomingTitle>
              <div>01.01.2024 - 31.12.2024</div>
            </StlyedDataRowTitle>
            <StlyedDataRowTitle>1,0125</StlyedDataRowTitle>
          </StyledDataRow>
        </StyledIndexationList>
        <StyledDivider isExpanded={isExpanded}>
          <section {...getCollapseProps()}>
            <StyledHistoricalIndexationList>
              <StyledHistoricalHeaderRow>
                <StlyedDataRowTitle style={{ color: '#000000A6' }}>
                  {t('historical_indexation')}
                </StlyedDataRowTitle>
              </StyledHistoricalHeaderRow>
              <StyledHistoricalHeaderRow>
                <StlyedDataRowTitle>
                  {t('indexation_period').toUpperCase()}
                </StlyedDataRowTitle>
                <StlyedDataRowTitle>{t('index')}</StlyedDataRowTitle>
              </StyledHistoricalHeaderRow>
              <StyledHistoricalDataRow>
                <StlyedDataRowTitle>01.01.2024 - 31.12.2024</StlyedDataRowTitle>
                <StlyedDataRowTitle>1,0125</StlyedDataRowTitle>
              </StyledHistoricalDataRow>
              <StyledHistoricalDataRow>
                <StlyedDataRowTitle>
                  <div>01.01.2024 - 31.12.2024</div>
                </StlyedDataRowTitle>
                <StlyedDataRowTitle>1,0125</StlyedDataRowTitle>
              </StyledHistoricalDataRow>
            </StyledHistoricalIndexationList>
          </section>
        </StyledDivider>
        <ArrowButton
          {...getToggleProps({
            onClick: toggleShowHistoricalIndexes,
          })}
          style={{ marginTop: '35px' }}
        >
          {isExpanded ? <ArrowUp /> : <ArrowDown />}
          {isExpanded ? (
            <ArrowText>{t('hide_historical_indexes')}</ArrowText>
          ) : (
            <ArrowText>{t('show_historical_indexes')}</ArrowText>
          )}
        </ArrowButton>
      </Container>
    </StlyedTabWrapper>
  );
};

export default IndexationTab;
