import styled from 'styled-components';

import { Checkbox } from 'antd';

import { SubHeader } from 'components/shared/Text';
import { COLORS } from 'constants/colors';

export const StyledPMOSettingInstruction = styled(SubHeader)``;

export const StyledCheckbox = styled(Checkbox)<{ isError?: boolean }>`
  & .ant-checkbox-inner {
    border-color: ${(props) => props.isError && COLORS.ErrorBase};
  }
`;
