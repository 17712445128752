import React, { CSSProperties, useMemo } from 'react';

import { Field, FieldProps } from 'formik';

import {
  FormInput,
  FormNumberInput,
  StyledTextArea,
} from 'components/shared/Input';

type FormikInputProps = {
  fieldName: string;
  type?: string;
  placeholder?: string;
  disabled?: boolean;
  suffix?: React.ReactNode;
  prefix?: React.ReactNode;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  additionalStyles?: CSSProperties;
  rows?: number;
};

const FormikInput: React.FC<FormikInputProps> = ({
  fieldName,
  placeholder,
  type = 'text',
  disabled = false,
  suffix,
  prefix,
  onKeyPress,
  onChange,
  onFocus,
  additionalStyles = {},
  rows,
}) => {
  const Component = useMemo(() => {
    switch (type) {
      case 'number':
        return (props: any) => <FormNumberInput controls={false} {...props} />;
      case 'textarea':
        return (props: any) => <StyledTextArea {...props} />;
      default:
        return (props: any) => <FormInput {...props} />;
    }
  }, [type]);

  return (
    <Field name={fieldName}>
      {({ field, meta, form }: FieldProps) => (
        <Component
          {...field}
          suffix={suffix}
          prefix={prefix}
          type={type}
          placeholder={placeholder}
          isError={!!meta.error}
          disabled={disabled}
          onKeyPress={onKeyPress}
          onFocus={() => onFocus?.()}
          onChange={(e: any) => {
            if (type === 'number') {
              return form.setFieldValue(field.name, e);
            }
            onChange?.(e);
            return field.onChange(e);
          }}
          onWheel={(e: any) =>
            type === 'number' ? e.currentTarget.blur() : null
          }
          style={additionalStyles}
          rows={rows}
        />
      )}
    </Field>
  );
};

export default FormikInput;
