import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import CommunityData from 'components/communities/Charts/CommunityData';
import ConsumerSummaryView from 'components/communities/Charts/ConsumerSummaryView';
import ElectricityConsumption from 'components/communities/Charts/ElectricityConsumption';
import RepartitionKey from 'components/communities/Charts/RepartitionKey';
import SelfConsumption from 'components/communities/Charts/SelfConsumption';
import {
  LABELS,
  mapElectricityConsumptionData,
} from 'components/communities/MyCommunity/constants/ElectricityConsumption';
import {
  selfConsumedEmptyStateByHours,
  selfConsumedInfoItemsByHours,
} from 'components/communities/constants';
import { Row } from 'components/communities/styles';
import { StripeMessageTypes } from 'components/communities/types';
import {
  getFormattedInfoChartValue,
  getPieColorByHours,
} from 'components/communities/utils';
import ModalMessage from 'components/shared/ModalMessage';
import {
  useGetCommunityDataQuery,
  useGetElectricityTotalQuery,
  useGetElectricityUsageQuery,
  useGetRepartitionKeyQuery,
  useGetSelfConsumedByTypeQuery,
} from 'services/dashboardManagment';
import { useGetMemberByIdQuery } from 'services/pmoMemberDashboardManagment';
import { ILabelMap } from 'services/types';

import { useAppSelector } from 'utils/hooks';
import { getDateRange } from 'utils/url/getDateRange';

import {
  mapRepartitionKeyData,
  sortSelfConsumedByHoursData,
} from '../../utils';
import EnergyTips from './components/EnergyTips';
import {
  legendItems as ElectricityConsumptionLegendItems,
  areaColors,
  infoItems,
  lineColors,
} from './constants/ElectricityConsumption';
import { tipsData } from './constants/EnergyTips';

const CHART_KEYS = [
  'mySelfConsumptionRatio',
  'gridConsumptionRatio',
  'selfConsumptionRatio',
];

const ConsumerDashboard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const period = useAppSelector((state) => state.dashboardPeriod.period);

  const { data: repartitionKeyData } = useGetRepartitionKeyQuery(
    getDateRange(period),
  );

  const { data: consumer } = useGetMemberByIdQuery(undefined);

  const { data: electricityConsumptionData } = useGetElectricityUsageQuery(
    getDateRange(period),
  );
  const { data: electricityTotalData } = useGetElectricityTotalQuery(
    getDateRange(period),
  );
  const { data: communityData } = useGetCommunityDataQuery(
    getDateRange(period),
  );
  const { data: selfConsumedByTypeData } = useGetSelfConsumedByTypeQuery(
    getDateRange(period),
  );
  const electricityConsumptionInfoDataArray = [
    electricityTotalData?.consumption,
    electricityTotalData?.selfConsumption,
    electricityTotalData?.gridConsumption,
  ];

  const consumerName = `${consumer?.firstName} ${consumer?.middleName} ${consumer?.lastName}`;

  const directDebitSetup = searchParams.get('directDebitSetup');

  const electricityConsumptionDataMapped = useMemo(() => {
    const labelsMap: ILabelMap = {
      totalConsumption: LABELS.TOTAL_CONSUMPTION,
      selfConsumption: LABELS.SELF_CONSUMPTION,
      gridConsumption: LABELS.GRID_CONSUMPTION,
    };

    return mapElectricityConsumptionData(
      electricityConsumptionData,
      period,
      labelsMap,
      t,
    );
  }, [electricityConsumptionData, period]);

  const repartitionKeyDataMapped = useMemo(() => {
    return mapRepartitionKeyData(repartitionKeyData?.byPeriod, period, t);
  }, [repartitionKeyData, period]);

  const communityDataMapped = useMemo(() => {
    if (communityData) {
      return Object.keys(communityData)
        .filter((item) => CHART_KEYS.includes(item))
        .map((item, index) => ({
          date: index,
          value: communityData[item],
          type: item,
        }));
    }
    return [];
  }, [communityData]);

  const selfConsumedByTypePresentableData =
    selfConsumedByTypeData && selfConsumedByTypeData.length
      ? selfConsumedByTypeData?.map((item: any) => ({
          value: item.consumedInPercent,
          type: item.scheduleType,
        }))
      : selfConsumedEmptyStateByHours;

  return (
    <>
      <ConsumerSummaryView period={period} />
      <ElectricityConsumption
        isConsumer
        title={t('electricity_consumption')}
        legendItems={ElectricityConsumptionLegendItems(t)}
        infoItems={infoItems(t)}
        lineColors={lineColors}
        areaColors={areaColors}
        total={getFormattedInfoChartValue(
          electricityTotalData?.consumption,
          electricityConsumptionInfoDataArray,
        )}
        selfConsumption={getFormattedInfoChartValue(
          electricityTotalData?.selfConsumption,
          electricityConsumptionInfoDataArray,
        )}
        gridConsumption={getFormattedInfoChartValue(
          electricityTotalData?.gridConsumption,
          electricityConsumptionInfoDataArray,
        )}
        electricityConsumption={electricityConsumptionDataMapped}
      />
      <Row>
        <RepartitionKey
          data={repartitionKeyDataMapped}
          percent={repartitionKeyData?.average}
        />
        <CommunityData
          chartData={communityDataMapped}
          data={communityData}
          benchMarkData={undefined}
          communityName={communityData?.communityName}
        />
      </Row>
      <Row>
        <SelfConsumption
          getPieColor={getPieColorByHours}
          infoItems={selfConsumedInfoItemsByHours(t)}
          chartData={sortSelfConsumedByHoursData(
            selfConsumedByTypePresentableData,
          )}
          title={t('energy_self_consumed_by_type_of_hours')}
        />
        <EnergyTips
          consumerName={consumerName}
          tip={tipsData[consumer?.additionalInfo?.type || 'none']}
        />
      </Row>
      {directDebitSetup && (
        <>
          {directDebitSetup === StripeMessageTypes.Success ? (
            <ModalMessage
              messageType={StripeMessageTypes.Success}
              title={t('welcome_onboard')}
              subTitle={t('successfully_added_payment_method')}
              btnText={t('ok')}
            />
          ) : (
            <ModalMessage
              messageType={'warining'}
              title={t('payment_method_was_not_added')}
              subTitle={t('some_issues_with_adding_your_payment_method')}
              btnText={t('try_again')}
              btnWidth="98px"
              handleBtn={() => navigate(-1)}
            />
          )}
        </>
      )}
    </>
  );
};

export default ConsumerDashboard;
