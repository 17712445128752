import { styled } from 'styled-components';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { COLORS } from 'constants/colors';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ArrowDown = styled(DownOutlined)`
  font-size: 14px;
  color: ${COLORS.TextBase};
`;

export const ArrowUp = styled(UpOutlined)`
  font-size: 14px;
  color: ${COLORS.TextBase};
`;

export const ArrowButton = styled.div`
  text-align: end;
  cursor: pointer;
  margin-right: 16px;
`;

export const StyledQuestionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 24px;
  background: ${COLORS.Grey2};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  cursor: pointer;
`;

export const StyledAnswerContainer = styled.div`
  padding: 24px;
  background: ${COLORS.BgContainer};
  border: 1px solid #ededf5;
`;

export const StyledHeader = styled.div`
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 40px;
  margin-top: 14px;
`;

export const StyledQuestion = styled.div<{ isExpanded: boolean }>`
  font-size: 16px;
  font-weight: 600;
  color: ${({ isExpanded }) => (isExpanded ? COLORS.Green8 : COLORS.TextBase)};
`;

export const StyledContactUs = styled(StyledHeader)`
  font-size: 16px;
  margin-bottom: 16px;
  margin-top: 60px;
`;

export const StyledContactFormContainer = styled.div`
  padding: 6px 24px 32px;
  background: ${COLORS.Grey2};
`;

export const StyledRequiredFields = styled.div`
  font-size: 14px;
  color: ${COLORS.Grey4};
  margin-bottom: 20px;
`;

export const StyledContactFormRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  margin-bottom: 20px;
  span {
    text-transform: capitalize;
  }
`;

export const StyledContactFormText = styled.div`
  font-size: 14px;
  color: ${COLORS.Grey4};
  margin: 20px 0 40px;
`;
