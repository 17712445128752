import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import PeriodPicker from 'components/shared/PeriodPicker';
import { setPeriod } from 'components/shared/PeriodPicker/slice';
import TabsComponent from 'components/shared/Tabs';
import { COLORS } from 'constants/colors';
import { CHART_TYPE } from 'constants/global';
import AppRoutes from 'constants/routes';
import { PERIOD } from 'constants/types';

import { useAppDispatch } from 'utils/hooks';

import {
  StyledHeaderRow,
  StyledHeaderWrapper,
  StyledPeriodPicker,
} from './styles';
import { useTabsConfig } from './useTabsConfig';

const Reports = () => {
  const tabsConfig = useTabsConfig();
  const dispatch = useAppDispatch();
  const useUrlQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const isFinancialData = useUrlQuery()
    .get('tab')
    ?.includes(AppRoutes.FinancialData);

  useEffect(() => {
    if (isFinancialData) {
      dispatch(setPeriod(PERIOD.CURRENT_YEAR));
      window.sessionStorage.setItem('period', PERIOD.CURRENT_YEAR);
    }
  }, [isFinancialData]);

  return (
    <StyledHeaderWrapper>
      <StyledHeaderRow>
        <StyledPeriodPicker>
          <PeriodPicker
            chartType={isFinancialData ? CHART_TYPE.FINANCIAL_DATA : undefined}
          />
        </StyledPeriodPicker>
      </StyledHeaderRow>
      <TabsComponent backgroundColor={COLORS.BgContainer} items={tabsConfig} />
    </StyledHeaderWrapper>
  );
};

export default Reports;
