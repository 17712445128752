import React from 'react';

const Icon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="37"
      viewBox="0 0 36 37"
      fill="none"
    >
      <path
        d="M4.125 0.705078L20.4375 4.50195V32.4863H4.125V0.705078Z"
        fill="#91CAFF"
      />
      <path
        d="M2.88281 32.4863H22.5703V35.2988H2.88281V32.4863Z"
        fill="white"
      />
      <path
        d="M17.9297 10.9707H33.1172V35.2988H17.9297V10.9707Z"
        fill="#FFF1B8"
      />
      <path
        d="M19.0547 7.0332H31.9922V10.9707H19.0547V7.0332Z"
        fill="#E1E3E9"
      />
      <path
        d="M33.1172 10.2676H32.6953V7.03325C32.6953 6.64489 32.3803 6.33013 31.9922 6.33013H21.1404V4.50201C21.1404 4.17516 20.9152 3.89144 20.5969 3.81728L4.28439 0.0204098C4.07565 -0.0284794 3.85565 0.020959 3.68784 0.154443C3.51974 0.287927 3.42197 0.490624 3.42197 0.705132V31.7832H2.88281C2.49445 31.7832 2.17969 32.098 2.17969 32.4864V35.2989C2.17969 35.6872 2.49445 36.002 2.88281 36.002H33.1172C33.5053 36.002 33.8203 35.6872 33.8203 35.2989V26.2989C33.8203 25.9105 33.5053 25.5957 33.1172 25.5957C32.7288 25.5957 32.4141 25.9105 32.4141 26.2989V34.5957H18.6328V11.6739H32.4141V19.9707C32.4141 20.3591 32.7288 20.6739 33.1172 20.6739C33.5053 20.6739 33.8203 20.3591 33.8203 19.9707V10.9708C33.8203 10.5824 33.5053 10.2676 33.1172 10.2676ZM4.82794 29.8145H12.2811C12.6694 29.8145 12.9842 29.4997 12.9842 29.1114C12.9842 28.723 12.6694 28.4082 12.2811 28.4082H4.82794V26.4395H12.2811C12.6694 26.4395 12.9842 26.1247 12.9842 25.7364C12.9842 25.348 12.6694 25.0332 12.2811 25.0332H4.82794V23.0645H12.2811C12.6694 23.0645 12.9842 22.7497 12.9842 22.3614C12.9842 21.973 12.6694 21.6582 12.2811 21.6582H4.82794V19.6895H12.2811C12.6694 19.6895 12.9842 19.3747 12.9842 18.9864C12.9842 18.598 12.6694 18.2832 12.2811 18.2832H4.82794V16.3145H12.2811C12.6694 16.3145 12.9842 15.9997 12.9842 15.6114C12.9842 15.223 12.6694 14.9083 12.2811 14.9083H4.82794V12.9395H12.2811C12.6694 12.9395 12.9842 12.6247 12.9842 12.2364C12.9842 11.848 12.6694 11.5333 12.2811 11.5333H4.82794V9.5645H12.2811C12.6694 9.5645 12.9842 9.24975 12.9842 8.86138C12.9842 8.47301 12.6694 8.15825 12.2811 8.15825H4.82794V1.5909L19.7342 5.06039V6.33013H19.0547C18.6663 6.33013 18.3516 6.64489 18.3516 7.03325V10.2676H17.9297C17.5413 10.2676 17.2266 10.5824 17.2266 10.9708V31.7832H4.82794V29.8145ZM3.58594 33.1895H17.2266V34.5957H3.58594V33.1895ZM19.7578 10.2676V7.73638H31.2891V10.2676H19.7578Z"
        fill="black"
        fillOpacity="0.88"
      />
      <path
        d="M22.2891 14.8516C22.2891 14.4635 21.974 14.1484 21.5859 14.1484C21.1976 14.1484 20.8828 14.4635 20.8828 14.8516V16.8203C20.8828 17.2087 21.1976 17.5234 21.5859 17.5234C21.974 17.5234 22.2891 17.2087 22.2891 16.8203V14.8516Z"
        fill="black"
        fillOpacity="0.88"
      />
      <path
        d="M26.2266 14.8516C26.2266 14.4635 25.9115 14.1484 25.5234 14.1484C25.1351 14.1484 24.8203 14.4635 24.8203 14.8516V16.8203C24.8203 17.2087 25.1351 17.5234 25.5234 17.5234C25.9115 17.5234 26.2266 17.2087 26.2266 16.8203V14.8516Z"
        fill="black"
        fillOpacity="0.88"
      />
      <path
        d="M30.1641 14.8516C30.1641 14.4635 29.849 14.1484 29.4609 14.1484C29.0726 14.1484 28.7578 14.4635 28.7578 14.8516V16.8203C28.7578 17.2087 29.0726 17.5234 29.4609 17.5234C29.849 17.5234 30.1641 17.2087 30.1641 16.8203V14.8516Z"
        fill="black"
        fillOpacity="0.88"
      />
      <path
        d="M22.2891 19.7178C22.2891 19.3294 21.974 19.0146 21.5859 19.0146C21.1976 19.0146 20.8828 19.3294 20.8828 19.7178V21.6865C20.8828 22.0746 21.1976 22.3896 21.5859 22.3896C21.974 22.3896 22.2891 22.0746 22.2891 21.6865V19.7178Z"
        fill="black"
        fillOpacity="0.88"
      />
      <path
        d="M26.2266 19.7178C26.2266 19.3294 25.9115 19.0146 25.5234 19.0146C25.1351 19.0146 24.8203 19.3294 24.8203 19.7178V21.6865C24.8203 22.0746 25.1351 22.3896 25.5234 22.3896C25.9115 22.3896 26.2266 22.0746 26.2266 21.6865V19.7178Z"
        fill="black"
        fillOpacity="0.88"
      />
      <path
        d="M30.1641 19.7178C30.1641 19.3294 29.849 19.0146 29.4609 19.0146C29.0726 19.0146 28.7578 19.3294 28.7578 19.7178V21.6865C28.7578 22.0746 29.0726 22.3896 29.4609 22.3896C29.849 22.3896 30.1641 22.0746 30.1641 21.6865V19.7178Z"
        fill="black"
        fillOpacity="0.88"
      />
      <path
        d="M22.2891 24.584C22.2891 24.1956 21.974 23.8809 21.5859 23.8809C21.1976 23.8809 20.8828 24.1956 20.8828 24.584V26.5527C20.8828 26.9411 21.1976 27.2559 21.5859 27.2559C21.974 27.2559 22.2891 26.9411 22.2891 26.5527V24.584Z"
        fill="black"
        fillOpacity="0.88"
      />
      <path
        d="M26.2266 24.584C26.2266 24.1956 25.9115 23.8809 25.5234 23.8809C25.1351 23.8809 24.8203 24.1956 24.8203 24.584V26.5527C24.8203 26.9411 25.1351 27.2559 25.5234 27.2559C25.9115 27.2559 26.2266 26.9411 26.2266 26.5527V24.584Z"
        fill="black"
        fillOpacity="0.88"
      />
      <path
        d="M30.1641 24.584C30.1641 24.1956 29.849 23.8809 29.4609 23.8809C29.0726 23.8809 28.7578 24.1956 28.7578 24.584V26.5527C28.7578 26.9411 29.0726 27.2559 29.4609 27.2559C29.849 27.2559 30.1641 26.9411 30.1641 26.5527V24.584Z"
        fill="black"
        fillOpacity="0.88"
      />
      <path
        d="M21.5859 28.7461C21.1976 28.7461 20.8828 29.0609 20.8828 29.4492V31.418C20.8828 31.8063 21.1976 32.1211 21.5859 32.1211C21.974 32.1211 22.2891 31.8063 22.2891 31.418V29.4492C22.2891 29.0609 21.974 28.7461 21.5859 28.7461Z"
        fill="black"
        fillOpacity="0.88"
      />
      <path
        d="M25.5234 28.7461C25.1351 28.7461 24.8203 29.0609 24.8203 29.4492V31.418C24.8203 31.8063 25.1351 32.1211 25.5234 32.1211C25.9115 32.1211 26.2266 31.8063 26.2266 31.418V29.4492C26.2266 29.0609 25.9115 28.7461 25.5234 28.7461Z"
        fill="black"
        fillOpacity="0.88"
      />
      <path
        d="M29.4609 28.7461C29.0726 28.7461 28.7578 29.0609 28.7578 29.4492V31.418C28.7578 31.8063 29.0726 32.1211 29.4609 32.1211C29.849 32.1211 30.1641 31.8063 30.1641 31.418V29.4492C30.1641 29.0609 29.849 28.7461 29.4609 28.7461Z"
        fill="black"
        fillOpacity="0.88"
      />
      <path
        d="M33.1172 22.4316C32.9321 22.4316 32.7508 22.5069 32.6201 22.6376C32.489 22.7684 32.4141 22.9499 32.4141 23.1348C32.4141 23.3196 32.489 23.5012 32.6201 23.6319C32.7508 23.7626 32.9321 23.8379 33.1172 23.8379C33.302 23.8379 33.4833 23.7626 33.614 23.6319C33.7448 23.5012 33.8203 23.3196 33.8203 23.1348C33.8203 22.9499 33.7451 22.7684 33.614 22.6376C33.4833 22.5069 33.302 22.4316 33.1172 22.4316Z"
        fill="black"
        fillOpacity="0.88"
      />
    </svg>
  );
};

export default Icon;
