import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Column } from '@ant-design/plots';

import { formatChartDataByFullDate } from 'components/communities/utils';
import { COLORS } from 'constants/colors';

import { useAppSelector } from 'utils/hooks';
import { SkeletonAreaChartBig } from 'components/shared/Skeleton';
import CustomFinancialFollowUpTooltip from '../FinancialFollowup/component/CustomTooltip';
import { StyledColumnChartWrapper } from './styles';

const getColumnChartBarColorByDataType = (dataType: string) => {
  switch (dataType) {
    case 'earned_from_community':
      return COLORS.Green6;
    case 'earned_tax_refund':
      return COLORS.geekBlue6;
    case 'paid_amount':
      return COLORS.Green7;
    case 'overdue_amount':
      return COLORS.ErrorBorder;
    case 'expected_amount':
      return COLORS.Green4;
    case 'Turpe':
      return COLORS.Blue5;
    case 'Prime':
      return COLORS.Yellow5;
    case 'Accise':
      return COLORS.Orange6;
    case 'gridConsumption':
      return COLORS.Orange5;
    case 'totalProduction':
      return COLORS.Yellow5;
    case 'selfConsumption':
      return COLORS.Green6;
    case 'overproduction':
      return COLORS.TextTertiary;
    case 'mySelfConsumptionRatio':
      return COLORS.Yellow5;
    case 'gridConsumptionRatio':
      return '#3ECC72';
    case 'selfConsumptionRatio':
      return COLORS.Orange5;
    default:
      return COLORS.BgContainerDisabled;
  }
};

interface IColumnChart {
  hasData: boolean;
  data: any;
  legendItems?: any;
  xAxis?: boolean;
  unit?: string;
  hideTooltip?: boolean;
  removeShowingDataByFullPeriod?: boolean;
  chartHeight?: number;
  isLoading?: boolean;
  chartLoadingComponent?: ReactNode;
}

const ColumnChart = ({
  hasData,
  data,
  removeShowingDataByFullPeriod = false,
  legendItems = [],
  xAxis = true,
  unit,
  hideTooltip = false,
  chartHeight,
  isLoading,
  chartLoadingComponent,
}: IColumnChart) => {
  const { t } = useTranslation();
  const period = useAppSelector((state) => state.dashboardPeriod.period);
  const config = {
    data: !removeShowingDataByFullPeriod
      ? formatChartDataByFullDate({ data, period, isFinancial: true, t })
      : data,
    isStack: true,
    autoFit: true,
    colorField: 'type',
    color: (dataItem: { type: 'string' }) =>
      getColumnChartBarColorByDataType(dataItem.type),
    maxColumnWidth: 20,
    marginRatio: 100,
    xField: 'date',
    yField: 'value',
    seriesField: 'type',
    xAxis,
    yAxis: {
      title: {
        text: unit ? unit : '€',
        style: {
          fontSize: 12,
          textAlign: 'center',
          fill: '#8C8C8C',
          rotate: -90,
        },
      },
    },
    height: chartHeight ?? 287,
    legend:
      legendItems.length > 0
        ? {
            position: 'bottom',
            offsetY: 10,
            offsetX: 20,
            items: legendItems,
          }
        : false,

    tooltip:
      hasData && !hideTooltip
        ? {
            marker: false,
            shared: true,
            customContent: (datum: string, tooltipData: any) =>
              data ? (
                <CustomFinancialFollowUpTooltip
                  period={period}
                  key={datum}
                  data={tooltipData}
                />
              ) : undefined,
          }
        : false,
  };

  // without ts-ignore column chart doesn't want to take legend position value
  // @ts-ignore
  return (
    <StyledColumnChartWrapper>
      <Column
        {...(config as any)}
        loading={isLoading}
        loadingTemplate={chartLoadingComponent || <SkeletonAreaChartBig />}
      />
    </StyledColumnChartWrapper>
  );
};

export default ColumnChart;
