import { styled } from 'styled-components';

import { Button } from 'antd';

import { COLORS } from 'constants/colors';

export const BtnDefault = styled(Button)<{ border?: string }>`
  border-color: ${(props) => (props.border ? props.border : 'transparent')};
  font-size: 14px;
  font-weight: 600;
  padding: 0 16px;
  height: 40px;
  color: ${(props) => (props.color ? props.color : COLORS.Green8)};

  &:hover {
    color: ${COLORS.TextBase};
  }

  &:disabled {
    color: ${COLORS.Grey3};
  }

  &:hover:disabled {
    color: ${COLORS.Grey3};
  }
`;
