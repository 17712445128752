import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { authApi } from 'services/auth';

export type UserAuth = {
  username: string;
  password: string;
};

export type UserType = {
  access_token: string;
  expires_in: number;
  id_token: string;
  refresh_token: string;
  scope: string;
  token_type: string;
  roles?: string;
};

interface AuthState {
  user: UserType | null;
  isLoading: boolean;
  error: string | null;
}

const initialState: AuthState = {
  user: null,
  isLoading: false,
  error: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: (state, action: PayloadAction<UserType>) => {
      state.user = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    clearAuth: (state) => {
      state.user = null;
      state.isLoading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(authApi.endpoints.login.matchPending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addMatcher(
        authApi.endpoints.login.matchFulfilled,
        (state, { payload }) => {
          state.user = payload;
          state.isLoading = false;
        },
      )
      .addMatcher(
        authApi.endpoints.login.matchRejected,
        (state, { payload, error }) => {
          state.error = error.message ? error.message : 'Unknown error';
          state.isLoading = false;
        },
      )
      .addMatcher(
        authApi.endpoints.refreshToken.matchFulfilled,
        (state, { payload }) => {
          state.user = payload;
          state.isLoading = false;
          state.error = null;
        },
      );
  },
});

export default authSlice.reducer;
