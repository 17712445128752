import { CONSUMER_TYPE } from 'constants/types';

type ConsumerDataValuesByCompanyType = {
  hasAccise: boolean;
  consumerType: CONSUMER_TYPE;
  name: string;
  siretNumber: number | null | undefined;
  location: string | undefined;
  acciseType: string | null;
};

type LegalRepresentativeValuesByCompanyType = {
  consumerType: CONSUMER_TYPE;
  name: string;
  email: any;
  position: string | undefined;
  contactNumber: string | number | undefined;
};

export const getConsumerDataValuesByCompanyType = ({
  hasAccise,
  consumerType,
  name,
  siretNumber,
  location,
  acciseType,
}: ConsumerDataValuesByCompanyType): Record<string, any> => {
  if (hasAccise) {
    return {
      first: name,
      second: siretNumber,
      third: location,
      fourth: acciseType,
    };
  }

  switch (consumerType) {
    case CONSUMER_TYPE.PRIVATE: {
      return {
        first: name,
        second: location,
      };
    }

    default: {
      return {
        first: name,
        second: siretNumber,
        third: location,
      };
    }
  }
};

export const getLegalRepresentativeValuesByCompanyType = ({
  consumerType,
  name,
  email,
  position,
  contactNumber,
}: LegalRepresentativeValuesByCompanyType): Record<string, any> => {
  switch (consumerType) {
    case CONSUMER_TYPE.PRIVATE: {
      return {
        first: email,
        second: contactNumber,
      };
    }

    default: {
      return {
        first: name,
        second: email,
        third: position,
        fourth: contactNumber,
      };
    }
  }
};
