import { v4 as uuidv4 } from 'uuid';

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DynamicEditForm from 'components/communities/components/DynamicEditForm';
import GenericFormName from 'components/communities/components/GenericFormName';
import Loader from 'components/shared/Loader';
import { useUpdateMemberMutation } from 'services/membersManagement';
import { useGetMemberByIdQuery } from 'services/pmoMemberDashboardManagment';
import { IMember } from 'services/types';

import { editAdminSchema } from 'utils/validation/schemas';

import DetailCard from '../Card';
import {
  Button,
  CardWrapper,
  EditIcon,
  StyledBlockBody,
  StyledBlockHeader,
  StyledBlockTitle,
  StyledBlockWrapper,
} from './styles';

const AdminUserData = () => {
  const { t } = useTranslation();
  const {
    data: member,
    isLoading: isLoadingMemberData,
    refetch,
  } = useGetMemberByIdQuery(undefined);
  const [
    updateMember,
    { isLoading: isLoadingMemberUpdateData, data: memberData },
  ] = useUpdateMemberMutation();

  const [isEditMode, setEditMode] = useState(false);
  const [userProfile, setUserProfile] = useState(member);
  const isLoading = isLoadingMemberData || isLoadingMemberUpdateData;

  useEffect(() => {
    setUserProfile(memberData);
  }, [memberData]);

  useEffect(() => {
    setUserProfile(member);
  }, [member]);

  const name = `${userProfile?.firstName} ${userProfile?.middleName} ${userProfile?.lastName}`;
  const email = userProfile?.email;
  const contactNumber = userProfile?.contactNumber;
  const address = userProfile?.address.street;

  const userEditFormData = {
    oyo_data: [
      {
        id: uuidv4(),
        label: 'legal_person_name',
        key: '',
        value: name,
        component: GenericFormName,
      },
      {
        id: uuidv4(),
        label: 'legal_person_email_address',
        key: 'email',
        value: email,
        disabled: true,
      },
      {
        id: uuidv4(),
        label: 'legal_person_contact_number',
        key: 'contactNumber',
        value: contactNumber,
      },
      {
        id: uuidv4(),
        label: 'legal_address',
        key: 'address.street',
        value: address,
      },
    ],
  };

  const handleOnEdit = () => {
    setUserProfile(userProfile);
    setEditMode(true);
  };
  const handleOnSave = (values: IMember) => {
    setEditMode(false);

    setUserProfile(values);
    updateMember(values);
    refetch();
  };

  return (
    <StyledBlockWrapper>
      {isLoading && <Loader />}
      <StyledBlockHeader>
        <StyledBlockTitle>{t('profile_data')}</StyledBlockTitle>
        <Button
          size="small"
          type="text"
          disabled={isEditMode}
          onClick={handleOnEdit}
        >
          <EditIcon disabled={isEditMode} />
          {t('edit')}
        </Button>
      </StyledBlockHeader>
      <StyledBlockBody>
        <DynamicEditForm
          isEditMode={isEditMode}
          formData={userEditFormData}
          initialValues={userProfile}
          validationSchema={editAdminSchema(t)}
          onClose={() => setEditMode(false)}
          onSave={handleOnSave}
        />
        <CardWrapper>
          <DetailCard
            isLoading={isLoading}
            isEditMode={isEditMode}
            labelWidth={'150px'}
            labels={{
              first: 'legal_person_name',
              second: 'legal_person_email_address',
              third: 'legal_person_contact_number',
              fourth: 'legal_address',
            }}
            title={t('oyo_data').toString()}
            values={{
              first: name,
              second: email,
              third: contactNumber,
              forth: address,
            }}
          />
        </CardWrapper>
      </StyledBlockBody>
    </StyledBlockWrapper>
  );
};

export default AdminUserData;
