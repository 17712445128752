import { v4 as uuidv4 } from 'uuid';

import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DynamicEditForm from 'components/communities/components/DynamicEditForm';
import GenericFormName from 'components/communities/components/GenericFormName';
import Loader from 'components/shared/Loader';
import { USER_ROLES } from 'constants/global';
import { useUpdateMemberMutation } from 'services/membersManagement';
import { IMember, IPaymentMethod } from 'services/types';

import { billingMemberSchema } from 'utils/validation/schemas';

import DetailCard from '../../../../../Profile/components/Card';
import {
  Button,
  EditIcon,
  StyledBlockBody,
  StyledBlockHeader,
  StyledBlockTitle,
  StyledBlockWrapper,
} from '../../styles';
import PaymentMethodCard from './PaymentMethod';
import { BillingAddressLabels, BillingInfoLabels } from './constants';

interface BillingDataProps {
  member: IMember | undefined;
  paymentMethod?: IPaymentMethod;
  refetchPayment: () => void;
  refetchMember: () => void;
  isLoading?: boolean;
  isLoadingPayments?: boolean;
}

const BillingData: FC<BillingDataProps> = ({
  member,
  paymentMethod,
  refetchPayment,
  refetchMember,
  isLoading: isLoadingMember,
  isLoadingPayments,
}) => {
  const { t } = useTranslation();
  const [isEditMode, setEditMode] = useState(false);
  const [userProfile, setUserProfile] = useState(member);

  const [updateMember, { isLoading, data: memberData }] =
    useUpdateMemberMutation();

  const billingAddress = member?.additionalInfo.billingAddress.street;
  const billingPerson = member?.additionalInfo.billingPerson;
  const paymentMethodAdded = member?.additionalInfo.paymentMethodAdded || false;

  const billingName = `${billingPerson?.firstName} ${billingPerson?.middleName} ${billingPerson?.lastName}`;
  const iban = paymentMethod
    ? `${paymentMethod?.country}**************${paymentMethod?.ibanLast4}`
    : 'N/A';

  const userBillingFormData = {
    billing_information: [
      {
        id: uuidv4(),
        label: 'billing_responsible',
        key: 'additionalInfo.billingPerson',
        value: billingName,
        component: GenericFormName,
      },
      {
        id: uuidv4(),
        label: 'billing_responsible_email_address',
        key: 'additionalInfo.billingPerson.email',
        value: billingPerson?.email,
      },
      {
        id: uuidv4(),
        label: 'billing_responsible_phone_number',
        key: 'additionalInfo.billingPerson.contactNumber',
        value: billingPerson?.contactNumber,
      },
    ],
    billing_address: [
      {
        id: uuidv4(),
        label: 'billing_address',
        key: 'additionalInfo.billingAddress.street',
        value: billingAddress,
      },
    ],
    payment_method: [
      {
        id: uuidv4(),
        key: '',
        component: () => (
          <PaymentMethodCard
            title={t('payment_method')}
            labelWidth={'90px'}
            iban={iban}
            paymentMethodId={paymentMethod?.id}
            paymentMethodAdded={paymentMethodAdded}
            refetch={refetchPayment}
          />
        ),
      },
    ],
  };

  const handleOnSave = async (values: IMember) => {
    setEditMode(false);
    await updateMember(values);
    refetchMember();
  };

  const handleOnEdit = () => {
    if (userProfile?.role === USER_ROLES.CONSUMER) {
      setUserProfile(member);
      setEditMode(true);
    }
  };

  useEffect(() => {
    setUserProfile(memberData);
  }, [memberData]);

  useEffect(() => {
    setUserProfile(member);
  }, [member]);

  return (
    <StyledBlockWrapper>
      {isLoading && <Loader />}
      <StyledBlockHeader>
        <StyledBlockTitle>{t('billing_data')}</StyledBlockTitle>
        <Button
          size="small"
          type="text"
          disabled={isEditMode}
          onClick={handleOnEdit}
        >
          <EditIcon disabled={isEditMode} />
          {t('edit')}
        </Button>
      </StyledBlockHeader>
      <StyledBlockBody count={isEditMode ? 1 : 3}>
        <DynamicEditForm
          isEditMode={isEditMode}
          formData={userBillingFormData}
          initialValues={userProfile}
          validationSchema={billingMemberSchema(t)}
          onClose={() => setEditMode(false)}
          onSave={handleOnSave}
        />
        <DetailCard
          isEditMode={isEditMode}
          labelWidth={'40%'}
          valueWidth={'calc(100% - 40% - 30px)'}
          title={t('billing_information').toString()}
          labels={BillingInfoLabels}
          values={{
            first: billingName,
            second: billingPerson?.email,
            third: billingPerson?.contactNumber,
          }}
          isLoading={isLoadingMember}
        />
        <DetailCard
          isEditMode={isEditMode}
          labelWidth={'30%'}
          valueWidth={'calc(100% - 30% - 30px)'}
          labels={BillingAddressLabels}
          title={t('billing_address').toString()}
          values={{
            first: billingAddress,
            second: '',
            third: '',
          }}
          isLoading={isLoadingMember}
        />
        {!isEditMode && (
          <PaymentMethodCard
            title={t('payment_method')}
            labelWidth={'90px'}
            iban={iban}
            paymentMethodId={paymentMethod?.id}
            paymentMethodAdded={paymentMethodAdded}
            refetch={refetchPayment}
            isLoading={isLoadingPayments}
          />
        )}
      </StyledBlockBody>
    </StyledBlockWrapper>
  );
};

export default BillingData;
