import { generateEmptyArray, getAppRole, makeRedirect } from 'utils';

import { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Space } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { SkeletonLine } from 'components/shared/Skeleton';
import { Card } from 'components/communities/Dashboard/components/ProducerDashboard/components/FinancialDataTab/styles';
import {
  fetchAndDownloadPdfFile,
  fetchViewerPdfFile,
} from 'components/communities/Dashboard/utils';
import DownloadAction from 'components/communities/components/DownloadAction';
import PayByCreditAction from 'components/communities/components/PayByCreditAction';
import ViewAction from 'components/communities/components/ViewAction';
import { MAX_PAGINATION_PAGE_SIZE } from 'components/communities/constants';
import { consumerInvoiceStatusFilters } from 'components/communities/constants';
import PdfViewer from 'components/shared/PdfViewer';
import { TextRegular } from 'components/shared/Text';
import { CONSUMER_INVOICE_STATUSES, USER_ROLES } from 'constants/types';
import { useGetInvoiceHistoryQuery } from 'services/dashboardManagment';
import { useCreateInvoiceMutation } from 'services/invoiceManagment';

import { columns, getPrmStatusTagByInvoiceType } from './columns';
import { InvoiceHistoryTable, StyledInvoiceHistoryWrapper } from './styles';

const InvoiceHistory = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const role = getAppRole();
  const { data: tableData, isLoading: isLoadingInvoiceHistory } =
    useGetInvoiceHistoryQuery({ userId: id });
  const [createInvoice] = useCreateInvoiceMutation();
  const [prmFilters, setPrmFilters] = useState<any>([]);
  const [pdfFile, setPdfFile] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>(
    {
      current: 1,
      pageSize: 10,
    },
  );
  useEffect(() => {
    const uniquePrmNumberArray = new Set(
      tableData?.map((item: any) => item.prmNumber),
    );
    const uniquePrmNumbersFilter = Array.from(uniquePrmNumberArray).map(
      (item) => ({
        text: item,
        value: item,
      }),
    );
    setPrmFilters(uniquePrmNumbersFilter);
  }, [tableData]);

  const handleRedirectToPaymentDetails = async (id: string) => {
    const { error }: any = await createInvoice({ id });
    const { data: redirectUrl } = error;

    if (redirectUrl) {
      makeRedirect(redirectUrl);
    }
  };

  const handleViewPdfFile = async (
    downloadLink: string,
    dateCreated: string,
  ) => {
    try {
      const url: any | undefined = await fetchViewerPdfFile(
        downloadLink,
        dateCreated,
      );

      if (url) {
        setPdfFile(url);
        setIsModalOpen(true);
      }
      if (!url) {
        setPdfFile('');
        setIsModalOpen(false);
      }
    } catch (error) {
      setIsModalOpen(false);
    }
  };
  const handlePaginationChange = (page: number, pageSize: number) => {
    setTablePagination((prev) => ({ ...prev, current: page, pageSize }));
  };

  const showPayCreditCardOfRole = (status: any) => {
    return (
      (status === CONSUMER_INVOICE_STATUSES.OVERDUE ||
        status === CONSUMER_INVOICE_STATUSES.PAYMENT_ERROR) &&
      role === USER_ROLES.CONSUMER
    );
  };

  let modifiedColumns: ColumnsType<any> = [
    ...columns(t),
    {
      title: t('prm_number').toUpperCase(),
      dataIndex: 'prmNumber',
      filters: prmFilters,
      onFilter: (value, record) => record.prmNumber.includes(String(value)),
    },

    {
      title: t('invoice_amount_eur').toUpperCase(),
      width: '15%',
      dataIndex: 'invoiceAmount',
      render: (amount) => amount.toFixed(2),
      sorter: (firstnInvoice, nextInvoice) =>
        parseInt(firstnInvoice.invoiceAmount) -
        parseInt(nextInvoice.invoiceAmount),
    },
    {
      title: t('status').toUpperCase(),
      dataIndex: 'status',
      render: (status) => getPrmStatusTagByInvoiceType(status, t),
      filters: consumerInvoiceStatusFilters(t),
      onFilter: (value, record) => record.status.includes(String(value)),
    },
    {
      title: t('action').toUpperCase(),
      width: '10%',
      render: ({ downloadLink, dateCreated, status, invoiceNumber }) => (
        <Space>
          <div
            onClick={() => fetchAndDownloadPdfFile(downloadLink, dateCreated)}
          >
            <DownloadAction />
          </div>
          <div onClick={() => handleViewPdfFile(downloadLink, dateCreated)}>
            <ViewAction />
          </div>
          {showPayCreditCardOfRole(status) && (
            <div onClick={() => handleRedirectToPaymentDetails(invoiceNumber)}>
              <PayByCreditAction />
            </div>
          )}
        </Space>
      ),
    },
  ];
  const hasEnoughDataToShowPagination =
    tableData && tableData?.length > MAX_PAGINATION_PAGE_SIZE;

  if (isLoadingInvoiceHistory) {
    modifiedColumns = modifiedColumns.map((column) => ({
      ...column,
      render: () => <SkeletonLine active height={22} width={66} />,
    }));
  }

  const modifiedTableData = useMemo(
    () => (isLoadingInvoiceHistory ? generateEmptyArray(10) : tableData),
    [isLoadingInvoiceHistory, tableData],
  );
  const showEmptyTable = useMemo(
    () => !isLoadingInvoiceHistory && (!tableData || !tableData.length),
    [isLoadingInvoiceHistory, tableData],
  );

  return (
    <>
      <StyledInvoiceHistoryWrapper>
        {showEmptyTable ? (
          <Card isEmpty fullWidth>
            <TextRegular>{t('no_invoices').toUpperCase()}</TextRegular>
          </Card>
        ) : (
          <InvoiceHistoryTable
            columns={modifiedColumns}
            dataSource={modifiedTableData.map((item: any, index: number) => ({
              ...item,
              key: index,
            }))}
            pagination={
              hasEnoughDataToShowPagination && !isLoadingInvoiceHistory
                ? {
                    ...tablePagination,
                    position: ['bottomCenter'],
                    showSizeChanger: true,
                    onChange: handlePaginationChange,
                    showTotal: (total) =>
                      `${t('total')} ${total} ${t('items')}`,
                  }
                : false
            }
          />
        )}
      </StyledInvoiceHistoryWrapper>
      <PdfViewer
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        pdfFile={pdfFile}
      />
    </>
  );
};

export default InvoiceHistory;
