import { PriceVariant } from 'components/pricing/components/TaxPrices/types';
import { ITaxPriceRangeType } from 'services/taxPrices.types';

import CompanyTypeItem from '../CompanyTypeItem';
import { CellWrapper, CommonCell, Row, TextBold, TextRegular } from './styles';

type ICompanyType = {
  priceVariant?: PriceVariant;
  title: string;
  description: string;
  rows: ITaxPriceRangeType;
};

const CompanyType = ({
  priceVariant,
  title,
  description,
  rows,
}: ICompanyType) => (
  <Row type={priceVariant}>
    <CommonCell>
      <CellWrapper>
        <TextBold>{title}</TextBold>
        <TextRegular>{description}</TextRegular>
      </CellWrapper>
    </CommonCell>

    <CompanyTypeItem title="HT" property="ht" rows={rows} />
    <CompanyTypeItem title="TVA" property="vat" rows={rows} />
    <CompanyTypeItem title="TTC" property="ttc" rows={rows} />
  </Row>
);

export default CompanyType;
