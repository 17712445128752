import { useTranslation } from 'react-i18next';

import ExpandedWrapper from 'components/hoc/ExpandedWrapper';
import {
  HeaderCell,
  HeaderCellLeft,
  HeaderRow,
  Table,
  TableTitle,
  TableTitleText,
} from 'components/pricing/components/TaxPrices/styles';
import { renderTableHeader } from 'components/pricing/components/TaxPrices/tax-prices.helper';
import {
  PriceType,
  PriceVariant,
} from 'components/pricing/components/TaxPrices/types';
import { ITaxTable } from 'services/taxPrices.types';

import { getDateRange } from 'utils/date/date-range';

import EditPriceBtn from '../../../EditPriceBtn';
import CompanyType from '../CompanyType';

const TurpeTable = ({
  priceVariant,
  taxPrices,
  onEdit = () => {},
}: ITaxTable) => {
  const { t } = useTranslation();
  const isHystoricalTaxPrices = priceVariant === PriceVariant.HISTORICAL;

  const titleText = t('price_valid_date_range', {
    dateRange: getDateRange(taxPrices),
  });

  if (!taxPrices.turpePerMWh) {
    return null;
  }

  return (
    <ExpandedWrapper
      isCollapsed={isHystoricalTaxPrices}
      arrowUpText={t('hide_historical_prices')}
      arrowDownText={t('show_historical_prices')}
    >
      <TableTitle type={priceVariant}>
        <TableTitleText>
          {renderTableHeader({
            priceType: PriceType.TURPE,
            priceVariant,
            titleText,
            t,
          })}
        </TableTitleText>
        <EditPriceBtn priceVariant={priceVariant} onClick={onEdit} />
      </TableTitle>
      <Table>
        <HeaderRow type={priceVariant}>
          <HeaderCellLeft>{`${t('electricity')}, TTC`}</HeaderCellLeft>
          <HeaderCell />
          <HeaderCell>{t('rush_hours')}</HeaderCell>
          <HeaderCell>{`${t('high_season')} ${t(
            'high_hours',
          ).toLowerCase()}`}</HeaderCell>
          <HeaderCell>{`${t('high_season')} ${t(
            'low_hours',
          ).toLowerCase()}`}</HeaderCell>
          <HeaderCell>{`${t('low_season')} ${t(
            'high_hours',
          ).toLowerCase()}`}</HeaderCell>
          <HeaderCell>{`${t('low_season')} ${t(
            'low_hours',
          ).toLowerCase()}`}</HeaderCell>
        </HeaderRow>
        <CompanyType
          priceVariant={priceVariant}
          title={t('energy_private_house')}
          description="(<36 kVA)"
          rows={taxPrices.turpePerMWh?.private}
        />
        <CompanyType
          priceVariant={priceVariant}
          title={t('small_and_medium_companies')}
          description="(>36 kVA)"
          rows={taxPrices.turpePerMWh?.medium}
        />
        <CompanyType
          priceVariant={priceVariant}
          title={t('large_company')}
          description="(>250 kVA)"
          rows={taxPrices.turpePerMWh?.large}
        />
      </Table>
    </ExpandedWrapper>
  );
};

export default TurpeTable;
