import { PriceVariant } from 'components/pricing/components/TaxPrices/types';
import { ITaxPrice } from 'services/taxPrices.types';

import {
  Cell,
  CellWrapper,
  HeaderRow,
  Row,
  TextBold,
  TextRegular,
} from './styles';

type ICompanyType = {
  priceVariant: PriceVariant;
  title: string;
  description: string;
  rows: ITaxPrice;
};

const CompanyType = ({
  priceVariant,
  title,
  description,
  rows,
}: ICompanyType) => (
  <Row type={priceVariant}>
    <HeaderRow>
      <CellWrapper>
        <TextBold>{title}</TextBold>
      </CellWrapper>
      <TextRegular>{description}</TextRegular>
    </HeaderRow>
    <Cell>
      <TextRegular>{rows?.ht}</TextRegular>
    </Cell>
    <Cell>
      <TextRegular>{rows?.vat}</TextRegular>
    </Cell>
    <Cell>
      <TextRegular>{rows?.ttc}</TextRegular>
    </Cell>
  </Row>
);

export default CompanyType;
