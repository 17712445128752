import { createApi } from '@reduxjs/toolkit/query/react';

import { API_URLS, REQUEST_METHOD } from 'constants/global';

import { baseQuery } from './baseQuery';
import { IMember } from './types';

export const pmoMemberDashboard = createApi({
  reducerPath: 'pmoMemberDashboard',
  baseQuery,
  endpoints: (builder) => ({
    getMemberById: builder.query<IMember, any>({
      query: (memberId = undefined) => ({
        url: `${API_URLS.MEMBER}${memberId ? `?id=${memberId}` : ''}`,
        method: REQUEST_METHOD.GET,
      }),
    }),
  }),
  tagTypes: ['pmoMemberDashboard'],
});

export const { useGetMemberByIdQuery } = pmoMemberDashboard;
