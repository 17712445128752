export const tipsData: Record<string, any> = {
  private: {
    title: 'energy_tips_private_title',
    items: [
      'energy_tips_private_first_tip',
      'energy_tips_private_second_tip',
      'energy_tips_private_third_tip',
      'energy_tips_private_fourth_tip',
    ],
  },
  small: {
    title: 'energy_tips_small_and_medium_title',
    items: [
      'energy_tips_small_and_medium_first_tip',
      'energy_tips_small_and_medium_second_tip',
      'energy_tips_small_and_medium_third_tip',
      'energy_tips_small_and_medium_fourth_tip',
    ],
  },
  medium: {
    title: 'energy_tips_small_and_medium_title',
    items: [
      'energy_tips_small_and_medium_first_tip',
      'energy_tips_small_and_medium_second_tip',
      'energy_tips_small_and_medium_third_tip',
      'energy_tips_small_and_medium_fourth_tip',
    ],
  },
  large: {
    title: 'energy_tips_large_title',
    items: [
      'energy_tips_large_first_tip',
      'energy_tips_large_second_tip',
      'energy_tips_large_third_tip',
      'energy_tips_large_fourth_tip',
    ],
  },
  collectivity: {
    title: 'energy_tips_collectivity_title',
    items: [
      'energy_tips_collectivity_first_tip',
      'energy_tips_collectivity_second_tip',
      'energy_tips_collectivity_third_tip',
      'energy_tips_collectivity_fourth_tip',
    ],
  },
  none: {
    title: '',
    items: [],
  },
};
