import { SorterTypes } from 'components/pricing/types';

export const sorterOptions = [
  {
    value: SorterTypes.CONSUMER_NAME,
    label: 'consumer_name',
  },
  {
    value: SorterTypes.CONSUMER_TYPE,
    label: 'consumer_type',
  },
  {
    value: SorterTypes.CONTRACT_END_DATE,
    label: 'contract_end_date',
  },
];
