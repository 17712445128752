import { createSlice } from '@reduxjs/toolkit';

import { predefinedRanges } from './constants';
import { DATE_PICKER_STATE, DatePickerType } from './types';

interface ReportDatePickerState {
  dates: DatePickerType;
}

const initialState: ReportDatePickerState = {
  dates: {
    ...predefinedRanges[DATE_PICKER_STATE.CURRENT_YEAR],
    label: DATE_PICKER_STATE.CURRENT_YEAR,
  },
};

export const ReportDatePickerSlice = createSlice({
  name: 'reportDatePicker',
  initialState,
  reducers: {
    setDates: (state, action) => {
      state.dates = action.payload;
    },
  },
});

export const { setDates } = ReportDatePickerSlice.actions;

export default ReportDatePickerSlice.reducer;
