import { v4 as uuidv4 } from 'uuid';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import PlusOutlines from 'components/communities/Communities/img/PlusOutlined.svg';
import { BtnWrapper } from 'components/communities/Communities/styles';
import ExpandedWrapper from 'components/hoc/ExpandedWrapper';
import {
  GroupedTaxPricesByDateRange,
  groupDateRanges,
  renderTableHeader,
} from 'components/pricing/components/TaxPrices/tax-prices.helper';
import { BtnPrimary } from 'components/shared/Button';
import Image from 'components/shared/Image';
import { IDBTaxPrices, ITaxPrices } from 'services/taxPrices.types';
import Loader from 'components/shared/Loader';

import { BlockHeaderWithBottomSpace, Title } from '../../styles';
import { PriceType, PriceVariant } from '../../types';
import EmptyPrices from '../EmptyPrices';
import ModifyPrices from '../ModifyPrices';
import DiscountedAcciseTable from './components/DiscountedAcciseTable';
import TableRow from './components/TableRow';
import { Page } from './components/styles';
import { getAppRole } from 'utils';
import { USER_ROLES } from 'constants/types';
import { useGetMemberQuery } from 'services/membersManagement';
import { useGetCommunityDataQuery } from 'services/dashboardManagment';

const DiscountedAccise = (data: ITaxPrices) => {
  const userRole = getAppRole();
  const isProducer = userRole === USER_ROLES.PRODUCER;

  const { data: userData } = useGetMemberQuery(undefined, {
    skip: !isProducer,
  });
  const { data: communityData, isLoading: isCommunityDataLoading } =
    useGetCommunityDataQuery(
      {
        userId: userData?._id,
      },
      { skip: !userData?._id },
    );

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editTaxes, setEditTaxes] = useState<IDBTaxPrices | undefined>(
    undefined,
  );

  const { t } = useTranslation();
  const { current, upcoming, historical } = data;

  const handleOnEdit = (taxPrice: IDBTaxPrices) => {
    setIsModalVisible(true);
    setEditTaxes(taxPrice);
  };

  const handleOnModalVisibleChange = (isModalVisible: boolean) => {
    setIsModalVisible(isModalVisible);
    setEditTaxes(undefined);
  };

  const renderTable = (
    taxPrices: GroupedTaxPricesByDateRange,
    priceVariant: PriceVariant,
    hasEdit = false,
  ) =>
    Object.keys(taxPrices).map((taxPrice) => (
      <DiscountedAcciseTable
        hasEdit={hasEdit}
        priceVariant={priceVariant}
        headerText={renderTableHeader({
          priceType: t('discounted_accise').toUpperCase(),
          priceVariant,
          titleText: t('price_valid_date_range', {
            dateRange: taxPrice,
          }),
          t,
        })}
      >
        {taxPrices[taxPrice].map((item: IDBTaxPrices) => (
          <TableRow
            key={uuidv4()}
            hasEdit={hasEdit}
            priceVariant={priceVariant}
            price={item}
            onEdit={hasEdit ? () => handleOnEdit(item) : undefined}
          />
        ))}
      </DiscountedAcciseTable>
    ));

  const renderPrices = (
    prices: IDBTaxPrices[],
    priceVariant: PriceVariant,
    hasEdit = false,
  ) => {
    if (!prices?.length) return;
    const taxPrices = groupDateRanges(
      prices,
      'discountedAccisePerMWh',
      isProducer && communityData?.communityId,
    );

    return renderTable(taxPrices, priceVariant, hasEdit);
  };

  const renderHistoricalPrices = () => {
    if (
      !historical?.filter(
        (taxPrice) => taxPrice.discountedAccisePerMWh !== undefined,
      ).length
    )
      return null;

    const taxPrices = groupDateRanges(
      historical,
      'discountedAccisePerMWh',
      isProducer && communityData?.communityId,
    );
    return (
      <ExpandedWrapper
        isCollapsed
        arrowUpText={t('hide_historical_prices')}
        arrowDownText={t('show_historical_prices')}
      >
        {renderTable(taxPrices, PriceVariant.HISTORICAL)}
      </ExpandedWrapper>
    );
  };

  if (isCommunityDataLoading) {
    return (
      <Page>
        <Loader />
      </Page>
    );
  }

  return (
    <Page>
      <BlockHeaderWithBottomSpace>
        <Title>{t('discounted_accise')}</Title>
        {!isProducer && (
          <BtnWrapper>
            <BtnPrimary onClick={() => setIsModalVisible(true)}>
              <Image src={PlusOutlines} alt="PlusIcon" margin="0 5px 0 0" />
              {t('add_new_discounted_accise')}
            </BtnPrimary>
          </BtnWrapper>
        )}
      </BlockHeaderWithBottomSpace>
      {renderPrices(current, PriceVariant.CURRENT)}
      {renderPrices(upcoming, PriceVariant.UPCOMMING, !isProducer)}
      {renderHistoricalPrices()}
      <ModifyPrices
        isModalVisible={isModalVisible}
        priceType={PriceType.DISCOUNTED_ACCISE}
        taxPrices={editTaxes}
        onModalVisibleChange={handleOnModalVisibleChange}
      />
      <EmptyPrices
        {...data}
        priceType={PriceType.DISCOUNTED_ACCISE}
        priceKey="discountedAccisePerMWh"
        communityId={isProducer && communityData?.communityId}
      />
    </Page>
  );
};

export default DiscountedAccise;
