import { CreateProducerPayload } from 'services/types';

export const initialValues: CreateProducerPayload = {
  firstName: '',
  lastName: '',
  middleName: '',
  contactNumber: '',
  email: '',
  role: 'Producer',

  address: {
    city: '',
    country: 'France',
    postalCode: '',
    street: '',
    building: '',
    location: {
      coordinates: [],
    },
  },

  additionalInfo: {
    siretNumber: null,
    power: null,
    vatNumber: null,
    sharedCapital: '',
    billingAddress: {
      city: '',
      country: 'France',
      postalCode: '',
      street: '',
      building: '',
      location: {
        coordinates: [43.433031, 6.736018],
        type: 'Point',
      },
    },
    billingPerson: {
      firstName: '',
      lastName: '',
      middleName: '',
      email: '',
      contactNumber: '',
    },
    legalPerson: {
      firstName: '',
      lastName: '',
      middleName: '',
      email: '',
      contactNumber: '',
      position: '',
    },
    prms: [
      {
        prmValue: null,
      },
    ],
    agreementSigned: false,
    licenseOperationSigned: false,
    consumptionAgreementSigned: false,
    enedisConsentSigned: false,
  },
};
