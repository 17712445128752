import { styled } from 'styled-components';
import { Link } from 'react-router-dom';
import { ExpandAltOutlined } from '@ant-design/icons';
import { MAX_CONTENT_WIDTH } from 'constants/screen-breakpoints';
import { SubHeader } from 'components/shared/Text';
import { COLORS } from 'constants/colors';

export const TableWrapper = styled.div`
  max-width: ${MAX_CONTENT_WIDTH}px;
`;

export const TableHeader = styled(SubHeader)`
  margin: 0;
  text-align: start;
  font-weight: 600;
`;

export const StyledInputWrapper = styled.div`
  max-width: 325px;
  margin: 24px 0;
`;

export const StyledExpandAltOutlined = styled(ExpandAltOutlined)`
  color: ${COLORS.PrimaryBase};
`;

export const StyledEmptyDataWrapper = styled.div`
  width: 100%;
  height: 650px;
  background-color: ${COLORS.BgContainerDisabled};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledSelfProductionWrapper = styled.div`
  display: flex;
  justify-content: left;
`;

export const StyledMemberNameLink = styled(Link)`
  color: ${COLORS.TextBase};
  font-weight: 600;
  &:hover {
    text-decoration: underline;
    color: ${COLORS.Green7};
  }
`;
