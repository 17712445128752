import { useTranslation } from 'react-i18next';
import { PAYMENT_STATUS } from 'constants/types';
import {
  StyledAwaitingPayment,
  StyledIssued,
  StyledPaid,
  StyledOverdue,
  StyledPaymentError,
  StyledCancelled,
} from './styles';

interface IPaymentStatusCard {
  status: PAYMENT_STATUS;
}

const PaymentStatusCard = ({ status }: IPaymentStatusCard) => {
  const { t } = useTranslation();
  const statusText = t(`payment_statuses.${status}`);

  switch (status) {
    case PAYMENT_STATUS.AWAITING_PAYMENT:
      return <StyledAwaitingPayment>{statusText}</StyledAwaitingPayment>;
    case PAYMENT_STATUS.ISSUED:
      return <StyledIssued>{statusText}</StyledIssued>;
    case PAYMENT_STATUS.CANCELLED:
      return <StyledCancelled>{statusText}</StyledCancelled>;
    case PAYMENT_STATUS.OVERDUE:
      return <StyledOverdue>{statusText}</StyledOverdue>;
    case PAYMENT_STATUS.PAID:
      return <StyledPaid>{statusText}</StyledPaid>;
    case PAYMENT_STATUS.PAYMENT_ERROR:
      return <StyledPaymentError>{statusText}</StyledPaymentError>;
  }
};

export default PaymentStatusCard;
