interface PriceType {
  [key: string]: number;
}

export interface InputObjectType {
  large: PriceType;
  small: PriceType;
  private: PriceType;
  medium: PriceType;
  collectivity: PriceType;
}

export enum FinancialDataTypes {
  EARNED_FROM_COMMUNITY = 'earned_from_community',
  EARNED_TAX_REFUND = 'earned_tax_refund',
  ALL_EARNINGS = 'all_earnings',
  PAID_AMOUNT = 'paid_amount',
  OVERDUE_AMOUNT = 'overdue_amount',
}
