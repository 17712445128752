import { styled } from 'styled-components';

export const StyledPieChartContainer = styled.div<{ isLoading?: boolean }>`
  width: 50%;
  height: ${({ isLoading }) => (isLoading ? '95%' : '233px')};
  padding-left: 40px;
  position: relative;

  svg {
    width: 100%;
    height: 100%;
  }
`;
