import ConsumerProfileTab from 'components/communities/Dashboard/components/ConsumerDashboard/components/ProfileDataTab';
import ProducerProfileTab from 'components/communities/Dashboard/components/ProducerDashboard/components/ProfileDataTab';
import { USER_ROLES } from 'constants/types';

const ProfileTab = ({ role }: { role: string | null }) => {
  return role === USER_ROLES.PRODUCER ? (
    <ProducerProfileTab />
  ) : (
    <ConsumerProfileTab />
  );
};

export default ProfileTab;
