import { ColumnsType } from 'antd/es/table';

import { TFunction } from 'i18next';

export const columns = (t: TFunction) => [
  {
    title: `${t('total_production').toUpperCase()}, ${t('kwh')}`,
    dataIndex: 'totalProduction',
    render: (text: string) => parseInt(text),
    sorter: (report: any, nextReport: any) =>
      parseInt(report.totalProduction) - parseInt(nextReport.totalProduction),
  },
  {
    title: `${t('overproduction').toUpperCase()}, ${t('kwh')}`,
    dataIndex: 'overproduction',
    render: (text: string) => parseInt(text),
    sorter: (report: any, nextReport: any) =>
      parseInt(report.overproduction) - parseInt(nextReport.overproduction),
  },
];
