import React from 'react';

const Icon: React.FC = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.4592 0.704102C8.21488 0.704102 7.19556 1.72455 7.19556 2.9702V5.2363H28.8047V2.9702C28.8047 1.72455 27.7854 0.704102 26.5411 0.704102H9.4592Z"
        fill="#69B1FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.88867 26.8994H15.4379V31.8124H3.88867V26.8994Z"
        fill="#FFD591"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4377 24.615V26.899V31.812H20.5623V26.899V24.615C20.5623 24.328 20.3274 24.0928 20.0387 24.0928H15.9594C15.6727 24.0928 15.4377 24.328 15.4377 24.615Z"
        fill="#69B1FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5623 26.8994H32.1095V31.8124H20.5623V26.8994Z"
        fill="#FFD591"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.1105 16.3357V20.561H15.8897V16.3357H20.1105ZM12.2584 8.88963V13.1149H8.03767V8.88963H12.2584ZM5.35195 5.23633C4.54764 5.23633 3.88867 5.896 3.88867 6.70122V26.899H15.4379V24.6149C15.4379 24.3279 15.6728 24.0927 15.9595 24.0927H20.0388C20.3275 24.0927 20.5624 24.3279 20.5624 24.6149V26.899H32.1096V6.70122C32.1096 5.896 31.4526 5.23633 30.6483 5.23633H28.8048H7.19561H5.35195ZM27.9625 20.561H23.7418V16.3357H27.9625V20.561ZM12.2584 16.3357V20.561H8.03767V16.3357H12.2584ZM27.9625 13.1149H23.7418V8.88963H27.9625V13.1149ZM20.1105 13.1149H15.8897V8.88963H20.1105V13.1149Z"
        fill="#FFE7BA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.7417 16.3359H27.9624V20.5612H23.7417V16.3359Z"
        fill="#BAE0FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8896 16.3359H20.1104V20.5612H15.8896V16.3359Z"
        fill="#BAE0FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.0376 16.3359H12.2583V20.5612H8.0376V16.3359Z"
        fill="#BAE0FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.0376 8.88965H12.2583V13.1149H8.0376V8.88965Z"
        fill="#BAE0FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8896 8.88965H20.1104V13.1149H15.8896V8.88965Z"
        fill="#BAE0FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.7417 8.88965H27.9624V13.1149H23.7417V8.88965Z"
        fill="#BAE0FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4377 31.8115H3.88856H3.04045C2.54672 31.8115 2.14453 32.1364 2.14453 32.533V34.5759C2.14453 34.9726 2.54869 35.2974 3.04045 35.2974H32.9596C33.4514 35.2974 33.8555 34.9726 33.8555 34.5759V32.533C33.8555 32.1364 33.4514 31.8115 32.9596 31.8115H32.1095H20.5623H15.4377Z"
        fill="#FFD8BF"
      />
      <path
        d="M32.9596 31.1088H32.8127V6.70127C32.8127 5.50581 31.8417 4.53325 30.6482 4.53325H29.5078V2.9702C29.5078 1.33298 28.1769 0.000976562 26.541 0.000976562H9.45914C7.82325 0.000976562 6.49237 1.33298 6.49237 2.9702V4.53318H5.35191C4.1573 4.53318 3.18544 5.50581 3.18544 6.7012V31.1088H3.04045C2.15873 31.1088 1.44141 31.7478 1.44141 32.5334V34.5763C1.44148 35.3619 2.1588 36.001 3.04052 36.001H14.8465C15.2348 36.001 15.5496 35.6862 15.5496 35.2979C15.5496 34.9095 15.2348 34.5947 14.8465 34.5947H3.04052C2.94251 34.5947 2.87564 34.5599 2.84773 34.5372V32.5721C2.87367 32.551 2.9373 32.5151 3.04052 32.5151H32.9597C33.0577 32.5151 33.1246 32.5499 33.1525 32.5726V34.5372C33.1246 34.5599 33.0577 34.5947 32.9597 34.5947H21.1537C20.7653 34.5947 20.4505 34.9095 20.4505 35.2979C20.4505 35.6862 20.7653 36.001 21.1537 36.001H32.9596C33.8413 36.001 34.5587 35.3619 34.5587 34.5764V32.5334C34.5587 31.7479 33.8413 31.1088 32.9596 31.1088ZM16.1409 24.7959H19.8593V31.1088H16.1409V24.7959ZM21.2655 27.6021H31.4064V31.1088H21.2655V27.6021ZM7.89862 2.9702C7.89862 2.10838 8.59866 1.40723 9.45914 1.40723H26.541C27.4015 1.40723 28.1015 2.10838 28.1015 2.9702V4.53318H7.89862V2.9702ZM5.35191 5.93943H30.6482C31.0663 5.93943 31.4064 6.28115 31.4064 6.7012V26.1959H21.2655V24.615C21.2655 23.9394 20.7151 23.3897 20.0387 23.3897H15.9593C15.284 23.3897 14.7346 23.9394 14.7346 24.615V26.1959H4.59176V6.70127C4.59176 6.28122 4.93277 5.93943 5.35191 5.93943ZM4.59176 27.6021H14.7347V31.1088H4.59176V27.6021Z"
        fill="black"
        fillOpacity="0.88"
      />
      <path
        d="M8.0376 13.8181H12.2583C12.6467 13.8181 12.9614 13.5033 12.9614 13.1149V8.88965C12.9614 8.50131 12.6467 8.18652 12.2583 8.18652H8.0376C7.64926 8.18652 7.33447 8.50131 7.33447 8.88965V13.1149C7.33447 13.5033 7.64926 13.8181 8.0376 13.8181ZM8.74072 9.59277H11.5552V12.4118H8.74072V9.59277Z"
        fill="black"
        fillOpacity="0.88"
      />
      <path
        d="M15.8896 13.8181H20.1103C20.4986 13.8181 20.8134 13.5033 20.8134 13.1149V8.88965C20.8134 8.50131 20.4986 8.18652 20.1103 8.18652H15.8896C15.5013 8.18652 15.1865 8.50131 15.1865 8.88965V13.1149C15.1865 13.5033 15.5013 13.8181 15.8896 13.8181ZM16.5928 9.59277H19.4072V12.4118H16.5928V9.59277Z"
        fill="black"
        fillOpacity="0.88"
      />
      <path
        d="M23.7417 13.8181H27.9624C28.3508 13.8181 28.6655 13.5033 28.6655 13.1149V8.88965C28.6655 8.50131 28.3508 8.18652 27.9624 8.18652H23.7417C23.3534 8.18652 23.0386 8.50131 23.0386 8.88965V13.1149C23.0386 13.5033 23.3534 13.8181 23.7417 13.8181ZM24.4448 9.59277H27.2593V12.4118H24.4448V9.59277Z"
        fill="black"
        fillOpacity="0.88"
      />
      <path
        d="M8.0376 21.2644H12.2583C12.6467 21.2644 12.9614 20.9496 12.9614 20.5612V16.3359C12.9614 15.9476 12.6467 15.6328 12.2583 15.6328H8.0376C7.64926 15.6328 7.33447 15.9476 7.33447 16.3359V20.5612C7.33447 20.9496 7.64926 21.2644 8.0376 21.2644ZM8.74072 17.0391H11.5552V19.8581H8.74072V17.0391Z"
        fill="black"
        fillOpacity="0.88"
      />
      <path
        d="M15.8896 21.2644H20.1103C20.4986 21.2644 20.8134 20.9496 20.8134 20.5612V16.3359C20.8134 15.9476 20.4986 15.6328 20.1103 15.6328H15.8896C15.5013 15.6328 15.1865 15.9476 15.1865 16.3359V20.5612C15.1865 20.9496 15.5013 21.2644 15.8896 21.2644ZM16.5928 17.0391H19.4072V19.8581H16.5928V17.0391Z"
        fill="black"
        fillOpacity="0.88"
      />
      <path
        d="M23.7417 21.2644H27.9624C28.3508 21.2644 28.6655 20.9496 28.6655 20.5612V16.3359C28.6655 15.9476 28.3508 15.6328 27.9624 15.6328H23.7417C23.3534 15.6328 23.0386 15.9476 23.0386 16.3359V20.5612C23.0386 20.9496 23.3534 21.2644 23.7417 21.2644ZM24.4448 17.0391H27.2593V19.8581H24.4448V17.0391Z"
        fill="black"
        fillOpacity="0.88"
      />
      <path
        d="M18 34.5947C17.6117 34.5947 17.2969 34.9095 17.2969 35.2979C17.2969 35.6862 17.6117 36.001 18 36.001H18.004C18.3923 36.001 18.7052 35.6862 18.7052 35.2979C18.7052 34.9095 18.3883 34.5947 18 34.5947Z"
        fill="black"
        fillOpacity="0.88"
      />
    </svg>
  );
};

export default Icon;
