import { FC, useCallback, useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { getAuthUser } from 'utils';
import FormikInput from 'components/shared/Input/FormikInput';
import { InputGroup } from 'components/shared/InputGroup';
import { BtnPrimary } from 'components/shared/Button';
import ModalMessage from 'components/shared/ModalMessage';
import { contactFormSchema } from './contact-form.schema';
import { useContactUsMutation } from 'services/userManagement';
import {
  StyledContactFormContainer,
  StyledHeader,
  StyledRequiredFields,
  StyledContactFormRow,
  StyledContactFormText,
} from '../styles';

const ContactForm: FC = () => {
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showFailModal, setShowFailModal] = useState(false);
  const { t } = useTranslation();
  const { email, given_name, family_name } = getAuthUser();
  const initialValues = useMemo(
    () => ({ email, name: `${given_name} ${family_name}` }),
    [email, given_name, family_name],
  );
  const [sendContactEmail, { isError, isSuccess }] = useContactUsMutation();

  const onSubmit = useCallback(
    (values: any) => {
      sendContactEmail(values);
    },
    [sendContactEmail],
  );

  useEffect(() => {
    if (isError) setShowFailModal(true);
  }, [isError]);

  useEffect(() => {
    if (isSuccess) setShowSuccessModal(true);
  }, [isSuccess]);

  return (
    <StyledContactFormContainer>
      {showFailModal && (
        <ModalMessage
          messageType="cancel"
          title={t('contact_us_fail_title')}
          subTitle={t('contact_us_fail_text')}
          btnText={t('ok')}
          handleBtn={() => setShowFailModal(false)}
        />
      )}
      <StyledHeader>{t('contact_us_title')}</StyledHeader>
      <StyledRequiredFields>{t('required_fields_marked')}</StyledRequiredFields>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        validationSchema={contactFormSchema(t)}
        onSubmit={onSubmit}
      >
        {({
          isValid,
          isSubmitting,
          validateForm,
          setErrors,
          handleSubmit,
          resetForm,
        }) => {
          const handleOnFocus = () => {
            setErrors({});
          };
          const handleOnSubmit = () => {
            validateForm();
            if (isValid) {
              handleSubmit();
            }
          };
          const isBtnDisabled = !isValid || isSubmitting;
          const closeSuccessModal = () => {
            setShowSuccessModal(false);
            resetForm();
          };

          return (
            <Form>
              {showSuccessModal && (
                <ModalMessage
                  messageType="success"
                  title={t('contact_us_success_title')}
                  subTitle={t('contact_us_success_text')}
                  btnText={t('ok')}
                  handleBtn={closeSuccessModal}
                />
              )}
              <StyledContactFormRow>
                <InputGroup
                  isShowError={false}
                  label={`${t('email').toString()}*`}
                >
                  <FormikInput
                    onFocus={handleOnFocus}
                    type="email"
                    fieldName="email"
                    placeholder={'emilia.rossi-fattoria@com'}
                  />
                </InputGroup>
                <InputGroup
                  isShowError={false}
                  label={`${t('name').toString()}*`}
                >
                  <FormikInput
                    onFocus={handleOnFocus}
                    type="text"
                    fieldName="name"
                    placeholder={'Emilia Rossi'}
                  />
                </InputGroup>
              </StyledContactFormRow>
              <StyledContactFormRow>
                <InputGroup isShowError={false} label={t('company').toString()}>
                  <FormikInput
                    onFocus={handleOnFocus}
                    type="text"
                    fieldName="company"
                    placeholder={'Rattoria@com'}
                  />
                </InputGroup>
                <InputGroup
                  isShowError={false}
                  label={t('job_title').toString()}
                >
                  <FormikInput
                    onFocus={handleOnFocus}
                    type="text"
                    fieldName="jobTitle"
                    placeholder={t('job_title').toString()}
                  />
                </InputGroup>
              </StyledContactFormRow>
              <StyledContactFormRow>
                <InputGroup
                  isShowError={false}
                  label={`${t('message').toString()}*`}
                >
                  <FormikInput
                    onFocus={handleOnFocus}
                    type="textarea"
                    fieldName="message"
                    placeholder={''}
                    rows={4}
                  />
                </InputGroup>
              </StyledContactFormRow>
              <StyledContactFormText>
                {t('your_information_processed')}
              </StyledContactFormText>
              <BtnPrimary
                additionalStyles={{ width: '324px', margin: '0 auto' }}
                isDisabled={isBtnDisabled}
                onClick={handleOnSubmit}
              >
                {t('submit')}
              </BtnPrimary>
            </Form>
          );
        }}
      </Formik>
    </StyledContactFormContainer>
  );
};

export default ContactForm;
