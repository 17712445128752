import styled from 'styled-components';

import { StyledCommunityTitle } from '../../setup-community/styles';

export const StyledPMOTitle = styled(StyledCommunityTitle)`
  margin-top: 0;
  margin-bottom: 10px;
`;

export const StyledPMOAddressLabel = styled(StyledCommunityTitle)`
  margin: 0px;
`;

export const StyledAddressWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 25px;
`;
