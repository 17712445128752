import styled from 'styled-components';

import { DownloadOutlined, EuroCircleOutlined } from '@ant-design/icons';

import { TextRegular } from 'components/shared/Text';
import { COLORS } from 'constants/colors';
import {
  MAX_CONTENT_WIDTH,
  ScreenLarge,
  ScreenContentMaxSize,
  ScreenContentCustomSize,
} from 'constants/screen-breakpoints';

export const PageHeader = styled.div<{
  background: string;
  paddingTop?: string;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: ${(props) => props.background};
  box-sizing: border-box;
  width: 100%;
  padding-top: ${(props) => (props.paddingTop ? props.paddingTop : '0')};
  border-radius: 6px;
`;

export const Wrapper = styled.div<{ paddingTop?: string }>`
  padding-top: ${(props) => (props.paddingTop ? props.paddingTop : '0')};
  background: ${COLORS.BgLayout};
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

export const StyledPricesWrapper = styled.div`
  background: ${COLORS.BgLayout};
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

export const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderRowItem = styled.div<{ position?: string }>`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: baseline;
  justify-content: ${({ position }) => (position ? position : undefined)};
  box-sizing: border-box;
`;

export const ChartsWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  background: ${COLORS.BgLayout};
`;

export const Container = styled.div<{ fullWidth?: boolean }>`
  padding: 32px;
  margin: 0 auto;
  box-sizing: content-box;
  gap: 24px;
  display: flex;
  flex-direction: column;
  width: ${MAX_CONTENT_WIDTH}px;
  ${ScreenContentCustomSize(MAX_CONTENT_WIDTH + 64)`
    width: 100%;
    box-sizing: border-box;
  `}
`;

export const EnergyContainer = styled(Container)`
  width: ${MAX_CONTENT_WIDTH}px;
  ${ScreenContentMaxSize`
    width: 100%;
  `}
`;

export const Row = styled.div`
  display: flex;
  column-gap: 24px;
  row-gap: 24px;
  margin-bottom: 24px;
  width: 100%;
  flex-wrap: wrap;
`;

export const CardMedium = styled.div<{ height: number }>`
  display: flex;
  width: calc((100% - 24px) / 2);
  min-height: ${(props) => props.height}px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  box-sizing: border-box;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  background: ${COLORS.BgContainer};

  ${ScreenLarge`
    width: 100%;
  `}
`;

export const ChartTitle = styled.span`
  color: ${COLORS.TextBase};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`;

export const CardHeader = styled.div`
  display: flex;
  padding: 16px 24px;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  align-self: stretch;
  border-bottom: 1px solid ${COLORS.Grey2};
`;

export const CardBody = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
`;

export const Table = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const TableItem = styled.div`
  display: flex;
  flex: 0.7;
  flex-direction: row;
  align-items: center;
`;

export const ColumnItem = styled(TableItem)`
  flex-direction: column;
  justify-content: flex-end;
`;

export const RowTitle = styled(TextRegular)<{ width?: number }>`
  color: ${COLORS.TextBase};
  width: ${({ width }) => (width ? `${width}px` : undefined)};
`;

export const HeaderItem = styled(RowTitle)`
  display: flex;
  align-self: flex-start;
  font-weight: 600;
  text-transform: uppercase;
  max-width: 100px;
`;

export const Value = styled(RowTitle)`
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
`;

export const Percent = styled(TextRegular)`
  font-size: 14px;
  font-weight: 600;
  color: ${COLORS.TextSecondary};
  margin-left: 4px;
`;

export const InlineItem = styled.div`
  display: flex;
  align-self: flex-start;
  align-items: center;
`;

export const ColorLine = styled.div<{ color: string }>`
  border-radius: 2px;
  background: ${(props) => props.color};
  width: 4px;
  height: 100%;
  margin-right: 4px;
`;

export const NotAvailableValue = styled.div`
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.32px;
`;

export const CardDeliveredBody = styled.div<{ verticalDirection: string }>`
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: space-between;
  align-items: ${(props) => props.verticalDirection};
`;

export const WidgetWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: calc((100% - 72px) / 4);
  height: 140px;
  padding: 12px;
  background-color: ${COLORS.BgElevated};
  box-sizing: border-box;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.06);

  ${ScreenLarge`
    width: calc((100% - 24px) / 2);
  `}
`;

export const SummaryViewRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 24px;
  align-items: center;
  margin-bottom: 24px;
  row-gap: 0;
  ${ScreenLarge`
    row-gap: 24px;
  `}
`;

export const HeaderContainer = styled.div`
  margin: 0 auto;
  padding: 0 32px;
  width: -webkit-fill-available;
  box-sizing: content-box;
  max-width: ${MAX_CONTENT_WIDTH}px;
  ${ScreenContentMaxSize`
    box-sizing: border-box;
  `}
`;

export const StyledDownloadOutlined = styled(DownloadOutlined)`
  color: ${COLORS.PrimaryBase};
`;

export const StyledEuroCircleOutlined = styled(EuroCircleOutlined)`
  color: ${COLORS.PrimaryBase};
`;
