import { styled } from 'styled-components';

import { PriceVariant } from 'components/pricing/components/TaxPrices/types';
import { COLORS } from 'constants/colors';

export const Table = styled.div`
  display: grid;
  grid-template-rows: 46px repeat(3, 1fr);
  background: ${COLORS.BgContainer};
`;

export const HeaderRow = styled.div<{ type?: PriceVariant }>`
  background: ${(props) =>
    props.type === PriceVariant.HISTORICAL ? COLORS.Grey7 : 'transparent'};
  display: grid;
  grid-template-columns: 35% 45% repeat(2, 1fr);
  border-top: 1px solid ${COLORS.ColorSplit};
  border-bottom: 1px solid ${COLORS.ColorSplit};

  > :last-child {
    border-right: none;
  }
`;

export const HeaderCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 22px;
  padding: 0 8px;
  margin: 12px 0;

  font-size: 14px;
  font-weight: 600;

  border-right: 1px solid ${COLORS.ColorSplit};
`;

export const HeaderCellLeft = styled(HeaderCell)`
  justify-content: flex-start;
  padding-left: 8px;
`;
