import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  Container,
  SuccessIcon,
} from 'components/auth/CreatePassword/components/styles';
import { BtnPrimary } from 'components/shared/Button';
import { MarginTop20vh, MarginTop40px } from 'components/shared/Margin';
import { Header, TextRegular } from 'components/shared/Text';
import AppRoutes from 'constants/routes';

interface ISetPasswordSuccess {
  headerText: string;
  headerDescription: string;
}

const CreatePasswordSuccess = ({ headerText }: ISetPasswordSuccess) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleOnClick = () => navigate(AppRoutes.SignIn);

  return (
    <Container>
      <MarginTop20vh />
      <SuccessIcon />
      <Header>{headerText}</Header>
      <TextRegular>{t('password_changed_success')}</TextRegular>
      <MarginTop40px />
      <BtnPrimary onClick={handleOnClick}>{t('login_now')}</BtnPrimary>
    </Container>
  );
};

export default CreatePasswordSuccess;
