import { useCallback, useEffect, useMemo, useState } from 'react';
import { INotification } from 'constants/types';
import { EVENTS } from 'constants/events';
import { getAppRole } from '../index';
import { useGetMemberQuery } from '../../services/membersManagement';
import { getRoomId } from 'utils/websocket/getRoom';
import { io, Socket } from 'socket.io-client';

export const useNotificationsSocket = () => {
  const role = getAppRole();
  const { data: userData } = useGetMemberQuery();

  const userId = userData?._id || '';
  const roomId = getRoomId(userId, role);
  const [notifications, setNotifications] = useState<INotification[]>([]);

  const websocketURL = process.env.REACT_APP_WEBSOCKET_URL || '';

  const socket: Socket = useMemo(
    () =>
      io(websocketURL, {
        query: { roomId, userId, role },
      }),
    [websocketURL, roomId, userId, role],
  );

  useEffect(() => {
    if (!socket) return;

    const handleConnect = () => {
      socket.emit(EVENTS.GET_ALL_NOTIFICATIONS);
    };

    const handleGetAllNotifications = (data: INotification[]) => {
      setNotifications(data);
    };

    const handleNotificationUpdate = (notification: INotification) => {
      setNotifications((prevNotifications) => [
        notification,
        ...prevNotifications,
      ]);
    };

    const handleMarkAsRead = (updatedNotification: INotification) => {
      setNotifications((prevNotifications) =>
        prevNotifications.map((notification) =>
          notification._id === updatedNotification._id
            ? updatedNotification
            : notification,
        ),
      );
    };

    socket.on(EVENTS.CONNECT, handleConnect);
    socket.on(EVENTS.GET_ALL_NOTIFICATIONS, handleGetAllNotifications);
    socket.on(EVENTS.GET_NOTIFICATIONS_UPDATES, handleNotificationUpdate);
    socket.on(EVENTS.MARK_AS_READ, handleMarkAsRead);

    return () => {
      socket.off(EVENTS.CONNECT, handleConnect);
      socket.off(EVENTS.GET_ALL_NOTIFICATIONS, handleGetAllNotifications);
      socket.off(EVENTS.GET_NOTIFICATIONS_UPDATES, handleNotificationUpdate);
      socket.off(EVENTS.MARK_AS_READ, handleMarkAsRead);
      socket.disconnect();
    };
  }, [socket]);

  const markAsRead = useCallback(
    (id: string) => socket.emit(EVENTS.MARK_AS_READ, id),
    [socket],
  );

  return {
    notifications,
    markAsRead,
  };
};
