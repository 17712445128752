import { TFunction } from 'i18next';

import { CHART_TYPE } from 'constants/global';
import { PERIOD } from 'constants/types';

interface IGetPeriodFiltersByRole {
  t: TFunction;
  chartType?: CHART_TYPE;
}

export const getPeriodFiltersByRole = ({
  t,
  chartType,
}: IGetPeriodFiltersByRole) => {
  const SHORT_LIST_FILTERS = [
    {
      key: PERIOD.LAST_MONTH,
      label: t('last_month'),
    },
    {
      key: PERIOD.CURRENT_YEAR,
      label: t('current_year'),
    },
    {
      key: PERIOD.LAST_YEAR,
      label: t('last_year'),
    },
  ];
  const ALL_FILTERS = [
    {
      key: PERIOD.PREVIOUS_DAY,
      label: t('previous_day'),
    },
    {
      key: PERIOD.LAST_SEVEN_DAYS,
      label: t('last_seven_days'),
    },
    {
      key: PERIOD.CURRENT_MONTH,
      label: t('current_month'),
    },
    ...SHORT_LIST_FILTERS,
  ];

  switch (chartType) {
    case CHART_TYPE.FINANCIAL_DATA: {
      return SHORT_LIST_FILTERS;
    }

    default: {
      return ALL_FILTERS;
    }
  }
};
