import { getAppRole } from 'utils';

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import TabsComponent from 'components/shared/Tabs';
import { COLORS } from 'constants/colors';
import { USER_ROLES } from 'constants/types';
import { useGetTaxPricesQuery } from 'services/taxPrices.service';

import { Container, PageTitle, PageTitleWrapper } from './styles';
import { useTabsConfig } from './useTabsConfig';

const TaxPrices = () => {
  const { t } = useTranslation();
  const userRole = getAppRole();

  const isProducer = useMemo(
    () => userRole === USER_ROLES.PRODUCER,
    [userRole],
  );

  const { data = { current: [], upcoming: [], historical: [] }, isLoading } =
    useGetTaxPricesQuery({ userId: undefined });
  const tabsConfig = useTabsConfig(data, isLoading);

  return (
    <Container isProducer={isProducer}>
      {!isProducer && (
        <PageTitleWrapper>
          <PageTitle>{t('oyo_coefficients')}</PageTitle>
        </PageTitleWrapper>
      )}
      <TabsComponent
        backgroundColor={COLORS.BgContainer}
        paddingLeft="32px"
        items={tabsConfig}
      />
    </Container>
  );
};

export default TaxPrices;
