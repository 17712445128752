import { ITaxTable } from 'services/taxPrices.types';

import { getDateRange } from 'utils/date/date-range';

import EditPriceBtn from '../../../EditPriceBtn';
import TableTitleRow from '../TableTitleRow';
import { Cell, LineHeader, Row, Table, TextBold } from './styles';

const IndexTable = ({
  priceVariant,
  taxPrices,
  onEdit = () => {},
}: ITaxTable) => {
  if (!taxPrices.index) {
    return null;
  }

  return (
    <Table>
      <Row type={priceVariant}>
        <LineHeader>
          <TableTitleRow priceVariant={priceVariant} />
          <TextBold>{getDateRange(taxPrices)}</TextBold>
        </LineHeader>
        <Cell>
          <TextBold>{taxPrices.index}</TextBold>
          <EditPriceBtn priceVariant={priceVariant} onClick={onEdit} />
        </Cell>
      </Row>
    </Table>
  );
};

export default IndexTable;
