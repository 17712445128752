import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Formik, FormikValues } from 'formik';

import { BtnPrimary } from 'components/shared/Button';
import ContactUs from 'components/shared/ContactUs';
import FormValidationItem from 'components/shared/PasswordForm/FormValidationItem';
import {
  Container,
  ErrorConfirmPasswordIcon,
  FieldsWithValidation,
  HeaderWrapper,
  PasswordInput,
  ValidationItem,
  ValidationList,
} from 'components/shared/PasswordForm/styles';
import { Header, SubHeader, TextRegular } from 'components/shared/Text';
import { COLORS } from 'constants/colors';
import { PASWORD_MIN_LENGTH } from 'constants/global';

import { getCreatePasswordSchema } from 'utils/validation/schemas';

import { MarginTop24px } from '../Margin';

interface IPasswordForm {
  header: string;
  subHeader?: string;
  onSubmit: (values: FormikValues) => void;
}

enum INPUT_STATUS {
  Valid = '',
  Error = 'error',
}

const PasswordForm = ({ header, subHeader, onSubmit }: IPasswordForm) => {
  const { t } = useTranslation();
  const createPasswordSchema = getCreatePasswordSchema(t);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [isPasswordsEquals, setIsPasswordsEquals] = useState(true);

  const newPasswordPlaceholder = t('new_password');
  const reEnterNewPasswordPlaceholder = t('re_enter_new_password');

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        password: '',
        confirmPassword: '',
      }}
      validationSchema={createPasswordSchema}
      onSubmit={onSubmit}
    >
      {({
        isValid,
        values,
        errors,
        validateForm,
        handleChange,
        handleSubmit,
        setErrors,
      }) => {
        const hasEightCharacter = values.password.length >= PASWORD_MIN_LENGTH;
        const hasOneDigit = /\d+/.test(values.password);
        const hasOneLowercase = /[a-z]+/.test(values.password);
        const hasOneUppercase = /[A-Z]+/.test(values.password);
        const hasOneSpecialSymbol = /[!@#$%^&*()-+]+/.test(values.password);
        const isBothPasswordEquals = values.password === values.confirmPassword;

        const handleOnPress = () => {
          validateForm();

          if (!isValid) {
            setIsBtnDisabled(true);
            return;
          }

          if (!isBothPasswordEquals) {
            setIsPasswordsEquals(false);
            setIsBtnDisabled(true);
            return;
          }

          handleSubmit();
        };

        const handleOnFocus = () => {
          setIsBtnDisabled(false);
          setIsPasswordsEquals(true);
          setErrors({});
        };

        const fieldsStatus = isValid ? INPUT_STATUS.Valid : INPUT_STATUS.Error;

        return (
          <Container>
            <HeaderWrapper>
              <Header>{header}</Header>
              <SubHeader>{subHeader}</SubHeader>
            </HeaderWrapper>
            <FieldsWithValidation>
              <TextRegular>
                {values.password ? newPasswordPlaceholder : ''}
              </TextRegular>
              <PasswordInput
                status={fieldsStatus}
                placeholder={newPasswordPlaceholder}
                value={values.password}
                onFocus={handleOnFocus}
                onChange={handleChange('password')}
              />
              <MarginTop24px />
              <TextRegular>
                {values.confirmPassword ? reEnterNewPasswordPlaceholder : ''}
              </TextRegular>
              <PasswordInput
                status={fieldsStatus}
                placeholder={reEnterNewPasswordPlaceholder}
                value={values.confirmPassword}
                onFocus={handleOnFocus}
                onChange={handleChange('confirmPassword')}
              />
              <ValidationItem>
                {!isPasswordsEquals && (
                  <>
                    <ErrorConfirmPasswordIcon />
                    <TextRegular color={COLORS.ErrorBase}>
                      {t('password_does_not_match')}
                    </TextRegular>
                  </>
                )}
              </ValidationItem>
              <MarginTop24px />
              <ValidationList>
                <FormValidationItem
                  isFormValid={isValid}
                  isItemValid={hasEightCharacter}
                  text={t('eight_characters')}
                />
                <FormValidationItem
                  isFormValid={isValid}
                  isItemValid={hasOneUppercase}
                  text={t('one_uppercase')}
                />
                <FormValidationItem
                  isFormValid={isValid}
                  isItemValid={hasOneLowercase}
                  text={t('one_lowercase')}
                />
                <FormValidationItem
                  isFormValid={isValid}
                  isItemValid={hasOneDigit}
                  text={t('one_number')}
                />
                <FormValidationItem
                  isFormValid={isValid}
                  isItemValid={hasOneSpecialSymbol}
                  text={t('one_special_symbol')}
                />
              </ValidationList>
              <MarginTop24px />
              <BtnPrimary isDisabled={isBtnDisabled} onClick={handleOnPress}>
                {t('submit')}
              </BtnPrimary>
            </FieldsWithValidation>
            <ContactUs />
          </Container>
        );
      }}
    </Formik>
  );
};

export default PasswordForm;
