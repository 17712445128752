import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { API_URLS, REQUEST_METHOD } from '../constants/global';
import { baseQuery } from './baseQuery';
import { IDBTaxPrices, ITaxPrices } from './taxPrices.types';

export const taxPricesApi = createApi({
  reducerPath: 'taxPricesApi',
  baseQuery,
  endpoints: (builder) => ({
    getTaxPrices: builder.query<ITaxPrices, { userId?: string }>({
      query: ({ userId }) => ({
        url: `${API_URLS.GET_TAX_PRICES}${userId ? `?userId=${userId}` : ''}`,
        method: REQUEST_METHOD.GET,
      }),
    }),
    addUpcommingTaxPrices: builder.mutation<void, IDBTaxPrices>({
      query: (taxPrices: IDBTaxPrices) => ({
        url: API_URLS.GET_TAX_PRICES,
        method: REQUEST_METHOD.POST,
        body: taxPrices,
      }),
    }),
    updateUpcommingTaxPrices: builder.mutation<void, IDBTaxPrices>({
      query: (taxPrices: IDBTaxPrices) => ({
        url: `${API_URLS.GET_TAX_PRICES}/${taxPrices._id}`,
        method: REQUEST_METHOD.PUT,
        body: taxPrices,
      }),
    }),
    deleteUpcommingTaxPricesById: builder.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `${API_URLS.GET_TAX_PRICES}/${id}`,
        method: REQUEST_METHOD.DELETE,
      }),
    }),
  }),
  tagTypes: ['taxPrices'],
});

export const {
  useGetTaxPricesQuery,
  useAddUpcommingTaxPricesMutation,
  useUpdateUpcommingTaxPricesMutation,
  useDeleteUpcommingTaxPricesByIdMutation,
} = taxPricesApi;
