import { FC } from 'react';

import ColumnChart from 'components/communities/Charts/ColumnChart';
import { SkeletonAreaChartSmall } from 'components/shared/Skeleton';
import { StyledChartWrapper } from './styles';

interface CommunityDataChartProps {
  chartData?: any;
  hasData: boolean;
  isLoading?: boolean;
}

const CommunityDataChart: FC<CommunityDataChartProps> = ({
  chartData,
  hasData,
  isLoading,
}) => {
  return (
    <StyledChartWrapper>
      <ColumnChart
        xAxis={false}
        data={chartData}
        hasData={hasData}
        unit="kWh"
        hideTooltip={true}
        removeShowingDataByFullPeriod={true}
        chartHeight={180}
        isLoading={isLoading}
        chartLoadingComponent={<SkeletonAreaChartSmall />}
      />
    </StyledChartWrapper>
  );
};

export default CommunityDataChart;
