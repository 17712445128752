import { useTranslation } from 'react-i18next';
import { SkeletonLine } from 'components/shared/Skeleton';
import { NotAvailableValue } from 'components/communities/styles';

import { StyledKw } from '../../../ElectricityConsumption/components/AreaChart/styles';
import {
  RepartitionSubtitle,
  RingProggresPercent,
  RingProgressRow,
  RingProgressTitle,
  RingProgressWrapper,
} from '../../styles';
import RingProggresChart from './RingProgressChart';

const RingProgressInfo = ({
  percent,
  isLoading,
}: {
  percent: number | undefined;
  isLoading?: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <RingProgressWrapper>
      <RingProgressTitle>{t('average_repartition_key')}</RingProgressTitle>
      <RepartitionSubtitle>{t('for_selected_time_period')}</RepartitionSubtitle>
      <RingProgressRow>
        <RingProggresChart
          isLoading={isLoading}
          percent={percent ? percent / 100 : 0}
        />
        {isLoading && <SkeletonLine active width={40} height={30} />}
        {percent && !isLoading && (
          <RingProggresPercent>
            {percent}
            <StyledKw>%</StyledKw>
          </RingProggresPercent>
        )}
        {!percent && !isLoading && <NotAvailableValue> N/A </NotAvailableValue>}
      </RingProgressRow>
    </RingProgressWrapper>
  );
};

export default RingProgressInfo;
