import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { API_URLS, REQUEST_METHOD } from '../constants/global';
import { baseQuery } from './baseQuery';

export const reportApi = createApi({
  reducerPath: 'reportApi',
  baseQuery,
  endpoints: (builder) => ({
    getEnergyReportsByUserId: builder.query<any, { userId?: string }>({
      query: ({ userId }) => ({
        url: `${API_URLS.GET_ENERGY_REPORTS}${
          userId ? `?userId=${userId}` : ''
        }`,
        method: REQUEST_METHOD.GET,
      }),
    }),
    getProductionEnergy: builder.query<any, { userId?: string }>({
      query: ({ userId }) => ({
        url: `${API_URLS.GET_PRODUCTION_ENERGY}${
          userId ? `?userId=${userId}` : ''
        }`,
        method: REQUEST_METHOD.GET,
      }),
    }),
  }),
  tagTypes: ['reportApi'],
});

export const { useGetEnergyReportsByUserIdQuery, useGetProductionEnergyQuery } =
  reportApi;
