import styled from 'styled-components';

import { Input as AntInput, InputNumber as AntInputNumber } from 'antd';

import { COLORS } from 'constants/colors';

const { TextArea } = AntInput;

export const StyledTextArea = styled(TextArea)<{ isError?: boolean }>`
  height: 40px;
  border-color: ${(props) => props.isError && COLORS.ErrorBase};
`;

export const FormInput = styled(AntInput)<{ isError?: boolean }>`
  height: 40px;
  border-color: ${(props) => props.isError && COLORS.ErrorBase};
`;

export const FormNumberInput = styled(AntInputNumber)<{ isError?: boolean }>`
  height: 40px;
  border-color: ${(props) => props.isError && COLORS.ErrorBase};
  width: 100%;

  & > div,
  .ant-input-number-input-wrap,
  input {
    height: 100%;
  }
`;
