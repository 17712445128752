import { v4 as uuidv4 } from 'uuid';

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generateEmptyArray } from 'utils';
import PlusOutlines from 'components/communities/Communities/img/PlusOutlined.svg';
import { BtnWrapper } from 'components/communities/Communities/styles';
import ExpandedWrapper from 'components/hoc/ExpandedWrapper';
import { BtnPrimary } from 'components/shared/Button';
import Image from 'components/shared/Image';
import { IDBTaxPrices, ITaxPrices } from 'services/taxPrices.types';

import {
  BlockContainer,
  BlockHeaderWithBottomSpace,
  Title,
  Wrapper,
} from '../../styles';
import { PriceType, PriceVariant } from '../../types';
import EmptyPrices from '../EmptyPrices';
import ModifyPrices from '../ModifyPrices';
import IndexTable from './components/IndexTable';
import TableHeaderRow from './components/TableHeaderRow';
import { Row, Table, LineHeader, Cell } from './components/IndexTable/styles';
import { SkeletonLine } from 'components/shared/Skeleton';

const Indexation = (data: ITaxPrices) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editTaxes, setEditTaxes] = useState<IDBTaxPrices | undefined>(
    undefined,
  );

  const { t } = useTranslation();
  const { current, upcoming, historical, isLoading } = data;

  const handleOnEdit = (taxPrice: IDBTaxPrices) => {
    setIsModalVisible(true);
    setEditTaxes(taxPrice);
  };

  const handleOnModalVisibleChange = (isModalVisible: boolean) => {
    setIsModalVisible(isModalVisible);
    setEditTaxes(undefined);
  };

  const renderTable = (
    prices: IDBTaxPrices[],
    priceVariant: PriceVariant,
    hasEdit = false,
  ) => {
    if (priceVariant === PriceVariant.HISTORICAL) {
      return (
        <IndexTable
          priceVariant={priceVariant}
          taxPrices={prices[0]}
          onEdit={hasEdit ? () => handleOnEdit(prices[0]) : undefined}
        />
      );
    }

    return prices.map((taxPrice) => (
      <IndexTable
        key={uuidv4()}
        priceVariant={priceVariant}
        taxPrices={taxPrice}
        onEdit={hasEdit ? () => handleOnEdit(taxPrice) : undefined}
      />
    ));
  };

  const renderPrices = (
    prices: IDBTaxPrices[],
    priceVariant: PriceVariant,
    hasEdit = false,
  ) => {
    if (!prices?.length) return null;

    return renderTable(prices, priceVariant, hasEdit);
  };

  const renderHistoricalPrices = () => {
    if (!historical?.length) return null;
    return (
      <ExpandedWrapper
        isCollapsed
        arrowUpText={t('hide_historical_indexes')}
        arrowDownText={t('show_historical_indexes')}
      >
        <TableHeaderRow
          priceVariant={PriceVariant.HISTORICAL}
          title={t('historical_indexation').toUpperCase()}
        />
        {renderTable(historical, PriceVariant.HISTORICAL)}
      </ExpandedWrapper>
    );
  };

  return (
    <BlockContainer>
      <Wrapper>
        <BlockHeaderWithBottomSpace>
          <Title>{t('indexation').toUpperCase()}</Title>
          <BtnWrapper>
            <BtnPrimary onClick={() => setIsModalVisible(true)}>
              <Image src={PlusOutlines} alt="PlusIcon" margin="0 5px 0 0" />
              {t('add_new_index')}
            </BtnPrimary>
          </BtnWrapper>
        </BlockHeaderWithBottomSpace>
        <TableHeaderRow
          title={t('indexation_period').toString()}
          description={t('index').toString()}
        />
        {isLoading &&
          generateEmptyArray(3).map(() => (
            <Table>
              <Row>
                <LineHeader>
                  <SkeletonLine active width={136} height={22} />
                </LineHeader>
                <Cell>
                  <SkeletonLine active width={73} height={22} />
                </Cell>
              </Row>
            </Table>
          ))}
        {!isLoading && renderPrices(current, PriceVariant.CURRENT)}
        {!isLoading && renderPrices(upcoming, PriceVariant.UPCOMMING, true)}
        {!isLoading && renderHistoricalPrices()}
        <ModifyPrices
          isModalVisible={isModalVisible}
          priceType={PriceType.INDEX}
          taxPrices={editTaxes}
          onModalVisibleChange={handleOnModalVisibleChange}
        />
        <EmptyPrices {...data} priceType={PriceType.INDEX} priceKey="index" />
      </Wrapper>
    </BlockContainer>
  );
};

export default Indexation;
