import { styled } from 'styled-components';

import { SubHeader } from 'components/shared/Text';

export const StyledPriceDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledPriceDetails = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const StyledTypeWrapper = styled.div<{ width: number }>`
  display: flex;
  flex-direction: column;
  width: ${(props) => props.width}px;
`;

export const StyledTitle = styled.div`
  display: flex;
`;

export const StyledRushInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
`;

export const StyledRushInput = styled.div`
  width: 308px;
  margin: 0 0 0 auto;
`;

export const Title = styled(SubHeader)`
  display: flex;
  align-items: center;
  column-gap: 5px;
  margin: 0;
  text-align: start;
`;

export const StyledTitlesRow = styled.div<{ marginTop: number }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
  row-gap: 46px;
  margin-top: ${(props) => props.marginTop}px;
`;

export const StyledPriceTitle = styled.div`
  font-size: 14px;
  line-height: 22px;
  min-width: 80px;
`;

export const StyledInputsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
`;

export const StyledRushTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100px;
`;

export const StyledInputWrapper = styled.div``;
