import { styled } from 'styled-components';

import { COLORS } from 'constants/colors';

export const StyledBlockWrapper = styled.div`
  background-color: ${COLORS.BgContainer};
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  min-height: 150px;
  box-sizing: border-box;
`;

export const StyledBlockHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
`;

export const StyledBlockTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
`;

export const StyledBlockBody = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(32%, 1fr));
  column-gap: 25px;
  padding: 16px 18px;
`;

export const StyledButtonRow = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 50px;
`;
