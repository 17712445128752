import React, { ReactNode } from 'react';

import { CardBodyWrapper } from 'components/communities/Communities/styles';
import { TextBold, TextRegular } from 'components/shared/Text';

interface ICardLine {
  isTitleBold?: boolean;
  title: string;
  value?: number | string | ReactNode;
}

const CardLine = ({ isTitleBold, title, value }: ICardLine) => (
  <CardBodyWrapper>
    <CardBodyWrapper size={1}>
      {isTitleBold ? (
        <TextBold>{title}</TextBold>
      ) : (
        <TextRegular>{title}</TextRegular>
      )}
    </CardBodyWrapper>
    <CardBodyWrapper size={1}>
      <TextBold>{value}</TextBold>
    </CardBodyWrapper>
  </CardBodyWrapper>
);

export default CardLine;
