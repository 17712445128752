import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SkeletonLine } from 'components/shared/Skeleton';
import { StyledInfoKw } from 'components/communities/Charts/ElectricityConsumption/styles';
import EnergyIcon from 'components/communities/Charts/SummaryView/components/EnergyIcon';
import { getFormattedInfoChartValue } from 'components/communities/utils';
import CommunityCardChart from 'components/shared/PieChart';
import { LegendText } from 'components/shared/Text/LegendText';
import { COLORS } from 'constants/colors';
import { EnergyTypes, mapChartKeys } from 'constants/energy-types';

import { Amount, Title } from '../../styles';
import { CommunityDataType } from '../../types';
import {
  ChartInfoItem,
  ChartInfoRow,
  CommunityDataWrapper,
  PiechartContainer,
} from './styles';

const INNER_RADUIS = 0.75;
const CHART_SIZE = 190;
const SKELETON_RADIUS = 178;

interface InfoItemProps {
  color: string;
  title: string;
  amount: number | string;
  isBold?: boolean;
  isLoading?: boolean;
}

interface CommunityDataChartProps {
  data?: CommunityDataType;
  chartData: any;
  color: COLORS[];
  isLoading?: boolean;
}

const InfoItem: FC<InfoItemProps> = ({
  color,
  title,
  amount,
  isBold,
  isLoading,
}) => (
  <ChartInfoItem>
    <Title>
      <EnergyIcon color={color} />
      <LegendText bold={isBold}>{title}</LegendText>
    </Title>
    {isLoading ? (
      <SkeletonLine active width={49} height={30} />
    ) : (
      <Amount>
        {amount} <StyledInfoKw>%</StyledInfoKw>
      </Amount>
    )}
  </ChartInfoItem>
);
const CommunityDataChart: FC<CommunityDataChartProps> = ({
  data,
  chartData,
  color,
  isLoading,
}) => {
  const [hoveredType, setHoveredType] = useState(EnergyTypes.NONE);
  const { t } = useTranslation();

  const infoDataArray = [
    data?.mySelfConsumptionRatio,
    data?.selfConsumptionRatio,
    data?.gridConsumptionRatio,
  ];

  return (
    <CommunityDataWrapper>
      <ChartInfoRow>
        <InfoItem
          isBold={hoveredType === EnergyTypes.MY_SELF_CONSUMPTION_RATIO}
          color={COLORS.Yellow5}
          title={t('my_self_consumption').toString()}
          amount={
            data?.mySelfConsumptionRatio
              ? getFormattedInfoChartValue(
                  data?.mySelfConsumptionRatio,
                  infoDataArray,
                )
              : 'N/A'
          }
          isLoading={isLoading}
        />
        <InfoItem
          isBold={hoveredType === EnergyTypes.SELF_CONSUMPTION_RATIO}
          color={COLORS.Green5}
          title={t('community_self_consumption').toString()}
          amount={
            data?.selfConsumptionRatio
              ? getFormattedInfoChartValue(
                  data?.selfConsumptionRatio,
                  infoDataArray,
                )
              : 'N/A'
          }
          isLoading={isLoading}
        />
        <InfoItem
          isBold={hoveredType === EnergyTypes.GRID_CONSUMPTION_RATIO}
          color={COLORS.Orange5}
          title={t('community_consumption_part').toString()}
          amount={
            data?.gridConsumptionRatio
              ? getFormattedInfoChartValue(
                  data?.gridConsumptionRatio,
                  infoDataArray,
                )
              : 'N/A'
          }
          isLoading={isLoading}
        />
      </ChartInfoRow>
      <PiechartContainer>
        <CommunityCardChart
          size={CHART_SIZE}
          innerRadius={INNER_RADUIS}
          color={color}
          chartData={chartData}
          handleOnHover={(type) => setHoveredType(mapChartKeys(type))}
          handleOnBlur={() => setHoveredType(EnergyTypes.NONE)}
          isLoading={isLoading}
          skeletonRadius={SKELETON_RADIUS}
        />
      </PiechartContainer>
    </CommunityDataWrapper>
  );
};

export default CommunityDataChart;
