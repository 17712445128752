import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Container } from 'components/auth/CreatePassword/components/styles';
import AuthWrapper from 'components/shared/AuthWrapper';
import { BtnPrimary } from 'components/shared/Button';
import ContactUs from 'components/shared/ContactUs';
import { MarginTop16px, MarginTop68px } from 'components/shared/Margin';
import { HeaderWrapper } from 'components/shared/PasswordForm/styles';
import { Header, SubHeader } from 'components/shared/Text';
import { useCreatePaymentMutation } from 'services/paymentManagment';
import { useGetMemberByIdQuery } from 'services/pmoMemberDashboardManagment';

const AddPayment = () => {
  const { t } = useTranslation();
  const { data: member } = useGetMemberByIdQuery(undefined);

  const [postPayment, { isSuccess, data }] = useCreatePaymentMutation();

  const handleOnClick = async () => {
    await postPayment({});
  };

  useEffect(() => {
    if (isSuccess) {
      window.location.replace(data?.sessionUrl!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  if (!member) {
    return <></>;
  }

  return (
    <AuthWrapper>
      <Container>
        <HeaderWrapper>
          <Header>{t('welcome_to_oyo_platform')}</Header>
          <SubHeader>
            {t('successfully_connected', {
              companyName: `${member?.firstName} ${member?.middleName} ${member?.lastName}`,
            })}
          </SubHeader>
          <SubHeader>{t('we_are_happy_to_partner')}</SubHeader>
          <SubHeader>{t('welcome_to_oyo_platform_description')}</SubHeader>
        </HeaderWrapper>
        <MarginTop16px />
        <BtnPrimary onClick={handleOnClick}>
          {t('add_payment_method')}
        </BtnPrimary>
        <MarginTop68px />
        <ContactUs />
      </Container>
    </AuthWrapper>
  );
};

export default AddPayment;
