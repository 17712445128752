export const emptyData = [
  {
    date: 'Jan',
    value: 600,
  },
  {
    date: 'Feb',
    value: 550,
  },
  {
    date: 'Mar',
    value: 200,
  },
  {
    date: 'Apr',
    value: 300,
  },
  {
    date: 'May',
    value: 500,
  },
  {
    date: 'Jun',
    value: 200,
  },
  {
    date: 'Jul',
    value: 600,
  },
  {
    date: 'Aug',
    value: 500,
  },
  {
    date: 'Sep',
    value: 400,
  },
  {
    date: 'Oct',
    value: 600,
  },
  {
    date: 'Nov',
    value: 300,
  },
  {
    date: 'Dec',
    value: 500,
  },
];

export const percent = 24;
