import { v4 as uuidv4 } from 'uuid';

import { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { Flex, Select } from 'antd';

import { SorterTypes } from 'components/pricing/types';

import { sorterOptions } from './constants';
import { StyledSorterSelect, StyledSorterSuffix } from './styles';

const { Option } = Select;

interface ConsumerSorterProps {
  setSorterValue: Dispatch<SetStateAction<SorterTypes>>;
}

const ConsumerSorter: FC<ConsumerSorterProps> = ({ setSorterValue }) => {
  const { t } = useTranslation();
  return (
    <Flex align="center">
      <StyledSorterSuffix>{t('sort_by')}</StyledSorterSuffix>
      <StyledSorterSelect
        onChange={(value) => setSorterValue(value as SorterTypes)}
        defaultValue={SorterTypes.CONSUMER_NAME}
      >
        {sorterOptions.map((item) => (
          <Option key={uuidv4()} value={item.value}>
            {t(item.label)}
          </Option>
        ))}
      </StyledSorterSelect>
    </Flex>
  );
};

export default ConsumerSorter;
