import React from 'react';
import { useTranslation } from 'react-i18next';

import { FieldArray, useFormikContext } from 'formik';

import { PlusOutlined } from 'components/members/createConsumer/ConsumerType/Icons';
import TrashSvg from 'components/members/createConsumer/img/Trash.svg';
import { initialValues } from 'components/members/createProducer/constants';
import Image from 'components/shared/Image';
import FormikInput from 'components/shared/Input/FormikInput';
import { InputGroup } from 'components/shared/InputGroup';

import { onKeyPressOnlyNumbers } from 'utils/forms';

import { StyledAddTitle } from './styles';

const MAX_FIELDS = 10;

interface IPrmNumbersErrors {
  prmValue?: string;
}

const PrmNumber = () => {
  const { errors, values } = useFormikContext<typeof initialValues>();
  const { t } = useTranslation();

  const isMaxFields = values.additionalInfo.prms.length !== MAX_FIELDS;

  return (
    <FieldArray
      name="additionalInfo.prms"
      render={(arrayHelpers) => (
        <>
          {values.additionalInfo.prms &&
            values.additionalInfo.prms.map((number, index) => (
              <React.Fragment key={index}>
                <InputGroup
                  label={t('prm_number').toString()}
                  error={
                    errors?.additionalInfo?.prms &&
                    (errors.additionalInfo.prms as IPrmNumbersErrors[])?.[index]
                      ?.prmValue
                  }
                >
                  <FormikInput
                    fieldName={`additionalInfo.prms.${index}.prmValue`}
                    placeholder="Ex. 22516914714270"
                    suffix={
                      index > 0 && (
                        <Image
                          onClick={() => arrayHelpers.remove(index)}
                          src={TrashSvg}
                          clickable
                        />
                      )
                    }
                    type="string"
                    onKeyPress={onKeyPressOnlyNumbers}
                  />
                </InputGroup>
                {index === values.additionalInfo.prms.length - 1 &&
                  isMaxFields && (
                    <StyledAddTitle
                      onClick={() => arrayHelpers.push({ prmValue: null })}
                    >
                      <PlusOutlined />
                      {t('add_prm_number')}
                    </StyledAddTitle>
                  )}
              </React.Fragment>
            ))}
        </>
      )}
    />
  );
};

export default PrmNumber;
