import { TFunction } from 'i18next';

export const emptyData = (t: TFunction) => [
  {
    type: 'empty1',
    value: 60,
    date: t('january'),
  },
  {
    type: 'empty2',
    value: 5,
    date: t('january'),
  },
  {
    type: 'empty2',
    value: 4,
    date: t('january'),
  },
  {
    type: 'empty3',
    value: 6,
    date: t('january'),
  },

  {
    type: 'empty1',
    value: 10,
    date: t('february'),
  },
  {
    type: 'empty2',
    value: 14,
    date: t('february'),
  },
  {
    type: 'empty2',
    value: 4,
    date: t('february'),
  },
  {
    type: 'empty3',
    value: 5,
    date: t('february'),
  },

  {
    type: 'empty1',
    value: 9,
    date: t('march'),
  },
  {
    type: 'empty1',
    value: 14,
    date: t('march'),
  },
  {
    type: 'empty2',
    value: 5,
    date: t('march'),
  },

  {
    type: 'empty1',
    value: 7,
    date: t('april'),
  },
  {
    type: 'empty2',
    value: 4,
    date: t('april'),
  },
  {
    type: 'empty2',
    value: 9,
    date: t('april'),
  },
  {
    type: 'empty3',
    value: 10,
    date: t('april'),
  },

  {
    type: 'empty1',
    value: 4,
    date: t('april'),
  },

  {
    type: 'empty1',
    value: 18,
    date: t('may'),
  },
  {
    type: 'empty2',
    value: 8,
    date: t('may'),
  },
  {
    type: 'empty2',
    value: 5,
    date: t('may'),
  },
  {
    type: 'empty2',
    value: 3,
    date: t('may'),
  },

  {
    type: 'empty3',
    value: 8,
    date: t('june'),
  },

  {
    type: 'none',
    value: 10,
    date: t('july'),
  },

  {
    type: 'none',
    value: 5,
    date: t('august'),
  },

  {
    type: 'none',
    value: 8,
    date: t('september'),
  },

  {
    type: 'none',
    value: 11,
    date: t('october'),
  },

  {
    type: 'none',
    value: 4,
    date: t('november'),
  },

  {
    type: 'empty1',
    value: 11,
    date: t('december'),
  },
  {
    type: 'empty2',
    value: 11,
    date: t('december'),
  },
];
