import { ErrorConfirmPasswordIcon } from '../PasswordForm/styles';
import { StyledInputError } from './styles';

type InputErrorProps = {
  error?: string | null;
  errorCentered?: boolean;
};

const InputError: React.FC<InputErrorProps> = ({
  errorCentered = false,
  error,
}) => {
  return (
    <StyledInputError errorCentered={errorCentered}>
      {error ? (
        <>
          <ErrorConfirmPasswordIcon />
          {error}
        </>
      ) : (
        '\u00A0'
      )}
    </StyledInputError>
  );
};

export default InputError;
