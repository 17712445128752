import qs from 'qs';

import { parseCSVFileNameFromContentDisposition } from 'components/communities/Dashboard/utils';
import { labelsByType } from 'components/communities/constants';
import { REQUEST_METHOD } from 'constants/global';

import { LOCAL_STORAGE_KEYS } from 'utils/local-storage';

export const getFinancialDataTotal = (data: any) => {
  return data?.reduce(
    (acc: any, currentDataItem: any) => {
      let indexByType = 0;
      if (labelsByType[currentDataItem.status] === 'paid_amount')
        indexByType = 0;
      if (labelsByType[currentDataItem.status] === 'overdue_amount')
        indexByType = 1;
      if (labelsByType[currentDataItem.status] === 'expected_amount')
        indexByType = 2;
      acc[indexByType] += currentDataItem.total;
      return acc;
    },
    [0, 0, 0],
  );
};

const getAuthorizationHeader = () => ({
  'Content-Type': 'text/csv',
  Authorization: `Bearer ${localStorage.getItem(
    LOCAL_STORAGE_KEYS.AccessToken,
  )}`,
});

export const getCsvCommunityReport = async ({
  apiUrl,
  dates,
  consumerIds,
  userId,
}: any) => {
  const withUserId = userId ? `&userId=${userId}` : '';
  const formattedConsumersIds = `${consumerIds.map(
    (id: string) => `&consumerIds=${id}`,
  )}`.replaceAll(',', '');
  const url = `${process.env.REACT_APP_BACKEND_URL}${apiUrl}?${qs.stringify(
    dates,
  )}${formattedConsumersIds}${withUserId}`;

  try {
    const response = await fetch(url, {
      method: REQUEST_METHOD.GET,
      headers: getAuthorizationHeader(),
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch: ${response.statusText}`);
    }
    const contentDisposition = response.headers.get('Content-Disposition');

    const filename = parseCSVFileNameFromContentDisposition(
      contentDisposition!,
    );

    return { data: await response.text(), fileName: filename };
  } catch (error) {
    console.error('Error fetching CSV energy report:', error);
    throw error;
  }
};
