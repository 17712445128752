import { styled } from 'styled-components';

import { Dropdown } from 'antd';

import { DownOutlined, UpOutlined } from '@ant-design/icons';

import { COLORS } from 'constants/colors';

export const ArrowDownIcon = styled(DownOutlined)`
  font-size: 12px;
  color: ${COLORS.ColorSplit};
  pointer-events: none;
`;

export const ArrowUpIcon = styled(UpOutlined)`
  font-size: 12px;
  color: ${COLORS.ColorSplit};
  pointer-events: none;
`;

export const StyledSelect = styled(Dropdown)<{
  withNoBorder?: boolean;
  width?: number;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;

  width: ${(props) => (props.width === 226 ? '270px' : '170px')};

  &:hover {
    button {
      color: #54ab29;
      border-color: #54ab29;
    }
  }

  button {
    overflow: hidden;
    border-width: ${(props) => (props.withNoBorder ? '0px' : '1px')};
    box-shadow: none;
  }

  button:nth-child(1) {
    min-width: ${(props) => `${props.width}px`};
    text-align: left;
  }

  button:nth-child(1) {
    border-right: 0px;
  }

  button:nth-child(2) {
    border-left: 0px;
  }
`;

export const ArrowValueWrapper = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
`;
