import { CONSUMER_TYPE } from 'constants/types';

type ConsumerDataLabelsByCompanyType = {
  hasAccise: boolean;
  consumerType: CONSUMER_TYPE;
};

export const getConsumerDataLabelsByCompanyType = ({
  hasAccise,
  consumerType,
}: ConsumerDataLabelsByCompanyType): Record<string, string> => {
  if (hasAccise) {
    return {
      first: 'consumer_name',
      second: 'siret_number',
      third: 'location',
      fourth: 'accise_type',
    };
  }

  switch (consumerType) {
    case CONSUMER_TYPE.PRIVATE: {
      return {
        first: 'consumer_name',
        second: 'location',
      };
    }

    default: {
      return {
        first: 'consumer_name',
        second: 'siret_number',
        third: 'location',
      };
    }
  }
};

export const getLegalRepresentativeLabelsByCompanyType = ({
  consumerType,
}: Partial<ConsumerDataLabelsByCompanyType>): Record<string, string> => {
  switch (consumerType) {
    case CONSUMER_TYPE.PRIVATE: {
      return {
        first: 'legal_person_email_address',
        second: 'legal_person_contact_number',
      };
    }

    default: {
      return {
        first: 'legal_person_name',
        second: 'legal_person_email_address',
        third: 'legal_person_position',
        fourth: 'legal_person_contact_number',
      };
    }
  }
};
