import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useFormikContext } from 'formik';

import { StyledForm } from 'components/auth/SignIn/styles';
import { StyledCheckboxesWrapper } from 'components/communities/CreateCommunity/add-pmo/styles';
import PrmNumber from 'components/members/createProducer/Settings/PrmNumber';
import { BtnPrimary, BtnSecondary } from 'components/shared/Button';
import Checkbox from 'components/shared/Checkbox';
import { InputGroup } from 'components/shared/InputGroup';
import { MarginTop24px, MarginTop40px } from 'components/shared/Margin';
import { COLORS } from 'constants/colors';

import { initialValues } from '../constants';
import ProductionPower from './ProductionPower';

interface SettingsProps {
  onBackButtonClick: () => void;
  validateFields: (fields: string[]) => Promise<boolean>;
  hasToBeValidated: boolean;
  setHasToBeValidated: (validate: boolean) => void;
  isError: boolean;
}

const currentFields = [
  'additionalInfo.consumptionAgreementSigned',
  'additionalInfo.licenseOperationSigned',
  'additionalInfo.agreementSigned',
  'additionalInfo.enedisConsentSigned',
  'additionalInfo.power',
  'additionalInfo.prms',
];

const Settings: FC<SettingsProps> = ({
  onBackButtonClick,
  hasToBeValidated,
  validateFields,
  setHasToBeValidated,
  isError,
}) => {
  const { t } = useTranslation();
  const { isValid, values, handleSubmit } =
    useFormikContext<typeof initialValues>();

  const onConfirmClick = async () => {
    setHasToBeValidated(true);
    await validateFields(currentFields);
    handleSubmit();
  };

  return (
    <StyledForm>
      <ProductionPower />

      <PrmNumber />

      <StyledCheckboxesWrapper>
        <Checkbox
          name="additionalInfo.consumptionAgreementSigned"
          isError={
            hasToBeValidated &&
            !values?.additionalInfo?.consumptionAgreementSigned
          }
          label={t('self_consumption_agreement_is_signed')}
          errorText={t(
            'validation.collective_self-consumption_management_agreement_is_signed_required',
          ).toString()}
        />
        <Checkbox
          name="additionalInfo.licenseOperationSigned"
          isError={
            hasToBeValidated && !values.additionalInfo.licenseOperationSigned
          }
          label={t('license_of_operation_is_signed')}
          errorText={t(
            'validation.license_of_operation_is_signed_required',
          ).toString()}
        />
        <Checkbox
          name="additionalInfo.agreementSigned"
          isError={hasToBeValidated && !values.additionalInfo.agreementSigned}
          label={t('pmo_membership_agreement_is_signed')}
          errorText={t(
            'validation.pmo_membership_agreement_sign_required',
          ).toString()}
        />
        <Checkbox
          name="additionalInfo.enedisConsentSigned"
          isError={
            hasToBeValidated && !values.additionalInfo.enedisConsentSigned
          }
          label={t('enedis_consent_form_is_signed')}
          errorText={t(
            'validation.enedis_consent_form_sign_required',
          ).toString()}
        />
      </StyledCheckboxesWrapper>
      <MarginTop40px />
      <InputGroup
        errorCentered={true}
        error={isError ? t('something_went_wrong').toString() : ''}
      >
        <>
          <BtnPrimary
            onClick={onConfirmClick}
            isDisabled={hasToBeValidated && !isValid}
          >
            {t('create_new_producer')}
          </BtnPrimary>
          <MarginTop24px />
          <BtnSecondary color={COLORS.TextBase} onClick={onBackButtonClick}>
            {t('back')}
          </BtnSecondary>
        </>
      </InputGroup>
    </StyledForm>
  );
};

export default Settings;
