const taxPrices = { vat: undefined, ht: undefined, ttc: undefined };
const hoursPrices = {
  RH: taxPrices,
  HS_HH: taxPrices,
  HS_LH: taxPrices,
  LS_HH: taxPrices,
  LS_LH: taxPrices,
};

export const turpePricesInitialValues = {
  large: hoursPrices,
  small: hoursPrices,
  private: hoursPrices,
  medium: hoursPrices,
};
