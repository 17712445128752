import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getPrmStatusLayout } from 'components/pricing/components/communityTab/ConsumerPrmItem';
import { USER_ROLES } from 'constants/types';
import { IDBPrm } from 'services/types';
import { useGetPrmStatusesQuery } from 'services/prmManagement';
import PaymentStatusCard from 'components/shared/PaymentStatusCard';
import { SkeletonLine } from 'components/shared/Skeleton';
import { generateEmptyArray } from 'utils';
import { DetailCardRow } from '../../styles';
import {
  DetailCardBlock,
  DetailCardLabel,
  DetailCardTitle,
  DetailCardValue,
  StatusPrmsContainer,
  StyledStatusesRow,
  StyledStatusesCol,
  StyledStatusesTitle,
  StyledEmptyStatusesContainer,
  StyledEmptyStatusesText,
  StyledStatusesContainer,
} from './styles';

interface StatusCardProps {
  title: string;
  role?: string;
  prms: IDBPrm[];
  containerWidth?: string;
}

interface ConsumerStatusCardProps {
  title: string;
  role?: string;
  containerWidth?: string;
}

interface ProducerStatusCardProps {
  title: string;
  prms: IDBPrm[];
}

const ConsumerStatusCard: FC<ConsumerStatusCardProps> = ({
  title,
  containerWidth,
}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { data: prmPaymentStatuses, isLoading: isPrmStatusesLoading } =
    useGetPrmStatusesQuery({ userId: id || '' }, { skip: !id });

  const hasData = useMemo(
    () => prmPaymentStatuses && prmPaymentStatuses.length,
    [prmPaymentStatuses],
  );

  return (
    <DetailCardBlock width={containerWidth}>
      <DetailCardTitle>{title}</DetailCardTitle>
      {!hasData && !isPrmStatusesLoading && (
        <StyledEmptyStatusesContainer>
          <StyledEmptyStatusesText>{t('no_prms_yet')}</StyledEmptyStatusesText>
        </StyledEmptyStatusesContainer>
      )}
      {hasData && (
        <StyledStatusesRow>
          <StyledStatusesTitle>{t('prm').toUpperCase()}</StyledStatusesTitle>
          <StyledStatusesTitle>{t('prm_status')}</StyledStatusesTitle>
          <StyledStatusesTitle>{t('payment_status')}</StyledStatusesTitle>
        </StyledStatusesRow>
      )}
      <StyledStatusesContainer>
        {hasData &&
          !isPrmStatusesLoading &&
          prmPaymentStatuses?.map(
            ({
              prmValue,
              prmStatus,
              paymentStatus,
              deactivatedAt,
              statusErrorMessage,
            }) => (
              <StyledStatusesRow key={prmValue}>
                <StyledStatusesCol>{prmValue}</StyledStatusesCol>
                <StyledStatusesCol>
                  {getPrmStatusLayout(
                    prmStatus,
                    t,
                    deactivatedAt,
                    statusErrorMessage,
                  )}
                </StyledStatusesCol>
                <StyledStatusesCol>
                  <PaymentStatusCard status={paymentStatus} />
                </StyledStatusesCol>
              </StyledStatusesRow>
            ),
          )}
        {isPrmStatusesLoading &&
          generateEmptyArray(3).map((i) => (
            <StyledStatusesRow key={i}>
              <StyledStatusesCol>
                <SkeletonLine height={22} block active />
              </StyledStatusesCol>
              <StyledStatusesCol>
                <SkeletonLine height={22} block active />
              </StyledStatusesCol>
              <StyledStatusesCol>
                <SkeletonLine height={22} block active />
              </StyledStatusesCol>
            </StyledStatusesRow>
          ))}
      </StyledStatusesContainer>
    </DetailCardBlock>
  );
};

const ProducerStatusCard: FC<ProducerStatusCardProps> = ({ title, prms }) => {
  const { t } = useTranslation();
  return (
    <DetailCardBlock width="calc((100% - 48px) / 3)">
      <DetailCardTitle>{title}</DetailCardTitle>
      <DetailCardRow>
        <DetailCardLabel width="100px">
          {t('prm').toUpperCase()}
        </DetailCardLabel>
        <DetailCardLabel width="100px">
          {t('prm').toUpperCase() + ' ' + t('status').toLowerCase()}
        </DetailCardLabel>
      </DetailCardRow>
      <StatusPrmsContainer>
        {prms.map(({ prmValue, status }) => (
          <DetailCardRow>
            <DetailCardValue width="100px">{prmValue}</DetailCardValue>
            <DetailCardValue width="100px">
              {getPrmStatusLayout(status as string, t)}
            </DetailCardValue>
          </DetailCardRow>
        ))}
      </StatusPrmsContainer>
    </DetailCardBlock>
  );
};

const StatusCard: FC<StatusCardProps> = ({
  title,
  role,
  prms,
  containerWidth,
}) => {
  return role === USER_ROLES.CONSUMER ? (
    <ConsumerStatusCard
      title={title}
      role={role}
      containerWidth={containerWidth}
    />
  ) : (
    <ProducerStatusCard title={title} prms={prms} />
  );
};

export default StatusCard;
