import { styled } from 'styled-components';
import { Button } from 'antd';
import { EditTwoTone } from '@ant-design/icons';
import { MAX_CONTENT_WIDTH, ScreenLarge } from 'constants/screen-breakpoints';
import { BtnDefault } from 'components/shared/Button';
import { COLORS } from 'constants/colors';
import { widthByCount } from '../../../ConsumerDashboard/components/ProfileDataTab/styles';

export const StyledBlockWrapper = styled.div`
  background-color: ${COLORS.BgContainer};
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  min-height: 150px;
  box-sizing: border-box;
  margin-bottom: 24px;
`;

export const StyledBlockHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
`;

export const StyledBlockTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
`;

export const StyledContainer = styled.div`
  padding: 32px;
  margin: 0 auto;
  box-sizing: content-box;
  gap: 24px;
  display: flex;
  flex-direction: column;
  max-width: ${MAX_CONTENT_WIDTH}px;
  ${ScreenLarge`
    box-sizing: border-box;
  `}
`;

export const StyledBlockBody = styled.div<{ count: number }>`
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(${({ count }) => widthByCount(count)}, 1fr)
  );
  column-gap: 24px;
  row-gap: 24px;
  padding: 16px 24px;
`;

export const StyledButtonRow = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 50px;
`;

export const StlyedProfileSupportText = styled.span`
  color: ${COLORS.TextBase};
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
`;

export const StyledSupportWrapper = styled.div`
  padding-left: 24px;
`;

export const StyledProfileSupportEmail = styled.a`
  padding-left: 4px;
  color: ${COLORS.Green7};
`;

export const StyledEditButton = styled(BtnDefault)`
  height: 28px;
`;

export const EditIcon = styled(EditTwoTone).attrs(() => ({
  twoToneColor: COLORS.Green6,
}))``;

export const StyledResendInvitationLink = styled(Button)`
  padding: 0 8px;
  height: auto;
  color: ${COLORS.Green7};
  font-weight: 600;
`;
