import React from 'react';
import { useTranslation } from 'react-i18next';

import { Formik } from 'formik';

import { Flex } from 'antd';

import { BtnDefault, BtnSmallPrimary } from 'components/shared/Button';
import { FormInput } from 'components/shared/Input';
import { InputGroup } from 'components/shared/InputGroup';

import { getValueByPath } from './dynamic-edit-form.helper';
import {
  BlockContainer,
  BlockFieldContainer,
  BtnContainer,
  FormContainer,
  FormWrapper,
  Title,
  TitleWrapper,
} from './styles';

interface FormData {
  [key: string]: any;
}

interface DynamicFormProps {
  isEditMode: boolean;
  formData: FormData;
  initialValues?: any;
  validationSchema: any;
  onClose: () => void;
  onSave: (values: any) => void;
}

const DynamicForm = ({
  isEditMode,
  formData,
  initialValues,
  validationSchema,
  onClose,
  onSave,
}: DynamicFormProps) => {
  const { t } = useTranslation();

  if (!isEditMode) return null;

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={(values) => onSave(values)}
    >
      {({
        isValid,
        values,
        errors,
        validateForm,
        setErrors,
        setFieldValue,
        handleSubmit,
      }) => {
        const handleOnSave = () => {
          validateForm();

          if (!isValid) {
            return;
          }

          handleSubmit();
        };

        const handleOnFocus = () => {
          setErrors({});
        };

        const containerLength =
          Object.keys(formData).length === 1 ? 2 : Object.keys(formData).length;

        return (
          <FormWrapper>
            <FormContainer childCount={containerLength}>
              {Object.keys(formData).map((blockKey) => (
                <BlockContainer key={blockKey}>
                  <TitleWrapper>
                    <Title>{t(blockKey)}</Title>
                  </TitleWrapper>
                  {formData[blockKey].map((field: any) => (
                    <BlockFieldContainer key={field.id}>
                      {field.component ? (
                        <>
                          <field.component
                            path={field.key}
                            placeholder={t(field.label)}
                            disabled={field.disabled}
                            value={getValueByPath(values, field.key) || ''}
                            setFieldValue={setFieldValue}
                            error={getValueByPath(errors, field.key)}
                            resetError={handleOnFocus}
                          />
                          {field.additionalBottomComponent}
                        </>
                      ) : (
                        <InputGroup
                          label={t(field.label).toString()}
                          error={getValueByPath(errors, field.key)}
                        >
                          <Flex style={{ flexDirection: 'column' }}>
                            <FormInput
                              isError={Boolean(
                                getValueByPath(errors, field.key),
                              )}
                              type="text"
                              placeholder={t(field.label).toString()}
                              value={getValueByPath(values, field.key) || ''}
                              onFocus={handleOnFocus}
                              onChange={(e) =>
                                setFieldValue(field.key, e.target.value)
                              }
                              disabled={field.disabled}
                            />
                            {field.additionalBottomComponent}
                          </Flex>
                        </InputGroup>
                      )}
                    </BlockFieldContainer>
                  ))}
                </BlockContainer>
              ))}
            </FormContainer>
            <BtnContainer>
              <BtnDefault onClick={onClose}>{t('cancel')}</BtnDefault>
              <BtnSmallPrimary onClick={handleOnSave}>
                {t('save_changes')}
              </BtnSmallPrimary>
            </BtnContainer>
          </FormWrapper>
        );
      }}
    </Formik>
  );
};

export default DynamicForm;
