import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  Container,
  MailIcon,
} from 'components/auth/CreatePassword/components/styles';
import { BtnPrimary, BtnSecondary } from 'components/shared/Button';
import { MarginTop24px, MarginTop40px } from 'components/shared/Margin';
import { Header, TextRegular } from 'components/shared/Text';
import AppRoutes from 'constants/routes';

interface IForgotPasswordSuccess {
  email: string;
  onResendEmail: () => void;
}

const ForgotPasswordSuccess = ({
  email,
  onResendEmail,
}: IForgotPasswordSuccess) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleOnClick = () => navigate(AppRoutes.SignIn);

  return (
    <Container>
      <MailIcon />
      <Header>{t('check_your_email')}</Header>
      <TextRegular>{t('reset_link_sent_to_email', { email })}</TextRegular>
      <MarginTop40px />
      <BtnPrimary onClick={handleOnClick}>{t('back_to_login')}</BtnPrimary>
      <MarginTop24px />
      <BtnSecondary onClick={onResendEmail}>{t('resend_email')}</BtnSecondary>
    </Container>
  );
};

export default ForgotPasswordSuccess;
