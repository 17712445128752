import React, { useEffect, useState } from 'react';

import { popoverOverlayStyles } from 'components/communities/Communities/styles';
import CommunityCardChart from 'components/shared/PieChart';
import Popover from 'components/shared/Popover';
import { TextBold } from 'components/shared/Text';
import { COLORS } from 'constants/colors';
import { EnergyTypes } from 'constants/energy-types';
import { SkeletonLine } from 'components/shared/Skeleton';
import { VALUE_MAX, VALUE_MIN } from '../../constants';
import EnergyIcon from '../EnergyIcon';
import EnergyValue from '../EnergyValue';
import {
  CardChart,
  CardInfo,
  CardSmall,
  ChartCardLine,
  ChartCardLineItem,
  ChartData,
  ChartDataColumn,
  ChartHeader,
  LegendText,
} from './styles';

const CHART_SIZE = 180;

interface ISummaryCard {
  title: string;
  tooltipText: string;
  chartColors: COLORS[];
  selfEnergyTitle: string;
  gridEnergyTitle: string;
  selfEnergy?: number;
  gridEnergy?: number;
  isLoading?: boolean;
}

const SummaryCard = ({
  title,
  tooltipText,
  chartColors,
  selfEnergyTitle,
  gridEnergyTitle,
  selfEnergy,
  gridEnergy,
  isLoading,
}: ISummaryCard) => {
  const [hoveredType, setHoveredType] = useState(EnergyTypes.NONE);
  const [hasData, setHasData] = useState(false);

  const isSelfEnergyHighlighted =
    hasData && hoveredType === EnergyTypes.SELF_ENERGY;
  const isGridEnergyHighlighted =
    hasData && hoveredType === EnergyTypes.GRID_ENERGY;

  useEffect(() => {
    if (gridEnergy || selfEnergy) {
      setHasData(true);
    }
  }, [selfEnergy, gridEnergy]);

  const chartSegmentColors = () => {
    if (!selfEnergy) {
      return [COLORS.BgContainerDisabled, COLORS.BgContainerDisabled];
    }

    return chartColors;
  };

  return (
    <CardSmall>
      <CardInfo>
        <ChartHeader>
          <TextBold>{title}</TextBold>
          <Popover context={tooltipText} overlayStyles={popoverOverlayStyles} />
        </ChartHeader>
        <ChartData>
          <ChartDataColumn>
            <ChartCardLine>
              <ChartCardLineItem>
                <EnergyIcon color={chartColors[0]} />
                <LegendText bold={isSelfEnergyHighlighted}>
                  {selfEnergyTitle}
                </LegendText>
              </ChartCardLineItem>
              <ChartCardLineItem>
                {isLoading ? (
                  <SkeletonLine active width={38} height={21} block />
                ) : (
                  <EnergyValue value={selfEnergy} />
                )}
              </ChartCardLineItem>
            </ChartCardLine>
            <ChartCardLine>
              <ChartCardLineItem>
                <EnergyIcon color={chartColors[1]} />
                <LegendText bold={isGridEnergyHighlighted}>
                  {gridEnergyTitle}
                </LegendText>
              </ChartCardLineItem>
              <ChartCardLineItem>
                {isLoading ? (
                  <SkeletonLine active width={38} height={21} block />
                ) : (
                  <EnergyValue value={gridEnergy} />
                )}
              </ChartCardLineItem>
            </ChartCardLine>
          </ChartDataColumn>
        </ChartData>
      </CardInfo>
      <CardChart className="community-card">
        <CommunityCardChart
          size={CHART_SIZE}
          color={chartSegmentColors()}
          chartData={[
            {
              type: EnergyTypes.SELF_ENERGY,
              value: selfEnergy || VALUE_MIN,
            },
            {
              type: EnergyTypes.GRID_ENERGY,
              value: gridEnergy || VALUE_MAX,
            },
          ]}
          handleOnHover={setHoveredType}
          handleOnBlur={() => setHoveredType(EnergyTypes.NONE)}
          isLoading={isLoading}
          skeletonRadius={164}
        />
      </CardChart>
    </CardSmall>
  );
};

export default SummaryCard;
