import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import ElectricityConsumption from 'components/communities/Charts/ElectricityConsumption';
import { ALL_PRM } from 'components/communities/Dashboard/constants';
import {
  LABELS,
  mapElectricityConsumptionData,
} from 'components/communities/MyCommunity/constants/ElectricityConsumption';
import { getFormattedInfoChartValue } from 'components/communities/utils';
import { CustomSelectItem } from 'constants/types';
import {
  useGetElectricityTotalQuery,
  useGetElectricityUsageQuery,
} from 'services/dashboardManagment';
import { useGetMemberByIdQuery } from 'services/pmoMemberDashboardManagment';
import { useGetPrmByUserIdQuery } from 'services/prmManagement';
import { ILabelMap } from 'services/types';

import { useAppSelector } from 'utils/hooks';
import { getDateRange } from 'utils/url/getDateRange';

import {
  areaColors,
  infoItems,
  legendItems,
  lineColors,
} from '../../constants';

const EnergyDataChart = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const period = useAppSelector((state) => state.dashboardPeriod.period);
  const [electricityPrmValue, setElectricityPrmValue] =
    useState<CustomSelectItem>({ label: ALL_PRM, key: ALL_PRM });
  const { data: consumer } = useGetMemberByIdQuery(id);
  const { data: prmData, isFetching } = useGetPrmByUserIdQuery({
    userId: id,
  });
  const { data: electricityConsumptionData } = useGetElectricityUsageQuery({
    ...getDateRange(period),
    userId: consumer?._id,
    prmId:
      electricityPrmValue.key === ALL_PRM ? undefined : electricityPrmValue.key,
  });
  const {
    data: electricityTotalData,
    isLoading: isLoadingElectricityConsumption,
  } = useGetElectricityTotalQuery({
    ...getDateRange(period),
    userId: consumer?._id,
    prmId:
      electricityPrmValue.key === ALL_PRM ? undefined : electricityPrmValue.key,
  });
  const electricityConsumptionInfoDataArray = [
    electricityTotalData?.consumption,
    electricityTotalData?.selfConsumption,
    electricityTotalData?.gridConsumption,
  ];

  const [prmItems, setPrmItems] = useState([
    {
      label: t(ALL_PRM),
      key: ALL_PRM,
    },
  ]);

  const onElectricityPrmValueChange = (prm: CustomSelectItem) => {
    setElectricityPrmValue(prm);
  };

  const electricityConsumptionDataMapped = useMemo(() => {
    const labelsMap: ILabelMap = {
      totalConsumption: LABELS.TOTAL_CONSUMPTION,
      selfConsumption: LABELS.SELF_CONSUMPTION,
      gridConsumption: LABELS.GRID_CONSUMPTION,
    };

    return mapElectricityConsumptionData(
      electricityConsumptionData,
      period,
      labelsMap,
      t,
    );
  }, [electricityConsumptionData, period]);

  useEffect(() => {
    if (!isFetching && Array.isArray(prmData)) {
      const newPrmItems: typeof prmItems = [];
      prmData.forEach((prm) => {
        newPrmItems.push({
          label: prm?.name || prm.prmValue?.toString() || '',
          key: prm._id,
        });
      });
      setPrmItems((prev) => [...prev, ...newPrmItems]);
    }
  }, [prmData, isFetching]);

  return (
    <ElectricityConsumption
      title={t('electricity_consumption')}
      legendItems={legendItems(t)}
      areaColors={areaColors}
      lineColors={lineColors}
      infoItems={infoItems(t)}
      electricityConsumption={electricityConsumptionDataMapped}
      selectItems={prmItems}
      selectValue={electricityPrmValue}
      onChangeSelectValue={onElectricityPrmValueChange}
      total={getFormattedInfoChartValue(
        electricityTotalData?.consumption,
        electricityConsumptionInfoDataArray,
      )}
      selfConsumption={getFormattedInfoChartValue(
        electricityTotalData?.selfConsumption,
        electricityConsumptionInfoDataArray,
      )}
      gridConsumption={getFormattedInfoChartValue(
        electricityTotalData?.gridConsumption,
        electricityConsumptionInfoDataArray,
      )}
      isLoading={isLoadingElectricityConsumption}
    />
  );
};

export default React.memo(EnergyDataChart);
