import Lottie from 'lottie-react';

import React from 'react';

import loader from './loader.json';
import { LoaderContainer, StyledLoader } from './styles';

const Loader = ({
  size = 100,
  withoutPadding = false,
}: {
  size?: number;
  withoutPadding?: boolean;
}) => {
  return (
    <LoaderContainer>
      <StyledLoader padding={withoutPadding}>
        <Lottie
          animationData={loader}
          loop
          autoplay
          style={{
            height: size,
            width: size,
            padding: withoutPadding ? 0 : size / 3,
          }}
        />
      </StyledLoader>
    </LoaderContainer>
  );
};

export default Loader;
