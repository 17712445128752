import { useTranslation } from 'react-i18next';

import { Button } from 'antd';

import { EyeTwoTone } from '@ant-design/icons';

import { TextRegular } from 'components/shared/Text';
import CustomTooltip from 'components/shared/Tooltip';

const ViewAction = () => {
  const { t } = useTranslation();
  return (
    <CustomTooltip
      tooltip={<TextRegular>{t('view_invoice_details')}</TextRegular>}
      withoutIcon
    >
      <Button size="small" icon={<EyeTwoTone />} />
    </CustomTooltip>
  );
};

export default ViewAction;
