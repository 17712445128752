import { styled } from 'styled-components';

export const StyledPercentRow = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5px;
`;

export const StyledPercent = styled.div<{ color: string }>`
  font-size: 12px;
  line-height: 20px;
  color: ${(props) => props.color};
`;

export const StyledPercentImageRotate = styled.div<{ rotate: boolean }>`
  transform: rotate(${(props) => (props.rotate ? '180deg' : '0deg')});
`;
