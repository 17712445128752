import { TFunction } from 'i18next';

import { IPrmNumber } from 'services/types';

export const validatePrmsValues = (prms: IPrmNumber[]) => {
  return prms.map((obj) => {
    const { gridPrice, ...rest } = obj;
    if (gridPrice) {
      const { dynamic: dynamicType, static: staticType }: any = gridPrice;

      // Check if both dynamic and static are null
      if (
        dynamicType &&
        Object.values(dynamicType).every((value) => !value) &&
        !staticType
      ) {
        return gridPrice?.dynamic
          ? { ...rest, gridPrice: dynamicType }
          : { ...rest, static: staticType };
      }

      // Check if dynamic is null or contains only null values
      if (dynamicType && Object.values(dynamicType).every((value) => !value)) {
        return { ...rest, gridPrice: { static: staticType } };
      }

      // Check if static is null
      if (!staticType) {
        return { ...rest, gridPrice: { dynamic: dynamicType } };
      }
    }

    return obj;
  });
};

export const getPowerInputPlaceholderByType = (type: string, t: TFunction) => {
  switch (type) {
    case 'private' || 'small':
      return t('up_to_36').toString();
    case 'small':
      return t('up_to_36').toString();
    case 'medium':
      return t('36_to_250').toString();
    case 'large':
      return t('more_than_250').toString();
    case 'collectivity':
      return t('up_to_250').toString();
    default:
      return '';
  }
};
