import { ChangeEvent } from 'react';

import { useFormikContext } from 'formik';

import { FieldStyled } from 'components/pricing/components/TaxPrices/styles';
import { TextBold } from 'components/shared/Text';
import { ITaxPriceRangeType } from 'services/taxPrices.types';

import { Cell } from '../PriceRow/styles';

type ICompanyTypeItem = {
  property: 'ht' | 'vat' | 'ttc';
  title: string;
  path: 'private' | 'medium' | 'large';
  row: ITaxPriceRangeType;
};

const PriceRowLine = ({ title, row, path, property }: ICompanyTypeItem) => {
  const { values, errors, setFieldValue, setErrors } = useFormikContext();

  const isDisabled = property === 'vat' || property === 'ttc';
  const err = errors as any;
  const vals = values as any;

  const placeholder = isDisabled ? '0.00' : '0.11';

  const handleOnChange = async (
    event: ChangeEvent<HTMLInputElement>,
    key: string,
  ) => {
    const ht = Number(event.target.value) as any;
    const vat = parseFloat(((ht / 100) * 20).toFixed(2));
    const ttc = parseFloat((ht * 1.2).toFixed(2));

    await setFieldValue(`turpePerMWh.${path}.${key}.ht`, ht);
    await setFieldValue(`turpePerMWh.${path}.${key}.vat`, vat);
    await setFieldValue(`turpePerMWh.${path}.${key}.ttc`, ttc);

    if (path === 'medium') {
      await setFieldValue(`turpePerMWh.small.${key}.ht`, ht);
      await setFieldValue(`turpePerMWh.small.${key}.vat`, vat);
      await setFieldValue(`turpePerMWh.small.${key}.ttc`, ttc);
    }
  };

  const handleOnFocus = () => {
    setErrors({});
  };

  return (
    <>
      <Cell>
        <TextBold>{title}</TextBold>
      </Cell>
      {Object.keys(row).map((key) => {
        const isError =
          !isDisabled && !!err?.turpePerMWh?.[path]?.[key]?.[property];
        const value = vals?.turpePerMWh?.[path]?.[key]?.[property];

        return (
          <Cell key={key}>
            <FieldStyled
              error={isError ? 'error' : undefined}
              type="number"
              disabled={isDisabled}
              value={value || ''}
              name={`turpePerMWh.${path}.${key}.${property}`}
              placeholder={placeholder}
              onFocus={handleOnFocus}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleOnChange(event, key)
              }
            />
          </Cell>
        );
      })}
    </>
  );
};

export default PriceRowLine;
