import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useFormikContext } from 'formik';

import { FormInput } from 'components/shared/Input';
import { InputGroup } from 'components/shared/InputGroup';

import { useDebounce } from 'utils/hooks';

import { initialValues } from '../../constants';

const FullNameInput: React.FC = () => {
  const { t } = useTranslation();
  const { errors, setFieldValue } = useFormikContext<typeof initialValues>();
  const [fullName, setFullName] = useState('');

  const isError = !!(
    errors?.additionalInfo?.legalPerson?.firstName ||
    errors?.additionalInfo?.legalPerson?.lastName
  );

  const handleFullNameChange = useCallback(
    async (fullName: string) => {
      const fullNameArr = fullName.trim().split(' ');

      const firstName = fullNameArr[0] || '';
      const lastName =
        fullNameArr.length > 1 ? fullNameArr[fullNameArr.length - 1] : '';
      const middleName =
        fullNameArr.length > 2
          ? fullNameArr
              .slice(1, fullNameArr.length - 1)
              .join(' ')
              .trim()
          : '';

      await setFieldValue('additionalInfo.legalPerson.firstName', firstName);
      await setFieldValue('additionalInfo.legalPerson.middleName', middleName);
      await setFieldValue('additionalInfo.legalPerson.lastName', lastName);
    },
    [setFieldValue],
  );

  const debouncedFullName = useDebounce(fullName, 100);

  useEffect(() => {
    handleFullNameChange(debouncedFullName);
  }, [debouncedFullName, handleFullNameChange]);

  return (
    <InputGroup
      label={t('legal_person_name').toString()}
      error={
        errors?.additionalInfo?.legalPerson?.firstName ||
        errors?.additionalInfo?.legalPerson?.lastName
      }
    >
      <FormInput
        type="text"
        placeholder="Ex. Sabastian Buffa"
        isError={isError}
        onChange={(e) => setFullName(e.target.value)}
      />
    </InputGroup>
  );
};

export default FullNameInput;
