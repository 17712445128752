import { styled } from 'styled-components';

import { COLORS, colorWithOpacity } from 'constants/colors';

export const DetailCardBlock = styled.div<{ width?: string }>`
  width: ${(props) => (props.width ? props.width : 'auto')};
  box-sizing: border-box;
  min-height: 203px;
  padding: 12px;
  background-color: ${COLORS.BgLayout};
`;

export const DetailCardTitle = styled.div`
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 12px;
`;

export const DetailCardLabel = styled.div<{ width?: string }>`
  color: ${COLORS.TextSecondary};
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  width: ${(props) => (props.width ? props.width : '90px')};
  margin-top: 1px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const DetailCardValue = styled.div<{ width?: string }>`
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  width: ${({ width }) => (width ? width : 'auto')};
  word-wrap: break-word;
`;

export const StatusCardPendingRow = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-bottom: 12px;
`;

export const StatusCardPendingMessage = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
`;

export const StatusPrmsContainer = styled.div`
  height: 122px;
  overflow-y: scroll;
`;

export const StyledStatusesRow = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 12px;
`;

export const StyledStatusesCol = styled.div`
  width: calc((100% - 32px) / 3);
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
`;

export const StyledStatusesTitle = styled(StyledStatusesCol)`
  font-size: 12px;
  color: ${colorWithOpacity(COLORS.TextBase, 0.65)};
  text-align: left;
`;

export const StyledEmptyStatusesContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  padding-top: 13%;
`;

export const StyledEmptyStatusesText = styled.div`
  color: ${colorWithOpacity(COLORS.TextBase, 0.65)};
`;

export const StyledStatusesContainer = styled.div`
  max-height: 160px;
  overflow-y: auto;
`;
