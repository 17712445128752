import { PriceVariant } from 'components/pricing/components/TaxPrices/types';

import { Cell, HeaderRow, Row, Table, TextBold } from '../IndexTable/styles';

type ITableHeaderRow = {
  priceVariant?: PriceVariant;
  title?: string;
  description?: string;
};

const TableHeaderRow = ({
  priceVariant,
  title,
  description,
}: ITableHeaderRow) => (
  <Table>
    <Row type={priceVariant}>
      <HeaderRow>
        <TextBold>{title}</TextBold>
      </HeaderRow>
      <Cell>
        <TextBold>{description}</TextBold>
      </Cell>
    </Row>
  </Table>
);

export default TableHeaderRow;
