import dayjs from 'dayjs';

import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Flex } from 'antd';

import { TextRegular } from 'components/shared/Text';
import CustomTooltip from 'components/shared/Tooltip';
import { SkeletonLine } from 'components/shared/Skeleton';
import {
  StyledCalendarIcon,
  StyledCommunityTitle,
  StyledIndexCol,
  StyledIndexIcon,
  StyledPriceCol,
  StyledPriceContainer,
  StyledPriceTypeText,
  StyledPriceUnitText,
  StyledPriceValue,
  StyledPricesContainer,
  StyledPricesTitle,
  StyledPricesTitleContainer,
  customTooltipStyles,
  StyledPriceItemCol,
  StyledPricesRow,
  SkeletonInputWrapper,
} from './styles';

interface CommunityPricesProps {
  period: any;
  indexation: string;
  isLoading?: boolean;
}

const emptySymbol = '-';

const CommunityPrices: FC<CommunityPricesProps> = ({
  period = {},
  indexation,
  isLoading,
}) => {
  const { t } = useTranslation();
  const { scheduleTypes, startDate, endDate } = period;
  const hasData = useMemo(
    () => scheduleTypes && startDate && endDate,
    [scheduleTypes, startDate, endDate],
  );

  const HS_HH = hasData ? scheduleTypes.HS_HH : emptySymbol;
  const HS_LH = hasData ? scheduleTypes.HS_LH : emptySymbol;
  const LS_HH = hasData ? scheduleTypes.LS_HH : emptySymbol;
  const LS_LH = hasData ? scheduleTypes.LS_LH : emptySymbol;
  const RH = hasData ? scheduleTypes.RH : emptySymbol;
  const indexationValue = hasData ? indexation : emptySymbol;

  return (
    <StyledPricesContainer>
      <Flex>
        <StyledCommunityTitle>
          {t('community_electricity_price')}
          <CustomTooltip
            tooltip={
              <TextRegular>{t('ttc_price_all_taxes_included')}</TextRegular>
            }
          />
        </StyledCommunityTitle>
      </Flex>
      <Flex>
        <StyledPricesTitleContainer>
          <StyledPricesTitle>{t('actual_prices')}</StyledPricesTitle>
          <CustomTooltip
            tooltip={<TextRegular>{t('actual_prices_tooltip')}</TextRegular>}
            iconSize={16}
            wrapperStyles={customTooltipStyles}
            containerStyles={customTooltipStyles}
          />
        </StyledPricesTitleContainer>
      </Flex>
      <StyledPricesRow>
        <StyledIndexCol width="150px">
          <div>
            <StyledCalendarIcon />
            {!isLoading && hasData && (
              <span>
                {`${dayjs(startDate).format('DD.MM.YYYY')} - ${dayjs(
                  endDate,
                ).format('DD.MM.YYYY')}`}
              </span>
            )}
            {!isLoading && !hasData && <span>{emptySymbol}</span>}
            {isLoading && <SkeletonLine active width={130} height={18} />}
          </div>
          <div>
            <StyledIndexIcon />
            {isLoading ? (
              <SkeletonLine active width={30} height={18} />
            ) : (
              <span>{indexationValue}</span>
            )}
          </div>
        </StyledIndexCol>
        <StyledPriceItemCol>
          <StyledPriceContainer>
            <div>
              <StyledPriceTypeText>{t('hshp')}</StyledPriceTypeText>
              <span>,</span>
              <StyledPriceUnitText> {t('eur_kwh')}</StyledPriceUnitText>
            </div>
            <StyledPriceValue>
              {isLoading ? (
                <SkeletonInputWrapper>
                  <SkeletonLine block active />
                </SkeletonInputWrapper>
              ) : (
                HS_HH
              )}
            </StyledPriceValue>
          </StyledPriceContainer>
        </StyledPriceItemCol>
        <StyledPriceItemCol>
          <StyledPriceContainer>
            <div>
              <StyledPriceTypeText>{t('hshc')}</StyledPriceTypeText>
              <span>,</span>
              <StyledPriceUnitText> {t('eur_kwh')}</StyledPriceUnitText>
            </div>
            <StyledPriceValue>
              {isLoading ? (
                <SkeletonInputWrapper>
                  <SkeletonLine block active />
                </SkeletonInputWrapper>
              ) : (
                HS_LH
              )}
            </StyledPriceValue>
          </StyledPriceContainer>
        </StyledPriceItemCol>
        <StyledPriceItemCol>
          <StyledPriceContainer>
            <div>
              <StyledPriceTypeText>{t('bshp')}</StyledPriceTypeText>
              <span>,</span>
              <StyledPriceUnitText> {t('eur_kwh')}</StyledPriceUnitText>
            </div>
            <StyledPriceValue>
              {isLoading ? (
                <SkeletonInputWrapper>
                  <SkeletonLine block active />
                </SkeletonInputWrapper>
              ) : (
                LS_HH
              )}
            </StyledPriceValue>
          </StyledPriceContainer>
        </StyledPriceItemCol>
        <StyledPriceItemCol>
          <StyledPriceContainer>
            <div>
              <StyledPriceTypeText>{t('bshc')}</StyledPriceTypeText>
              <span>,</span>
              <StyledPriceUnitText> {t('eur_kwh')}</StyledPriceUnitText>
            </div>
            <StyledPriceValue>
              {isLoading ? (
                <SkeletonInputWrapper>
                  <SkeletonLine block active />
                </SkeletonInputWrapper>
              ) : (
                LS_LH
              )}
            </StyledPriceValue>
          </StyledPriceContainer>
        </StyledPriceItemCol>
        <StyledPriceItemCol>
          <StyledPriceContainer>
            <div>
              <StyledPriceTypeText>{t('hdp')}</StyledPriceTypeText>
              <span>,</span>
              <StyledPriceUnitText> {t('eur_kwh')}</StyledPriceUnitText>
            </div>
            <StyledPriceValue>
              {isLoading ? (
                <SkeletonInputWrapper>
                  <SkeletonLine block active />
                </SkeletonInputWrapper>
              ) : (
                RH
              )}
            </StyledPriceValue>
          </StyledPriceContainer>
        </StyledPriceItemCol>
        <StyledPriceCol width="62px" />
      </StyledPricesRow>
    </StyledPricesContainer>
  );
};

export default CommunityPrices;
