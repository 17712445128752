import { styled } from 'styled-components';

import Link from 'antd/es/typography/Link';

import { TextRegular } from 'components/shared/Text';
import { COLORS } from 'constants/colors';

export const StyledTooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledListItem = styled.div`
  display: flex;
  gap: 5px;
`;

export const StyledTitle = styled(TextRegular)`
  font-weight: 600;
`;

export const StyledLink = styled(Link)`
  & u {
    color: ${COLORS.TextBase};
  }
`;

export const StyledText = styled(TextRegular)`
  color: ${COLORS.TextBase};
`;

export const StyledListAdditionalInfo = styled.div`
  margin: 0 15px;
`;
