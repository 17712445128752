import { getAppRole } from 'utils';
import { v4 as uuidv4 } from 'uuid';

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { message } from 'antd';

import DynamicEditForm from 'components/communities/components/DynamicEditForm';
import GenericFormName from 'components/communities/components/GenericFormName';
import Loader from 'components/shared/Loader';
import { USER_ROLES } from 'constants/types';
import { useGetCommunityQuery } from 'services/communityManagement';
import { useUpdateMemberMutation } from 'services/membersManagement';
import { useGetMemberByIdQuery } from 'services/pmoMemberDashboardManagment';
import { IMember } from 'services/types';

import { editPmoSchema } from 'utils/validation/schemas';

import DetailCard from '../Card';
import {
  Button,
  CardWrapper,
  EditIcon,
  StyledBlockBody,
  StyledBlockHeader,
  StyledBlockTitle,
  StyledBlockWrapper,
} from './styles';

type IPmoUserData = {
  isLoading?: boolean;
  communityPmoOrgName?: string;
  refetchCommunityData?: () => void;
};

const PmoUserData = ({
  communityPmoOrgName = '',
  refetchCommunityData,
}: IPmoUserData) => {
  const { t } = useTranslation();
  const { id } = useParams();

  const {
    data: member,
    refetch: refetchMember,
    isLoading: isLoadingMember,
  } = useGetMemberByIdQuery(id);
  const [updateMember, { isLoading, data: memberData }] =
    useUpdateMemberMutation();
  const {
    data: community,
    refetch: refetchCommunity,
    isLoading: isLoadingCommunity,
  } = useGetCommunityQuery();
  const userRole = getAppRole();
  const isPmoAdmin = [USER_ROLES.PMO_ADMIN.toString()].includes(userRole);

  const [isEditMode, setEditMode] = useState(false);
  const [userProfile, setUserProfile] = useState(member);
  const [pmoOrgName, setPmoOrgName] = useState(
    communityPmoOrgName || community?.pmoOrgName,
  );

  useEffect(() => {
    setUserProfile(memberData);
  }, [memberData]);

  useEffect(() => {
    setUserProfile(member);
  }, [member]);

  useEffect(() => {
    setPmoOrgName(communityPmoOrgName || community?.pmoOrgName);
  }, [communityPmoOrgName, community]);

  const name = `${userProfile?.firstName} ${userProfile?.middleName} ${userProfile?.lastName}`;
  const email = userProfile?.email;
  const contactNumber = userProfile?.contactNumber;

  const userEditFormData = {
    pmo_data: [
      {
        id: uuidv4(),
        label: 'pmo_organization_name',
        key: 'pmoOrgName',
        value: pmoOrgName,
      },
      {
        id: uuidv4(),
        label: 'legal_representative_label',
        key: '',
        value: name,
        component: GenericFormName,
      },
      {
        id: uuidv4(),
        label: 'representative_email_label',
        key: 'email',
        value: email,
        disabled: isPmoAdmin,
      },
      {
        id: uuidv4(),
        label: 'representative_phone_number_label',
        key: 'contactNumber',
        value: contactNumber,
      },
    ],
  };

  const handleOnEdit = () => {
    setUserProfile(member);
    setEditMode(true);
  };

  const handleOnSave = async (values: IMember) => {
    setEditMode(false);

    await updateMember(values);
    await refetchMember();
    await refetchCommunity();

    refetchCommunityData
      ? await refetchCommunityData()
      : await refetchCommunity();

    if (member?.email !== values?.email) {
      const textMessage = t('email_updated_and_invitation_sent', {
        email: values?.email,
      });

      message.success(textMessage);
    }
  };

  return (
    <StyledBlockWrapper>
      {isLoading && <Loader />}
      <StyledBlockHeader>
        <StyledBlockTitle>{t('profile_data')}</StyledBlockTitle>
        <Button
          size="small"
          type="text"
          disabled={isEditMode}
          onClick={handleOnEdit}
        >
          <EditIcon disabled={isEditMode} />
          {t('edit')}
        </Button>
      </StyledBlockHeader>
      <StyledBlockBody>
        <DynamicEditForm
          isEditMode={isEditMode}
          formData={userEditFormData}
          initialValues={{ ...userProfile, pmoOrgName }}
          validationSchema={editPmoSchema(t)}
          onClose={() => setEditMode(false)}
          onSave={handleOnSave}
        />
        <CardWrapper>
          <DetailCard
            isEditMode={isEditMode}
            labelWidth={'150px'}
            labels={{
              first: 'pmo_organization_name',
              second: 'legal_representative_label',
              third: 'representative_email_label',
              fourth: 'representative_phone_number_label',
            }}
            title={t('pmo_data').toString()}
            values={{
              first: pmoOrgName,
              second: name,
              third: email,
              forth: contactNumber,
            }}
            isLoading={isLoadingMember || isLoadingCommunity}
          />
        </CardWrapper>
      </StyledBlockBody>
    </StyledBlockWrapper>
  );
};

export default PmoUserData;
