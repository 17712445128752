import * as yup from 'yup';

import { TFunction } from 'i18next';

export const taxDiscountedAccisePricesSchema = (t: TFunction) =>
  yup.object().shape({
    startDate: yup
      .date()
      .required(t('field_is_required').toString())
      .min(new Date(), t('end_date_before_start_date').toString()),
    endDate: yup
      .date()
      .required(t('field_is_required').toString())
      .min(yup.ref('startDate'), t('end_date_after_start_date').toString()),
    discountedAccisePerMWh: yup.object().shape({
      communityName: yup.string().required(t('field_is_required').toString()),
      communityId: yup.string().required(t('field_is_required').toString()),
      condition: yup.string().required(t('field_is_required').toString()),
      vat: yup
        .number()
        .required(t('field_is_required').toString())
        .positive(t('value_should_be_positive').toString()),
      ht: yup
        .number()
        .required(t('field_is_required').toString())
        .positive(t('value_should_be_positive').toString()),
      ttc: yup
        .number()
        .required(t('field_is_required').toString())
        .positive(t('value_should_be_positive').toString()),
    }),
  });
