import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useFormikContext } from 'formik';

import { Radio, Space } from 'antd';

import { StyledTab } from 'components/communities/CreateCommunity/styles';
import { Prices } from 'components/members/types';
import { TextRegular } from 'components/shared/Text';
import CustomTooltip from 'components/shared/Tooltip';
import TooltipIcon from 'components/shared/Tooltip/TooltipIcon';

import { initialValues } from '../../constants';
import { emptyDynamicObject } from '../constants';
import PriceDetails from './PriceDetails';
import { PRICE_TYPES } from './constants';
import {
  StyledPricesWrapper,
  StyledRadio,
  StyledRadioWrapper,
  StyledSubHeader,
  StyledTextInfo,
  StyledTooltipInfo,
  StyledWrapper,
} from './styles';

const ElectricityTypePrice = ({ index }: { index: number }) => {
  const { setFieldValue } = useFormikContext<typeof initialValues>();
  const { t } = useTranslation();
  const [priceType, setPriceType] = useState(Prices.static);

  const onRadioChange = async (type: Prices) => {
    const filteredGridPrice =
      type === Prices.static
        ? { static: null }
        : { dynamic: emptyDynamicObject };

    await setFieldValue(
      `additionalInfo.prms.${index}.gridPrice`,
      filteredGridPrice,
    );
    setPriceType(type);
  };

  return (
    <StyledWrapper>
      <StyledSubHeader>
        {`${index + 1} PRM ${t('electricity_price_type_of_the_grid')}`}
        <TextRegular>{` (${t('optional')})`}</TextRegular>
        <CustomTooltip
          tooltip={
            <TextRegular>{t('by_providing_this_information')}</TextRegular>
          }
        />
      </StyledSubHeader>

      <StyledPricesWrapper>
        <StyledRadioWrapper>
          <Radio.Group
            value={priceType}
            onChange={(e) => onRadioChange(e.target.value)}
          >
            <Space size={120}>
              <StyledRadio value={Prices.static}>
                {t('static_price')}
              </StyledRadio>
              <StyledRadio value={Prices.dynamic}>
                {t('dynamic_price')}
              </StyledRadio>
            </Space>
          </Radio.Group>
        </StyledRadioWrapper>
        <StyledTab width="100%" active={priceType === Prices.dynamic}>
          <PriceDetails
            priceType={priceType}
            titles={['high_season', 'low_season']}
            priceTypes={PRICE_TYPES[Prices.dynamic]}
            index={index}
          />
        </StyledTab>

        <StyledTab width="100%" active={priceType === Prices.static}>
          <PriceDetails
            priceType={priceType}
            titles={['electricity_grid_price']}
            priceTypes={PRICE_TYPES[Prices.static]}
            index={index}
          />
        </StyledTab>
        <StyledTooltipInfo>
          <TooltipIcon size={14} color="TextSecondary" />

          <StyledTextInfo>
            {t(
              'to_calculate_cost_savings_please_ensure_all_prices_are_entered',
            )}
          </StyledTextInfo>
        </StyledTooltipInfo>
      </StyledPricesWrapper>
    </StyledWrapper>
  );
};

export default ElectricityTypePrice;
