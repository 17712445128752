import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import TitleItem from 'components/communities/Charts/SummaryView/components/Title/components/TitleItem';
import {
  LineDivider,
  LocationIcon,
  SummaryTitle,
  ThunderboltIcon,
} from 'components/communities/Charts/SummaryView/components/Title/styles';
import { USER_ROLES } from 'constants/global';
import { SkeletonLine } from 'components/shared/Skeleton';
import { capitalizeFirstLetter } from 'utils/forms';

import { CommunityIcon, HomeIcon } from './styles';

interface PmoMemberTitleProps {
  community?: string;
  location?: string;
  type?: string;
  power?: number | null | string;
  role?: string;
  prmNumbers?: number | null;
  isLoading: boolean;
}

export const PmoMemberTitle: FC<PmoMemberTitleProps> = ({
  community = 'N/A',
  location = 'N/A',
  type = 'n/A',
  power = 0,
  prmNumbers = 0,
  role,
  isLoading,
}) => {
  const { t } = useTranslation();

  const communityValue = useMemo(
    () =>
      isLoading ? <SkeletonLine height={22} width={100} active /> : community,
    [isLoading, community],
  );
  const locationValue = useMemo(
    () =>
      isLoading ? <SkeletonLine height={22} width={104} active /> : location,
    [isLoading, location],
  );
  const typeValue = useMemo(
    () =>
      isLoading ? (
        <SkeletonLine height={22} width={116} active />
      ) : (
        capitalizeFirstLetter(type)
      ),
    [isLoading, type],
  );
  const prmNumbersValue = useMemo(
    () =>
      isLoading ? (
        <SkeletonLine height={22} width={30} active />
      ) : (
        prmNumbers + ''
      ),
    [isLoading, prmNumbers],
  );

  return (
    <SummaryTitle>
      <TitleItem
        preffixDescription={t('community').toString().toUpperCase()}
        value={communityValue}
      >
        <CommunityIcon />
      </TitleItem>
      <LineDivider>|</LineDivider>
      <TitleItem value={locationValue}>
        <LocationIcon />
      </TitleItem>
      {role === USER_ROLES.CONSUMER && (
        <>
          <LineDivider>|</LineDivider>
          <TitleItem
            preffixDescription={t('type').toString().toUpperCase()}
            value={typeValue}
          >
            <HomeIcon />
          </TitleItem>
        </>
      )}
      <LineDivider>|</LineDivider>
      {role === USER_ROLES.PRODUCER ? (
        <TitleItem
          preffixDescription={t('produced_power').toString().toUpperCase()}
          value={power + ' MW'}
        >
          <ThunderboltIcon />
        </TitleItem>
      ) : (
        <TitleItem preffixDescription={"PRM'S"} value={prmNumbersValue}>
          <ThunderboltIcon />
        </TitleItem>
      )}
    </SummaryTitle>
  );
};

export default PmoMemberTitle;
