import { getAppRole } from 'utils';

import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

import CostEarned from 'components/communities/Charts/CostEarned';
import ElectricityConsumption, {
  DataInfo,
} from 'components/communities/Charts/ElectricityConsumption';
import ProducerSummaryView from 'components/communities/Charts/ProducerSummaryView';
import SelfConsumption from 'components/communities/Charts/SelfConsumption';
import ProducerCommunityData from 'components/communities/Dashboard/components/ProducerDashboard/components/CommunityData';
import ConsumersMap from 'components/communities/Dashboard/components/ProducerDashboard/components/ConsumersMap/index';
import {
  getFinancialDataTotalByType,
  parseFinancialDataByType,
} from 'components/communities/Dashboard/components/ProducerDashboard/components/FinancialDataTab/components/FinancialFollowUpChartWrapper/utils';
import { FinancialDataTypes } from 'components/communities/Dashboard/types';
import {
  mapFinancialData,
  sortSelfConsumedByHoursData,
} from 'components/communities/Dashboard/utils';
import {
  LABELS,
  mapElectricityConsumptionData,
} from 'components/communities/MyCommunity/constants/ElectricityConsumption';
import {
  selfConsumedEmptyStateByConsumerType,
  selfConsumedEmptyStateByHours,
  selfConsumedInfoItemsByConsumerType,
  selfConsumedInfoItemsByHours,
} from 'components/communities/constants';
import { Row } from 'components/communities/styles';
import {
  formatFinancialDataWithEmptyTypes,
  getFormattedInfoChartValue,
  getPieColorByConsumerType,
  getPieColorByHours,
} from 'components/communities/utils';
import { PERIOD, USER_ROLES } from 'constants/types';
import {
  useGetEarningsQuery,
  useGetElectricityProductionQuery,
  useGetElectricityTotalQuery,
  useGetFinancialDataQuery,
  useGetSelfConsumedBySourceQuery,
  useGetSelfConsumedByTypeQuery,
} from 'services/dashboardManagment';
import { ILabelMap } from 'services/types';

import { useAppSelector } from 'utils/hooks';
import { getDateRange } from 'utils/url/getDateRange';

import {
  getColumnColorByType,
  getCostEarnedInfoDataByType,
} from './constants/CostEarned';
import {
  infoItems as ElectricityConsumptionItems,
  areaColors,
  legendItems,
  lineColors,
} from './constants/ElectricityConsumption';

const useUrlQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const PmoProducerDashboard: FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const query = useUrlQuery();
  const communityId = query.get('communityId') || undefined;
  const role = getAppRole();

  const period = useAppSelector((state) => state.dashboardPeriod.period);
  const [costEarnedChartData, setCostEarnedChartData] = useState([]);
  const { data: costEarningData, isLoading: isLoadingCostEarning } =
    useGetFinancialDataQuery({
      ...getDateRange(period),
      userId: id,
    });

  const {
    data: totalProducerEarnings,
    isLoading: isLoadingTotalProducerEarnings,
  } = useGetEarningsQuery({
    ...getDateRange(period),
    communityId,
  });
  const {
    data: electricityConsumptionData,
    isLoading: isLoadingElectricityConsumption,
  } = useGetElectricityProductionQuery({
    ...getDateRange(period),
    userId: id,
  });
  const { data: electricityTotalData, isLoading: isLoadingElectricityTotal } =
    useGetElectricityTotalQuery({
      ...getDateRange(period),
      userId: id,
    });
  const {
    data: selfConsumedByTypeData,
    isLoading: isLoadingSelfConsumedByType,
  } = useGetSelfConsumedByTypeQuery({
    ...getDateRange(period),
    userId: id,
  });
  const {
    data: selfConsumedBySourceData,
    isLoading: isLoadingSelfConsumedBySource,
  } = useGetSelfConsumedBySourceQuery({
    ...getDateRange(period),
    userId: id,
  });

  const electricityConsumptionInfoDataArray = [
    electricityTotalData?.production,
    electricityTotalData?.selfConsumption,
    electricityTotalData?.overproduction,
  ];
  const electricityConsumptionDataMapped = useMemo(() => {
    const labelsMap: ILabelMap = {
      totalProduction: LABELS.TOTAL_PRODUCTION,
      selfConsumption: LABELS.SELF_CONSUMPTION,
      overproduction: LABELS.OVERPRODUCTION,
    };

    return mapElectricityConsumptionData(
      electricityConsumptionData,
      period,
      labelsMap,
      t,
    );
  }, [electricityConsumptionData, period, t]);

  const overproductionValue = getFormattedInfoChartValue(
    electricityTotalData?.overproduction,
    electricityConsumptionInfoDataArray,
  );

  const dataInfo: DataInfo[] | undefined = [
    {
      title: LABELS.TOTAL_PRODUCTION,
      power: getFormattedInfoChartValue(
        electricityTotalData?.production,
        electricityConsumptionInfoDataArray,
      ),
    },
    {
      title: LABELS.SELF_CONSUMPTION,
      power: getFormattedInfoChartValue(
        electricityTotalData?.selfConsumption,
        electricityConsumptionInfoDataArray,
      ),
    },
    {
      title: LABELS.OVERPRODUCTION,
      power:
        overproductionValue !== 'N/A'
          ? Math.abs(electricityTotalData?.overproduction)
          : overproductionValue,
    },
  ];

  // Cost Earning mappers

  const costEarendDataTypeByRole =
    role === USER_ROLES.PMO_ADMIN
      ? FinancialDataTypes.EARNED_FROM_COMMUNITY
      : FinancialDataTypes.ALL_EARNINGS;

  const isMonthlyStep = useMemo(() => {
    return (
      period === PERIOD.CURRENT_YEAR ||
      period === PERIOD.LAST_YEAR ||
      period === PERIOD.LAST_MONTH
    );
  }, [period]);

  const costEarnedInfoData = useMemo(() => {
    return getCostEarnedInfoDataByType(costEarendDataTypeByRole, t);
  }, [costEarendDataTypeByRole, t]);

  const mappedCostEarningData = useMemo(() => {
    if (isMonthlyStep && costEarningData && costEarningData.length > 0) {
      const mappedData = mapFinancialData(costEarnedChartData, t);
      return formatFinancialDataWithEmptyTypes(
        mappedData,
        costEarnedInfoData?.types || [],
      );
    }
    return [];
  }, [
    costEarnedChartData,
    isMonthlyStep,
    t,
    costEarningData,
    costEarnedInfoData,
  ]);

  const costEarnedTotals = useMemo(() => {
    if (isMonthlyStep)
      return getFinancialDataTotalByType(
        costEarnedChartData,
        costEarendDataTypeByRole,
      );

    return [0, 0];
  }, [costEarnedChartData, isMonthlyStep, costEarendDataTypeByRole]);

  useEffect(() => {
    const parsedFinancialDataByType = parseFinancialDataByType(
      costEarningData,
      costEarendDataTypeByRole,
    );
    if (costEarendDataTypeByRole === FinancialDataTypes.ALL_EARNINGS) {
      setCostEarnedChartData(parsedFinancialDataByType);
    } else if (
      costEarendDataTypeByRole === FinancialDataTypes.EARNED_FROM_COMMUNITY
    ) {
      setCostEarnedChartData(
        parsedFinancialDataByType
          ?.filter((item: any) => item.status === 'paid_amount')
          ?.map((item: any) => ({ ...item, status: 'earned_from_community' })),
      );
    }
  }, [costEarningData, costEarendDataTypeByRole]);

  const selfConsumedByTypePresentableData =
    selfConsumedByTypeData && selfConsumedByTypeData.length > 0
      ? selfConsumedByTypeData?.map((item: any) => ({
          value: item.consumedInPercent,
          type: item.scheduleType,
        }))
      : selfConsumedEmptyStateByHours;

  const selfConsumedBySourcePresentableData =
    selfConsumedBySourceData && selfConsumedBySourceData.length > 0
      ? selfConsumedBySourceData?.map((item: any) => ({
          value: item.consumedInPercent,
          type: item.consumerType,
        }))
      : selfConsumedEmptyStateByConsumerType;

  return (
    <>
      <ProducerSummaryView period={period} userId={id} />
      <ElectricityConsumption
        isConsumer
        title={t('energy_production')}
        lineColors={lineColors}
        areaColors={areaColors}
        infoItems={ElectricityConsumptionItems(t)}
        legendItems={legendItems(t)}
        dataInfo={dataInfo}
        electricityConsumption={electricityConsumptionDataMapped}
        isLoading={isLoadingElectricityTotal || isLoadingElectricityConsumption}
      />
      <Row>
        <SelfConsumption
          getPieColor={getPieColorByHours}
          infoItems={selfConsumedInfoItemsByHours(t)}
          chartData={sortSelfConsumedByHoursData(
            selfConsumedByTypePresentableData,
          )}
          title={t('energy_consumed_by_type_of_hours')}
          isLoading={isLoadingSelfConsumedByType}
        />
        <SelfConsumption
          getPieColor={getPieColorByConsumerType}
          infoItems={selfConsumedInfoItemsByConsumerType(t)}
          chartData={selfConsumedBySourcePresentableData}
          title={t('self_consumption_by_consumer_type')}
          isLoading={isLoadingSelfConsumedBySource}
        />
      </Row>
      <Row>
        <CostEarned
          isMonthlyStep={isMonthlyStep}
          chartData={mappedCostEarningData}
          totalCostEarned={totalProducerEarnings?.earnings}
          totals={costEarnedTotals}
          legendItems={costEarnedInfoData?.legendItems}
          getColumnColorByType={getColumnColorByType}
          title={t('cost_earned')}
          infoItems={costEarnedInfoData?.infoItems}
          isLoading={isLoadingCostEarning || isLoadingTotalProducerEarnings}
        />
        <ProducerCommunityData userId={id} />
      </Row>
      <ConsumersMap userId={id} />
    </>
  );
};

export default PmoProducerDashboard;
