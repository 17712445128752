import { styled } from 'styled-components';
import { ScreenLarge, ScreenMedium } from 'constants/screen-breakpoints';
import { TextRegular } from 'components/shared/Text';
import { COLORS } from 'constants/colors';

export const StyledReportWrapper = styled.div`
  box-sizing: border-box;
  background-color: ${COLORS.ColorFillTertiary};
  padding: 25px 27px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 32px;
  margin-top: 40px;
  ${ScreenLarge`
    column-gap: 0;
    flex-wrap: wrap;
    row-gap: 24px;
  `}
`;

export const StyledReportButton = styled.div`
  width: 262px;
  ${ScreenLarge`
    width: 180px;
    margin-left: auto;
  `}
  ${ScreenMedium`
    width: 140px;
  `}
`;

export const StyledProducerReportButton = styled(StyledReportButton)`
  ${ScreenLarge`
    width: 262px;
  `}
`;

export const StyledReportSelectRow = styled.div`
  display: flex;
  column-gap: 10px;
  align-items: center;
`;

export const StyledLabel = styled(TextRegular)`
  width: auto;
  white-space: nowrap;
  text-align: right;

  &::first-letter {
    text-transform: uppercase;
  }
`;
