import React from 'react';

const Icon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
    >
      <path
        d="M9.14062 28.9688C8.75243 28.9688 8.4375 29.2845 8.4375 29.6719C8.4375 30.06 8.75243 30.375 9.14062 30.375C9.52805 30.375 9.84375 30.06 9.84375 29.6719C9.84375 29.2845 9.52805 28.9688 9.14062 28.9688Z"
        fill="black"
        fillOpacity="0.25"
      />
      <path
        d="M26.8594 30.375C27.2468 30.375 27.5625 30.06 27.5625 29.6719C27.5625 29.2845 27.2468 28.9688 26.8594 28.9688C26.472 28.9688 26.1562 29.2845 26.1562 29.6719C26.1562 30.06 26.472 30.375 26.8594 30.375Z"
        fill="black"
        fillOpacity="0.25"
      />
      <path
        d="M35.2969 31.7812H34.5938V16.3125H35.2969C35.5302 16.3125 35.7483 16.1968 35.8791 16.0035C36.0098 15.8103 36.0364 15.5649 35.9497 15.3482L33.1372 8.31698C33.0304 8.05001 32.7719 7.875 32.4844 7.875H22.6866C21.8724 6.34739 20.3909 5.33152 18.7031 5.10905V4.21875H22.2188C22.607 4.21875 22.9219 3.90389 22.9219 3.51562V0.703125C22.9219 0.314859 22.607 0 22.2188 0H18C17.6117 0 17.2969 0.314859 17.2969 0.703125V5.10905C15.6092 5.33152 14.1276 6.34739 13.3134 7.875H3.51562C3.22812 7.875 2.96958 8.05001 2.86277 8.31698L0.0502734 15.3482C-0.0363516 15.5649 -0.00984375 15.8103 0.120938 16.0035C0.251719 16.1968 0.469828 16.3125 0.703125 16.3125H1.40625V31.7812H0.703125C0.314859 31.7812 0 32.0961 0 32.4844V35.2969C0 35.6851 0.314859 36 0.703125 36H35.2969C35.6851 36 36 35.6851 36 35.2969V32.4844C36 32.0961 35.6852 31.7812 35.2969 31.7812ZM18.7031 1.40625H21.5156V2.8125H18.7031V1.40625ZM34.2584 14.9062H26.3041L23.4112 9.28125H32.0084L34.2584 14.9062ZM3.99171 9.28125H12.5889L9.69595 14.9062H1.74171L3.99171 9.28125ZM10.2656 34.5938H1.40625V33.1875H10.2656V34.5938ZM24.3281 34.5938H11.6719V33.1875H24.3281V34.5938ZM13.0781 31.7812V24.75H17.2969V31.7812H13.0781ZM18.7031 31.7812V24.75H22.9219V31.7812H18.7031ZM23.625 23.3438H12.375C11.9867 23.3438 11.6719 23.6586 11.6719 24.0469V31.7812H2.8125V30.375H6.32812C6.71555 30.375 7.03125 30.06 7.03125 29.6719C7.03125 29.2845 6.71555 28.9688 6.32812 28.9688H2.8125V16.3125H10.125C10.3885 16.3125 10.6298 16.1652 10.7503 15.9309L14.5265 8.58825C15.1988 7.28093 16.5298 6.46875 18 6.46875C19.4702 6.46875 20.8012 7.28093 21.4735 8.58832L25.2497 15.931C25.3702 16.1653 25.6115 16.3126 25.875 16.3126H33.1875V28.9688H29.6719C29.2845 28.9688 28.9688 29.2845 28.9688 29.6719C28.9688 30.0601 29.2845 30.3751 29.6719 30.3751H33.1875V31.7813H24.3281V24.0469C24.3281 23.6586 24.0133 23.3438 23.625 23.3438ZM34.5938 34.5938H25.7344V33.1875H34.5938V34.5938Z"
        fill="black"
        fillOpacity="0.25"
      />
      <path
        d="M27.5625 21.9375H31.0781C31.4664 21.9375 31.7812 21.6226 31.7812 21.2344V18.4219C31.7812 18.0336 31.4664 17.7188 31.0781 17.7188H27.5625C27.1742 17.7188 26.8594 18.0336 26.8594 18.4219V21.2344C26.8594 21.6227 27.1742 21.9375 27.5625 21.9375ZM28.2656 19.125H30.375V20.5312H28.2656V19.125Z"
        fill="black"
        fillOpacity="0.25"
      />
      <path
        d="M24.75 21.2344V18.4219C24.75 18.0336 24.4351 17.7188 24.0469 17.7188H19.8281C19.4399 17.7188 19.125 18.0336 19.125 18.4219V21.2344C19.125 21.6226 19.4399 21.9375 19.8281 21.9375H24.0469C24.4352 21.9375 24.75 21.6227 24.75 21.2344ZM23.3438 20.5312H20.5312V19.125H23.3438V20.5312Z"
        fill="black"
        fillOpacity="0.25"
      />
      <path
        d="M16.1719 17.7188H11.9531C11.5649 17.7188 11.25 18.0336 11.25 18.4219V21.2344C11.25 21.6226 11.5649 21.9375 11.9531 21.9375H16.1719C16.5601 21.9375 16.875 21.6226 16.875 21.2344V18.4219C16.875 18.0336 16.5602 17.7188 16.1719 17.7188ZM15.4688 20.5312H12.6562V19.125H15.4688V20.5312Z"
        fill="black"
        fillOpacity="0.25"
      />
      <path
        d="M4.92188 21.9375H8.4375C8.82577 21.9375 9.14062 21.6226 9.14062 21.2344V18.4219C9.14062 18.0336 8.82577 17.7188 8.4375 17.7188H4.92188C4.53361 17.7188 4.21875 18.0336 4.21875 18.4219V21.2344C4.21875 21.6227 4.53361 21.9375 4.92188 21.9375ZM5.625 19.125H7.73438V20.5312H5.625V19.125Z"
        fill="black"
        fillOpacity="0.25"
      />
      <path
        d="M27.5625 27.5625H31.0781C31.4664 27.5625 31.7812 27.2476 31.7812 26.8594V24.0469C31.7812 23.6586 31.4664 23.3438 31.0781 23.3438H27.5625C27.1742 23.3438 26.8594 23.6586 26.8594 24.0469V26.8594C26.8594 27.2477 27.1742 27.5625 27.5625 27.5625ZM28.2656 24.75H30.375V26.1562H28.2656V24.75Z"
        fill="black"
        fillOpacity="0.25"
      />
      <path
        d="M9.14062 26.8594V24.0469C9.14062 23.6586 8.82577 23.3438 8.4375 23.3438H4.92188C4.53361 23.3438 4.21875 23.6586 4.21875 24.0469V26.8594C4.21875 27.2476 4.53361 27.5625 4.92188 27.5625H8.4375C8.82584 27.5625 9.14062 27.2477 9.14062 26.8594ZM7.73438 26.1562H5.625V24.75H7.73438V26.1562Z"
        fill="black"
        fillOpacity="0.25"
      />
      <path
        d="M4.80132 13.45C4.88696 13.4842 4.97534 13.5004 5.06224 13.5004C5.34124 13.5004 5.60534 13.3332 5.71531 13.0583L6.27781 11.652C6.42202 11.2914 6.24666 10.8823 5.8861 10.738C5.5256 10.5937 5.11638 10.7691 4.9721 11.1297L4.4096 12.536C4.26539 12.8965 4.44075 13.3058 4.80132 13.45Z"
        fill="black"
        fillOpacity="0.25"
      />
      <path
        d="M8.03569 13.45C8.12133 13.4842 8.20971 13.5004 8.29662 13.5004C8.57562 13.5004 8.83971 13.3332 8.94968 13.0583L9.51218 11.652C9.65639 11.2914 9.48103 10.8823 9.12047 10.738C8.76005 10.5937 8.35076 10.7691 8.20648 11.1297L7.64398 12.536C7.49977 12.8965 7.67513 13.3058 8.03569 13.45Z"
        fill="black"
        fillOpacity="0.25"
      />
      <path
        d="M30.2846 13.0582C30.3946 13.3331 30.6586 13.5004 30.9377 13.5004C31.0246 13.5004 31.113 13.4841 31.1986 13.4499C31.5592 13.3056 31.7345 12.8964 31.5903 12.5359L31.0278 11.1296C30.8835 10.769 30.4742 10.5939 30.1138 10.7379C29.7533 10.8822 29.5779 11.2914 29.7221 11.6519L30.2846 13.0582Z"
        fill="black"
        fillOpacity="0.25"
      />
      <path
        d="M27.0502 13.0582C27.1602 13.3331 27.4242 13.5004 27.7033 13.5004C27.7902 13.5004 27.8786 13.4841 27.9642 13.4499C28.3248 13.3056 28.5001 12.8964 28.3559 12.5359L27.7934 11.1296C27.6492 10.769 27.2399 10.5939 26.8794 10.7379C26.5189 10.8822 26.3435 11.2914 26.4877 11.6519L27.0502 13.0582Z"
        fill="black"
        fillOpacity="0.25"
      />
      <path
        d="M21.5156 13.0781C21.5156 11.1396 19.9385 9.5625 18 9.5625C16.0615 9.5625 14.4844 11.1396 14.4844 13.0781C14.4844 15.0166 16.0615 16.5938 18 16.5938C19.9385 16.5938 21.5156 15.0167 21.5156 13.0781ZM18 10.9688C18.3164 10.9688 18.6164 11.0392 18.8861 11.1646L17.9835 12.0672L17.0912 11.1749C17.3666 11.0429 17.6748 10.9688 18 10.9688ZM18 15.1875C16.8369 15.1875 15.8906 14.2412 15.8906 13.0781C15.8906 12.7529 15.9647 12.4447 16.0968 12.1693L17.4863 13.5588C17.6236 13.696 17.8035 13.7647 17.9835 13.7647C18.1634 13.7647 18.3433 13.696 18.4807 13.5588L19.8922 12.1472C20.031 12.4282 20.1094 12.7441 20.1094 13.0781C20.1094 14.2412 19.1631 15.1875 18 15.1875Z"
        fill="black"
        fillOpacity="0.25"
      />
      <path
        d="M15.1875 29.3203C15.5758 29.3203 15.8906 29.0055 15.8906 28.6172C15.8906 28.2289 15.5758 27.9141 15.1875 27.9141C14.7992 27.9141 14.4844 28.2289 14.4844 28.6172C14.4844 29.0055 14.7992 29.3203 15.1875 29.3203Z"
        fill="black"
        fillOpacity="0.25"
      />
      <path
        d="M20.8125 29.3203C21.2008 29.3203 21.5156 29.0055 21.5156 28.6172C21.5156 28.2289 21.2008 27.9141 20.8125 27.9141C20.4242 27.9141 20.1094 28.2289 20.1094 28.6172C20.1094 29.0055 20.4242 29.3203 20.8125 29.3203Z"
        fill="black"
        fillOpacity="0.25"
      />
    </svg>
  );
};

export default Icon;
