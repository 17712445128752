import { styled } from 'styled-components';
import { ScreenLarge, ScreenMedium } from 'constants/screen-breakpoints';
import { COLORS } from 'constants/colors';

export const StyledReportWrapper = styled.div`
  box-sizing: border-box;
  background-color: ${COLORS.ColorFillTertiary};
  padding: 25px 27px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 27px;
  margin-top: 40px;
  margin-bottom: 32px;
  ${ScreenLarge`
    column-gap: 0;
    flex-wrap: wrap;
    row-gap: 24px;
  `}
`;

export const StyledReportButton = styled.div`
  width: 262px;
  ${ScreenLarge`
    margin-left: auto;
  `}
`;

export const StyledReportSelectRow = styled.div`
  display: flex;
  column-gap: 10px;
  align-items: center;
`;

export const StyledReportCheckboxRow = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;
