import { COLORS } from 'constants/colors';

export const getPieColorByType = (type: string) => {
  switch (type) {
    case 'HS_HH':
      return COLORS.Yellow4;
    case 'HS_LH':
      return COLORS.Yellow5;
    case 'LS_HH':
      return COLORS.Gold5;
    case 'LS_LH':
      return COLORS.Gold6;
    case 'RH':
      return COLORS.Gold7;
    default:
      return COLORS.TextDescription;
  }
};

export const getPieColorBySource = (type: string) => {
  switch (type) {
    case 'private':
      return COLORS.Green3;
    case 'small':
      return COLORS.Green4;
    case 'medium':
      return COLORS.Green5;
    case 'large':
      return COLORS.Green6;
    case 'collectivities':
      return COLORS.Green7;
    default:
      return COLORS.TextDescription;
  }
};
