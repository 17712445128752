import { getAppRole } from 'utils';

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { MenuProps } from 'antd';

import { setPeriod } from 'components/shared/PeriodPicker/slice';
import { CHART_TYPE } from 'constants/global';
import { PERIOD, USER_ROLES } from 'constants/types';

import { useAppDispatch } from 'utils/hooks';

import { CalendarIcon, Container, SelectPeriod } from './styles';
import { getPeriodFiltersByRole } from './utils';

export interface IPeriodPickerResp {
  startDate: string;
  endDate: string;
  period: string;
}

interface IPeriodPicker {
  chartType?: CHART_TYPE;
  onChange?: (res: IPeriodPickerResp) => void;
}

const PeriodPicker = ({ chartType, onChange }: IPeriodPicker) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const role = getAppRole();
  const items = getPeriodFiltersByRole({ t, chartType });
  const [period, setDataPeriod] = useState(() => {
    if (role === USER_ROLES.ADMIN) {
      return PERIOD.CURRENT_YEAR;
    }
    return (sessionStorage.getItem('period') as PERIOD) || PERIOD.CURRENT_YEAR;
  });
  const [dropdownVisible, setDropdownVisible] = useState(false);

  useEffect(() => {
    window.sessionStorage.setItem('period', period);
    dispatch(setPeriod(period));
  }, [dispatch, period, onChange]);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleMenuClick: MenuProps['onClick'] = (event) => {
    setDataPeriod(event.key as PERIOD);
    toggleDropdown();
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      // Check if the click is outside the dropdown
      if (
        !event.target.closest('.dropdown-classs') &&
        !event.target.closest('.ant-dropdown')
      ) {
        setDropdownVisible(false);
      }
    };

    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (chartType === CHART_TYPE.FINANCIAL_DATA) {
      setDataPeriod(PERIOD.CURRENT_YEAR);
    }
  }, [chartType]);

  return (
    <Container>
      <SelectPeriod
        className="dropdown-classs"
        open={dropdownVisible}
        menu={menuProps}
        placement="bottomRight"
        icon={<CalendarIcon onClick={() => toggleDropdown()} />}
        onClick={toggleDropdown}
        overlayStyle={{ width: '170px' }}
      >
        {t(period).toUpperCase()}
      </SelectPeriod>
    </Container>
  );
};

export default PeriodPicker;
