import { styled } from 'styled-components';

import { PlusOutlined } from '@ant-design/icons';
import { InfoCircleOutlined } from '@ant-design/icons';

import { COLORS } from 'constants/colors';

export const PageWrapper = styled.div`
  padding: 0 32px;
  max-width: 1376px;
  margin: 0 auto;
`;

export const StyledAddPrmButton = styled.div`
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.06);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 2px 8px;
  cursor: pointer;

  color: ${COLORS.Green8};
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
`;

export const StyledPlusIcon = styled(PlusOutlined)`
  color: ${COLORS.Green6};
`;

export const InfoIcon = styled(InfoCircleOutlined)`
  color: ${COLORS.TextSecondary};
`;
