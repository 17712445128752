import { getAppRole, getConsumerLegalInitials, getUseMenuByRole } from 'utils';

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Dropdown } from 'antd';

import { COLORS } from 'constants/colors';
import { USER_ROLES } from 'constants/types';

import { useAppLanguage } from 'utils/hooks';

import {
  StyledMenuBtn,
  StyledMenuWrapper,
  ThunderboltIcon,
  dropdownOverlayStyle,
} from './styles';

const UserMenu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { onLogout } = useAppLanguage();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userRole = getAppRole();

  const items = useMemo(() => {
    return getUseMenuByRole().map((item: any) => ({
      ...item,
      label: t(item.label),
    }));
  }, [t]);

  const btnContent = useMemo(() => {
    switch (userRole) {
      case USER_ROLES.ADMIN:
        return t('oyo');
      case USER_ROLES.PMO_ADMIN:
        return t('pmo');
      case USER_ROLES.CONSUMER:
        return getConsumerLegalInitials();
      case USER_ROLES.PRODUCER:
        return <ThunderboltIcon />;
    }
  }, [userRole]);

  const btnColor = useMemo(() => {
    switch (userRole) {
      case USER_ROLES.ADMIN:
        return COLORS.Orange4;
      case USER_ROLES.PMO_ADMIN:
        return COLORS.Blue5;
      case USER_ROLES.CONSUMER:
        return COLORS.Green5;
      case USER_ROLES.PRODUCER:
        return COLORS.Yellow2;
    }
  }, [userRole]);

  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
    onLogout();
  };

  const handleMenuItemClick = useCallback(
    ({ item, key, keyPath, domEvent }: any) => {
      domEvent.preventDefault();
      setIsMenuOpen(false);
      const menuItem = items[keyPath];
      if (!menuItem) return;
      if (!menuItem.route) {
        return handleLogout();
      }

      const tabParam = menuItem.tab ? `?tab=${menuItem.tab}` : '';
      navigate(`${menuItem.route}${tabParam}`);
    },
    [items, userRole],
  );

  const toggleMenu = useCallback(() => setIsMenuOpen((val) => !val), []);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        !event.target.closest('.dropdown-classs') &&
        !event.target.closest('.ant-dropdown')
      ) {
        setIsMenuOpen(false);
      }
    };
    const handleScrollOutside = () => setIsMenuOpen(false);
    window.addEventListener('scroll', handleScrollOutside);
    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('scroll', handleScrollOutside);
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <StyledMenuWrapper>
      <Dropdown
        className="dropdown-classs"
        menu={{ items, onClick: handleMenuItemClick } as any}
        trigger={['click']}
        open={isMenuOpen}
        placement="bottomLeft"
        overlayStyle={dropdownOverlayStyle as any}
      >
        <StyledMenuBtn color={btnColor as string} onClick={toggleMenu}>
          {btnContent}
        </StyledMenuBtn>
      </Dropdown>
    </StyledMenuWrapper>
  );
};

export default UserMenu;
