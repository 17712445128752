import { PrmsType } from './components/PrmBody/types';

export const initialValues: PrmsType = {
  prms: [
    {
      name: '',
      id: '',
      prmValue: '',
      status: 'Active',
    },
  ],
};
