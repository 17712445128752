import { Link } from 'react-router-dom';

import { styled } from 'styled-components';

import { COLORS } from 'constants/colors';

export const LinkPrimary = styled(Link)`
  text-align: right;
  color: ${COLORS.Green7};
  font-weight: 600;
  text-decoration: none;
  font-size: 14px;
  margin: 16px 0;
`;
