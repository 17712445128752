import { camelCaseTo18nKey, formatTooltipLabel } from 'utils';

import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { PERIOD } from 'constants/types';

import {
  StyledAmount,
  StyledDate,
  StyledRow,
  StyledType,
  StyledWrapper,
} from './styles';

type TooltipDataType = {
  color: string;
  data: any;
  mappingData: any;
  marker: boolean;
  name: string;
  title: string;
  value: string;
  x: number;
  y: number;
};

interface CustomTooltipProps {
  data: TooltipDataType[];
  period: PERIOD;
}

const CustomFinancialFollowUpTooltip: FC<CustomTooltipProps> = ({
  data,
  period,
}) => {
  const { t } = useTranslation();

  return (
    <StyledWrapper>
      <StyledDate>
        {formatTooltipLabel(
          data[0].data.tooltipData,
          data[0].title,
          period as PERIOD,
          t,
        )}
      </StyledDate>
      <StyledRow>
        {data.map((item, index) => {
          const numberValue = +item.value;
          const type = item.name;
          const toFixedNumberValue =
            numberValue &&
            typeof numberValue === 'number' &&
            numberValue.toFixed(2);

          if (!toFixedNumberValue) {
            return <></>;
          }

          return (
            <StyledAmount key={index}>
              {toFixedNumberValue}
              <StyledType> € - {camelCaseTo18nKey(type, t)}</StyledType>
            </StyledAmount>
          );
        })}
      </StyledRow>
    </StyledWrapper>
  );
};

export default CustomFinancialFollowUpTooltip;
