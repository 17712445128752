import { useTranslation } from 'react-i18next';

import { WidgetTitle } from 'components/communities/Charts/ConsumerSummaryView/styles';
import { formatNumberWithSpace } from 'components/communities/Charts/ElectricityConsumption/utils';
import EmissionsPrevented from 'components/communities/Charts/img/EmissionsPrevented.svg';
import { popoverOverlayStyles } from 'components/communities/Communities/styles';
import Popover from 'components/shared/Popover';
import { SkeletonLine } from 'components/shared/Skeleton';

import {
  WidgetAmount,
  WidgetAmountType,
  WidgetColumn,
  WidgetDescription,
  WidgetImage,
  WidgetImageWrapper,
  WidgetWrapper,
} from '../../styles';

const EMISSION_DEFAULT_COEFFICIENT = 34.2;

const WidgetEmissions = ({
  amount,
  isLoading,
}: {
  amount?: number;
  isLoading: boolean;
}) => {
  const { t } = useTranslation();
  const emissionAmount = amount
    ? formatNumberWithSpace(amount)
    : EMISSION_DEFAULT_COEFFICIENT;
  return (
    <WidgetWrapper>
      <WidgetColumn>
        <WidgetImageWrapper>
          <WidgetImage src={EmissionsPrevented} />
        </WidgetImageWrapper>
        <WidgetTitle>
          {t('emissions_prevented')}
          <Popover
            placement="bottom"
            context={t('community_emissions_prevented_tooltip')}
            overlayStyles={popoverOverlayStyles}
          />
        </WidgetTitle>
        <WidgetAmount>
          {isLoading ? (
            <SkeletonLine active height={32} />
          ) : (
            <>
              {emissionAmount}
              <WidgetAmountType>{amount ? 'kgCO2' : 'gCO2'}</WidgetAmountType>
            </>
          )}
        </WidgetAmount>
        <WidgetDescription>
          {!amount && !isLoading ? t('for_each_kWh_spent') : ''}
        </WidgetDescription>
      </WidgetColumn>
    </WidgetWrapper>
  );
};

export default WidgetEmissions;
