import React from 'react';
import { useTranslation } from 'react-i18next';

import { FieldArray, useFormikContext } from 'formik';

import { PlusOutlined } from 'components/members/createConsumer/ConsumerType/Icons';
import { initialValues } from 'components/members/createConsumer/constants';
import FormikInput from 'components/shared/Input/FormikInput';
import { InputGroup } from 'components/shared/InputGroup';

import { onKeyPressOnlyNumbers } from 'utils/forms';

import ElectricityTypePrice from '../ElectrycityTypePrice';
import { emptyPrmObject } from '../constants';
import {
  StyledAddTitle,
  StyledDeletePrmButton,
  StyledInputsRow,
  StyledPrmFieldWrapper,
} from './styles';

const MAX_FIELDS = 10;

interface IPrmNumbersErrors {
  prmValue: string;
  power: string;
}

const PrmNumber = () => {
  const { errors, values } = useFormikContext<typeof initialValues>();
  const { t } = useTranslation();

  const isMaxFields = values.additionalInfo.prms.length !== MAX_FIELDS;

  return (
    <FieldArray
      name="additionalInfo.prms"
      render={(arrayHelpers) => (
        <>
          {values.additionalInfo.prms &&
            values.additionalInfo.prms.map((number, index) => (
              <React.Fragment key={index}>
                <StyledPrmFieldWrapper>
                  <InputGroup
                    label={`${index + 1} ${t('prm_number')}`}
                    error={
                      errors?.additionalInfo?.prms &&
                      (errors?.additionalInfo?.prms as IPrmNumbersErrors[])?.[
                        index
                      ]?.prmValue
                    }
                  >
                    <FormikInput
                      fieldName={`additionalInfo.prms.${index}.prmValue`}
                      placeholder="Ex. 22516914714270"
                      type="string"
                      onKeyPress={onKeyPressOnlyNumbers}
                    />
                  </InputGroup>
                  {index > 0 && (
                    <StyledDeletePrmButton
                      onClick={() => {
                        arrayHelpers.remove(index);
                      }}
                    >
                      {t('delete_prm_number')}
                    </StyledDeletePrmButton>
                  )}
                </StyledPrmFieldWrapper>
                <StyledInputsRow>
                  <InputGroup
                    error={
                      errors?.additionalInfo?.prms &&
                      (errors?.additionalInfo?.prms as IPrmNumbersErrors[])?.[
                        index
                      ]?.power
                    }
                    label={t('subscripted_power').toString()}
                  >
                    <FormikInput
                      fieldName={`additionalInfo.prms.${index}.power`}
                      placeholder={'Ex. 36 KVA'}
                      type="number"
                      suffix={
                        values?.additionalInfo?.prms[index]?.power ? 'KVA' : ' '
                      }
                    />
                  </InputGroup>
                  <InputGroup label={`${t('given_name')} (${t('optional')})`}>
                    <FormikInput
                      fieldName={`additionalInfo.prms.${index}.name`}
                      placeholder="Ex. Marc Department"
                      type="text"
                    />
                  </InputGroup>
                </StyledInputsRow>

                <ElectricityTypePrice index={index} />

                {index === values.additionalInfo.prms.length - 1 &&
                  isMaxFields && (
                    <StyledAddTitle
                      onClick={() => arrayHelpers.push(emptyPrmObject)}
                    >
                      <PlusOutlined />
                      {t('add_prm_number')}
                    </StyledAddTitle>
                  )}
              </React.Fragment>
            ))}
        </>
      )}
    />
  );
};

export default PrmNumber;
