import { styled } from 'styled-components';

export const CardBody = styled.div`
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
`;

export const CommunityDataRow = styled.div`
  display: flex;
  column-gap: 17px;
  padding: 0px 10px;
`;
