import { TFunction } from 'i18next';

import { LABELS } from 'components/communities/MyCommunity/constants/ElectricityConsumption';
import { COLORS } from 'constants/colors';

export const legendItems = (t: TFunction) => [
  {
    value: LABELS.TOTAL_PRODUCTION,
    name: t('total_production'),
    marker: { symbol: 'square', style: { fill: COLORS.Yellow5 } },
  },
  {
    value: LABELS.SELF_CONSUMPTION,
    name: t('self_consumption'),
    marker: { symbol: 'square', style: { fill: COLORS.Green6 } },
  },
  {
    value: LABELS.OVERPRODUCTION,
    name: t('overproduction'),
    marker: { symbol: 'square', style: { fill: COLORS.TextTertiary } },
  },
];

export const infoItems = (t: TFunction) => [
  {
    title: t('total_production').toString(),
    color: COLORS.Yellow5,
  },
  {
    title: t('self_consumption').toString(),
    color: COLORS.Green6,
  },
  {
    title: t('overproduction').toString(),
    color: COLORS.TextTertiary,
  },
];

export const lineColors = [COLORS.Yellow5, COLORS.Green6, COLORS.TextTertiary];

export const areaColors = ['#FEF08A70', '#BBF7D050', '#F5F5F550'];
