import styled from 'styled-components';

export const MarginTop16px = styled.div`
  margin-top: 16px;
`;

export const MarginTop24px = styled.div`
  margin-top: 24px;
`;

export const MarginTop32px = styled.div`
  margin-top: 32px;
`;

export const MarginTop40px = styled.div`
  margin-top: 40px;
`;

export const MarginTop68px = styled.div`
  margin-top: 68px;
`;

export const MarginTop20vh = styled.div`
  margin-top: 20vh;
`;
