import { styled } from 'styled-components';

import { MailTwoTone } from '@ant-design/icons';

import { COLORS } from 'constants/colors';

export const StyledProfileHeader = styled.div`
  padding: 32px;
  box-sizing: border-box;
  margin: 0 auto;
  width: 1440px;
`;

export const DetailCardRow = styled.div`
  display: flex;
  column-gap: 30px;
  margin-bottom: 10px;
`;

export const StyledProfileRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledProfileTitle = styled.div`
  font-size: 30px;
  font-weight: 600;
  line-height: 1;
`;

export const MailIcon = styled(MailTwoTone).attrs(() => ({
  twoToneColor: COLORS.Green6,
}))`
  margin-right: 5px;
`;

export const EmailContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 2px;
`;

export const StyledMail = styled.span`
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  display: flex;
  align-items: center;
`;

export const StyledTabWrapper = styled.div`
  background-color: ${COLORS.BgLayout};
`;

export const StyledLanguageWrapper = styled.div`
  padding: 0 32px 12px;
  box-sizing: content-box;
  max-width: 1376px;
  margin: 0 auto;
`;

export const StyledWrapper = styled.div<{ hasTopMargin: boolean }>`
  margin-top: ${({ hasTopMargin }) => (hasTopMargin ? '24px' : '0')};
`;

export const StyledLanguageContainer = styled.div`
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  padding: 14px 16px;
  background: ${COLORS.BgContainer};
  justify-content: space-between;
  align-items: center;
`;

export const StyledLanguageTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
`;
