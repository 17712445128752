import { PrmDataList } from './components/PrmBody/types';

export const initialValues: PrmDataList = {
  prms: [
    {
      name: '',
      id: '',
      prmValue: '',
      prmType: '',
      status: '',
      power: null,
    },
  ],
};
