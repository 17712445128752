import React, { useEffect, useRef, useState } from 'react';

import styled from 'styled-components';

import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

import { COLORS } from 'constants/colors';
import { BESANCON_COORDINATES } from 'constants/global';
import {
  CommunitiesPerimeter,
  MapPoint,
  NumberTuple,
  USER_ROLES,
} from 'constants/types';

import Consumer from './img/Consumer.svg';
import Producer from './img/Producer.svg';
import { perimeterLayerStyles } from './styles';

// @ts-ignore
mapboxgl.workerClass =
  // eslint-disable-next-line import/no-webpack-loader-syntax
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN || '';

interface IIMapboxMap {
  isShowOnlyPerimeter?: boolean;
  height?: string;
  coordinates?: number[];
  communitiesCoordinates?: MapPoint[];
  communitiesPerimeter?: CommunitiesPerimeter;
  onDragDropEnd?: (res: NumberTuple) => void;
}

enum MAPBOX_EVENTS {
  MOVE_END = 'moveend',
  DRAG_END = 'dragend',
}

interface MapStyleProps {
  height?: string;
}

const Map = styled.div<MapStyleProps>`
  width: 100%;
  height: ${(props) => (props.height ? props.height : '260px')};
  cursor: pointer;
`;

const MAP_ZOOM_INIT = 11;
const MAP_ZOOM_RESULT = 13;
const ZOOM_SHIFT = 3;
const DURATION_TIME = 2000;
const MAPBOX_MAP_STYLE = 'mapbox://styles/mapbox/streets-v11';
const PERIMETER_LAYER_TYPE = 'fill';
const PERIMETER_GEOMETRY_TYPE = 'Polygon';

enum MAP_EVENTS {
  LOAD = 'load',
}

enum MAP_SOURCE_TYPE {
  GEO_JSON = 'geojson',
}

enum MAP_SOURCE_DATA_TYPE {
  FEATURE = 'Feature',
  FEATURE_COLLECTION = 'FeatureCollection',
}

const MARKER_OPTIONS = {
  draggable: true,
  color: COLORS.Green6,
};

const NAVIGATION_CONTROL_OPTIONS = {
  showCompass: false,
};
const NAVIGATION_CONTROL_POSITION = 'bottom-right';

export const isValidCoordinate = (coordinate: number | null) => {
  const AVAILABLE_MIN_COORDINATE = -90;
  const AVAILABLE_MAX_COORDINATE = 90;

  return !(
    coordinate === null ||
    coordinate < AVAILABLE_MIN_COORDINATE ||
    coordinate > AVAILABLE_MAX_COORDINATE
  );
};

const MapboxMap: React.FC<IIMapboxMap> = ({
  isShowOnlyPerimeter,
  height,
  coordinates = [BESANCON_COORDINATES.latitude, BESANCON_COORDINATES.longitude],
  communitiesCoordinates,
  communitiesPerimeter,
  onDragDropEnd,
}: IIMapboxMap) => {
  const [zoom, setZoom] = useState(MAP_ZOOM_INIT);

  const mapContainerRef = useRef<HTMLDivElement>(null);
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const markerRef = useRef<mapboxgl.Marker | null>(null);

  const [latitude, longitude] = coordinates;

  const handleOnDragEnd = () => {
    const lngLat = markerRef.current?.getLngLat();

    mapRef.current?.flyTo({
      center: lngLat,
      duration: DURATION_TIME,
      easing: (time) => time,
      zoom: MAP_ZOOM_RESULT,
    });

    mapRef.current?.once(MAPBOX_EVENTS.MOVE_END, () => {
      if (onDragDropEnd) {
        onDragDropEnd([lngLat?.lat!, lngLat?.lng!]);
      }
      setZoom(MAP_ZOOM_RESULT + ZOOM_SHIFT);
    });
  };

  useEffect(() => {
    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current!,
      style: MAPBOX_MAP_STYLE,
      center: [longitude, latitude],
      zoom,
    });

    mapRef.current?.addControl(
      new mapboxgl.NavigationControl(NAVIGATION_CONTROL_OPTIONS),
      NAVIGATION_CONTROL_POSITION,
    );

    if (communitiesPerimeter) {
      mapRef.current?.on(MAP_EVENTS.LOAD, () => {
        communitiesPerimeter.forEach((communityPerimeter) => {
          mapRef.current?.addSource(communityPerimeter.id, {
            type: MAP_SOURCE_TYPE.GEO_JSON,
            data: {
              type: MAP_SOURCE_DATA_TYPE.FEATURE_COLLECTION,
              features: [
                {
                  type: MAP_SOURCE_DATA_TYPE.FEATURE,
                  properties: {
                    name: communityPerimeter.id,
                    'name:en': 'Province',
                    population: 1,
                    'sq-km': 1,
                  },
                  geometry: {
                    type: PERIMETER_GEOMETRY_TYPE,
                    coordinates: [communityPerimeter.coordinates],
                  },
                  id: communityPerimeter.id,
                },
              ],
            },
          });

          mapRef.current?.addLayer({
            id: communityPerimeter.id,
            type: PERIMETER_LAYER_TYPE,
            source: communityPerimeter.id,
            paint: perimeterLayerStyles,
          });
        });
      });

      if (isShowOnlyPerimeter) {
        return () => {
          markerRef.current?.remove();
          mapRef.current?.remove();
        };
      }
    }

    if (communitiesCoordinates) {
      if (communitiesCoordinates.length > 0) {
        communitiesCoordinates.forEach((point) => {
          const el = document.createElement('div');
          const markerIcon =
            point.type === USER_ROLES.PRODUCER ? Producer : Consumer;

          el.className = 'marker';
          el.style.backgroundImage = `url(${markerIcon})`;
          el.style.width = '40px';
          el.style.height = '40px';

          new mapboxgl.Marker(el)
            .setLngLat([point.longitude, point.latitude])
            .setPopup(
              new mapboxgl.Popup({
                offset: 0,
                closeButton: false,
                className: 'custom-popup',
              }).setHTML(`<p>${point.title}</p><h4>${point.location}</h4>`),
            )
            .addTo(mapRef.current!);
        });

        const bounds = communitiesCoordinates.reduce(
          (acc, point) => acc.extend([point.longitude, point.latitude]),
          new mapboxgl.LngLatBounds([
            communitiesCoordinates[0].longitude,
            communitiesCoordinates[0].latitude,
            communitiesCoordinates[0].longitude,
            communitiesCoordinates[0].latitude,
          ]),
        );

        mapRef.current.fitBounds(bounds, {
          padding: 50,
          maxZoom: 16,
        });
      }
      return () => {
        markerRef.current?.remove();
        mapRef.current?.remove();
      };
    }

    markerRef.current = new mapboxgl.Marker(MARKER_OPTIONS)
      .setLngLat([longitude, latitude])
      .addTo(mapRef.current);

    markerRef.current?.on(MAPBOX_EVENTS.DRAG_END, handleOnDragEnd);

    return () => {
      markerRef.current?.remove();
      mapRef.current?.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isValidCoordinate(latitude)) return;
    if (!isValidCoordinate(longitude)) return;

    if (!mapRef.current) return;
    if (communitiesCoordinates) return;

    mapRef.current.flyTo({
      center: [longitude, latitude],
      duration: DURATION_TIME,
      easing: (time) => time,
      zoom: MAP_ZOOM_RESULT,
    });

    if (markerRef.current) {
      markerRef.current.remove();
    }

    markerRef.current = new mapboxgl.Marker(MARKER_OPTIONS)
      .setLngLat([longitude, latitude])
      .addTo(mapRef.current!);

    markerRef.current?.on(MAPBOX_EVENTS.DRAG_END, handleOnDragEnd);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [longitude, latitude]);

  return <Map ref={mapContainerRef} height={height} />;
};
export default MapboxMap;
