import { useTranslation } from 'react-i18next';

import { Field, FieldProps } from 'formik';

import { InputGroup } from '../InputGroup';
import { StyledCheckbox } from './styles';

interface ICheckboxProps {
  isError: boolean;
  name: string;
  label: string;
  errorText?: string;
}

const Checkbox: React.FC<ICheckboxProps> = ({
  isError,
  name,
  label,
  errorText,
}) => {
  const { t } = useTranslation();

  const getErrorText = () => {
    if (errorText && isError) return errorText;
    if (isError) return t('validation.is_checkbox_signed').toString();
    return '';
  };

  return (
    <InputGroup error={getErrorText()}>
      <Field name={name}>
        {({ field }: FieldProps) => (
          <StyledCheckbox checked={field.value} {...field} isError={isError}>
            {label}
          </StyledCheckbox>
        )}
      </Field>
    </InputGroup>
  );
};

export default Checkbox;
