import { styled } from 'styled-components';

import { COLORS } from 'constants/colors';

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;

  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  background: ${COLORS.BgContainer};
  margin-bottom: 20px;
`;

export const CardBody = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: space-between;
  row-gap: 50px;
  align-items: center;
  margin-top: 34px;
`;

export const StyledInfoRow = styled.div<{ isConsumer?: boolean }>`
  display: flex;
  flex-direction: column;
  row-gap: ${(props) => (props.isConsumer ? '40px' : '20px')};
  padding-left: 15px;
  margin-bottom: 40px;
`;

export const StyledInfoBlock = styled.div<{ borderColor?: string }>`
  display: flex;
  flex-direction: column;
  align-items: start;
  border-left: 4px solid ${(props) => props.borderColor};
  height: 60px;
  padding-left: 6px;
`;

export const StyledInfoTitle = styled.div`
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 3px;
`;

export const StyledInfoPower = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
`;

export const StyledInfoKw = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.65);
`;
