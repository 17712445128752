import dayjs from 'dayjs';

import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Radio } from 'antd';
import engLocale from 'antd/es/date-picker/locale/en_US';
import frLocale from 'antd/es/date-picker/locale/fr_FR';

import { COLORS } from 'constants/colors';
import {
  DATE_FORMAT_TYPE,
  MONTH_FORMAT_TYPE,
  SUPPORTED_LANGUAGES,
} from 'constants/global';

import { useAppDispatch } from 'utils/hooks';

import { datePickerRadioItems, predefinedRanges } from '../../constants';
import { setDates as setReportDatePickerDates } from '../../slice';
import {
  StyledApplyButton,
  StyledButtonsRow,
  StyledCancelButton,
  StyledDatePicker,
  StyledDatePickerWrapper,
  StyledDatePickersRow,
  StyledDivider,
  StyledRadio,
  StyledRadioRow,
  StyledRow,
  StyledWrapper,
} from '../../styles';
import { DATE_PICKER_STATE, DatePickerType } from '../../types';
import 'dayjs/locale/en';
import 'dayjs/locale/fr';

interface CustomDatePickerContentProps {
  type?: 'month' | 'date';
  setOpen: (open: boolean) => void;
}

const DatePickerContent: FC<CustomDatePickerContentProps> = ({
  type,
  setOpen,
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const [dates, setDates] = useState<DatePickerType | any>(
    predefinedRanges[DATE_PICKER_STATE.CURRENT_YEAR],
  );
  const [period, setPeriod] = useState(DATE_PICKER_STATE.CURRENT_YEAR);

  const locale = useMemo(() => {
    if (i18n.language === SUPPORTED_LANGUAGES.English) return engLocale;
    return frLocale;
  }, [i18n.language]);

  const handlePeriodChange = useCallback(
    (e: any) => {
      const newPeriod = e.target.value;
      setPeriod(newPeriod);

      if (newPeriod !== DATE_PICKER_STATE.CUSTOM) {
        const range = predefinedRanges[newPeriod as DATE_PICKER_STATE];
        const format = type === 'month' ? MONTH_FORMAT_TYPE : DATE_FORMAT_TYPE;
        const start = dayjs(range.start).format(format);
        const end = dayjs(range.end).format(format);
        const label =
          newPeriod !== DATE_PICKER_STATE.CUSTOM
            ? newPeriod
            : `${start} - ${end}`;

        dispatch(
          setReportDatePickerDates({
            start,
            end,
            label,
          }),
        );
        return setDates(range);
      }
      setDates([null, null]);
    },
    [type],
  );

  const handleRangeChange = (range: any) => {
    if (range) {
      setDates({
        start: dayjs(range[0]).toDate(),
        end: dayjs(range[1]).toDate(),
      });
    }
  };

  const handleApply = useCallback(() => {
    const start =
      type === 'month'
        ? dayjs(dates.start).startOf('month').format(DATE_FORMAT_TYPE)
        : dayjs(dates.start).format(DATE_FORMAT_TYPE);
    const end =
      type === 'month'
        ? dayjs(dates.end).endOf('month').format(DATE_FORMAT_TYPE)
        : dayjs(dates.end).format(DATE_FORMAT_TYPE);
    let labelStart = start;
    let labelEnd = end;

    if (type === 'month') {
      labelStart = dayjs(start).format(MONTH_FORMAT_TYPE);
      labelEnd = dayjs(end).format(MONTH_FORMAT_TYPE);
    }
    const label =
      period !== DATE_PICKER_STATE.CUSTOM
        ? period
        : `${labelStart} - ${labelEnd}`;

    dispatch(
      setReportDatePickerDates({
        start,
        end,
        label,
      }),
    );
    setOpen(false);
  }, [period, type, dates]);

  const handleCancel = () => {
    setDates(predefinedRanges[DATE_PICKER_STATE.LAST_SIX_MONTH]);
    setOpen(false);
  };

  const disabledDate = (current: any) => {
    return current && current > dayjs().endOf('day');
  };

  useEffect(() => {
    setDefaultDate();
  }, [pathname]);

  const setDefaultDate = () => {
    setDates(predefinedRanges[DATE_PICKER_STATE.CURRENT_YEAR]);
    setPeriod(DATE_PICKER_STATE.CURRENT_YEAR);
  };

  return (
    <>
      <StyledWrapper>
        <StyledRow>
          <Radio.Group
            onChange={handlePeriodChange}
            value={period}
            buttonStyle="solid"
          >
            <StyledRadioRow>
              {datePickerRadioItems(t).map((item) => (
                <StyledRadio value={item.value}>{item.label}</StyledRadio>
              ))}
              <StyledDivider />
              <StyledRadio value={DATE_PICKER_STATE.CUSTOM}>
                {t('custom_period')}
              </StyledRadio>
            </StyledRadioRow>
          </Radio.Group>
          <StyledDatePickersRow>
            <StyledDatePickerWrapper>
              <StyledDatePicker
                getPopupContainer={(node: any) => node.parentNode}
                inputReadOnly={true}
                locale={locale}
                separator={<>–</>}
                popupClassName={'date-picker-popup'}
                rootClassName={'date-picker-input'}
                picker={period !== DATE_PICKER_STATE.CUSTOM ? 'month' : type}
                open={true}
                renderExtraFooter={() => (
                  <>
                    <StyledButtonsRow>
                      <StyledCancelButton
                        color={COLORS.TextBase}
                        onClick={handleCancel}
                      >
                        {t('cancel')}
                      </StyledCancelButton>
                      <StyledApplyButton onClick={handleApply}>
                        {t('apply')}
                      </StyledApplyButton>
                    </StyledButtonsRow>
                  </>
                )}
                variant="outlined"
                value={[dayjs(dates.start), dayjs(dates.end)]}
                onChange={handleRangeChange}
                format={type === 'month' ? MONTH_FORMAT_TYPE : DATE_FORMAT_TYPE}
                disabledDate={disabledDate}
              />
            </StyledDatePickerWrapper>
          </StyledDatePickersRow>
        </StyledRow>
      </StyledWrapper>
    </>
  );
};

export default DatePickerContent;
