import { styled } from 'styled-components';

import { COLORS } from 'constants/colors';

export const StlyedTabWrapper = styled.div`
  background-color: ${COLORS.BgLayout};
`;

export const StlyedBtnWrapper = styled.div`
  min-width: 209px;
  max-width: 249px;
`;
