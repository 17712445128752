import * as yup from 'yup';

import { TFunction } from 'i18next';

import { taxPriceSchema } from 'components/pricing/components/TaxPrices/tax-prices.schema';

const taxPriceRangeTypeSchema = (t: TFunction) =>
  yup.object().shape({
    RH: taxPriceSchema(t),
    HS_HH: taxPriceSchema(t),
    HS_LH: taxPriceSchema(t),
    LS_HH: taxPriceSchema(t),
    LS_LH: taxPriceSchema(t),
  });

export const taxTurpePricesSchema = (t: TFunction) =>
  yup.object().shape({
    startDate: yup
      .date()
      .required(t('field_is_required').toString())
      .min(new Date(), t('end_date_before_start_date').toString()),
    endDate: yup
      .date()
      .required(t('field_is_required').toString())
      .min(yup.ref('startDate'), t('end_date_after_start_date').toString()),
    turpePerMWh: yup.object().shape({
      large: taxPriceRangeTypeSchema(t),
      small: taxPriceRangeTypeSchema(t),
      private: taxPriceRangeTypeSchema(t),
      medium: taxPriceRangeTypeSchema(t),
    }),
  });
