import { Select } from 'antd';

import { SUPPORTED_LANGUAGES } from 'constants/global';

import { useAppLanguage } from 'utils/hooks';

import Flag from './Flag';
import { StyledSelect } from './styles';

const LanguageSelect: React.FC = () => {
  const { lang, changeLanguage } = useAppLanguage();

  return (
    <StyledSelect
      value={lang}
      onChange={changeLanguage as (value: unknown) => void}
    >
      {Object.keys(SUPPORTED_LANGUAGES).map((langKey) => (
        <Select.Option
          key={langKey}
          value={
            SUPPORTED_LANGUAGES[langKey as keyof typeof SUPPORTED_LANGUAGES]
          }
        >
          {langKey}{' '}
          <Flag
            country={
              SUPPORTED_LANGUAGES[langKey as keyof typeof SUPPORTED_LANGUAGES]
            }
          />
        </Select.Option>
      ))}
    </StyledSelect>
  );
};

export default LanguageSelect;
