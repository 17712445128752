import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Loader from 'components/shared/Loader';
import Modal from 'components/shared/Modal';
import {
  useAddUpcommingTaxPricesMutation,
  useDeleteUpcommingTaxPricesByIdMutation,
  useGetTaxPricesQuery,
  useUpdateUpcommingTaxPricesMutation,
} from 'services/taxPrices.service';
import { IDBTaxPrices } from 'services/taxPrices.types';

import { PriceType } from '../../types';
import ModifyAccisePrices from '../AccisePrices/components/ModifyAccisePrices';
import ModifyDiscountedAccisePrices from '../DiscountedAccisePrices/components/ModifyDiscountedAccisePrices';
import ModifyIndexation from '../Indexation/components/ModifyIndexation';
import ModifyPrimePrices from '../PrimePrices/components/ModifyPrimePrices';
import ModifyTurpePrices from '../TurpePrices/components/ModifyTurpePrices';

type IModifyPrices = {
  isModalVisible: boolean;
  priceType: PriceType;
  taxPrices: IDBTaxPrices | undefined;
  onModalVisibleChange: (isVisible: boolean) => void;
};

const ModifyPrices = ({
  isModalVisible,
  priceType,
  taxPrices,
  onModalVisibleChange,
}: IModifyPrices) => {
  const { t } = useTranslation();
  const [
    addUpcommingTaxPrices,
    { isLoading: isAddTaxPricesInProgress, isSuccess: isAddTaxPricesSuccess },
  ] = useAddUpcommingTaxPricesMutation();
  const [
    updateUpcommingTaxPrices,
    {
      isLoading: isUpdateTaxPricesInProgress,
      isSuccess: isUpdateTaxPricesSuccess,
    },
  ] = useUpdateUpcommingTaxPricesMutation();
  const [
    deleteUpcommingTaxPricesById,
    {
      isLoading: isDeleteTaxPricesInProgress,
      isSuccess: isDeleteTaxPricesSuccess,
    },
  ] = useDeleteUpcommingTaxPricesByIdMutation();
  const { refetch: refetchTaxPrices } = useGetTaxPricesQuery({});

  useEffect(() => {
    if (
      isAddTaxPricesSuccess ||
      isUpdateTaxPricesSuccess ||
      isDeleteTaxPricesSuccess
    ) {
      onModalVisibleChange(false);
      refetchTaxPrices();
    }
  }, [
    isAddTaxPricesSuccess,
    isUpdateTaxPricesSuccess,
    isDeleteTaxPricesSuccess,
  ]);

  const handleOnClose = () => {
    onModalVisibleChange(false);
  };

  const handleOnDelete = (id: string) => {
    deleteUpcommingTaxPricesById({ id });
  };

  const handleOnSave = (values: IDBTaxPrices) => {
    if (values._id) {
      updateUpcommingTaxPrices(values);
      return;
    }

    addUpcommingTaxPrices(values);
  };

  const isLoading =
    isAddTaxPricesInProgress ||
    isUpdateTaxPricesInProgress ||
    isDeleteTaxPricesInProgress;

  const getModalBodyComponent = () => {
    switch (priceType) {
      case PriceType.INDEX: {
        return (
          <ModifyIndexation
            taxPrices={taxPrices}
            onClose={handleOnClose}
            onDelete={handleOnDelete}
            onSave={handleOnSave}
          />
        );
      }

      case PriceType.TURPE: {
        return (
          <ModifyTurpePrices
            taxPrices={taxPrices}
            onClose={handleOnClose}
            onDelete={handleOnDelete}
            onSave={handleOnSave}
          />
        );
      }

      case PriceType.ACCISE: {
        return (
          <ModifyAccisePrices
            taxPrices={taxPrices}
            onClose={handleOnClose}
            onDelete={handleOnDelete}
            onSave={handleOnSave}
          />
        );
      }

      case PriceType.DISCOUNTED_ACCISE: {
        return (
          <ModifyDiscountedAccisePrices
            taxPrices={taxPrices}
            onClose={handleOnClose}
            onDelete={handleOnDelete}
            onSave={handleOnSave}
          />
        );
      }

      case PriceType.PRIME: {
        return (
          <ModifyPrimePrices
            taxPrices={taxPrices}
            onClose={handleOnClose}
            onDelete={handleOnDelete}
            onSave={handleOnSave}
          />
        );
      }
    }
  };

  const getModalTitle = () => {
    const isEditMode = taxPrices !== undefined;

    switch (priceType) {
      case PriceType.INDEX: {
        const key = isEditMode ? 'edit_index' : 'add_new_index';

        return t(key);
      }

      case PriceType.DISCOUNTED_ACCISE: {
        const key = isEditMode ? 'edit_tax_prices' : 'add_new_tax_prices';

        return t(key, {
          priceType: t('discounted_accise').toUpperCase(),
        });
      }

      default: {
        const key = isEditMode ? 'edit_tax_prices' : 'add_new_tax_prices';

        return t(key, { priceType });
      }
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <Modal
        isVisible={isModalVisible}
        title={getModalTitle()}
        onClose={handleOnClose}
      >
        {getModalBodyComponent()}
      </Modal>
    </>
  );
};

export default ModifyPrices;
