import { styled } from 'styled-components';

import { CopyrightOutlined, HomeOutlined } from '@ant-design/icons';

import { COLORS } from 'constants/colors';

export const CommunityIcon = styled(CopyrightOutlined)`
  color: ${COLORS.Green6};
  width: 14px;
  height: 14px;
  margin-right: 6px;
`;

export const HomeIcon = styled(HomeOutlined)`
  color: ${COLORS.Green6};
  width: 14px;
  height: 14px;
  margin-right: 6px;
`;
