import dayjs from 'dayjs';

import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { TextRegular } from 'components/shared/Text';
import CustomTooltip from 'components/shared/Tooltip';
import { COLORS } from 'constants/colors';
import { INDEXATION } from 'services/types';
import {
  EditIcon,
  FileIcon,
  StyledCalendarIcon,
  StyledEditButton,
  StyledHistoricalPriceConatiner,
  StyledIndexIcon,
  StyledPriceContainer,
  StyledPriceValue,
  StyledPricesTitle,
  StyledPricesTitleContainer,
  StyledUpcomingPriceLeftCol,
  StyledUpcomingPriceRow,
  StyledUpcomingPriceWrapper,
  StyledUpcomingPricesContainer,
  customTooltipStyles,
  StyledContractInfoCol,
  StyledUpcomingPriceInfoCol,
  StyledUpcomingPriceItemCol,
  StyledUpcomingEditCol,
} from './styles';

interface UpcomingPricesProps {
  prmPrices: any;
  onEdit: (values: any) => void;
}

const UpcomingPrices: FC<UpcomingPricesProps> = ({ prmPrices, onEdit }) => {
  const { t } = useTranslation();

  const onEditPrmPrice = useCallback(
    ({
      indexation,
      startDate,
      endDate,
      communityId,
      cppa,
      prmPriceId,
      prmId,
      consumerId,
      scheduleTypes,
      periodId,
    }: any) => {
      onEdit({
        indexation,
        startDate,
        endDate,
        communityId,
        cppa,
        prmPriceId,
        prmId,
        periodId,
        consumerId,
        ...scheduleTypes,
      });
    },
    [],
  );

  return (
    <StyledUpcomingPricesContainer>
      <StyledUpcomingPriceRow>
        <StyledContractInfoCol>
          <StyledPricesTitleContainer>
            <StyledPricesTitle color={COLORS.Green7}>
              {t('upcoming_prices')}
            </StyledPricesTitle>
            <CustomTooltip
              tooltip={
                <TextRegular>{t('upcoming_prices_tooltip')}</TextRegular>
              }
              iconSize={16}
              wrapperStyles={customTooltipStyles}
              containerStyles={customTooltipStyles}
            />
          </StyledPricesTitleContainer>
        </StyledContractInfoCol>
      </StyledUpcomingPriceRow>
      {Object.keys(prmPrices)
        .sort((a, b) =>
          dayjs(prmPrices[a].startDate).isBefore(dayjs(prmPrices[b].startDate))
            ? -1
            : 1,
        )
        .map((cppa) => {
          const prmPrice = prmPrices[cppa.toString()];
          return (
            <StyledUpcomingPriceRow key={cppa}>
              <StyledUpcomingPriceLeftCol>
                {/* CPPA info should be displayed only for the new CPPA contracts with prices */}
                {prmPrice.consumerId && [
                  <div>
                    <FileIcon />
                    <span>{cppa}</span>
                  </div>,
                  <div>
                    <StyledCalendarIcon />
                    <span>
                      {`${dayjs(prmPrice.startDate).format('DD.MM.YYYY')} - ${dayjs(
                        prmPrice.endDate,
                      ).format('DD.MM.YYYY')}`}
                    </span>
                  </div>,
                ]}
              </StyledUpcomingPriceLeftCol>
              <StyledUpcomingPriceWrapper>
                {prmPrice.periods.map(
                  ({ scheduleTypes, startDate, endDate, index, _id }: any) => {
                    const contractIndexation = prmPrice.consumerId
                      ? prmPrice.indexation === INDEXATION.NO
                        ? '-'
                        : '1'
                      : index;
                    return (
                      <StyledHistoricalPriceConatiner key={_id}>
                        <StyledUpcomingPriceInfoCol>
                          <div>
                            <StyledCalendarIcon />
                            <span>
                              {`${dayjs(startDate).format(
                                'DD.MM.YYYY',
                              )} - ${dayjs(endDate).format('DD.MM.YYYY')}`}
                            </span>
                          </div>
                          <div>
                            <StyledIndexIcon />
                            <span>{contractIndexation}</span>
                          </div>
                        </StyledUpcomingPriceInfoCol>
                        <StyledUpcomingPriceItemCol>
                          <StyledPriceContainer>
                            <StyledPriceValue>
                              {scheduleTypes.HS_HH}
                            </StyledPriceValue>
                          </StyledPriceContainer>
                        </StyledUpcomingPriceItemCol>
                        <StyledUpcomingPriceItemCol>
                          <StyledPriceContainer>
                            <StyledPriceValue>
                              {scheduleTypes.HS_LH}
                            </StyledPriceValue>
                          </StyledPriceContainer>
                        </StyledUpcomingPriceItemCol>
                        <StyledUpcomingPriceItemCol>
                          <StyledPriceContainer>
                            <StyledPriceValue>
                              {scheduleTypes.LS_HH}
                            </StyledPriceValue>
                          </StyledPriceContainer>
                        </StyledUpcomingPriceItemCol>
                        <StyledUpcomingPriceItemCol>
                          <StyledPriceContainer>
                            <StyledPriceValue>
                              {scheduleTypes.LS_LH}
                            </StyledPriceValue>
                          </StyledPriceContainer>
                        </StyledUpcomingPriceItemCol>
                        <StyledUpcomingPriceItemCol>
                          <StyledPriceContainer>
                            <StyledPriceValue>
                              {scheduleTypes.RH}
                            </StyledPriceValue>
                          </StyledPriceContainer>
                        </StyledUpcomingPriceItemCol>
                        <StyledUpcomingEditCol>
                          {prmPrice.consumerId && (
                            <StyledEditButton
                              type="link"
                              onClick={() =>
                                onEditPrmPrice({
                                  indexation: prmPrice.indexation,
                                  startDate,
                                  endDate,
                                  communityId: prmPrice.communityId,
                                  cppa,
                                  prmPriceId: prmPrice.prmPriceId,
                                  prmId: prmPrice.prmId,
                                  periodId: _id,
                                  consumerId: prmPrice.consumerId,
                                  scheduleTypes,
                                })
                              }
                            >
                              <EditIcon />
                              {t('edit')}
                            </StyledEditButton>
                          )}
                        </StyledUpcomingEditCol>
                      </StyledHistoricalPriceConatiner>
                    );
                  },
                )}
              </StyledUpcomingPriceWrapper>
            </StyledUpcomingPriceRow>
          );
        })}
    </StyledUpcomingPricesContainer>
  );
};

export default UpcomingPrices;
