import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useFormikContext } from 'formik';

import { FormInput } from 'components/shared/Input';
import { InputGroup } from 'components/shared/InputGroup';

import { useDebounce } from 'utils/hooks';

import { initialValues } from '../../constants';

const FullNameForm: React.FC = () => {
  const { t } = useTranslation();
  const { errors, setFieldValue } = useFormikContext<typeof initialValues>();
  const [fullName, setFullName] = useState('');

  const isError = !!(errors.pmo?.firstName || errors.pmo?.lastName);

  const handleFullNameChange = useCallback(
    async (fullName: string) => {
      const fullNameArr = fullName.trim().split(' ');

      const firstName = fullNameArr[0] || '';
      const lastName =
        fullNameArr.length > 1 ? fullNameArr[fullNameArr.length - 1] : '';
      const middleName =
        fullNameArr.length > 2
          ? fullNameArr
              .slice(1, fullNameArr.length - 1)
              .join(' ')
              .trim()
          : '';

      await setFieldValue('pmo.firstName', firstName);
      await setFieldValue('pmo.lastName', lastName);
      setFieldValue('pmo.middleName', middleName);
    },
    [setFieldValue],
  );

  const debouncedFullName = useDebounce(fullName, 100);

  useEffect(() => {
    handleFullNameChange(debouncedFullName);
  }, [debouncedFullName, handleFullNameChange]);

  return (
    <InputGroup
      label={t('legal_representative_label').toString()}
      error={errors.pmo?.firstName || errors.pmo?.lastName}
    >
      <FormInput
        type="text"
        placeholder={t('legal_representative_placeholder').toString()}
        isError={isError}
        onChange={(e) => setFullName(e.target.value)}
      />
    </InputGroup>
  );
};

export default FullNameForm;
