import axios, { AxiosError, AxiosRequestConfig } from 'axios';

import { BaseQueryFn, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { LOCAL_STORAGE_KEYS } from 'utils/local-storage';

export const axiosBaseQuery =
  (
    {
      baseUrl,
      headers,
    }: {
      baseUrl: string | undefined;
      headers?: { [key: string]: string };
    } = { baseUrl: '' },
  ): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig['method'];
      data?: AxiosRequestConfig['data'];
      headers?: AxiosRequestConfig['headers'];
      params?: AxiosRequestConfig['params'];
    },
    unknown,
    unknown
  > =>
  async ({ url, ...rest }) => {
    try {
      const result = await axios({
        url: baseUrl + url,
        headers: {
          ...headers,
          ...rest.headers,
        },
        ...rest,
      });
      return { data: result.data };
    } catch (axiosError) {
      let err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BACKEND_URL,
  prepareHeaders: (headers) => {
    headers.set('Content-Type', 'application/json');
    headers.set(
      'Authorization',
      `Bearer ${localStorage.getItem(LOCAL_STORAGE_KEYS.AccessToken)}`,
    );
    return headers;
  },
});
