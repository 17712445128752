import { styled } from 'styled-components';
import { ScreenWSVGA } from 'constants/screen-breakpoints';
import { Tabs } from 'antd';

export const StyledTabs = styled(Tabs)`
  ${ScreenWSVGA`
    .ant-tabs-tab-btn {
      font-size: 14px;
    }
  `}
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
`;
