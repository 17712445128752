import styled from 'styled-components';

import { Dropdown } from 'antd';

import { CalendarTwoTone } from '@ant-design/icons';

import { COLORS } from 'constants/colors';

export const Container = styled.div`
  justify-content: flex-end;
  margin-left: 8px;
`;

export const CalendarIcon = styled(CalendarTwoTone).attrs(() => ({
  twoToneColor: COLORS.Green7,
}))`
  font-size: 16px;
`;

export const SelectPeriod = styled(Dropdown.Button)`
  display: flex;
  height: 32px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
