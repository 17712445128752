import { TFunction } from 'i18next';

import { CONSUMER_INVOICE_STATUSES } from 'constants/types';

export const statusFilters = (t: TFunction) => [
  {
    text: t('paid'),
    value: CONSUMER_INVOICE_STATUSES.PAID,
  },
  {
    text: t('overdue'),
    value: CONSUMER_INVOICE_STATUSES.OVERDUE,
  },
  {
    text: t('payment_error'),
    value: CONSUMER_INVOICE_STATUSES.PAYMENT_ERROR,
  },
  {
    text: t('issued'),
    value: CONSUMER_INVOICE_STATUSES.ISSUED,
  },
  {
    text: t('awaiting_payment'),
    value: CONSUMER_INVOICE_STATUSES.AWAITING_PAYMENT,
  },
];
