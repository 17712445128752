import { downloadFile } from 'utils';

import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getCsvCommunityReport } from 'components/communities/Reports/components/FinancialDataTab/utils';
import { BtnPrimary } from 'components/shared/Button';
import CustomDatePicker from 'components/shared/DatePicker';
import FullScreenLoader from 'components/shared/Loader/FullScreenLoader';
import ModalMessage from 'components/shared/ModalMessage';
import { TextRegular } from 'components/shared/Text';
import { API_URLS } from 'constants/global';
import { CustomSelectItem } from 'constants/types';

import { useAppSelector } from 'utils/hooks';

import CustomCheckboxSelect from '../CustomCheckboxSelect';
import {
  StyledReportButton,
  StyledReportCheckboxRow,
  StyledReportWrapper,
} from './styles';

interface IReport {
  fileName: string;
  data: Blob | string;
}

interface IProps {
  consumers?: CustomSelectItem[];
  datePickerType?: 'month' | 'date';
}

export const TOTAL_PRODUCTION = 'total_production';
export const SELF_CONSUMPTION = 'self_consumption';

const CreateReportFinancialData: FC<IProps> = ({
  consumers,
  datePickerType,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [report, setReport] = useState<IReport | null>({
    fileName: '',
    data: '',
  });
  const [selectedConsumersValues, setSelectedConsumerValues] = useState<
    CustomSelectItem[]
  >([]);
  const reportDatePickerDates = useAppSelector(
    (state) => state.reportDatePicker.dates,
  );

  const onCreateReport = async () => {
    try {
      setIsLoading(true);
      const selectedConsumerIds = selectedConsumersValues.map(
        (item) => item.key,
      );
      const { fileName, data }: any = await getCsvCommunityReport({
        dates: {
          startDate: reportDatePickerDates.start,
          endDate: reportDatePickerDates.end,
        },
        consumerIds: selectedConsumerIds,
        apiUrl: API_URLS.GET_FINANCIAL_REPORTS,
      });

      if (data || fileName) {
        setReport({ fileName, data });
      }

      setIsLoading(false);
      setIsModalOpen(true);
    } catch (error) {
      setIsLoading(false);
      setIsModalOpen(true);
    }
  };

  const handleButtonClick = () => {
    setIsModalOpen(false);
    downloadFile(report?.data, report?.fileName);
  };

  return (
    <StyledReportWrapper>
      <CustomDatePicker type={datePickerType} />
      <StyledReportCheckboxRow>
        <TextRegular>{t('consumer')}</TextRegular>
        <CustomCheckboxSelect
          items={consumers ? consumers : []}
          onChange={setSelectedConsumerValues}
        />
      </StyledReportCheckboxRow>
      <StyledReportButton>
        <BtnPrimary onClick={onCreateReport}>{t('create_report')}</BtnPrimary>
      </StyledReportButton>
      {isLoading && <FullScreenLoader />}
      {isModalOpen && (
        <ModalMessage
          messageType="info"
          title={''}
          subTitle={t('notification_report')}
          btnText={t('ok')}
          handleBtn={handleButtonClick}
        />
      )}
    </StyledReportWrapper>
  );
};

export default CreateReportFinancialData;
