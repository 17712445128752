import styled from 'styled-components';

import { COLORS } from 'constants/colors';

export const StyledPageWrapper = styled.div`
  width: 100%;
  background-color: ${COLORS.BgLayout};
  display: flex;
  justify-content: center;
`;
