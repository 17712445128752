const ArrowImage = ({ color }: { color: string }) => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.86659 5.45748L5.32889 0.22799C5.28787 0.18067 5.23716 0.14272 5.1802 0.11671C5.12323 0.0907009 5.06134 0.07724 4.99872 0.07724C4.93609 0.07724 4.8742 0.0907009 4.81723 0.11671C4.76027 0.14272 4.70956 0.18067 4.66854 0.22799L0.132211 5.45748C0.118504 5.47325 0.109615 5.49262 0.106606 5.51329C0.103596 5.53396 0.106592 5.55506 0.115236 5.57408C0.12388 5.59309 0.137808 5.60922 0.155361 5.62055C0.172915 5.63187 0.193353 5.63791 0.214243 5.63795H1.32166C1.38456 5.63795 1.44471 5.61061 1.48709 5.56276L4.47987 2.11334V9.81197C4.47987 9.87213 4.52909 9.92135 4.58924 9.92135H5.40956C5.46971 9.92135 5.51893 9.87213 5.51893 9.81197V2.11334L8.5117 5.56276C8.55272 5.61061 8.61288 5.63795 8.67713 5.63795H9.78456C9.87753 5.63795 9.92811 5.52858 9.86659 5.45748Z"
        fill={color}
      />
    </svg>
  );
};

export default ArrowImage;
