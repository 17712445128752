import React, { ChangeEvent, FocusEvent, useEffect, useState } from 'react';

import { FormInput } from 'components/shared/Input';
import { useGetSuggestionsQuery } from 'services/geocoder';

import {
  Container,
  Suggestions,
  SuggestionsList,
  SuggestionsListItem,
} from './styles';
import { ADDRESS_TYPE, AutocompleteResponse, Suggestion } from './types';
import {
  getListItemPresentable,
  getPostcodePresentable,
  getTextPresentable,
} from './utils';

interface IAddressAutocomplete {
  isError?: boolean;
  value?: any;
  types?: ADDRESS_TYPE;
  placeholder?: string;
  inputType?: string;
  onSelect: (res: AutocompleteResponse) => void;
  onChange: (res: string) => void;
}

const AddressAutocomplete = ({
  isError,
  inputType,
  types = ADDRESS_TYPE.NO_TYPE,
  placeholder,
  value,
  onChange,
  onSelect,
}: IAddressAutocomplete) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);

  const { data: placeSuggestions } = useGetSuggestionsQuery(value);

  const hasSuggestions = suggestions.length > 0;
  const hideSuggestions = () => {
    setIsDropdownVisible(false);
    setSuggestions([]);
  };

  useEffect(() => {
    if (!placeSuggestions) return;
    const hasPlaceSuggestions = placeSuggestions?.features?.length > 0;

    if (hasPlaceSuggestions) {
      setSuggestions(placeSuggestions.features);
    }
  }, [placeSuggestions]);

  const handleOnBlurComponent = (event: FocusEvent<HTMLDivElement>) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setIsDropdownVisible(false);
    }
  };

  const handleOnSelectSuggestion = (suggestion: Suggestion) => {
    hideSuggestions();

    onSelect({
      text: getTextPresentable({ suggestion, types }),
      address: suggestion?.place_name,
      postcode: getPostcodePresentable({ suggestion, types }),
      coordinates: [suggestion?.center[1], suggestion?.center[0]],
    });
  };

  const handleOnFocusInput = () => {
    setIsDropdownVisible(true);
  };

  const handleOnBlurInput = (event: FocusEvent<HTMLDivElement>) => {
    if (event.currentTarget.contains(event.relatedTarget)) {
      hideSuggestions();
    }
  };

  const handleOnChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    onChange(value);
  };

  return (
    <Container tabIndex={0} onBlur={handleOnBlurComponent}>
      <FormInput
        isError={isError}
        type={inputType}
        placeholder={placeholder}
        value={value}
        onFocus={handleOnFocusInput}
        onBlur={handleOnBlurInput}
        onChange={handleOnChangeInput}
      />
      {isDropdownVisible && hasSuggestions && (
        <Suggestions>
          <SuggestionsList>
            {suggestions.map((suggestion, index) => (
              <SuggestionsListItem
                key={index}
                onClick={() => handleOnSelectSuggestion(suggestion)}
              >
                {getListItemPresentable({ suggestion, types })}
              </SuggestionsListItem>
            ))}
          </SuggestionsList>
        </Suggestions>
      )}
    </Container>
  );
};

export default AddressAutocomplete;
