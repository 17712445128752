import React from 'react';

export function numberOrEmptyStr(value: string | number): number | string {
  switch (typeof value) {
    case 'number':
      return value;

    case 'string':
      if (!isNaN(parseFloat(value))) {
        return parseFloat(value);
      }
      break;
  }

  return '';
}

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const onKeyPressOnlyNumbers = (
  event: React.KeyboardEvent<HTMLInputElement>,
) => {
  if (!/[0-9]/.test(event.key)) {
    event.preventDefault();
  }
};

export const onKeyPressOnlyNumbersWithDot = (
  event: React.KeyboardEvent<HTMLInputElement>,
) => {
  const currentValue = event.currentTarget.value;
  const updatedValue =
    currentValue.substring(0, event.currentTarget.selectionStart!) +
    event.key +
    currentValue.substring(event.currentTarget.selectionStart!);

  if (!/^[0-9]*(\.[0-9]*)?$/.test(updatedValue)) {
    event.preventDefault();
  }
};
