import { useTranslation } from 'react-i18next';

import { PriceVariant } from 'components/pricing/components/TaxPrices/types';

import { UpcomingTitle } from '../IndexTable/styles';

type ITableTitleRow = {
  priceVariant: PriceVariant;
};

const TableTitleRow = ({ priceVariant }: ITableTitleRow) => {
  const { t } = useTranslation();

  if (priceVariant !== PriceVariant.UPCOMMING) {
    return null;
  }

  return (
    <UpcomingTitle>{t('upcoming_indexation').toUpperCase()}</UpcomingTitle>
  );
};

export default TableTitleRow;
