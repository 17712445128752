import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import {
  StyledWrapper,
  StyledHeader,
  StyledContainer,
  StyledOutletWrapper,
  StyledBody,
} from './styles';

const HelpCenter = () => {
  const { t } = useTranslation();

  return (
    <StyledWrapper>
      <StyledContainer>
        <StyledHeader>{t('help_center')}</StyledHeader>
        <StyledBody>
          <StyledOutletWrapper>
            <Outlet />
          </StyledOutletWrapper>
        </StyledBody>
      </StyledContainer>
    </StyledWrapper>
  );
};

export default HelpCenter;
