import { styled } from 'styled-components';

import { COLORS } from 'constants/colors';

import { TextRegular } from '../Text';

interface InputProps {
  errorCentered?: boolean;
}

export const StyledInputGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledInputError = styled(TextRegular)<InputProps>`
  display: block;
  color: ${COLORS.ErrorBase};
  white-space: pre-wrap;
  text-align: ${(props) => (props.errorCentered ? 'center' : 'left')};
  margin-top: 2px;
`;

export const StyledLabel = styled(TextRegular)`
  text-align: left;
  font-size: 14px;
  min-height: 22px;
`;
