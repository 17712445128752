import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface CreateConsumerState {
  consumerType: string;
  isSameAddress: boolean;
}

const initialState: CreateConsumerState = {
  consumerType: '',
  isSameAddress: false,
};

export const createConsumerSlice = createSlice({
  name: 'createConsumer',
  initialState,
  reducers: {
    setConsumerTypes: (state, action: PayloadAction<string>) => {
      state.consumerType = action.payload;
    },
    setIsSameAddress: (state, action: PayloadAction<boolean>) => {
      state.isSameAddress = action.payload;
    },
    resetConsumerState: () => {
      return initialState;
    },
  },
});

export const { setConsumerTypes, resetConsumerState, setIsSameAddress } =
  createConsumerSlice.actions;

export default createConsumerSlice.reducer;
