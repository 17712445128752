import axios from 'axios';
import qs from 'qs';

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_URLS, REQUEST_METHOD } from 'constants/global';

import { removeAuthTokens, setAuthTokens } from 'utils/local-storage';

interface LoginRequest {
  username: string;
  password: string;
}

export interface LoginResponse extends LoginRequest {
  access_token: string;
  refresh_token: string;
  id_token: string;
  scope: string;
  expires_in: number;
  token_type: string;
}

interface RefreshTokenRequest {
  refresh_token: string;
}

const AUTH0_SCOPE = 'openid profile email offline_access';

export const authApi = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_AUTH0_BASE_URL,
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
    },
  }),
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, LoginRequest>({
      query: ({ username, password }) => ({
        url: API_URLS.OAUTH_TOKEN,
        method: REQUEST_METHOD.POST,
        body: qs.stringify({
          grant_type: 'password',
          username: username,
          password: password,
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
          scope: AUTH0_SCOPE,
          client_secret: process.env.REACT_APP_AUTH0_CLIENT_SECRET,
        }),
      }),
      async transformResponse(response: LoginResponse) {
        try {
          await setAuthTokens(response);
        } catch (err) {
          console.error(err);
        }
        return response;
      },
      async onQueryStarted() {
        try {
          await removeAuthTokens();
        } catch (err) {
          console.error(err);
        }
      },
    }),
    refreshToken: builder.mutation<LoginResponse, RefreshTokenRequest>({
      query: ({ refresh_token }) => ({
        url: API_URLS.OAUTH_TOKEN,
        method: REQUEST_METHOD.POST,
        body: qs.stringify({
          grant_type: 'refresh_token',
          refresh_token: refresh_token,
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
          scope: AUTH0_SCOPE,
          client_secret: process.env.REACT_APP_AUTH0_CLIENT_SECRET,
        }),
      }),
    }),
  }),
});

export const checkAuth = async (accessToken: string) => {
  const response = await axios.get(
    `${process.env.REACT_APP_BACKEND_URL}${API_URLS.AUTH_CHECK}`,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    },
  );

  return response;
};

export const { useLoginMutation, useRefreshTokenMutation } = authApi;
