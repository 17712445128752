import styled from 'styled-components';

import { COLORS } from 'constants/colors';

export const Row = styled.div`
  display: grid;
  grid-template-columns: 199px 263px repeat(2, 132px);
  border-bottom: 1px solid ${COLORS.ColorSplit};
`;

export const Cell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
`;
