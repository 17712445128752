import { styled } from 'styled-components';

import { DownOutlined, UpOutlined } from '@ant-design/icons';

import { TextRegular } from 'components/shared/Text';
import { COLORS } from 'constants/colors';

export const Container = styled.div`
  padding-bottom: 32px;
`;

export const ArrowDown = styled(DownOutlined)`
  font-size: 14px;
  color: ${COLORS.TextBase};
`;

export const ArrowUp = styled(UpOutlined)`
  font-size: 14px;
  color: ${COLORS.TextBase};
`;

export const ArrowText = styled(TextRegular)`
  font-size: 14px;
  font-weight: 600;
  margin-left: 8px;
`;

export const ArrowButton = styled.div`
  margin-top: 32px;
  cursor: pointer;
`;
