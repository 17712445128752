import { downloadFile } from 'utils';

import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  ALL_PRM,
  DATA_TYPE_ITEMS,
} from 'components/communities/Dashboard/constants';
import { BtnPrimary } from 'components/shared/Button';
import CustomSelect from 'components/shared/CustomSelect/CustomSelect';
import CustomDatePicker from 'components/shared/DatePicker';
import FullScreenLoader from 'components/shared/Loader/FullScreenLoader';
import ModalMessage from 'components/shared/ModalMessage';
import { CustomSelectItem } from 'constants/types';
import { useGetPrmByUserIdQuery } from 'services/prmManagement';

import { useAppSelector } from 'utils/hooks';

import {
  StyledLabel,
  StyledReportButton,
  StyledReportSelectRow,
  StyledReportWrapper,
} from './styles';

interface CreateReportProps {
  setDataTypeValue?: Dispatch<SetStateAction<CustomSelectItem>>;
  dataTypeValue?: CustomSelectItem;
  datePickerType: 'month' | 'date';
  fetchCsvFile: ({
    dates,
    prmNumber,
    userId,
  }: {
    dates: any;
    prmNumber?: string | number;
    userId?: string;
  }) => void;
}

interface IReport {
  fileName: string;
  data: Blob | string;
}

const ConsumerCreateReport: FC<CreateReportProps> = ({
  dataTypeValue,
  setDataTypeValue,
  fetchCsvFile,
  datePickerType,
}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [report, setReport] = useState<IReport | null>({
    fileName: '',
    data: '',
  });
  const reportDate = useAppSelector((store) => store.reportDatePicker.dates);

  const { data: prmData, isFetching } = useGetPrmByUserIdQuery({
    userId: id,
  });

  const [prmValue, setPrmValue] = useState<CustomSelectItem>({
    label: ALL_PRM,
    key: ALL_PRM,
  });

  const [prmItems, setPrmItems] = useState([
    {
      label: t(ALL_PRM),
      key: ALL_PRM,
    },
  ]);

  const onCreateReport = async () => {
    try {
      setIsLoading(true);
      const prmNumber = prmValue.key === ALL_PRM ? undefined : prmValue.key;
      const { fileName, data }: any = await fetchCsvFile({
        prmNumber,
        dates: { startDate: reportDate.start, endDate: reportDate.end },
        userId: id,
      });

      if (data || fileName) {
        setReport({ fileName, data });
      }

      setIsLoading(false);
      setIsModalOpen(true);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleButtonClick = () => {
    setIsModalOpen(false);
    downloadFile(report?.data, report?.fileName);
  };

  const onPrmChange = (prm: CustomSelectItem) => {
    setPrmValue(prm);
  };

  const onDataTypeChange = (dataType: CustomSelectItem) => {
    setDataTypeValue?.(dataType);
  };

  useEffect(() => {
    if (!isFetching && Array.isArray(prmData)) {
      const newPrmItems: typeof prmItems = [];
      prmData.forEach((prm) => {
        newPrmItems.push({
          label: prm?.name || prm?.prmValue?.toString() || '',
          key: prm.prmValue?.toString() || '',
        });
      });
      setPrmItems((prev) => [...prev, ...newPrmItems]);
    }
  }, [prmData, isFetching]);

  return (
    <StyledReportWrapper>
      <CustomDatePicker type={datePickerType} />
      {dataTypeValue && (
        <StyledReportSelectRow>
          <StyledLabel>{t('show_data_for')}</StyledLabel>
          <CustomSelect
            width={226}
            items={DATA_TYPE_ITEMS(t)}
            value={dataTypeValue}
            onChange={onDataTypeChange}
          />
        </StyledReportSelectRow>
      )}
      <StyledReportSelectRow>
        <StyledLabel>{t('show_data_for')}</StyledLabel>
        <CustomSelect
          width={132}
          items={prmItems}
          value={prmValue}
          onChange={onPrmChange}
        />
      </StyledReportSelectRow>
      <StyledReportButton>
        <BtnPrimary onClick={onCreateReport}>{t('create_report')}</BtnPrimary>
      </StyledReportButton>
      {isLoading && <FullScreenLoader />}
      {isModalOpen && (
        <ModalMessage
          messageType="info"
          title={''}
          subTitle={t('notification_report')}
          btnText={t('ok')}
          handleBtn={handleButtonClick}
        />
      )}
    </StyledReportWrapper>
  );
};

export default ConsumerCreateReport;
