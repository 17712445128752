import styled from 'styled-components';

import { StyledCommunityTitle } from 'components/communities/CreateCommunity/setup-community/styles';

export const StyledConsumerTitle = styled(StyledCommunityTitle)`
  margin: 0 0 20px;
`;

export const StyledConsumerTabWrapper = styled.div`
  width: 100%;
`;

export const StyledConsumerTypes = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
