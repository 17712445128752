import { TextBold, TextRegular } from 'components/shared/Text';
import { ITaxPriceRangeType } from 'services/taxPrices.types';

import PriceRowLine from '../PriceRowLine';
import { CellWrapper, Header, Row } from './styles';

type IPriceRowe = {
  title: string;
  description: string;
  path: 'private' | 'medium' | 'large';
  row: ITaxPriceRangeType;
};

const PriceRow = ({ title, description, path, row }: IPriceRowe) => (
  <Row>
    <Header>
      <CellWrapper>
        <TextBold>{title}</TextBold>
        <TextRegular>{description}</TextRegular>
      </CellWrapper>
    </Header>
    <PriceRowLine title="HT" property="ht" path={path} row={row} />
    <PriceRowLine title="TVA" property="vat" path={path} row={row} />
    <PriceRowLine title="TTC" property="ttc" path={path} row={row} />
  </Row>
);

export default PriceRow;
