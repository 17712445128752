import React, { CSSProperties, ReactNode } from 'react';

import { Popover as AntPopover } from 'antd';
import { TooltipPlacement } from 'antd/es/tooltip';

import { PopoverIcon } from 'components/communities/Communities/styles';
import { TextRegular } from 'components/shared/Text';

interface IPopover {
  context: string;
  placement?: TooltipPlacement;
  overlayStyles?: CSSProperties;
  children?: ReactNode;
}

const Popover = ({
  overlayStyles,
  context,
  placement = undefined,
  children = <PopoverIcon />,
}: IPopover) => (
  <AntPopover
    placement={placement}
    content={<TextRegular>{context}</TextRegular>}
    overlayStyle={overlayStyles}
  >
    {children}
  </AntPopover>
);

export default Popover;
