import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { COUNTRY, EXTERNAL_API_URLS, REQUEST_METHOD } from 'constants/global';

import { Suggestion } from './types';

export interface IGetSuggestionsResp {
  features: Suggestion[];
}

const baseQuery = fetchBaseQuery({
  baseUrl: EXTERNAL_API_URLS.Geocoder,
});

export const geocoderApi = createApi({
  reducerPath: 'geocoder',
  baseQuery,
  endpoints: (builder) => ({
    getSuggestions: builder.query<IGetSuggestionsResp, string>({
      query: (value: string) => {
        const encodeURI = encodeURIComponent(value);
        return {
          url: `/${encodeURI}.json?&country=${COUNTRY.FRANCE}&access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`,
          method: REQUEST_METHOD.GET,
        };
      },
    }),
  }),
});

export const { useGetSuggestionsQuery } = geocoderApi;
