import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useFormikContext } from 'formik';

import { StyledForm } from 'components/auth/SignIn/styles';
import AddressAutocomplete from 'components/shared/AddressAutocomplete';
import { ADDRESS_TYPE } from 'components/shared/AddressAutocomplete/types';
import { BtnPrimary, BtnSecondary } from 'components/shared/Button';
import FormikInput from 'components/shared/Input/FormikInput';
import { InputGroup } from 'components/shared/InputGroup';
import MapboxMap from 'components/shared/MapboxMap';
import { MarginTop24px } from 'components/shared/Margin';
import { COLORS } from 'constants/colors';
import { BESANCON_COORDINATES } from 'constants/global';
import AppRoutes from 'constants/routes';

import { numberOrEmptyStr } from 'utils/forms';

import { IFields } from 'components/members/types';
import { initialValues } from '../constants';
import LatitudeTooltip from './LatitudeTooltip';
import {
  MapWrapper,
  StyledCity,
  StyledCommunityTitle,
  StyledCoordinateWrapper,
  StyledLocationWrapper,
  StyledPostalCode,
} from './styles';

const currentFields = [
  'name',
  'address.postalCode',
  'address.city',
  'address.location.coordinates.0',
  'address.location.coordinates.1',
];

interface SetupCommunityProps {
  onConfirmForm: (isValid: boolean) => void;
  areValidFields: (fields: string[]) => boolean;
  validateFields: (fields: string[]) => Promise<boolean>;
  hasToBeValidated: boolean;
}

const SetupCommunity: React.FC<SetupCommunityProps> = ({
  onConfirmForm,
  validateFields,
  areValidFields,
  hasToBeValidated,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [coordinates, setMapCoordinates] = useState([
    BESANCON_COORDINATES.latitude,
    BESANCON_COORDINATES.longitude,
  ]);
  const { values, errors, setSubmitting, setFieldValue } =
    useFormikContext<typeof initialValues>();

  const [latitude, longitude] = values?.address?.location
    ?.coordinates as number[];

  useEffect(() => {
    if (!latitude || !longitude) return;

    setMapCoordinates([latitude, longitude]);
  }, [latitude, longitude]);

  const isFieldDataValid = areValidFields(currentFields);

  const onCancelClick = () => {
    navigate(AppRoutes.Root);
  };

  const onConfirmClick = async () => {
    setSubmitting(true);
    const isValid = await validateFields(currentFields);
    setSubmitting(false);
    onConfirmForm(isValid);
  };

  const setFieldsCoordinates = async (coordinates: number[]) => {
    const [latitude, longitude] = coordinates;
    if (!latitude || !longitude) return;

    await setFieldValue('address.location.coordinates.[0]', latitude);
    await setFieldValue('address.location.coordinates.[1]', longitude);

    setMapCoordinates(coordinates);
  };

  const handleOnDragDropEnd = (coordinates: number[]) => {
    const [latitude, longitude] = coordinates;
    if (!latitude || !longitude) return;

    setMapCoordinates(coordinates);
    setFieldsCoordinates(coordinates);
  };

  const handleOnChangeAddress = async ({
    text,
    postcode,
    coordinates,
  }: IFields) => {
    await setFieldValue('address.city', text);

    if (postcode) {
      await setFieldValue('address.postalCode', postcode);
    }

    if (coordinates) {
      await setFieldsCoordinates(coordinates);
    }
  };

  const handleOnPostcodeChange = async (value: string) =>
    await setFieldValue('address.postalCode', numberOrEmptyStr(value));
  const handleOnCityChange = async (value: string) =>
    await setFieldValue('address.city', value);

  return (
    <StyledForm>
      <InputGroup label={t('community_name').toString()} error={errors.name}>
        <FormikInput fieldName="name" placeholder="Ex. Besanśon Community" />
      </InputGroup>

      <StyledCommunityTitle isUppercase={true}>
        {t('community_location')}
      </StyledCommunityTitle>

      <StyledLocationWrapper>
        <StyledPostalCode>
          <InputGroup
            label={t('postal_code').toString()}
            error={errors.address?.postalCode}
          >
            <AddressAutocomplete
              isError={!!errors?.address?.postalCode}
              placeholder="Ex. 64205"
              inputType="number"
              value={values.address.postalCode}
              onChange={handleOnPostcodeChange}
              onSelect={handleOnChangeAddress}
            />
          </InputGroup>
        </StyledPostalCode>
        <StyledCity>
          <InputGroup label={t('city').toString()} error={errors.address?.city}>
            <AddressAutocomplete
              isError={!!errors?.address?.city}
              placeholder="Ex. Besançon"
              types={ADDRESS_TYPE.PLACE_REGION_POSTCODE}
              value={values.address.city}
              onChange={handleOnCityChange}
              onSelect={handleOnChangeAddress}
            />
          </InputGroup>
        </StyledCity>
      </StyledLocationWrapper>

      <InputGroup label={t('country').toString()} isShowError={false}>
        <FormikInput fieldName="address.country" disabled />
      </InputGroup>

      <StyledCommunityTitle>
        {t('type_in_community_location_coordinates')}
      </StyledCommunityTitle>

      <InputGroup
        label={t('latitude_and_longitude').toString()}
        error={
          errors.address?.location?.coordinates?.[0] ||
          errors.address?.location?.coordinates?.[1]
        }
        tooltip={<LatitudeTooltip />}
      >
        <StyledLocationWrapper>
          <StyledCoordinateWrapper>
            <FormikInput
              fieldName="address.location.coordinates.[0]"
              type="number"
              placeholder="Ex. 41.40338"
            />
          </StyledCoordinateWrapper>
          <StyledCoordinateWrapper>
            <FormikInput
              fieldName="address.location.coordinates.[1]"
              type="number"
              placeholder="Ex. 2.17403"
            />
          </StyledCoordinateWrapper>
        </StyledLocationWrapper>
      </InputGroup>

      <MapWrapper>
        <StyledCommunityTitle>{t('adjust_map_marker')}</StyledCommunityTitle>
        <MapboxMap
          coordinates={coordinates}
          onDragDropEnd={handleOnDragDropEnd}
        />
      </MapWrapper>
      <MarginTop24px />
      <BtnPrimary
        onClick={onConfirmClick}
        isDisabled={hasToBeValidated && !isFieldDataValid}
      >
        {t('proceed')}
      </BtnPrimary>
      <MarginTop24px />
      <BtnSecondary color={COLORS.TextBase} onClick={onCancelClick}>
        {t('cancel')}
      </BtnSecondary>
    </StyledForm>
  );
};

export default SetupCommunity;
