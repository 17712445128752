import { getAppRole } from 'utils';

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { USER_ROLES } from 'constants/types';

import CommunityInvoicesTab from './components/CommunityInvoicesTab';
import TaxRefundInvoicesTab from './components/TaxRefundInvoicesTab';

export const useTabsConfig = () => {
  const { t } = useTranslation();

  const userRole = getAppRole();
  const isTaxRefundVisible = useMemo(
    () => userRole === USER_ROLES.PRODUCER || userRole === USER_ROLES.ADMIN,
    [userRole],
  );

  return [
    {
      label: t('community_invoices').toUpperCase(),
      key: '1',
      children: <CommunityInvoicesTab />,
    },
    ...(isTaxRefundVisible
      ? [
          {
            label: t('tax_refund_invoices').toUpperCase(),
            key: '2',
            children: <TaxRefundInvoicesTab />,
          },
        ]
      : []),
  ];
};
