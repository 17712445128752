import React, { useCallback, useEffect, useState } from 'react';

import { useFormikContext } from 'formik';

import { FormInput } from 'components/shared/Input';
import { InputGroup } from 'components/shared/InputGroup';

import { useDebounce } from 'utils/hooks';

import { getValueByPath } from '../DynamicEditForm/dynamic-edit-form.helper';

interface FormData {
  firstName: string;
  lastName: string;
  middleName: string;
}

interface IGenericFormName {
  path: string;
  placeholder: string;
}

const GenericFormName = ({ path, placeholder }: IGenericFormName) => {
  const { values, errors, setFieldValue } = useFormikContext<FormData>();

  const isError = !!(errors?.firstName || errors?.lastName);
  const firstNamePath = path ? path + '.firstName' : 'firstName';
  const lastNamePath = path ? path + '.lastName' : 'lastName';
  const middleNamePath = path ? path + '.middleName' : 'middleName';

  const firstName = getValueByPath(values, firstNamePath);
  const lastName = getValueByPath(values, lastNamePath);
  const middleName = getValueByPath(values, middleNamePath);

  const name = middleName
    ? `${firstName} ${middleName} ${lastName}`
    : `${firstName} ${lastName}`;
  const [fullName, setFullName] = useState(name);

  const handleFullNameChange = useCallback(
    async (fullName: string) => {
      const fullNameArr = fullName.trim().split(' ');

      const firstName = fullNameArr[0] || '';
      const lastName =
        fullNameArr.length > 1 ? fullNameArr[fullNameArr.length - 1] : '';
      const middleName =
        fullNameArr.length > 2
          ? fullNameArr
              .slice(1, fullNameArr.length - 1)
              .join(' ')
              .trim()
          : '';

      await setFieldValue(firstNamePath, firstName);
      await setFieldValue(lastNamePath, lastName);
      await setFieldValue(middleNamePath, middleName);
    },
    [setFieldValue],
  );

  const debouncedFullName = useDebounce(fullName, 100);

  useEffect(() => {
    handleFullNameChange(debouncedFullName);
  }, [debouncedFullName, handleFullNameChange]);

  return (
    <InputGroup
      label={placeholder}
      error={
        getValueByPath(errors, firstNamePath) ||
        getValueByPath(errors, lastNamePath)
      }
    >
      <FormInput
        isError={isError}
        type="text"
        placeholder={placeholder}
        value={fullName}
        onChange={(e) => setFullName(e.target.value)}
      />
    </InputGroup>
  );
};

export default GenericFormName;
