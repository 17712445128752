import { COLORS } from 'constants/colors';

export const chartInfoLabels = [
  {
    name: 'total_production',
    color: COLORS.Yellow5,
  },
  {
    name: 'self_consumption',
    color: COLORS.Green6,
  },

  {
    name: 'overproduction',
    color: COLORS.TextTertiary,
  },

  {
    name: 'grid_consumption',
    color: COLORS.Orange5,
  },
];

export const emptyChartData = [
  {
    date: 0,
    value: 755054,
    type: 'selfConsumption',
  },
  {
    date: 1,
    value: 448364,
    type: 'consumption',
  },
  {
    date: 2,
    value: 3193309,
    type: 'gridConsumption',
  },
  {
    date: 3,
    value: 1676165,
    type: 'overproduction',
  },
];
