import { styled } from 'styled-components';

import { COLORS } from 'constants/colors';

export const StyledReportWrapper = styled.div`
  box-sizing: border-box;
  background-color: ${COLORS.ColorFillTertiary};
  padding: 25px 27px;
  display: flex;
  align-items: center;
  justify-content: end;
  column-gap: 27px;
  margin-top: 40px;
  margin-bottom: 32px;
`;

export const StyledReportButton = styled.div`
  width: 100%;
  max-width: 262px;
`;

export const StyledReportCheckboxRow = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;
