import { getAppRole } from 'utils';

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { SummaryViewRow } from 'components/communities/styles';
import WidgetEmissions from 'components/shared/WidgetEmissions';
import { COLORS } from 'constants/colors';
import { PERIOD, USER_ROLES } from 'constants/types';
import { useGetCommunityEarningsQuery } from 'services/communityManagement';
import {
  useGetConsumerActualEmissionPreventedQuery,
  useGetConsumerSelfConsumptionRatioQuery,
  useGetConsumerSelfProductionRatioQuery,
  useGetEarningsQuery,
} from 'services/dashboardManagment';

import { getDateRange } from 'utils/url/getDateRange';

import WidgetRatio from '../components/WidgetRatio';
import WidgetEarnings from './components/WidgetEarnings';

const SelfProductionChartColors = [COLORS.Green5, COLORS.Orange5];
const SelfConsumptionChartColors = [COLORS.Green5, '#D4D5D6' as COLORS];

const useUrlQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ProducerSummaryView = ({
  userId,
  period,
}: {
  userId?: string;
  period: PERIOD;
}) => {
  const { t } = useTranslation();
  const query = useUrlQuery();
  const communityId = query.get('communityId') || undefined;
  const role = getAppRole();

  const {
    data: selfProductionRatioPercent,
    isLoading: isLoadingSelfProductionRatioPercent,
  } = useGetConsumerSelfProductionRatioQuery({
    ...getDateRange(period),
    userId,
  });
  const { data } = useGetConsumerSelfConsumptionRatioQuery({
    ...getDateRange(period),
    userId,
  });
  const { data: communityEarningsData, isLoading: isLoadingCommunityEarnings } =
    useGetCommunityEarningsQuery({
      ...getDateRange(period),
      communityId,
    });
  const { data: earningsData, isLoading: isLoadingEarnings } =
    useGetEarningsQuery({
      ...getDateRange(period),
      communityId,
    });
  const {
    data: actualEmissionPreventedData,
    isLoading: isLoadingActualEmissionPrevented,
  } = useGetConsumerActualEmissionPreventedQuery({
    ...getDateRange(period),
    userId,
  });

  const selfConsumptionRatioPercent = data?.ratio;
  const earningsAmountByRole =
    role === USER_ROLES.PRODUCER || role === USER_ROLES.ADMIN
      ? earningsData?.earnings
      : communityEarningsData?.earnings;
  const earningsAmount = earningsAmountByRole ? earningsAmountByRole : 'N/A';
  const isEarningsLoadingByRole =
    role === USER_ROLES.PRODUCER || role === USER_ROLES.ADMIN
      ? isLoadingEarnings
      : isLoadingCommunityEarnings;

  return (
    <SummaryViewRow>
      <WidgetRatio
        title={t('self_production_ratio').toString()}
        tooltipText={t('self_production_ratio_tooltip')}
        firstEnergyTitle={t('self_produced_energy')}
        secondEnergyTitle={t('grid_energy')}
        percent={selfProductionRatioPercent}
        chartColors={SelfProductionChartColors}
        isLoading={isLoadingSelfProductionRatioPercent}
      />
      <WidgetEarnings
        amount={earningsAmount}
        isLoading={isEarningsLoadingByRole}
      />
      <WidgetEmissions
        isLoading={isLoadingActualEmissionPrevented}
        amount={actualEmissionPreventedData?.actualEmissionPrevented}
        subTitle={t('for_each_kWh_spent')}
        tooltipContext={t('community_emissions_prevented_tooltip')}
      />
      <WidgetRatio
        title={t('self_consumption_ratio').toString()}
        tooltipText={t('self_consumption_ratio_tooltip')}
        firstEnergyTitle={t('self_consumed_energy').toString()}
        secondEnergyTitle={t('overproduction')}
        percent={selfConsumptionRatioPercent}
        chartColors={SelfConsumptionChartColors}
        isLoading={isLoadingSelfProductionRatioPercent}
      />
    </SummaryViewRow>
  );
};

export default ProducerSummaryView;
