import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  CardBody,
  CardHeader,
  CardTitle,
  MapContainer,
} from 'components/communities/Communities/styles';
import { getConsumersAndProducersCoordinatesPresentable } from 'components/communities/Dashboard/utils';
import MapboxMap from 'components/shared/MapboxMap';
import { IDBUser } from 'constants/types';
import { useGetMembersQuery } from 'services/membersManagement';
import { SkeletonLine } from 'components/shared/Skeleton';
import { MapWrapper } from './styles';

export const MAP_HEIGHT = '362px';

const getMapConfigPresentable = (members: IDBUser[]) => ({
  height: MAP_HEIGHT,
  communitiesCoordinates:
    getConsumersAndProducersCoordinatesPresentable(members),
});

const CommunityMap = () => {
  const { t } = useTranslation();
  const { isSuccess, data = [], isLoading } = useGetMembersQuery();

  return (
    <MapWrapper>
      <MapContainer>
        <CardHeader>
          <CardTitle>{t('community_map')}</CardTitle>
        </CardHeader>
        <CardBody>
          {isLoading && <SkeletonLine active block height={363} />}
          {isSuccess && <MapboxMap {...getMapConfigPresentable(data || {})} />}
        </CardBody>
      </MapContainer>
    </MapWrapper>
  );
};

export default CommunityMap;
