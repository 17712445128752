import { IPieItem } from 'components/shared/PieChart';
import { EnergyTypes } from 'constants/energy-types';

export const preparePieChartData = (
  selfConsumedEnergy?: number,
): IPieItem[] => [
  {
    type: EnergyTypes.SELF_CONSUMED,
    value: selfConsumedEnergy as number,
  },
  {
    type: EnergyTypes.GRID_ENERGY,
    value: 100 - (selfConsumedEnergy as number),
  },
];

export const prepareNextUrl = (communityName: string) =>
  communityName.toLowerCase().trim().split(' ').join('-');
