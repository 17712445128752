import AppRoutes from './routes';

export const CONTACT_US_EMAIL = 'support@oyo.ai';

export const SIREN_LENGTH = 9;
export const SIRET_LENGTH = 14;
export const PASWORD_MIN_LENGTH = 8;

export const SUPPORTED_LANGUAGES = {
  English: 'en',
  Français: 'fr',
};

export enum REQUEST_METHOD {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export enum STATUS_CODE {
  SUCCESS = 200,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  RESOURCE_NOT_FOUND = 404,
  INTERNAL_SERVER_ERROR = 500,
}

export const API_URLS = {
  OAUTH_TOKEN: '/oauth/token',
  AUTH_CHECK: '/users/check_auth',
  UPDATE_PASSWORD: '/users/update_password',
  REQUEST_CHANGE_PASSWORD: '/users/request_change_password',
  CREATE_COMMUNITY: '/community',
  GET_COMMUNITY: '/community',
  GET_COMMUNITIES: 'community/all',
  MEMBERS: '/users',
  MEMBER: 'users/user',
  GET_USER_BY_EMAIL: '/users/user_by_email',
  GET_COMMUNITY_SELF_PRODUCTION_RATIO: '/community/self_production_ratio',
  GET_COMMUNITY_SELF_CONSUMPTION_RATIO: '/community/self_consumption_ratio',
  GET_COMMUNITY_ELECTRICITY_TOTAL: '/community/electricity_total',
  GET_COMMUNITY_CONSUMERS: '/community/consumers',
  GET_COMMUNITY_ELECTRICITY_CONSUMPTION: '/community/electricity_consumption',
  GET_COMMUNITY_EARNINGS: '/community/earnings',
  GET_USER_SELF_PRODUCTION_RATIO: '/users/self_production_ratio',
  GET_USER_SELF_CONSUMPTION_RATIO: '/users/self_consumption_ratio',
  GET_USER_ELECTRICITY_USAGE: '/users/electricity_usage',
  GET_USER_ELECTRICITY_PRODUCTION: '/users/electricity_production',
  GET_USER_ELECTRICITY_TOTAL: '/users/electricity_total',
  GET_USER_ACTUAL_EMISSION_PREVENTED: '/users/actual_emission_prevented',
  GET_ACTUAL_COST_SAVED: '/users/actual_cost_saved',
  VALIDATE_TOKEN: (token: string) => `/users/validate_change_password/${token}`,
  PRM: '/prm',
  GET_USER_PRM: 'prm/user_prm',
  GET_USER_PRM_STATUSES: 'prm/user_prm/statuses',
  PRM_PRICE: 'prmprice',
  UPDATE_USER_PRM: 'prm/update',
  GET_COMMUNITY_PRICES: 'community/prices',
  GET_COMMUNITY_DATA: 'users/community',
  GET_COMMUNITY_INVOICE_HISTORY: '/community/invoice_history',
  GET_COMMUNITY_FINANCIAL_DATA: '/community/financial_data',
  GET_CSV_ENERGY_REPORTS: 'users/csv_energy_reports',
  GET_CONSUMERS_CSV_ENERGY_REPORTS: '/users/consumers/csv_energy_reports',
  GET_ENERGY_REPORTS: 'users/energy_reports',
  GET_SELF_CONSUMED_BY_TYPE: 'users/self_consumed_by_type',
  GET_SELF_CONSUMED_BY_SOURCE: 'users/self_consumed_by_source',
  PAYMENT_METHOD: '/payment-method',
  GET_FINANCIAL_REPORTS: '/users/csv_consumer_financial_reports',
  GET_REPARTITION_KEY: 'users/repartition_key',
  GET_EARNINGS: '/users/earnings',
  GET_PRODUCTION_ENERGY: '/users/production_energy',
  GET_TOTAL_PRODUCTION_REPORT: '/users/total_production_report',
  GET_SELF_CONSUMPTION_REPORT: '/users/self_consumption_report',
  GET_COMMUNITY_DATA_REPORT: '/users/community_data_report',
  GET_TAX_REFUND_REPORT: 'users/tax_refund_report',
  GET_FINANCIAL_DATA: 'users/financial_data',
  GET_INVOICE_HISTORY: 'users/invoice_history',
  GET_TAX_REFUND_INVOICE_HISTORY: 'users/tax_refund_invoice_history',
  CREATE_INVOICE: (id: string) => `/invoice/${id}/payment`,
  GET_INVOICE: '/invoice',
  GET_TAX_PRICES: '/tax-prices',
  USER_LANGUAGE: '/users/lang',
  GRID_PRICES: '/grid-prices',
  RESEND_INVITE: 'resend_invite',
  CONTACT_US: '/users/contact',
};

export const COMMUNITIES_NAV_LINKS_ROLE_OYO_ADMIN = [
  { title: 'communities', path: AppRoutes.Communities },
  { title: 'members', path: AppRoutes.Members },
  { title: 'pricing', path: AppRoutes.Pricing },
];

export const COMMUNITIES_NAV_LINKS_ROLE_PMO = [
  { title: 'my_community', path: AppRoutes.MyCommunity },
  { title: 'members', path: AppRoutes.Members },
  { title: 'reports', path: AppRoutes.Reports },
  { title: 'pricing', path: AppRoutes.Pricing },
];

export const COMMUNITIES_NAV_LINKS_ROLE_CONSUMER = [
  { title: 'dashboard', path: AppRoutes.Dashboard },
  { title: 'energy_data', path: AppRoutes.EnergyData, isDisabled: false },
  { title: 'financial_data', path: AppRoutes.FinancialData },
];

export const COMMUNITIES_NAV_LINKS_ROLE_PRODUCER = [
  { title: 'dashboard', path: AppRoutes.Dashboard },
  { title: 'energy_data', path: AppRoutes.EnergyData, isDisabled: false },
  { title: 'financial_data', path: AppRoutes.FinancialData, isDisabled: false },
  { title: 'prices', path: AppRoutes.Pricing },
];

export const CREATE_COMMUNITY_STEPS = [
  'setup_community',
  'add_pmo',
  'settings',
];

export const CREATE_MEMBER_STEPS = [
  'consumer_type',
  'consumer_details',
  'contact_info',
];

export const CREATE_PRODUCER_STEPS = [
  'producer_details',
  'legal_data',
  'settings',
];

export const EXTERNAL_API_URLS = {
  GoogleMaps: 'https://www.google.com/maps',
  Geocoder: 'https://api.mapbox.com/geocoding/v5/mapbox.places',
};

export enum BESANCON_COORDINATES {
  latitude = 47.23873408467293,
  longitude = 6.021869847021842,
}

export enum USER_ROLES {
  OYO_ADMIN = 'OYO_Admin',
  PMO = 'PMO',
  CONSUMER = 'Consumer',
  PRODUCER = 'Producer',
  NO_ROLE = '',
}

export const ADDRESS_FIELD_Z_INDEX = 1;

export enum CREATE_STEPS {
  FIRST = 0,
  SECOND = 1,
  THIRD = 2,
}

export enum POWER_OPTION {
  KW = 'kw',
  MW = 'mw',
}

export enum COUNTRY {
  FRANCE = 'fr',
}

export const DATE_FORMAT = 'DD/MM/YYYY';

export const MAP_HEIGHT = '362px';

export const SCREEN_CONTENT_SIZE = '1380px';

export enum CHART_TYPE {
  FINANCIAL_DATA,
}

export const dayLabels: {
  [key: string]: string;
} = {
  '1': 'sunday',
  '2': 'monday',
  '3': 'tuesday',
  '4': 'wednesday',
  '5': 'thursday',
  '6': 'friday',
  '7': 'saturday',
};

export const monthLabels: {
  [key: string]: string;
} = {
  '1': 'january',
  '2': 'february',
  '3': 'march',
  '4': 'april',
  '5': 'may',
  '6': 'june',
  '7': 'july',
  '8': 'august',
  '9': 'september',
  '10': 'october',
  '11': 'november',
  '12': 'december',
};

export const DATE_FORMAT_TYPE = 'YYYY-MM-DD';
export const MONTH_FORMAT_TYPE = 'YYYY-MM';

export const USER_MENU_ITEMS_ROLE_CONSUMER = [
  { label: 'profile', key: '0', route: AppRoutes.Profile },
  { label: 'settings', key: '1', route: AppRoutes.Settings },
  { label: 'documents', key: '2', route: AppRoutes.Documents },
  { label: 'help_center', key: '3', route: AppRoutes.HelpCenter },
  { type: 'divider', key: '4' },
  { label: 'sign_out', key: '5' },
];

export const USER_MENU_ITEMS_ROLE_PRODUCER = [
  { label: 'profile', key: '0', route: AppRoutes.Profile },
  { label: 'settings', key: '1', route: AppRoutes.Settings },
  { type: 'divider', key: '2' },
  { label: 'sign_out', key: '3' },
];

export const USER_MENU_ITEMS_ROLE_OYO_ADMIN = [
  { label: 'profile', key: '0', route: AppRoutes.Profile },
  { type: 'divider', key: '1' },
  { label: 'sign_out', key: '2' },
];

export const USER_MENU_ITEMS_ROLE_PMO = [
  { label: 'profile', key: '0', route: AppRoutes.Profile },
  { type: 'divider', key: '1' },
  { label: 'sign_out', key: '2' },
];

export enum ADMINISTRATIVE_NOTIFICATION_SUBTYPES {
  CREATE_COMMUNITY = 'create_community',
  PRODUCER_CONSUMER_ADDED = 'producer_consumer_added',
  PRM_ACTIVATED = 'prm_activated',
  PRM_ADDED = 'prm_added',
  PRM_REMOVED = 'prm_removed',
  PMO_ADDED = 'pmo_added',
  CONSUMER_ADDED_TO_COMMUNITY = 'consumer_added_to_community',
  PRM_DEACTIVATED = 'prm_deactivated',
}

export enum NOTIFICATION_TYPE {
  ERROR = 'error',
  WARNING = 'warning',
  SUCCESS = 'success',
  INFO = 'info',
}
