import React from 'react';
import { useParams } from 'react-router-dom';

import ProductionFollowUpTable from 'components/communities/components/ProductionFollowUpTable';
import { useGetProductionEnergyQuery } from 'services/reportManagment';

const ProductionTableWrapper = () => {
  const { id } = useParams();
  const {
    data: tableData,
    isSuccess,
    isLoading,
  } = useGetProductionEnergyQuery({
    userId: id,
  });
  return (
    <ProductionFollowUpTable
      tableData={tableData}
      isSuccess={isSuccess}
      isLoading={isLoading}
    />
  );
};

export default ProductionTableWrapper;
