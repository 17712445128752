import React from 'react';

const Icon: React.FC = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.53125 2.37498H8.46875C8.55208 2.37498 8.59375 2.41665 8.59375 2.49998V13.5C8.59375 13.5833 8.55208 13.625 8.46875 13.625H7.53125C7.44792 13.625 7.40625 13.5833 7.40625 13.5V2.49998C7.40625 2.41665 7.44792 2.37498 7.53125 2.37498Z"
        fill="black"
      />
      <path
        d="M2.75 7.40623H13.25C13.3333 7.40623 13.375 7.4479 13.375 7.53123V8.46873C13.375 8.55207 13.3333 8.59373 13.25 8.59373H2.75C2.66667 8.59373 2.625 8.55207 2.625 8.46873V7.53123C2.625 7.4479 2.66667 7.40623 2.75 7.40623Z"
        fill="black"
      />
    </svg>
  );
};

export default Icon;
