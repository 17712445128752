import { useTranslation } from 'react-i18next';

import { PriceVariant } from 'components/pricing/components/TaxPrices/types';

import { AlignCell, Cell, Row, TextBold } from '../styles';

type ITableHeader = {
  hasEdit?: boolean;
  priceVariant: PriceVariant;
};

const TableHeader = ({ hasEdit, priceVariant }: ITableHeader) => {
  const { t } = useTranslation();

  return (
    <Row hasEdit={hasEdit} type={priceVariant}>
      <AlignCell>
        <TextBold>{t('community_name')}</TextBold>
      </AlignCell>
      <AlignCell>
        <TextBold>{t('consumer_name')}</TextBold>
      </AlignCell>
      <Cell>
        <TextBold>{t('conditions_application')}</TextBold>
      </Cell>
      <Cell>
        <TextBold>{t('price_general')}</TextBold>
      </Cell>
      <Cell>
        <TextBold>{t('price_vat')}</TextBold>
      </Cell>
      <Cell>
        <TextBold>{t('price_ttc')}</TextBold>
      </Cell>
      {hasEdit && (
        <Cell>
          <TextBold>{t('action')}</TextBold>
        </Cell>
      )}
    </Row>
  );
};

export default TableHeader;
