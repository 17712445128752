import { styled } from 'styled-components';

import { Button } from 'antd';

import { ThunderboltTwoTone } from '@ant-design/icons';

import { COLORS } from 'constants/colors';

export const StyledMenuWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 32px;
`;

export const StyledMenuBtn = styled(Button)<{ color: string }>`
  display: flex;
  height: 32px;
  width: 32px;
  justify-content: center;
  align-items: center;
  border: 1px solid ${COLORS.Green6};
  background: ${({ color }) => color};
  border-radius: 16px;
  font-size: 12px;
  font-weigth: 600;
  color: ${COLORS.BgContainer};
`;

export const ThunderboltIcon = styled(ThunderboltTwoTone).attrs(() => ({
  twoToneColor: COLORS.Green6,
}))``;

export const dropdownOverlayStyle = {
  zIndex: 100002,
  textTransform: 'capitalize',
};
