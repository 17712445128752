import React, { PropsWithChildren } from 'react';

import { StyledContainer } from './styles';

interface ContainerProps extends PropsWithChildren {
  offset?: number;
}

const Container: React.FC<ContainerProps> = ({ offset, children }) => {
  const { offsetHeight } = offset
    ? { offsetHeight: offset }
    : document.getElementById('header') ?? {
        offsetHeight: 0,
      };
  return <StyledContainer offset={offsetHeight}>{children}</StyledContainer>;
};

export default Container;
