import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  StyledStep,
  StyledStepDescription,
  StyledStepDivider,
  StyledStepTitle,
  StyledStepsWrapper,
} from './styles';

type StepperProps = { steps: string[]; activeStep: number };

const Stepper: React.FC<StepperProps> = ({ steps, activeStep = 0 }) => {
  const { t } = useTranslation();
  return (
    <StyledStepsWrapper>
      {steps.map((step, index) => {
        const isActive = activeStep >= index;
        const currentStep = index + 1;

        return (
          <StyledStep key={step}>
            <StyledStepTitle active={isActive}>
              {t('step {{currentStep}}', { currentStep })}
            </StyledStepTitle>

            <StyledStepDivider active={isActive} />

            <StyledStepDescription
              active={isActive}
              isCurrentStep={activeStep === index}
            >
              {t(step)}
            </StyledStepDescription>
          </StyledStep>
        );
      })}
    </StyledStepsWrapper>
  );
};

export default Stepper;
