import React from 'react';
import { useTranslation } from 'react-i18next';

import { COLORS } from 'constants/colors';
import { EXTERNAL_API_URLS } from 'constants/global';

import {
  StyledLink,
  StyledListAdditionalInfo,
  StyledListItem,
  StyledText,
  StyledTitle,
  StyledTooltipWrapper,
} from './styles';

const LatitudeTooltip: React.FC = () => {
  const { t } = useTranslation();
  return (
    <StyledTooltipWrapper>
      <StyledTitle color={COLORS.TextBase}>
        {t('how_to_get_the_coordinates_of_a_place')}
      </StyledTitle>

      <StyledListItem>
        <StyledText>1.</StyledText>
        <StyledText>
          {t('on_your_computer_open')}{' '}
          <StyledLink
            target="_blank"
            underline
            href={EXTERNAL_API_URLS.GoogleMaps}
          >
            {t('google_maps')}
          </StyledLink>
        </StyledText>
      </StyledListItem>
      <StyledListItem>
        <StyledText>2.</StyledText>
        <StyledText>{t('right_click_on_area_map')}</StyledText>
      </StyledListItem>
      <StyledListAdditionalInfo>
        <StyledListItem>
          <StyledText>&#8226;</StyledText>
          <StyledText>
            {t(
              'you_can_find_your_latitude_and_longitude_in_decimal_format_at_the_top',
            )}
          </StyledText>
        </StyledListItem>
      </StyledListAdditionalInfo>

      <StyledListItem>
        <StyledText>3.</StyledText>
        <StyledText>
          {t(
            'copy_coordinates_automatically_left_click_on_the_latitude_and_longitude',
          )}
        </StyledText>
      </StyledListItem>
    </StyledTooltipWrapper>
  );
};

export default LatitudeTooltip;
