import { isValidRole } from 'utils';
import { v4 as uuidv4 } from 'uuid';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import CommunitiesWithMap from 'components/communities/Communities/components/CommunitiesWithMap';
import PlusOutlines from 'components/communities/Communities/img/PlusOutlined.svg';
import { BtnPrimary, BtnSecondary } from 'components/shared/Button';
import Image from 'components/shared/Image';
import { Header, TextRegular } from 'components/shared/Text';
import { USER_ROLES } from 'constants/global';
import AppRoutes from 'constants/routes';
import { useGetCommunitiesQuery } from 'services/communityManagement';
import { generateEmptyArray } from 'utils';
import { BtnWrapper, Card, Container, HeaderWrapper } from './styles';

const loadingCommunities = generateEmptyArray(3).map(() => ({
  id: uuidv4(),
  consumers: [],
  producers: [],
  communityName: '',
}));

const Communities: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const isOyoAdmin = isValidRole(USER_ROLES.OYO_ADMIN);

  const {
    data: communities = [],
    isSuccess,
    isLoading,
  } = useGetCommunitiesQuery();

  const hasNoCommunities = isSuccess && communities.length === 0;

  const renderCommunities = () => {
    if (hasNoCommunities) {
      return (
        <Card isEmpty={hasNoCommunities}>
          <TextRegular>{t('no_communities').toUpperCase()}</TextRegular>
          {isOyoAdmin && (
            <BtnSecondary onClick={() => navigate(AppRoutes.AddNewCommunity)}>
              {t('add_new_community')}
            </BtnSecondary>
          )}
        </Card>
      );
    }
    return (
      <CommunitiesWithMap
        isLoading={isLoading}
        communities={isLoading ? (loadingCommunities as any) : communities}
      />
    );
  };

  return (
    <Container>
      <HeaderWrapper>
        <Header>{t('communities')}</Header>
        <BtnWrapper>
          {isOyoAdmin && (
            <BtnPrimary onClick={() => navigate(AppRoutes.AddNewCommunity)}>
              <Image src={PlusOutlines} alt="PlusIcon" margin="0 5px 0 0" />
              {t('add_new_community')}
            </BtnPrimary>
          )}
        </BtnWrapper>
      </HeaderWrapper>
      {renderCommunities()}
    </Container>
  );
};

export default Communities;
