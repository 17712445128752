import { DateTime } from 'luxon';

function formatDate(dateString: string) {
  return DateTime.fromISO(dateString).toFormat('dd.MM.yyyy');
}

export function getDateRange({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}) {
  const startDateFormatted = formatDate(startDate);
  const endDateFormatted = formatDate(endDate);

  return `${startDateFormatted} - ${endDateFormatted}`;
}
