import dayjs from 'dayjs';

import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Tag, Flex } from 'antd/lib';

import { TFunction } from 'i18next';
import Popover from 'components/shared/Popover';
import { ThunderboltIcon } from 'components/communities/Charts/SummaryView/components/Title/styles';
import { PRM_STATUSES } from 'constants/types';

import { getDateRange } from 'utils/date/date-range';

import PrmPrices from '../PrmPrices';
import { IFormInitialData } from '../index';
import {
  CalendarIcon,
  FileIcon,
  StyledDeactivationStatus,
  StyledDeletedStatus,
  StyledExpiredDuration,
  StyledPrmBgWrapper,
  StyledPrmInfoItem,
  StyledPrmNumber,
  StyledPrmTitle,
  StyledPrmWrapper,
  PopoverIcon,
} from './styles';

export const getPrmStatusLayout = (
  type: string,
  t: TFunction,
  deactivationDate?: string,
  statusErrorMessage?: string,
) => {
  switch (type) {
    case PRM_STATUSES.ACTIVE:
      return <Tag color="success">{t('active')}</Tag>;
    case PRM_STATUSES.PENDING:
      return <Tag color="warning">{t('pending')}</Tag>;
    case PRM_STATUSES.CONFLICT:
      return (
        <Flex style={{ alignItems: 'center' }}>
          <Tag color="error">{t('in_conflict')}</Tag>;
          <Popover context={statusErrorMessage || ''} />
        </Flex>
      );
    case PRM_STATUSES.INDEACTIVATION:
      return (
        <Flex style={{ alignItems: 'center', flexWrap: 'wrap', rowGap: '4px' }}>
          <StyledDeactivationStatus>
            {t('indeactivation')}
          </StyledDeactivationStatus>
          <Popover context={t('removal_date', { date: deactivationDate })}>
            <PopoverIcon />
          </Popover>
        </Flex>
      );
    case PRM_STATUSES.DEACTIVATED:
      return <StyledDeletedStatus>{t('deactivated')}</StyledDeletedStatus>;
  }
};

export const EXPIRES_MONTH_DIFF = 3;

interface ConsumerPrmItemProps {
  prm: any;
  isHistoricalPricesShown: boolean;
  openFormModal: (data: IFormInitialData) => void;
}

const ConsumerPrmItem: FC<ConsumerPrmItemProps> = ({
  prm,
  isHistoricalPricesShown,
  openFormModal,
}) => {
  const { t } = useTranslation();

  const prmActualPrmPrice = useMemo(() => {
    if (!prm.prmPrices || !prm.prmPrices.length) return undefined;
    const currentDate = dayjs();
    return prm.prmPrices.find((prmPrice: any) => {
      const startDate = dayjs(prmPrice.startDate);
      const endDate = dayjs(prmPrice.endDate);
      return currentDate.isBetween(startDate, endDate);
    });
  }, [prm]);

  const isExpiredSoon = useMemo(() => {
    if (!prmActualPrmPrice) return false;
    const { endDate } = prmActualPrmPrice;
    if (endDate) {
      const today = dayjs();
      return dayjs(endDate).diff(today, 'month') < EXPIRES_MONTH_DIFF;
    }
    return false;
  }, [prmActualPrmPrice]);

  return (
    <StyledPrmBgWrapper>
      <StyledPrmWrapper>
        <StyledPrmInfoItem width="35%">
          <StyledPrmNumber>
            {t('prm_with_value', { prmValue: prm.prmValue })}
            <span style={{ marginLeft: 10 }}>
              {getPrmStatusLayout(prm.status, t)}
            </span>
          </StyledPrmNumber>
        </StyledPrmInfoItem>
        <StyledPrmInfoItem width="17%">
          <ThunderboltIcon />
          <StyledPrmTitle>{t('power')}</StyledPrmTitle>
          {t('power_unit', { power: prm.power || '-' })}
        </StyledPrmInfoItem>
        <StyledPrmInfoItem width="18%">
          <FileIcon />
          <StyledPrmTitle>{t('cppa')}</StyledPrmTitle>
          {prmActualPrmPrice?.cppa || '-'}
        </StyledPrmInfoItem>
        <StyledPrmInfoItem width="30%">
          <CalendarIcon />
          <StyledPrmTitle>{t('duration')}</StyledPrmTitle>
          <StyledExpiredDuration isExpiredSoon={isExpiredSoon}>
            {prmActualPrmPrice
              ? getDateRange({
                  startDate: prmActualPrmPrice.startDate,
                  endDate: prmActualPrmPrice.endDate,
                })
              : '-'}
          </StyledExpiredDuration>
        </StyledPrmInfoItem>
      </StyledPrmWrapper>
      <PrmPrices
        prmPrices={prm.prmPrices}
        isHistoricalPricesShown={isHistoricalPricesShown}
        openFormModal={openFormModal}
        communityId={prm.community}
        consumerId={prm.user?._id}
        prmId={prm._id}
      />
    </StyledPrmBgWrapper>
  );
};

export default ConsumerPrmItem;
