import dayjs from 'dayjs';

import { TFunction } from 'i18next';

import { DATE_PICKER_STATE, DatePickerType } from './types';

export const datePickerRadioItems = (t: TFunction) => [
  {
    label: t('last_month'),
    value: DATE_PICKER_STATE.LAST_MONTH,
  },
  {
    label: t('last_three_month'),
    value: DATE_PICKER_STATE.LAST_THREE_MONTH,
  },
  {
    label: t('last_six_month'),
    value: DATE_PICKER_STATE.LAST_SIX_MONTH,
  },
  {
    label: t('current_year'),
    value: DATE_PICKER_STATE.CURRENT_YEAR,
  },
  {
    label: t('last_year'),
    value: DATE_PICKER_STATE.LAST_YEAR,
  },
];

export const predefinedRanges: Record<DATE_PICKER_STATE, DatePickerType> = {
  [DATE_PICKER_STATE.LAST_MONTH]: {
    start: dayjs().subtract(1, 'month').startOf('month').toDate(),
    end: dayjs().subtract(1, 'month').endOf('month').toDate(),
  },
  [DATE_PICKER_STATE.LAST_THREE_MONTH]: {
    start: dayjs().subtract(3, 'month').startOf('month').toDate(),
    end: dayjs().subtract(1, 'month').endOf('month').toDate(),
  },
  [DATE_PICKER_STATE.LAST_SIX_MONTH]: {
    start: dayjs().subtract(6, 'month').startOf('month').toDate(),
    end: dayjs().subtract(1, 'month').endOf('month').toDate(),
  },
  [DATE_PICKER_STATE.CURRENT_YEAR]: {
    start: dayjs().startOf('year').format('YYYY-MM-DD'),
    end: dayjs().subtract(1, 'day').toDate().toISOString(),
  },
  [DATE_PICKER_STATE.LAST_YEAR]: {
    start: dayjs()
      .subtract(1, 'year')
      .startOf('year')
      .startOf('month')
      .format('YYYY-MM-DD'),
    end: dayjs()
      .subtract(1, 'year')
      .endOf('year')
      .endOf('month')
      .format('YYYY-MM-DD'),
  },
  [DATE_PICKER_STATE.CUSTOM]: {
    start: null,
    end: null,
  },
};
