export const emptyData = [
  {
    category: 'TOTAL_CONSUMPTION',
    date: '01',
    value: 1390.5,
  },
  {
    category: 'TOTAL_CONSUMPTION',
    date: '02',
    value: 1469.5,
  },
  {
    category: 'TOTAL_CONSUMPTION',
    date: '03',
    value: 1521.7,
  },
  {
    category: 'TOTAL_CONSUMPTION',
    date: '04',
    value: 1615.9,
  },
  {
    category: 'TOTAL_CONSUMPTION',
    date: '05',
    value: 1703.7,
  },
  {
    category: 'TOTAL_CONSUMPTION',
    date: '06',
    value: 1767.8,
  },
  {
    category: 'TOTAL_CONSUMPTION',
    date: '07',
    value: 1806.2,
  },
  {
    category: 'TOTAL_CONSUMPTION',
    date: '08',
    value: 1903.5,
  },
  {
    category: 'TOTAL_CONSUMPTION',
    date: '09',
    value: 1986.6,
  },
  {
    category: 'TOTAL_CONSUMPTION',
    date: '10',
    value: 1952,
  },
  {
    category: 'TOTAL_CONSUMPTION',
    date: '11',
    value: 1910.4,
  },
  {
    category: 'TOTAL_CONSUMPTION',
    date: '12',
    value: 2015.8,
  },
  {
    category: 'TOTAL_CONSUMPTION',
    date: '13',
    value: 2074.7,
  },

  {
    category: 'TOTAL_CONSUMPTION',
    date: '14',
    value: 1390.5,
  },
  {
    category: 'TOTAL_CONSUMPTION',
    date: '15',
    value: 1469.5,
  },
  {
    category: 'TOTAL_CONSUMPTION',
    date: '16',
    value: 1521.7,
  },
  {
    category: 'TOTAL_CONSUMPTION',
    date: '17',
    value: 1615.9,
  },
  {
    category: 'TOTAL_CONSUMPTION',
    date: '18',
    value: 1703.7,
  },
  {
    category: 'TOTAL_CONSUMPTION',
    date: '19',
    value: 1767.8,
  },
  {
    category: 'TOTAL_CONSUMPTION',
    date: '20',
    value: 1806.2,
  },
  {
    category: 'TOTAL_CONSUMPTION',
    date: '21',
    value: 1903.5,
  },
  {
    category: 'TOTAL_CONSUMPTION',
    date: '22',
    value: 1986.6,
  },
  {
    category: 'TOTAL_CONSUMPTION',
    date: '23',
    value: 1952,
  },
  {
    category: 'TOTAL_CONSUMPTION',
    date: '24',
    value: 1910.4,
  },
  {
    category: 'TOTAL_CONSUMPTION',
    date: '25',
    value: 2015.8,
  },
  {
    category: 'TOTAL_CONSUMPTION',
    date: '26',
    value: 2074.7,
  },
  {
    category: 'TOTAL_CONSUMPTION',
    date: '27',
    value: 1952,
  },
  {
    category: 'TOTAL_CONSUMPTION',
    date: '28',
    value: 1910.4,
  },
  {
    category: 'TOTAL_CONSUMPTION',
    date: '29',
    value: 2015.8,
  },
  {
    category: 'TOTAL_CONSUMPTION',
    date: '30',
    value: 2074.7,
  },

  //Second

  {
    category: 'GRID_CONSUMPTION',
    date: '01',
    value: 1390.5,
  },
  {
    category: 'GRID_CONSUMPTION',
    date: '02',
    value: 1469.5,
  },
  {
    category: 'GRID_CONSUMPTION',
    date: '03',
    value: 1521.7,
  },
  {
    category: 'GRID_CONSUMPTION',
    date: '04',
    value: 1615.9,
  },
  {
    category: 'GRID_CONSUMPTION',
    date: '05',
    value: 1703.7,
  },
  {
    category: 'GRID_CONSUMPTION',
    date: '06',
    value: 1767.8,
  },
  {
    category: 'GRID_CONSUMPTION',
    date: '07',
    value: 1806.2,
  },
  {
    category: 'GRID_CONSUMPTION',
    date: '08',
    value: 1903.5,
  },
  {
    category: 'GRID_CONSUMPTION',
    date: '09',
    value: 1986.6,
  },
  {
    category: 'GRID_CONSUMPTION',
    date: '10',
    value: 1952,
  },
  {
    category: 'GRID_CONSUMPTION',
    date: '11',
    value: 1910.4,
  },
  {
    category: 'GRID_CONSUMPTION',
    date: '12',
    value: 2015.8,
  },
  {
    category: 'GRID_CONSUMPTION',
    date: '13',
    value: 2074.7,
  },

  {
    category: 'GRID_CONSUMPTION',
    date: '14',
    value: 1390.5,
  },
  {
    category: 'GRID_CONSUMPTION',
    date: '15',
    value: 1469.5,
  },
  {
    category: 'GRID_CONSUMPTION',
    date: '16',
    value: 1521.7,
  },
  {
    category: 'GRID_CONSUMPTION',
    date: '17',
    value: 1615.9,
  },
  {
    category: 'GRID_CONSUMPTION',
    date: '18',
    value: 1703.7,
  },
  {
    category: 'GRID_CONSUMPTION',
    date: '19',
    value: 1767.8,
  },
  {
    category: 'GRID_CONSUMPTION',
    date: '20',
    value: 1806.2,
  },
  {
    category: 'GRID_CONSUMPTION',
    date: '21',
    value: 1403.5,
  },
  {
    category: 'GRID_CONSUMPTION',
    date: '22',
    value: 1586.6,
  },
  {
    category: 'GRID_CONSUMPTION',
    date: '23',
    value: 1852,
  },
  {
    category: 'GRID_CONSUMPTION',
    date: '24',
    value: 1710.4,
  },
  {
    category: 'GRID_CONSUMPTION',
    date: '25',
    value: 1615.8,
  },
  {
    category: 'GRID_CONSUMPTION',
    date: '26',
    value: 1974.7,
  },
  {
    category: 'GRID_CONSUMPTION',
    date: '27',
    value: 1852,
  },
  {
    category: 'GRID_CONSUMPTION',
    date: '28',
    value: 1740.4,
  },
  {
    category: 'GRID_CONSUMPTION',
    date: '29',
    value: 1802.8,
  },
  {
    category: 'GRID_CONSUMPTION',
    date: '30',
    value: 1974.7,
  },
  //Third

  {
    category: 'SELF-CONSUMPTION',
    date: '01',
    value: 690.5,
  },
  {
    category: 'SELF-CONSUMPTION',
    date: '02',
    value: 869.5,
  },
  {
    category: 'SELF-CONSUMPTION',
    date: '03',
    value: 521.7,
  },
  {
    category: 'SELF-CONSUMPTION',
    date: '04',
    value: 615.9,
  },
  {
    category: 'SELF-CONSUMPTION',
    date: '05',
    value: 703.7,
  },
  {
    category: 'SELF-CONSUMPTION',
    date: '06',
    value: 967.8,
  },
  {
    category: 'SELF-CONSUMPTION',
    date: '07',
    value: 1406.2,
  },
  {
    category: 'SELF-CONSUMPTION',
    date: '08',
    value: 1203.5,
  },
  {
    category: 'SELF-CONSUMPTION',
    date: '09',
    value: 1486.6,
  },
  {
    category: 'SELF-CONSUMPTION',
    date: '10',
    value: 1052,
  },
  {
    category: 'SELF-CONSUMPTION',
    date: '11',
    value: 1310.4,
  },
  {
    category: 'SELF-CONSUMPTION',
    date: '12',
    value: 1015.8,
  },
  {
    category: 'SELF-CONSUMPTION',
    date: '13',
    value: 1274.7,
  },

  {
    category: 'SELF-CONSUMPTION',
    date: '14',
    value: 990.5,
  },
  {
    category: 'SELF-CONSUMPTION',
    date: '15',
    value: 1069.5,
  },
  {
    category: 'SELF-CONSUMPTION',
    date: '16',
    value: 1421.7,
  },
  {
    category: 'SELF-CONSUMPTION',
    date: '17',
    value: 1235.9,
  },
  {
    category: 'SELF-CONSUMPTION',
    date: '18',
    value: 1603.7,
  },
  {
    category: 'SELF-CONSUMPTION',
    date: '19',
    value: 1467.8,
  },
  {
    category: 'SELF-CONSUMPTION',
    date: '20',
    value: 1336.2,
  },
  {
    category: 'SELF-CONSUMPTION',
    date: '21',
    value: 903.5,
  },
  {
    category: 'SELF-CONSUMPTION',
    date: '22',
    value: 886.6,
  },
  {
    category: 'SELF-CONSUMPTION',
    date: '23',
    value: 952,
  },
  {
    category: 'SELF-CONSUMPTION',
    date: '24',
    value: 1210.4,
  },
  {
    category: 'SELF-CONSUMPTION',
    date: '25',
    value: 1415.8,
  },
  {
    category: 'SELF-CONSUMPTION',
    date: '26',
    value: 1074.7,
  },
  {
    category: 'SELF-CONSUMPTION',
    date: '27',
    value: 952,
  },
  {
    category: 'SELF-CONSUMPTION',
    date: '28',
    value: 1010.4,
  },
  {
    category: 'SELF-CONSUMPTION',
    date: '29',
    value: 1115.8,
  },
  {
    category: 'SELF-CONSUMPTION',
    date: '30',
    value: 1274.7,
  },

  //Fourth
  {
    category: 'OVERPRODUCTION',
    date: '01',
    value: -1390.5,
  },
  {
    category: 'OVERPRODUCTION',
    date: '02',
    value: -1469.5,
  },
  {
    category: 'OVERPRODUCTION',
    date: '03',
    value: -1521.7,
  },
  {
    category: 'OVERPRODUCTION',
    date: '04',
    value: -1615.9,
  },
  {
    category: 'OVERPRODUCTION',
    date: '05',
    value: -1703.7,
  },
  {
    category: 'OVERPRODUCTION',
    date: '06',
    value: -1767.8,
  },
  {
    category: 'OVERPRODUCTION',
    date: '07',
    value: -1806.2,
  },
  {
    category: 'OVERPRODUCTION',
    date: '08',
    value: -1903.5,
  },
  {
    category: 'OVERPRODUCTION',
    date: '09',
    value: -1986.6,
  },
  {
    category: 'OVERPRODUCTION',
    date: '10',
    value: -1952,
  },
  {
    category: 'OVERPRODUCTION',
    date: '11',
    value: -1910.4,
  },
  {
    category: 'OVERPRODUCTION',
    date: '12',
    value: -2015.8,
  },
  {
    category: 'OVERPRODUCTION',
    date: '13',
    value: -2074.7,
  },

  {
    category: 'OVERPRODUCTION',
    date: '14',
    value: -1390.5,
  },
  {
    category: 'OVERPRODUCTION',
    date: '15',
    value: -1469.5,
  },
  {
    category: 'OVERPRODUCTION',
    date: '16',
    value: -1521.7,
  },
  {
    category: 'OVERPRODUCTION',
    date: '17',
    value: -1615.9,
  },
  {
    category: 'OVERPRODUCTION',
    date: '18',
    value: -1703.7,
  },
  {
    category: 'OVERPRODUCTION',
    date: '19',
    value: -1767.8,
  },
  {
    category: 'OVERPRODUCTION',
    date: '20',
    value: -1806.2,
  },
  {
    category: 'OVERPRODUCTION',
    date: '21',
    value: -1903.5,
  },
  {
    category: 'OVERPRODUCTION',
    date: '22',
    value: -1986.6,
  },
  {
    category: 'OVERPRODUCTION',
    date: '23',
    value: -1952,
  },
  {
    category: 'OVERPRODUCTION',
    date: '24',
    value: -1910.4,
  },
  {
    category: 'OVERPRODUCTION',
    date: '25',
    value: -2015.8,
  },
  {
    category: 'OVERPRODUCTION',
    date: '26',
    value: -2074.7,
  },
  {
    category: 'OVERPRODUCTION',
    date: '27',
    value: -1952,
  },
  {
    category: 'OVERPRODUCTION',
    date: '28',
    value: -1910.4,
  },
  {
    category: 'OVERPRODUCTION',
    date: '29',
    value: -2015.8,
  },
  {
    category: 'OVERPRODUCTION',
    date: '30',
    value: -2074.7,
  },
];
