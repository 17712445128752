import { FC, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TablePaginationConfig } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { MAX_PAGINATION_PAGE_SIZE } from 'components/communities/constants';
import { TextRegular } from 'components/shared/Text';
import { SkeletonLine } from 'components/shared/Skeleton';
import { extractAndTransformDate } from 'utils/date/date-transform';
import { generateEmptyArray } from 'utils';
import {
  FollowUpTable,
  StyledEmptyDataWrapper,
  StyledTableWrapper,
} from '../../Dashboard/components/ProducerDashboard/components/EnergyData/styles';
import { Card } from '../../Dashboard/components/ProducerDashboard/components/FinancialDataTab/styles';
import { columns } from './constants';

type FilterType = {
  text: string;
  value: string;
};

type TableDataType = {
  period: string;
  prm: string;
  totalProduction: number;
  overproduction: number;
};

interface ProductionFollowUpTableProps {
  tableData?: TableDataType[];
  isSuccess?: boolean;
  isLoading?: boolean;
}

const ProductionFollowUpTable: FC<ProductionFollowUpTableProps> = ({
  tableData,
  isSuccess,
  isLoading,
}) => {
  const { t } = useTranslation();
  const [showedData, setShowedData] = useState<any>([]);
  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>(
    {
      current: 1,
      pageSize: 10,
    },
  );
  const [filterPrmNumber, setFilterPrmNumber] = useState<FilterType[]>([]);

  useEffect(() => {
    if (tableData) {
      setShowedData(Object.values(tableData)[0]);
      const prmNumbers = Object.keys(tableData).map((item: any) => ({
        text: item,
        value: item,
      }));

      setFilterPrmNumber(prmNumbers);
    }
  }, [tableData]);

  const handlePaginationChange = (page: number, pageSize: number) => {
    setTablePagination((prev) => ({ ...prev, current: page, pageSize }));
  };

  const modifiedColumns: ColumnsType<any> = [
    {
      title: t('period').toUpperCase(),
      dataIndex: 'period',
      sorter: (report: any, nextReport: any) => {
        const date1 = extractAndTransformDate(report.period);
        const date2 = extractAndTransformDate(nextReport.period);
        return date1.localeCompare(date2);
      },
    },
    {
      title: t('prm').toUpperCase(),
      dataIndex: 'prm',
      filters: filterPrmNumber,
      onFilter: (value: any, record: any) => record.prm.includes(String(value)),
    },
    ...columns(t),
  ].map((column) =>
    isLoading
      ? {
          ...column,
          render: () => <SkeletonLine active height={22} width={120} block />,
        }
      : column,
  );
  const hasEnoughDataToShowPagination =
    showedData && showedData?.length > MAX_PAGINATION_PAGE_SIZE && !isLoading;

  const showEmptyTable = useMemo(
    () => !isLoading && (!showedData || !showedData.length),
    [isLoading, showedData],
  );

  return (
    <>
      {showEmptyTable ? (
        <StyledEmptyDataWrapper>
          <Card isEmpty>
            <TextRegular>{t('no_data_yet').toUpperCase()}</TextRegular>
          </Card>
        </StyledEmptyDataWrapper>
      ) : (
        <StyledTableWrapper>
          <FollowUpTable
            columns={modifiedColumns}
            dataSource={isLoading ? generateEmptyArray(10) : showedData}
            pagination={
              hasEnoughDataToShowPagination
                ? {
                    ...tablePagination,
                    position: ['bottomCenter'],
                    showSizeChanger: true,
                    onChange: handlePaginationChange,
                    showTotal: (total) =>
                      `${t('total')} ${total} ${t('items')}`,
                  }
                : false
            }
          />
        </StyledTableWrapper>
      )}
    </>
  );
};

export default ProductionFollowUpTable;
