import { getAppRole } from 'utils';
import { useTranslation } from 'react-i18next';
import { PageHeader, Wrapper } from 'components/communities/styles';
import { SkeletonLine } from 'components/shared/Skeleton';
import { COLORS } from 'constants/colors';
import { USER_ROLES } from 'constants/types';
import { useGetMemberByIdQuery } from 'services/pmoMemberDashboardManagment';
import {
  MailIcon,
  StyledMail,
  StyledProfileHeader,
  StyledProfileRow,
  StyledProfileTitle,
  EmailContainer,
} from './styles';
import { useProfileByRole } from './useProfileByRole';
import React from 'react';

const Profile = () => {
  const { t } = useTranslation();
  const { profileConfig } = useProfileByRole(getAppRole());
  const { data: member, isLoading } = useGetMemberByIdQuery(undefined);

  const memberName = `${member?.firstName} ${member?.middleName} ${member?.lastName}`;
  const userRole = member?.role || USER_ROLES.NO_ROLE;
  const isAdmin = [USER_ROLES.ADMIN.toString()].includes(userRole);
  const title = isAdmin ? t('oyo_admin') : memberName;

  return (
    <Wrapper>
      <PageHeader background={COLORS.BgContainer}>
        <StyledProfileHeader>
          <StyledProfileRow>
            {isLoading ? (
              <SkeletonLine active size="large" height={38} />
            ) : (
              <StyledProfileTitle>{title}</StyledProfileTitle>
            )}
          </StyledProfileRow>
          <EmailContainer>
            <MailIcon />
            {isLoading ? (
              <SkeletonLine active size="small" height={20} />
            ) : (
              <StyledMail>{member?.email}</StyledMail>
            )}
          </EmailContainer>
        </StyledProfileHeader>
      </PageHeader>
      {profileConfig}
    </Wrapper>
  );
};

export default Profile;
