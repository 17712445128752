import { useTranslation } from 'react-i18next';

import { Button } from 'antd';

import { StyledEuroCircleOutlined } from 'components/communities/styles';
import { TextRegular } from 'components/shared/Text';
import CustomTooltip from 'components/shared/Tooltip';

const PayByCreditAction = () => {
  const { t } = useTranslation();
  return (
    <CustomTooltip
      tooltip={<TextRegular>{t('pay_by_credit_card')}</TextRegular>}
      withoutIcon
    >
      <Button size="small" icon={<StyledEuroCircleOutlined />} />
    </CustomTooltip>
  );
};

export default PayByCreditAction;
