import ConsumerSettingsTab from 'components/communities/Dashboard/components/ConsumerDashboard/components/SettingsTab';
import ProducerSettingsTab from 'components/communities/Dashboard/components/ProducerDashboard/components/SettingsTab';
import { USER_ROLES } from 'constants/types';

const SettingsTab = ({ role }: { role: string | null }) => {
  return role === USER_ROLES.PRODUCER ? (
    <ProducerSettingsTab />
  ) : (
    <ConsumerSettingsTab />
  );
};

export default SettingsTab;
