import { styled } from 'styled-components';

import { COLORS } from 'constants/colors';

import { SubHeader, TextRegular } from '../Text';

export const StyledStepsWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
`;

export const StyledStep = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const StyledStepTitle = styled(TextRegular)<{ active: boolean }>`
  font-weight: 600;
  text-transform: uppercase;
  color: ${(props) => (props.active ? COLORS.Green8 : COLORS.TextDescription)};
`;

export const StyledStepDivider = styled.div<{ active: boolean }>`
  height: ${(props) => (props.active ? '4px' : '2px')};
  margin: ${(props) => (props.active ? '0' : '1px 0')};
  width: 100%;
  background-color: ${(props) => (props.active ? COLORS.Green8 : COLORS.Grey3)};
  border-radius: 2px;
`;

export const StyledStepDescription = styled(SubHeader)<{
  active: boolean;
  isCurrentStep: boolean;
}>`
  text-align: left;
  margin: 0;
  font-weight: ${(props) => (props.isCurrentStep ? 600 : 400)};
  color: ${(props) =>
    props.active ? COLORS.TextBase : COLORS.TextDescription};
`;
