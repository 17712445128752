import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { API_URLS, REQUEST_METHOD } from '../constants/global';
import { baseQuery } from './baseQuery';
import { IGridPrices } from './gridPrices.types';

export const gridPricesApi = createApi({
  reducerPath: 'gridPricesApi',
  baseQuery,
  endpoints: (builder) => ({
    getGridPricesByPrmId: builder.query<
      IGridPrices,
      {
        id?: string;
      }
    >({
      query: ({ id }) => {
        return {
          url: `${API_URLS.GRID_PRICES}/${id}`,
          method: REQUEST_METHOD.GET,
        };
      },
    }),
    addGridPrices: builder.mutation<IGridPrices, IGridPrices>({
      query: (gridPrices) => ({
        url: API_URLS.GRID_PRICES,
        method: REQUEST_METHOD.POST,
        body: gridPrices,
      }),
    }),
    updateGridPrices: builder.mutation<IGridPrices, IGridPrices>({
      query: (gridPrices) => ({
        url: `${API_URLS.GRID_PRICES}/${gridPrices._id}`,
        method: REQUEST_METHOD.PUT,
        body: gridPrices,
      }),
    }),
  }),
  tagTypes: ['gridPricesApi'],
});

export const {
  useGetGridPricesByPrmIdQuery,
  useAddGridPricesMutation,
  useUpdateGridPricesMutation,
} = gridPricesApi;
