import React from 'react';
import { useTranslation } from 'react-i18next';

import { useFormikContext } from 'formik';

import { StyledAddressWrapper } from 'components/communities/CreateCommunity/add-pmo/AddressForm/styles';
import {
  StyledCity,
  StyledCommunityTitle,
  StyledLocationWrapper,
  StyledPostalCode,
} from 'components/communities/CreateCommunity/setup-community/styles';
import AddressAutocomplete from 'components/shared/AddressAutocomplete';
import { ADDRESS_TYPE } from 'components/shared/AddressAutocomplete/types';
import { StyledCheckbox } from 'components/shared/Checkbox/styles';
import FormikInput from 'components/shared/Input/FormikInput';
import { InputGroup } from 'components/shared/InputGroup';
import { ADDRESS_FIELD_Z_INDEX } from 'constants/global';

import { numberOrEmptyStr } from 'utils/forms';
import { useAppDispatch, useAppSelector } from 'utils/hooks';

import { IChangeAddress, IFields } from '../../../types';
import { initialValues } from '../../constants';
import { setIsSameAddress } from '../../slice';
import { StyledCheckboxWrapper } from './styles';

const BillingAddressForm: React.FC = () => {
  const { isSameAddress } = useAppSelector((state) => state.consumer);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { values, errors, setFieldValue } =
    useFormikContext<typeof initialValues>();
  const errorMessage =
    errors.additionalInfo?.billingAddress?.city ||
    errors.additionalInfo?.billingAddress?.postalCode ||
    errors.additionalInfo?.billingAddress?.street;

  const handleOnChangeAddress = async ({
    text,
    address,
    postcode,
    type,
  }: IChangeAddress) => {
    const isEmptyAddressValue =
      values.additionalInfo.billingAddress.street.length === 0;

    await setFieldValue('additionalInfo.billingAddress.city', text);

    if (postcode) {
      await setFieldValue('additionalInfo.billingAddress.postalCode', postcode);
    }

    switch (type) {
      case ADDRESS_TYPE.ADDRESS: {
        await setFieldValue('additionalInfo.billingAddress.street', address);
        break;
      }

      default: {
        if (isEmptyAddressValue) {
          await setFieldValue('additionalInfo.billingAddress.street', address);
        }
      }
    }
  };

  const handleOnCityChange = async (value: string) =>
    await setFieldValue('additionalInfo.billingAddress.city', value);
  const handleOnStreetChange = async (value: string) =>
    await setFieldValue('additionalInfo.billingAddress.street', value);
  const handleOnPostcodeChange = async (value: string) =>
    await setFieldValue(
      'additionalInfo.billingAddress.postalCode',
      numberOrEmptyStr(value),
    );

  const handleCheckbox = () => {
    dispatch(setIsSameAddress(!isSameAddress));
  };

  return (
    <>
      <StyledCommunityTitle isUppercase={true}>
        {t('billing_address')}
      </StyledCommunityTitle>

      <StyledCheckboxWrapper>
        <StyledCheckbox checked={isSameAddress} onChange={handleCheckbox}>
          {t('use_same_address_as_consumer_location')}
        </StyledCheckbox>
      </StyledCheckboxWrapper>

      {!isSameAddress && (
        <>
          <StyledAddressWrapper>
            <InputGroup
              label={t('billing_address').toString()}
              isShowError={false}
              customStyles={{ zIndex: ADDRESS_FIELD_Z_INDEX }}
            >
              <AddressAutocomplete
                isError={!!errors.additionalInfo?.billingAddress?.street}
                placeholder={t('billing_address_info').toString()}
                types={ADDRESS_TYPE.ADDRESS}
                value={values.additionalInfo.billingAddress.street}
                onChange={handleOnStreetChange}
                onSelect={(fields: IFields) =>
                  handleOnChangeAddress({
                    ...fields,
                    type: ADDRESS_TYPE.ADDRESS,
                  })
                }
              />
            </InputGroup>

            <FormikInput
              fieldName="additionalInfo.billingAddress.building"
              placeholder={t('additional_address_info').toString()}
            />
          </StyledAddressWrapper>
          <InputGroup error={errorMessage}>
            <StyledLocationWrapper>
              <StyledPostalCode>
                <InputGroup
                  label={t('postal_code').toString()}
                  isShowError={false}
                >
                  <AddressAutocomplete
                    isError={
                      !!errors.additionalInfo?.billingAddress?.postalCode
                    }
                    inputType="number"
                    placeholder="Ex. 64205"
                    value={values.additionalInfo.billingAddress.postalCode}
                    onChange={handleOnPostcodeChange}
                    onSelect={(fields: IFields) =>
                      handleOnChangeAddress({
                        ...fields,
                        type: ADDRESS_TYPE.POSTCODE,
                      })
                    }
                  />
                </InputGroup>
              </StyledPostalCode>
              <StyledCity>
                <InputGroup label={t('city').toString()} isShowError={false}>
                  <AddressAutocomplete
                    placeholder="Ex. Besançon"
                    types={ADDRESS_TYPE.PLACE_REGION_POSTCODE}
                    value={values.additionalInfo.billingAddress.city}
                    onChange={handleOnCityChange}
                    onSelect={(fields: IFields) =>
                      handleOnChangeAddress({
                        ...fields,
                        type: ADDRESS_TYPE.PLACE_REGION_POSTCODE,
                      })
                    }
                  />
                </InputGroup>
              </StyledCity>
            </StyledLocationWrapper>
          </InputGroup>
          <InputGroup label={t('country').toString()} isShowError={false}>
            <FormikInput
              fieldName="additionalInfo.billingAddress.country"
              placeholder={t('country').toString()}
              disabled
            />
          </InputGroup>
        </>
      )}
    </>
  );
};

export default BillingAddressForm;
