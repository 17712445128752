import styled from 'styled-components';

import { Switch } from 'antd';

import { SubHeader } from 'components/shared/Text';
import { COLORS } from 'constants/colors';

export const SwitchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-start;
`;

export const StyledSwitch = styled(Switch)`
  margin: 20px 8px 0 0;
`;

export const Placeholder = styled(SubHeader)`
  width: 100%;
  text-align: left;
  margin: 20px 0;
  color: ${COLORS.TextBase};
`;
