import { useTranslation } from 'react-i18next';

import { IDBTaxPrices, ITaxPrices } from 'services/taxPrices.types';

import { PriceType } from '../../types';
import { Contaner, Text } from './styled';

interface IEmptyPrices extends ITaxPrices {
  priceType: PriceType;
  priceKey:
    | 'accisePerMWh'
    | 'discountedAccisePerMWh'
    | 'turpePerMWh'
    | 'primePerMWh'
    | 'index';
  communityId?: string;
}

const EmptyPrices = ({
  current,
  upcoming,
  historical,
  priceType,
  priceKey,
  communityId,
}: IEmptyPrices) => {
  const { t } = useTranslation();

  const filterPrices = (prices: IDBTaxPrices[]) => {
    return prices.filter((price: IDBTaxPrices) => {
      if (priceKey === 'discountedAccisePerMWh' && communityId) {
        return (
          price[priceKey] !== undefined &&
          price.discountedAccisePerMWh?.communityId === communityId
        );
      }

      return price?.[priceKey] !== undefined;
    });
  };

  const currentPrices = filterPrices(current);
  const upcomingPrices = filterPrices(upcoming);
  const historicalPrices = filterPrices(historical);

  const getText = () => {
    switch (priceType) {
      case PriceType.INDEX: {
        return t('no_indexation_specified_yet').toUpperCase();
      }

      case PriceType.DISCOUNTED_ACCISE: {
        return t('no_discounted_accise').toUpperCase();
      }

      default: {
        return t('no_tax_prices', { priceType }).toUpperCase();
      }
    }
  };

  if (
    !currentPrices?.length &&
    !upcomingPrices?.length &&
    !historicalPrices?.length
  ) {
    return (
      <Contaner>
        <Text>{getText()}</Text>
      </Contaner>
    );
  }

  return null;
};

export default EmptyPrices;
