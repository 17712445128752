import React from 'react';

import {
  CheckMarkSelected,
  CheckMarkUnselected,
  ErrorValidationIcon,
  ValidationItem,
} from 'components/shared/PasswordForm/styles';
import { TextRegular } from 'components/shared/Text';

interface IFormValidationItem {
  isItemValid: boolean;
  isFormValid: boolean;
  text: string;
}

const FormValidationItem = ({
  isItemValid,
  isFormValid,
  text,
}: IFormValidationItem) => {
  const renderIcon = () => {
    if (isItemValid) return <CheckMarkSelected />;

    return !isItemValid && !isFormValid ? (
      <ErrorValidationIcon />
    ) : (
      <CheckMarkUnselected />
    );
  };

  return (
    <ValidationItem>
      {renderIcon()}
      <TextRegular>{text}</TextRegular>
    </ValidationItem>
  );
};

export default FormValidationItem;
