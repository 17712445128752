import { getNavLinks } from 'utils';

import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import AppRoutes from 'constants/routes';

import { NavItem, NavTitle, StyledContainer, Underline } from './styles';

const NavList: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const isNavLinkActive = useCallback(
    (path: AppRoutes) => pathname.startsWith(path),
    [pathname],
  );

  return (
    <StyledContainer>
      {getNavLinks().map(({ title, path, isDisabled }) => {
        const handleOnNavItemClick = () => {
          if (isDisabled) return;

          navigate(path);
        };

        return (
          <NavItem key={title} onClick={handleOnNavItemClick}>
            <NavTitle active={isNavLinkActive(path)} disabled={isDisabled}>
              {t(title)}
            </NavTitle>
            <Underline active={isNavLinkActive(path)}></Underline>
          </NavItem>
        );
      })}
    </StyledContainer>
  );
};

export default NavList;
