export const emptyPrmObject = {
  prmValue: null,
  power: null,
  name: '',
  gridPrice: {
    dynamic: {
      RH: null,
      HS_HH: null,
      HS_LH: null,
      LS_HH: null,
      LS_LH: null,
    },
    static: null,
  },
};

export const emptyDynamicObject = {
  RH: null,
  HS_HH: null,
  HS_LH: null,
  LS_HH: null,
  LS_LH: null,
};
