import { styled } from 'styled-components';

import { COLORS } from 'constants/colors';

export const StyledInfoWrapper = styled.div`
  display: flex;
  column-gap: 10px;
  padding: 25px 0px 0px 0px;
  flex-grow: 1;
`;

export const StyledInfoRow = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledInfoDataRow = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 22px;
`;

export const StyledInfoLabel = styled.div<{ borderColor: string }>`
  font-size: 12px;
  line-height: 20px;
  border-radius: 2px;
  padding-left: 8px;
  border-left: 3px solid ${(props) => props.borderColor};
  height: 24px;
  margin-bottom: 27px;
`;

export const StyledInfoAmount = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
`;

export const StyledInfoKwh = styled.span`
  color: ${COLORS.TextSecondary};
  font-size: 13px;
  font-weight: 600;
  line-height: 22px;
  margin-left: 5px;
`;
