import { styled } from 'styled-components';

import { COLORS } from 'constants/colors';

export const StyledPMOContainer = styled.div`
  padding: 32px;
  background: ${COLORS.BgContainer};
`;

export const StyledPMOBody = styled.div`
  padding: 32px;
`;

export const StyledPMOName = styled.div`
  font-size: 30px;
  font-weight: 600;
  display: flex;
  align-items: center;
`;
