import ConsumerEnergyData from 'components/communities/Dashboard/components/ConsumerDashboard/components/EnergyData';
import ProducerEnergyData from 'components/communities/Dashboard/components/ProducerDashboard/components/EnergyData';
import { USER_ROLES } from 'constants/types';

const EnergyDataTab = ({ role }: { role: string | null }) => {
  return role === USER_ROLES.PRODUCER ? (
    <ProducerEnergyData />
  ) : (
    <ConsumerEnergyData />
  );
};

export default EnergyDataTab;
