import { useTranslation } from 'react-i18next';
import {
  StyledWrapper,
  StyledHeader,
  StyledContainer,
  StyledBody,
  StyledEmptyDataWrapper,
  SubHeader,
} from './styles';

const Documents = () => {
  const { t } = useTranslation();

  return (
    <StyledWrapper>
      <StyledContainer>
        <StyledHeader>{t('documents')}</StyledHeader>
        <StyledBody>
          <StyledEmptyDataWrapper>
            <SubHeader>{t('no_documents_yet').toUpperCase()}</SubHeader>
          </StyledEmptyDataWrapper>
        </StyledBody>
      </StyledContainer>
    </StyledWrapper>
  );
};

export default Documents;
