import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation } from 'react-router-dom';

import PeriodPicker from 'components/shared/PeriodPicker';
import TabsComponent from 'components/shared/Tabs';
import { Header, TextRegular } from 'components/shared/Text';
import { COLORS } from 'constants/colors';
import AppRoutes from 'constants/routes';
import { useGetCommunityElectricityTotalQuery } from 'services/pmoDashboardManagment';
import { IDBUser } from 'services/types';

import { useAppSelector } from 'utils/hooks';
import { getDateRange } from 'utils/url/getDateRange';

import SummaryView from '../Charts/SummaryView';
import {
  HeaderContainer,
  HeaderRow,
  HeaderRowItem,
  PageHeader,
  Wrapper,
} from '../styles';
import { useTabsConfig } from './useTabsConfig';

const CommunityDetails = () => {
  const { state: community } = useLocation();
  const { t } = useTranslation();

  const period = useAppSelector((state) => state.dashboardPeriod.period);

  const { data: electricityTotal } = useGetCommunityElectricityTotalQuery({
    ...getDateRange(period),
    communityId: community?.id,
  });

  const uniqueUserIdsSet = new Set();

  const consumersAmount = community?.consumers?.reduce(
    (count: number, { user }: { user: IDBUser }) => {
      if (!uniqueUserIdsSet.has(user?._id)) {
        uniqueUserIdsSet.add(user?._id);
        return count + 1;
      }
      return count;
    },
    0,
  );

  const producersAmount = useMemo(() => {
    return Object.keys(
      community?.producers.reduce((acc: any, curr: any) => {
        if (curr.user) {
          acc[curr.user._id] = curr.user;
        }
        return acc;
      }, {}),
    ).length;
  }, [community]);

  const tabsConfig = useTabsConfig({
    consumptionTotal: electricityTotal?.consumption,
    selfConsumption: electricityTotal?.selfConsumption,
    gridConsumption: electricityTotal?.gridConsumption,
    overproduction: electricityTotal?.overproduction,
  });

  if (!community) {
    return <Navigate replace to={AppRoutes.Root} />;
  }

  return (
    <Wrapper>
      <PageHeader paddingTop="32px" background={COLORS.BgContainer}>
        <HeaderContainer>
          <HeaderRow>
            <HeaderRowItem>
              <Header>{community.communityName}</Header>
            </HeaderRowItem>
            <HeaderRowItem position="flex-end">
              <TextRegular>{t('show_data_for')}</TextRegular>
              <PeriodPicker />
            </HeaderRowItem>
          </HeaderRow>
          <SummaryView
            communityId={community.id}
            pvPower={community.pvPower}
            consumersAmount={consumersAmount}
            producersAmount={producersAmount}
            communityLocation={community.location}
            period={period}
            consumptionTotal={electricityTotal?.consumption}
            productionTotal={electricityTotal?.production}
            userId={community?.pmoId}
          />
        </HeaderContainer>
      </PageHeader>
      <TabsComponent backgroundColor={COLORS.BgContainer} items={tabsConfig} />
    </Wrapper>
  );
};

export default CommunityDetails;
