import React from 'react';
import { useTranslation } from 'react-i18next';

import { styled } from 'styled-components';

import { Divider as AntDivider } from 'antd';

import { COLORS } from 'constants/colors';
import { CONTACT_US_EMAIL } from 'constants/global';

import { StyledWrapper } from './styles';

export const Text = styled.span<{ color?: string }>`
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  color: ${({ color }) => (color ? color : COLORS.Grey4)};
`;

export const Link = styled.a`
  color: ${COLORS.Green7};
  font-weight: 600;
  font-size: 12px;
  text-decoration: none;
`;

export const Divider = styled(AntDivider)`
  display: flex;
  margin: 20px 0 8px;
`;

const ContactUs = () => {
  const { t } = useTranslation();
  const mailTo = `mailto:${CONTACT_US_EMAIL}`;

  return (
    <StyledWrapper>
      <Divider />
      <Text color={COLORS.TextBase}>{t('contact_us')}</Text>
      <Link href={mailTo}>{CONTACT_US_EMAIL}</Link>
    </StyledWrapper>
  );
};

export default ContactUs;
