import styled from 'styled-components';

import { Dropdown, MenuProps, Space } from 'antd';

import { DownOutlined } from '@ant-design/icons';

import { TextRegular } from 'components/shared/Text';
import { COLORS } from 'constants/colors';

interface IDropdown {
  currentPrm: any;
  items: { label: string; key: string }[];
  onSelect: (key: string) => void;
}

const Select = styled(Dropdown)`
  color: ${COLORS.TextSecondary};
`;

const Arrow = styled(DownOutlined)`
  color: ${COLORS.TextSecondary};
`;

const Label = styled(TextRegular)`
  font-size: 14px;
`;

const FinancialFollowupDropdown = ({
  currentPrm,
  items,
  onSelect,
}: IDropdown) => {
  const period = items.find((item) => item.key === currentPrm);

  const onClick: MenuProps['onClick'] = ({ key }: any) => {
    onSelect(key);
  };

  return (
    <Select menu={{ items, onClick }} overlayStyle={{ width: 184 }}>
      <span onClick={(event) => event.preventDefault()}>
        <Space>
          <Label>{period?.label}</Label>
          <Arrow />
        </Space>
      </span>
    </Select>
  );
};

export default FinancialFollowupDropdown;
