import { formatTooltipLabel } from 'utils';

import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { PERIOD } from 'constants/types';

import {
  StyledDate,
  StyledPercent,
  StyledPercentage,
  StyledWrapper,
} from './styles';

interface CustomTooltipProps {
  data: any;
  period: PERIOD;
}

const CustomTooltip: FC<CustomTooltipProps> = ({ data, period }) => {
  const { t } = useTranslation();
  return (
    <StyledWrapper>
      <StyledDate>
        {formatTooltipLabel(data[0].data.tooltipData, data[0].title, period, t)}
      </StyledDate>
      <StyledPercent>
        {data[0].value < 1 ? data[0].value : parseInt(String(data[0].value))}
        <StyledPercentage>%</StyledPercentage>
      </StyledPercent>
    </StyledWrapper>
  );
};

export default CustomTooltip;
