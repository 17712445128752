import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Formik, FormikValues } from 'formik';

import { StyledBackToLogin } from 'components/auth/SignIn/styles';
import { StyledPrimaryBtn } from 'components/members/styles';
import { BtnPrimary } from 'components/shared/Button';
import ContactUs from 'components/shared/ContactUs';
import {
  Container,
  EmailInput,
  ErrorConfirmPasswordIcon,
  FieldsWithValidation,
  HeaderWrapper,
  ValidationItem,
} from 'components/shared/PasswordForm/styles';
import { Header, SubHeader, TextRegular } from 'components/shared/Text';
import { COLORS } from 'constants/colors';
import AppRoutes from 'constants/routes';

import { getForgotPasswordSchema } from 'utils/validation/schemas';

enum INPUT_STATUS {
  Valid = '',
  Error = 'error',
}

interface IEmailForm {
  onSubmit: (values: FormikValues) => void;
}

const EmailForm = ({ onSubmit }: IEmailForm) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const forgotPasswordSchema = getForgotPasswordSchema(t);

  const emailPlaceholder = t('email');

  const handleOnNavigateToLogin = () => navigate(AppRoutes.SignIn);

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        email: '',
      }}
      validationSchema={forgotPasswordSchema}
      onSubmit={onSubmit}
    >
      {({
        isValid,
        values,
        errors,
        validateForm,
        handleChange,
        handleSubmit,
        setErrors,
      }) => {
        const handleOnPress = () => {
          validateForm();

          if (!isValid) {
            return;
          }

          handleSubmit();
        };

        const handleOnFocus = () => {
          setErrors({});
        };

        const fieldStatus = isValid ? INPUT_STATUS.Valid : INPUT_STATUS.Error;

        return (
          <Container>
            <HeaderWrapper>
              <Header>{t('forgot_password')}</Header>
              <SubHeader>{t('enter_your_email_address')}</SubHeader>
            </HeaderWrapper>
            <FieldsWithValidation>
              <TextRegular>{values.email ? emailPlaceholder : ''}</TextRegular>
              <EmailInput
                status={fieldStatus}
                placeholder={emailPlaceholder}
                value={values.email}
                onFocus={handleOnFocus}
                onChange={handleChange('email')}
              />
              <ValidationItem>
                {!isValid && (
                  <>
                    <ErrorConfirmPasswordIcon />
                    <TextRegular color={COLORS.ErrorBase}>
                      {errors.email}
                    </TextRegular>
                  </>
                )}
              </ValidationItem>
              <StyledPrimaryBtn>
                <BtnPrimary isDisabled={!isValid} onClick={handleOnPress}>
                  {t('submit')}
                </BtnPrimary>
              </StyledPrimaryBtn>
              <StyledBackToLogin
                type="link"
                size="large"
                onClick={handleOnNavigateToLogin}
              >
                {t('back_to_login')}
              </StyledBackToLogin>
            </FieldsWithValidation>
            <ContactUs />
          </Container>
        );
      }}
    </Formik>
  );
};

export default EmailForm;
