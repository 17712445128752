import { styled } from 'styled-components';

import { COLORS } from 'constants/colors';
import { ScreenMedium } from 'constants/screen-breakpoints';

import Image from '../Image';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  flex: 1;
`;

export const FormWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  flex: 4;
  min-width: 379px;
  background-color: ${COLORS.BgLayout};

  ${ScreenMedium`
    flex: 4;
  `}
`;

export const InnerWrapper = styled.div`
  width: 324px;
`;

export const ImageWrapper = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  flex: 6;

  ${ScreenMedium`
    display: none;
  `}
`;

export const LogoWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 440px;
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Logo = styled('img')({
  width: '164px',
  height: '80px',
});

export const BackgroundImage = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Text = styled.span`
  font-size: 18px;
  font-weight: 300;
  line-height: 28px;
  color: ${COLORS.Green3};
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-transform: uppercase;
  margin-top: 36px;
  word-wrap: break-word;
  text-align: center;
  margin-right: 32px;
  margin-left: 32px;
`;

export const TopLine = styled.div`
  display: flex;
`;

export const BottomLine = styled.div`
  display: flex;
  width: 100%;
  padding: 0 30px 14px;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
`;

export const BottomText = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  color: ${COLORS.Grey4};
  margin-left: 10px;
`;

export const Picture = styled.picture`
  flex: 1;
`;
