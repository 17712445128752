type PriceTypes = {
  [key: string]: PriceDetail[];
};

export type PriceDetail = {
  title: string;
  key: string;
  key2: string;
  tooltip?: string;
};
export const PRICE_TYPES: PriceTypes = {
  static: [
    {
      title: '',
      tooltip: '',
      key2: '',
      key: 'gridPrice.static',
    },
  ],
  dynamic: [
    {
      title: 'high_hours',
      tooltip: 'november_march_rush_hours',
      key: 'gridPrice.dynamic.HS_HH',
      key2: 'HS_HH',
    },
    {
      title: 'low_hours',
      tooltip: 'november_march__sun_whole_day',
      key: 'gridPrice.dynamic.HS_LH',
      key2: 'HS_LH',
    },
    {
      title: 'high_hours',
      tooltip: 'april_october_rush_hours',
      key: 'gridPrice.dynamic.LS_HH',
      key2: 'LS_HH',
    },
    {
      title: 'low_hours',
      tooltip: 'april_october_sun_whole_day',
      key: 'gridPrice.dynamic.LS_LH',
      key2: 'LS_LH',
    },
  ],
};
