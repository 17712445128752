import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import ElectricityConsumption from 'components/communities/Charts/ElectricityConsumption';
import {
  areaColors,
  infoItems,
  legendItems,
  lineColors,
  mapElectricityConsumptionData,
} from 'components/communities/MyCommunity/constants/ElectricityConsumption';
import CreateReportWithSelectCheckboxes from 'components/communities/components/CreateReportWithSelectCheckboxes';
import { getFormattedInfoChartValue } from 'components/communities/utils';
import TabsComponent from 'components/shared/Tabs';
import { COLORS } from 'constants/colors';
import { API_URLS, USER_ROLES } from 'constants/global';
import AppRoutes from 'constants/routes';
import {
  useGetCommunityElectricityConsumptionQuery,
  useGetCommunityQuery,
} from 'services/communityManagement';
import { useGetMembersQuery } from 'services/membersManagement';
import { useGetCommunityElectricityTotalQuery } from 'services/pmoDashboardManagment';

import { useAppSelector } from 'utils/hooks';
import { getDateRange } from 'utils/url/getDateRange';

import { StyledContainer, StyledTabWrapper } from '../../styles';
import { useTabsConfig } from './useTabsConfig';

const URLS = {
  total_production: API_URLS.GET_TOTAL_PRODUCTION_REPORT,
  self_consumption: API_URLS.GET_CONSUMERS_CSV_ENERGY_REPORTS,
};

const EnergyDataTab = () => {
  const { t } = useTranslation();
  const tabsConfig = useTabsConfig();
  const period = useAppSelector((state) => state.dashboardPeriod.period);
  const { data: community } = useGetCommunityQuery();
  const { data: members } = useGetMembersQuery();
  const [searchParams, setSearchParams] = useSearchParams();

  const { data: electricityTotal, isLoading: isLoadingElectricityTotal } =
    useGetCommunityElectricityTotalQuery({
      ...getDateRange(period),
      communityId: community?.id,
    });

  const {
    data: electricityConsumptionData,
    isLoading: isLoadingElectricityConsuption,
  } = useGetCommunityElectricityConsumptionQuery({
    ...getDateRange(period),
    communityId: community?.id,
  });

  const electricityConsumptionDataMapped = useMemo(
    () =>
      mapElectricityConsumptionData(
        electricityConsumptionData,
        period,
        undefined,
        t,
      ),
    [electricityConsumptionData, period, t],
  );

  const mappedSelectConsumers = useMemo(() => {
    if (!members) return [];
    return members
      ?.filter((member) => member.role !== USER_ROLES.PRODUCER, [])
      .map((member) => ({
        label: member.firstName + member.lastName,
        key: member._id!,
      }));
  }, [members]);

  const electricityConsumptionInfoDataArray = [
    electricityTotal?.consumption,
    electricityTotal?.selfConsumption,
    electricityTotal?.gridConsumption,
    electricityTotal?.overproduction,
  ];

  useEffect(() => {
    searchParams.set('tab', AppRoutes.EnergyData);
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  return (
    <StyledTabWrapper>
      <StyledContainer>
        <ElectricityConsumption
          title={t('electricity_consumption')}
          legendItems={legendItems(t)}
          infoItems={infoItems(t)}
          lineColors={lineColors}
          areaColors={areaColors}
          total={getFormattedInfoChartValue(
            electricityTotal?.consumption,
            electricityConsumptionInfoDataArray,
          )}
          selfConsumption={getFormattedInfoChartValue(
            electricityTotal?.selfConsumption,
            electricityConsumptionInfoDataArray,
          )}
          gridConsumption={getFormattedInfoChartValue(
            electricityTotal?.gridConsumption,
            electricityConsumptionInfoDataArray,
          )}
          overproduction={getFormattedInfoChartValue(
            electricityTotal?.overproduction,
            electricityConsumptionInfoDataArray,
          )}
          electricityConsumption={electricityConsumptionDataMapped}
          isLoading={
            isLoadingElectricityTotal || isLoadingElectricityConsuption
          }
        />
        <CreateReportWithSelectCheckboxes
          datePickerType="date"
          consumers={mappedSelectConsumers}
          apiUrls={URLS}
        />
        <TabsComponent backgroundColor={COLORS.BgLayout} items={tabsConfig} />
      </StyledContainer>
    </StyledTabWrapper>
  );
};

export default EnergyDataTab;
