import { styled } from 'styled-components';

import { COLORS } from 'constants/colors';

export const StyledSelectWrapper = styled.div<{ disabled?: boolean }>`
  position: relative;

  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : undefined)};
`;
export const StyledSelect = styled.div<{ width?: string }>`
  box-sizing: border-box;
  font-size: 14px;
  line-height: 22px;
  width: ${({ width }) => width || '252px'};
  padding: 0px 12px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  border: 1px solid #b9b9b9;
  background: ${COLORS.BgContainer};
  transition: all 0.2s ease;
  cursor: pointer;

  &:hover {
    color: ${COLORS.Green7};
    border-color: ${COLORS.Green7};
  }
`;

export const StyledSelectMenu = styled.div<{ display: boolean }>`
  box-sizing: border-box;
  position: absolute;
  top: 36px;
  left: 0;
  width: 252px;
  min-height: 22px;
  max-height: 250px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  z-index: 100;

  transition: display 0.2s ease-in-out;
  display: ${(props) => (props.display ? 'block' : 'none')};
  padding: 4px 0px;
  list-style-type: none;
  background-color: #ffffff;
  border-radius: 8px;
  outline: none;
  box-shadow:
    0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
  z-index: 1000;
`;

export const StyledSelectItemMenu = styled.div<{ withBottomBorder?: boolean }>`
  border-bottom: 1px solid #b9b9b9;
  border-width: ${(props) => (props.withBottomBorder ? '1px' : '0px')};
  padding: 4px 12px;
`;

export const StyledValueWrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
