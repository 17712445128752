import qs from 'qs';

import { createApi } from '@reduxjs/toolkit/query/react';

import { API_URLS, REQUEST_METHOD } from 'constants/global';

import { baseQuery } from './baseQuery';
import {
  DateRangeWithCommunityId,
  DateRangeWithUserId,
  DateRangeWithUserIdAndPrmId,
} from './types';

export const dashboardApi = createApi({
  reducerPath: 'consumerDashboard',
  baseQuery,
  endpoints: (builder) => ({
    getConsumerSelfProductionRatio: builder.query<any, DateRangeWithUserId>({
      query: (params) => ({
        url: `${API_URLS.GET_USER_SELF_PRODUCTION_RATIO}?${qs.stringify(
          params,
        )}`,
        method: REQUEST_METHOD.GET,
      }),
    }),
    getConsumerSelfConsumptionRatio: builder.query<any, DateRangeWithUserId>({
      query: (params) => ({
        url: `${API_URLS.GET_USER_SELF_CONSUMPTION_RATIO}?${qs.stringify(
          params,
        )}`,
        method: REQUEST_METHOD.GET,
      }),
    }),
    getElectricityUsage: builder.query<any, DateRangeWithUserIdAndPrmId>({
      query: (params) => ({
        url: `${API_URLS.GET_USER_ELECTRICITY_USAGE}?${qs.stringify(params)}`,
        method: REQUEST_METHOD.GET,
      }),
    }),
    getElectricityProduction: builder.query<any, DateRangeWithUserId>({
      query: (params) => ({
        url: `${API_URLS.GET_USER_ELECTRICITY_PRODUCTION}?${qs.stringify(
          params,
        )}`,
        method: REQUEST_METHOD.GET,
      }),
    }),
    getElectricityTotal: builder.query<any, DateRangeWithUserIdAndPrmId>({
      query: (params) => ({
        url: `${API_URLS.GET_USER_ELECTRICITY_TOTAL}?${qs.stringify(params)}`,
        method: REQUEST_METHOD.GET,
      }),
    }),

    getCommunityData: builder.query<any, DateRangeWithUserId>({
      query: (params) => ({
        url: `${API_URLS.GET_COMMUNITY_DATA}?${qs.stringify(params)}`,
        method: REQUEST_METHOD.GET,
      }),
    }),

    getSelfConsumedByType: builder.query<any, DateRangeWithUserId>({
      query: (params) => ({
        url: `${API_URLS.GET_SELF_CONSUMED_BY_TYPE}?${qs.stringify(params)}`,
        method: REQUEST_METHOD.GET,
      }),
    }),

    getSelfConsumedBySource: builder.query<any, DateRangeWithUserId>({
      query: (params) => ({
        url: `${API_URLS.GET_SELF_CONSUMED_BY_SOURCE}?${qs.stringify(params)}`,
        method: REQUEST_METHOD.GET,
      }),
    }),
    getRepartitionKey: builder.query<any, DateRangeWithUserId>({
      query: (params) => ({
        url: `${API_URLS.GET_REPARTITION_KEY}?${qs.stringify(params)}`,
        method: REQUEST_METHOD.GET,
      }),
    }),
    getEarnings: builder.query<{ earnings: number }, DateRangeWithCommunityId>({
      query: (params) => ({
        url: `${API_URLS.GET_EARNINGS}?${qs.stringify(params)}`,
        method: REQUEST_METHOD.GET,
      }),
    }),
    getFinancialData: builder.query<any, DateRangeWithUserIdAndPrmId>({
      query: (params) => ({
        url: `${API_URLS.GET_FINANCIAL_DATA}?${qs.stringify(params)}`,
        method: REQUEST_METHOD.GET,
      }),
    }),
    getInvoiceHistory: builder.query<any, { userId?: string }>({
      query: ({ userId }) => ({
        url: `${API_URLS.GET_INVOICE_HISTORY}?${qs.stringify({
          userId,
        })}`,
        method: REQUEST_METHOD.GET,
      }),
    }),
    getTaxRefundInvoiceHistory: builder.query<any, { userId?: string }>({
      query: ({ userId }) => ({
        url: `${API_URLS.GET_TAX_REFUND_INVOICE_HISTORY}?${qs.stringify({
          userId,
        })}`,
        method: REQUEST_METHOD.GET,
      }),
    }),
    getConsumerActualEmissionPrevented: builder.query<any, DateRangeWithUserId>(
      {
        query: (params) => ({
          url: `${API_URLS.GET_USER_ACTUAL_EMISSION_PREVENTED}?${qs.stringify(
            params,
          )}`,
          method: REQUEST_METHOD.GET,
        }),
      },
    ),
    getActualCostSaved: builder.query<
      { actualCostSaved?: number },
      DateRangeWithUserId
    >({
      query: (params) => ({
        url: `${API_URLS.GET_ACTUAL_COST_SAVED}?${qs.stringify(params)}`,
        method: REQUEST_METHOD.GET,
      }),
    }),
  }),
  tagTypes: ['consumerDashboard'],
});

export const {
  useGetConsumerSelfProductionRatioQuery,
  useGetConsumerSelfConsumptionRatioQuery,
  useGetElectricityUsageQuery,
  useGetElectricityProductionQuery,
  useGetElectricityTotalQuery,
  useGetCommunityDataQuery,
  useGetSelfConsumedBySourceQuery,
  useGetSelfConsumedByTypeQuery,
  useGetRepartitionKeyQuery,
  useGetEarningsQuery,
  useGetFinancialDataQuery,
  useGetInvoiceHistoryQuery,
  useGetTaxRefundInvoiceHistoryQuery,
  useGetConsumerActualEmissionPreventedQuery,
  useGetActualCostSavedQuery,
} = dashboardApi;
