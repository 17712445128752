import styled from 'styled-components';

import { PriceVariant } from 'components/pricing/components/TaxPrices/types';
import { COLORS } from 'constants/colors';

export const Table = styled.div`
  display: grid;
  grid-template-rows: 68px repeat(3, 1fr);
  background: ${COLORS.BgContainer};
`;

export const HeaderRow = styled.div<{ type?: PriceVariant }>`
  background: ${(props) =>
    props.type === PriceVariant.HISTORICAL ? COLORS.Grey7 : 'transparent'};
  display: grid;
  grid-template-columns: 1fr 64px repeat(5, 1fr);
  border-top: 1px solid ${COLORS.ColorSplit};
  border-bottom: 1px solid ${COLORS.ColorSplit};

  > :last-child {
    border-right: none;
  }
`;
