import React from 'react';
import { useTranslation } from 'react-i18next';

import { useFormikContext } from 'formik';

import AddressAutocomplete from 'components/shared/AddressAutocomplete';
import { ADDRESS_TYPE } from 'components/shared/AddressAutocomplete/types';
import FormikInput from 'components/shared/Input/FormikInput';
import { InputGroup } from 'components/shared/InputGroup';

import { numberOrEmptyStr } from 'utils/forms';

import { IChangeAddress } from '../../../../members/types';
import { initialValues } from '../../constants';
import {
  StyledCity,
  StyledLocationWrapper,
  StyledPostalCode,
} from '../../setup-community/styles';
import { StyledAddressWrapper, StyledPMOTitle } from './styles';

interface IFields {
  text: string;
  address: string;
  postcode: number | string;
}

const AddressForm: React.FC = () => {
  const { t } = useTranslation();
  const { errors, values, setFieldValue } =
    useFormikContext<typeof initialValues>();

  const handleOnChangeAddress = async ({
    text,
    address,
    postcode,
    type,
  }: IChangeAddress) => {
    const isEmptyAddressValue = values.pmo.address.street.length === 0;

    await setFieldValue('pmo.address.city', text);

    if (postcode) {
      await setFieldValue('pmo.address.postalCode', postcode);
    }

    switch (type) {
      case ADDRESS_TYPE.ADDRESS: {
        await setFieldValue('pmo.address.street', address);
        break;
      }

      default: {
        if (isEmptyAddressValue) {
          await setFieldValue('pmo.address.street', address);
        }
      }
    }
  };

  const handleOnStreetChange = async (value: string) =>
    setFieldValue('pmo.address.street', value);
  const handleOnCityChange = async (value: string) =>
    setFieldValue('pmo.address.city', value);
  const handleOnPostcodeChange = async (value: string) =>
    setFieldValue('pmo.address.postalCode', numberOrEmptyStr(value));

  return (
    <>
      <StyledPMOTitle isUppercase>{t('pmo_address')}</StyledPMOTitle>
      <StyledAddressWrapper>
        <InputGroup label={t('address').toString()} isShowError={false}>
          <AddressAutocomplete
            isError={!!errors.pmo?.address?.street}
            types={ADDRESS_TYPE.ADDRESS}
            placeholder={t('address_street_building_placeholder').toString()}
            value={values.pmo.address.street}
            onChange={handleOnStreetChange}
            onSelect={(fields: IFields) =>
              handleOnChangeAddress({
                ...fields,
                type: ADDRESS_TYPE.ADDRESS,
              })
            }
          />
        </InputGroup>
        <FormikInput
          fieldName="pmo.address.building"
          placeholder={t('additional_address_info').toString()}
        />
      </StyledAddressWrapper>
      <InputGroup
        error={
          errors.pmo?.address?.city ||
          errors.pmo?.address?.postalCode ||
          errors.pmo?.address?.street
        }
      >
        <StyledLocationWrapper>
          <StyledPostalCode>
            <InputGroup label={t('postal_code').toString()} isShowError={false}>
              <AddressAutocomplete
                isError={!!errors.pmo?.address?.postalCode}
                inputType="number"
                placeholder="Ex. 64205"
                value={values.pmo.address.postalCode}
                onChange={handleOnPostcodeChange}
                onSelect={(fields: IFields) =>
                  handleOnChangeAddress({
                    ...fields,
                    type: ADDRESS_TYPE.POSTCODE,
                  })
                }
              />
            </InputGroup>
          </StyledPostalCode>
          <StyledCity>
            <InputGroup label={t('city').toString()} isShowError={false}>
              <AddressAutocomplete
                isError={!!errors.pmo?.address?.city}
                placeholder="Ex. Besançon"
                types={ADDRESS_TYPE.PLACE_REGION_POSTCODE}
                value={values.pmo.address.city}
                onChange={handleOnCityChange}
                onSelect={(fields: IFields) =>
                  handleOnChangeAddress({
                    ...fields,
                    type: ADDRESS_TYPE.PLACE_REGION_POSTCODE,
                  })
                }
              />
            </InputGroup>
          </StyledCity>
        </StyledLocationWrapper>
      </InputGroup>
      <InputGroup label={t('country').toString()} isShowError={false}>
        <FormikInput
          fieldName="pmo.address.country"
          placeholder={t('country').toString()}
          disabled
        />
      </InputGroup>
    </>
  );
};

export default AddressForm;
