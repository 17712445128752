import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useField, useFormikContext } from 'formik';

import { StyledCommunityTitle } from 'components/communities/CreateCommunity/setup-community/styles';
import FormikInput from 'components/shared/Input/FormikInput';
import PhoneNumberInput from 'components/shared/Input/PhoneNumberInput';
import { InputGroup } from 'components/shared/InputGroup';

import { initialValues } from '../../constants';
import BillingFullName from './BillingFullName';
import { BillingInfoValuesType } from './types';

interface BillingInfoProps {
  values: BillingInfoValuesType;
}

const BillingInfo: FC<BillingInfoProps> = ({ values }) => {
  // eslint-disable @typescript-eslint/no-unused-vars
  const [, emailMeta] = useField(values.email);
  const [, phoneNumberMeta] = useField(values.phoneNumber);
  const { errors, setFieldValue } = useFormikContext<typeof initialValues>();

  const { t } = useTranslation();

  return (
    <>
      <StyledCommunityTitle isUppercase={true}>
        {t('billing_information')}
      </StyledCommunityTitle>

      <BillingFullName values={values} />

      <InputGroup
        label={t('billing_responsible_email_address').toString()}
        error={emailMeta.error}
      >
        <FormikInput fieldName={values.email} placeholder="Ex. mail@mail.com" />
      </InputGroup>

      <InputGroup
        label={t('billing_responsible_phone_number').toString()}
        error={phoneNumberMeta.error}
      >
        <PhoneNumberInput
          hasError={Boolean(
            errors?.additionalInfo?.billingPerson?.contactNumber,
          )}
          phone={values.phoneNumber}
          onChange={(contactNumber) =>
            setFieldValue(values.phoneNumber, contactNumber)
          }
        />
      </InputGroup>
    </>
  );
};

export default BillingInfo;
