import { styled } from 'styled-components';

import { SearchOutlined } from '@ant-design/icons';

export const StyledSearchWrapper = styled.div`
  min-width: 357px;
  max-width: 457px;
`;

export const StyledSearchIcon = styled(SearchOutlined)`
  opacity: 0.3;
`;
