import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SkeletonLine } from 'components/shared/Skeleton';
import {
  ConnectionIcon,
  ThunderboltIcon,
  UserIcon,
} from 'components/communities/Charts/SummaryView/components/Title/styles';

import {
  InfoItemAmount,
  InfoItemRow,
  InfoItemText,
  InfoRow,
} from '../../styles';
import { CommunityDataType } from '../../types';

interface CommunityDataInfoProps {
  data?: CommunityDataType;
  isLoading?: boolean;
}

const CommunityDataInfo: FC<CommunityDataInfoProps> = ({ data, isLoading }) => {
  const { t } = useTranslation();
  return (
    <InfoRow>
      <InfoItemRow>
        <UserIcon />
        {isLoading ? (
          <SkeletonLine active width={16} height={21} />
        ) : (
          <InfoItemAmount>{data ? data.consumersAmount : 0}</InfoItemAmount>
        )}
        <InfoItemText>{t('consumers_amount')}</InfoItemText>
      </InfoItemRow>
      <InfoItemRow>
        <ConnectionIcon />
        {isLoading ? (
          <SkeletonLine active width={16} height={21} />
        ) : (
          <InfoItemAmount>{data ? data.producersAmount : 0}</InfoItemAmount>
        )}
        <InfoItemText>{t('producers_amount')}</InfoItemText>
      </InfoItemRow>
      <InfoItemRow>
        <ThunderboltIcon />
        {isLoading ? (
          <SkeletonLine active width={36} height={21} />
        ) : (
          <InfoItemAmount>{data ? data.maxPover : 0} MW</InfoItemAmount>
        )}
        <InfoItemText>{t('pv_power')}</InfoItemText>
      </InfoItemRow>
    </InfoRow>
  );
};

export default CommunityDataInfo;
