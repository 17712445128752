import { TFunction } from 'i18next';

import { FinancialDataTypes } from 'components/communities/Dashboard/types';
import { COLORS } from 'constants/colors';

export const getColumnColorByType = (type: string) => {
  switch (type) {
    case 'earned_tax_refund':
      return COLORS.Blue6;
    case 'earned_from_community':
      return COLORS.Green7;
    default:
      return COLORS.BgContainerDisabled;
  }
};

export const getCostEarnedInfoDataByType = (type: string, t: TFunction) => {
  switch (type) {
    case FinancialDataTypes.ALL_EARNINGS:
      return {
        types: ['earned_from_community', 'earned_tax_refund'],
        infoItems: [
          {
            title: t('earned_from_community').toString(),
            color: COLORS.Green7,
            unit: '€',
          },
          {
            title: t('earned_tax_refund').toString(),
            color: COLORS.Blue6,
            unit: '€',
          },
        ],
        legendItems: [
          {
            value: 'earned_from_community',
            name: t('earned_from_community'),
            marker: { symbol: 'square', style: { fill: COLORS.Green7 } },
          },
          {
            value: 'earned_tax_refund',
            name: t('earned_tax_refund'),
            marker: { symbol: 'square', style: { fill: COLORS.Blue6 } },
          },
        ],
      };
    case FinancialDataTypes.EARNED_FROM_COMMUNITY:
      return {
        types: ['earned_from_community'],
        infoItems: [
          {
            color: COLORS.Green7,
            title: t('earned_from_community'),
            unit: '€',
          },
        ],
        legendItems: [
          {
            value: 'earned_from_community',
            name: t('earned_from_community'),
            marker: { symbol: 'square', style: { fill: COLORS.Green7 } },
          },
        ],
      };
  }
};
