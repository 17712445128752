import * as yup from 'yup';

import { TFunction } from 'i18next';

import { taxPriceSchema } from 'components/pricing/components/TaxPrices/tax-prices.schema';

export const taxAccisePricesSchema = (t: TFunction) =>
  yup.object().shape({
    startDate: yup
      .date()
      .required(t('field_is_required').toString())
      .min(new Date(), t('end_date_before_start_date').toString()),
    endDate: yup
      .date()
      .required(t('field_is_required').toString())
      .min(yup.ref('startDate'), t('end_date_after_start_date').toString()),
    accisePerMWh: yup.object().shape({
      large: taxPriceSchema(t),
      small: taxPriceSchema(t),
      private: taxPriceSchema(t),
      medium: taxPriceSchema(t),
    }),
  });
