import { getAppRole } from 'utils';

import { USER_ROLES } from 'constants/types';

import AdminUserData from '../AdminUserData';
import PmoUserData from '../PmoUserData';
import Security from '../Security';

const ProfileData = () => {
  const userRole = getAppRole();

  switch (userRole) {
    case USER_ROLES.ADMIN: {
      return (
        <>
          <AdminUserData />
          <Security />
        </>
      );
    }

    case USER_ROLES.PMO_ADMIN: {
      return (
        <>
          <PmoUserData />
          <Security />
        </>
      );
    }

    default: {
      return null;
    }
  }
};

export default ProfileData;
