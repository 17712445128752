import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';

import GenericFormName from 'components/communities/components/GenericFormName';
import AddressField from './components/AddressField';
import GenericFormSwitch from 'components/communities/components/GenericFormSwitch';
import {
  DetailCardLabel,
  StatusCardInfoIcon,
} from 'components/communities/Dashboard/components/Profile/components/Card/styles';
import { LegalPerson } from 'services/types';

interface UserEditFormData {
  isAdminOrPMO: boolean;
  isCompany: boolean;
  hasDiscountedAccise: boolean;
  hasSpecialTariffs: boolean;
  name: string;
  siretNumber?: number | null;
  location?: string;
  legalPersonName?: string;
  legalPerson?: LegalPerson;
}

export const useUserEditFormData = ({
  isAdminOrPMO,
  isCompany,
  hasDiscountedAccise,
  hasSpecialTariffs,
  name,
  siretNumber,
  location,
  legalPersonName,
  legalPerson,
}: UserEditFormData) => {
  const { t } = useTranslation();

  return {
    consumer_data: [
      {
        id: uuidv4(),
        label: 'consumer_name',
        key: '',
        value: name,
        component: GenericFormName,
        additionalBottomComponent: !isCompany && (
          <DetailCardLabel width="100%" />
        ),
      },
      isCompany && {
        id: uuidv4(),
        label: 'siren_number',
        key: 'additionalInfo.siretNumber',
        value: siretNumber,
        additionalBottomComponent: <DetailCardLabel width="100%" />,
      },
      {
        id: uuidv4(),
        label: 'location',
        key: 'address.street',
        value: location,
        component: AddressField,
      },
      hasDiscountedAccise
        ? {
            id: uuidv4(),
            label: 'special_tariffs',
            key: 'additionalInfo.hasSpecialTariffs',
            value: hasSpecialTariffs,
            component: GenericFormSwitch,
          }
        : null,
    ].filter((item) => item),
    legal_representative: [
      isCompany && {
        id: uuidv4(),
        label: 'legal_person_name',
        key: 'additionalInfo.legalPerson',
        value: legalPersonName,
        component: GenericFormName,
      },
      {
        id: uuidv4(),
        label: 'legal_person_email_address',
        key: 'additionalInfo.legalPerson.email',
        value: legalPerson?.email,
        disabled: !isAdminOrPMO,
        additionalBottomComponent: !isAdminOrPMO && (
          <DetailCardLabel width="100%">
            <StatusCardInfoIcon />
            {t('legal_person_email_change_info')}
          </DetailCardLabel>
        ),
      },
      isCompany && {
        id: uuidv4(),
        label: 'legal_person_position',
        key: 'additionalInfo.legalPerson.position',
        value: legalPerson?.position,
      },
      {
        id: uuidv4(),
        label: 'legal_person_phone_number',
        key: 'additionalInfo.legalPerson.contactNumber',
        value: legalPerson?.contactNumber,
      },
    ].filter((item) => item),
  };
};
