import { DateTime } from 'luxon';

import { PERIOD } from 'constants/types';

export const getDateRange = (period: PERIOD) => {
  const currentDay = DateTime.now();
  const previousDay = DateTime.now().minus({ days: 1 });
  const previousWeek = DateTime.now().minus({ weeks: 1 });
  const isFirstDayOfMonth = DateTime.now().day === 1;

  const endDate = previousDay.endOf('day').toISODate();

  switch (period) {
    case PERIOD.PREVIOUS_DAY: {
      const startDate = previousDay.startOf('day').toISODate();
      return {
        startDate,
        endDate,
      };
    }

    case PERIOD.LAST_SEVEN_DAYS: {
      const startDate = previousDay
        .minus({ days: 6 })
        .startOf('day')
        .toISODate();
      return {
        startDate,
        endDate,
      };
    }

    case PERIOD.CURRENT_WEEK: {
      const isFirstDayOfWeek = DateTime.now().weekday === 1;
      const firstDayOfPreviousWeek = previousWeek.startOf('week').toISODate();
      const lastDayOfPreviousWeek = previousWeek.endOf('week').toISODate();

      const startDate = DateTime.now().startOf('week').toISODate();
      return {
        startDate: isFirstDayOfWeek ? firstDayOfPreviousWeek : startDate,
        endDate: isFirstDayOfWeek ? lastDayOfPreviousWeek : endDate,
      };
    }

    case PERIOD.CURRENT_MONTH: {
      const firstDayOfPreviousMonth = DateTime.now()
        .startOf('month')
        .toISODate();
      const startDate = DateTime.now().startOf('month').toISODate();

      return {
        startDate: isFirstDayOfMonth ? firstDayOfPreviousMonth : startDate,
        endDate: previousDay.toISODate(),
      };
    }

    case PERIOD.LAST_MONTH: {
      const startDate = currentDay
        .minus({ months: 1 })
        .startOf('month')
        .toISODate();
      const endDate = currentDay
        .minus({ months: 1 })
        .endOf('month')
        .toISODate();

      return {
        startDate,
        endDate,
      };
    }

    case PERIOD.LAST_SIX_MONTH: {
      const startDate = DateTime.now()
        .minus({ months: isFirstDayOfMonth ? 6 : 5 })
        .startOf('month')
        .toISODate();
      return {
        startDate,
        endDate,
      };
    }

    case PERIOD.CURRENT_YEAR: {
      return {
        startDate: currentDay.startOf('year').toISODate(),
        endDate,
      };
    }

    case PERIOD.LAST_YEAR: {
      const startDate = currentDay
        .minus({ years: 1 })
        .startOf('year')
        .toISODate();
      const endDate = currentDay.minus({ years: 1 }).endOf('year').toISODate(); // End of the previous year
      return {
        startDate,
        endDate,
      };
    }

    case PERIOD.LAST_TWELVE_MONTHS: {
      // Date should be a full 12 calendar months from today’s date/ For example,
      // today’s date is 04/09/2024, so the self-production ratio should be for dates 04/01/2023 - 03/31/2024

      const endDate = DateTime.now().minus({ months: 1 }).endOf('month');
      const startDate = endDate.minus({ months: 11 }).startOf('month');
      return {
        startDate: startDate.toISODate(),
        endDate: endDate.toISODate(),
      };
    }

    case PERIOD.LAST_TWELVE_MONTHS_INCLUDING_CURRENT: {
      // Date should be a full 12 calendar months from today’s date/ For example,
      // today’s date is 04/09/2024, so the self-production ratio should be for dates 04/01/2023 - 03/31/2024

      const endDate = DateTime.now();
      const startDate = endDate.minus({ months: 12 });
      return {
        startDate: startDate.toISODate(),
        endDate: endDate.toISODate(),
      };
    }
  }
};
