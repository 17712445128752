import { FC, useMemo, useState } from 'react';
import { Document } from 'react-pdf';

import Loader from 'components/shared/Loader';

import { LOCAL_STORAGE_KEYS } from 'utils/local-storage';

import { StyledPdfPage, StyledPdfWrapper } from '../styles';

interface IPdfComponentProps {
  pdfFile: any;
}
const PdfComponent: FC<IPdfComponentProps> = ({ pdfFile }) => {
  const [numPages, setNumPages] = useState<number>(0);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  const options = useMemo(
    () => ({
      httpHeaders: {
        Authorization: `Bearer ${localStorage.getItem(
          LOCAL_STORAGE_KEYS.AccessToken,
        )}`,
      },
    }),
    [],
  );

  return (
    <StyledPdfWrapper>
      <Document
        file={pdfFile}
        loading={<Loader />}
        options={options}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <StyledPdfPage
            loading={false}
            key={`page_${index + 1}`}
            pageNumber={index + 1}
          />
        ))}
      </Document>
    </StyledPdfWrapper>
  );
};

export default PdfComponent;
