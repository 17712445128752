import { styled } from 'styled-components';

import { Button } from 'antd';

import {
  CalendarTwoTone,
  EditTwoTone,
  FileDoneOutlined,
  DeleteTwoTone,
} from '@ant-design/icons';

import { COLORS } from 'constants/colors';

export const StyledPrmWrapper = styled.div<{ pointerEvents: string }>`
  width: 100%;
  background-color: ${COLORS.BgLayout};
  padding: 12px 8px;
  box-sizing: border-box;
  pointer-events: ${(props) => props.pointerEvents};
  margin-bottom: 20px;
`;

export const StyledPrmHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px 6px 8px;
  border-bottom: 1px solid ${COLORS.ColorSplit};
`;

export const StyledPrmTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-right: 6px;
`;

export const StyledPrmRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-top: 16px;
`;

export const StyledGivenNameWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 16px;
  & > div > span:first-child {
    display: inline-block;
    white-space: nowrap;
    margin-right: 8px;
    color: ${COLORS.TextBase};
  }
`;

export const StyledPrmInfoItem = styled.div`
  display: flex;
  font-size: 12px;
  font-weight: 600;
  color: ${COLORS.TextBase};
`;

export const StyledPrmInfoText = styled.span`
  color: ${COLORS.Grey4};
  text-transform: uppercase;
  margin-right: 5px;
`;

export const FileIcon = styled(FileDoneOutlined)`
  color: ${COLORS.Green6};
  font-size: 12px;
  margin-right: 6px;
`;

export const CalendarIcon = styled(CalendarTwoTone).attrs(() => ({
  twoToneColor: COLORS.Green6,
}))`
  font-size: 12px;
  margin-right: 6px;
`;

export const StyledExpiredDuration = styled.span`
  color: ${COLORS.ErrorBase};
`;

export const EditIcon = styled(EditTwoTone).attrs(() => ({
  twoToneColor: COLORS.Green6,
}))`
  pointer-events: visible;
`;

export const StyledBtn = styled(Button)`
  height: 40px;
  width: 46px !important;
`;

export const StyledSuccessBtn = styled(StyledBtn)`
  background: ${COLORS.Green5};
  margin-right: 4px;
`;

export const DeleteIcon = styled(DeleteTwoTone).attrs(() => ({
  twoToneColor: COLORS.ErrorBorder,
}))`
  cursor: pointer;
  svg > path:first-child {
    fill: ${COLORS.Grey1};
  }
`;

export const SkeletonPrmNameWrapper = styled.div`
  background: ${COLORS.BgContainer};
  border: 1px solid #b9b9b9;
  border-radius: 6px;
  padding: 4px 40px 4px 4px;
`;
