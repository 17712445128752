import { TFunction } from 'i18next';

import { COLORS } from 'constants/colors';

export const legendItems = (t: TFunction) => [
  {
    value: 'TOTAL PRODUCTION',
    name: t('total_production'),
    marker: { symbol: 'square', style: { fill: COLORS.Yellow5 } },
  },
  {
    value: 'SELF-CONSUMPTION',
    name: t('self_consumption'),
    marker: { symbol: 'square', style: { fill: COLORS.Green6 } },
  },
  {
    value: 'OVERPRODUCTION',
    name: t('overproduction'),
    marker: { symbol: 'square', style: { fill: COLORS.TextTertiary } },
  },
];

export const infoItems = (t: TFunction) => [
  {
    title: t('total_production').toString(),
    color: COLORS.Yellow5,
  },
  {
    title: t('self_consumption').toString(),
    color: COLORS.Green6,
  },
  {
    title: t('overproduction').toString(),
    color: COLORS.TextTertiary,
  },
];

export const lineColors = [COLORS.Yellow5, COLORS.Green6, COLORS.TextTertiary];

export const areaColors = ['#FEF08A70', '#BBF7D050', '#F5F5F550'];
