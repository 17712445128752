import React from 'react';
import { useTranslation } from 'react-i18next';

import TitleItem from './components/TitleItem';
import {
  ConnectionIcon,
  LineDivider,
  LocationIcon,
  SummaryTitle,
  ThunderboltIcon,
  UserIcon,
} from './styles';

interface ITitle {
  communityLocation?: string;
  consumersAmount?: number;
  producersAmount?: number;
  pvPower?: string;
}

const Title = ({
  communityLocation = '25000 Besançan',
  consumersAmount = 12,
  producersAmount = 1,
  pvPower = '3',
}: ITitle) => {
  const { t } = useTranslation();

  return (
    <SummaryTitle>
      <TitleItem value={communityLocation}>
        <LocationIcon />
      </TitleItem>
      <LineDivider>|</LineDivider>
      <TitleItem
        value={consumersAmount}
        suffixDescription={t('consumers_amount').toUpperCase()}
      >
        <UserIcon />
      </TitleItem>
      <LineDivider>|</LineDivider>
      <TitleItem
        value={producersAmount}
        suffixDescription={t('producers_amount').toUpperCase()}
      >
        <ConnectionIcon />
      </TitleItem>
      <LineDivider>|</LineDivider>
      <TitleItem
        value={t('pv_power_amount', { pv_power: pvPower }).toString()}
        suffixDescription={t('pv_power').toUpperCase()}
      >
        <ThunderboltIcon />
      </TitleItem>
    </SummaryTitle>
  );
};

export default Title;
