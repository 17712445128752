import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { formatNumberWithSpace } from 'components/communities/Charts/ElectricityConsumption/utils';
import { SkeletonLine } from 'components/shared/Skeleton';
import {
  StyledInfoAmount,
  StyledInfoDataRow,
  StyledInfoKwh,
  StyledInfoLabel,
  StyledInfoRow,
  StyledInfoWrapper,
} from './styles';

interface CommunityDataChartInfoProps {
  labels: {
    name: string;
    color: string;
  }[];
  totalProduction?: string;
  selfConsumption?: string;
  gridConsumption?: string;
  overProduction?: string;
  isLoading?: boolean;
}

const CommunityDataInfoItem = ({ amount }: { amount: number | string }) => (
  <StyledInfoAmount>
    {amount ? formatNumberWithSpace(amount) : 'N/A'}
    <StyledInfoKwh>kWh</StyledInfoKwh>
  </StyledInfoAmount>
);

const CommunityDataChartInfo: FC<CommunityDataChartInfoProps> = ({
  labels,
  totalProduction = 'N/A',
  selfConsumption = 'N/A',
  gridConsumption = 'N/A',
  overProduction = 'N/A',
  isLoading,
}) => {
  const { t } = useTranslation();

  return (
    <StyledInfoWrapper>
      <StyledInfoRow>
        {labels.map((item, index) => (
          <StyledInfoLabel borderColor={item.color} key={index}>
            {t(item.name)}
          </StyledInfoLabel>
        ))}
      </StyledInfoRow>
      <StyledInfoDataRow>
        {isLoading ? (
          <SkeletonLine active width={82} height={28} />
        ) : (
          <CommunityDataInfoItem amount={totalProduction} />
        )}
        {isLoading ? (
          <SkeletonLine active width={82} height={28} />
        ) : (
          <CommunityDataInfoItem amount={selfConsumption} />
        )}
        {isLoading ? (
          <SkeletonLine active width={82} height={28} />
        ) : (
          <CommunityDataInfoItem amount={overProduction} />
        )}
        {isLoading ? (
          <SkeletonLine active width={82} height={28} />
        ) : (
          <CommunityDataInfoItem amount={gridConsumption} />
        )}
      </StyledInfoDataRow>
    </StyledInfoWrapper>
  );
};

export default CommunityDataChartInfo;
