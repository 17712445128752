import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Container,
  ExclamationIcon,
} from 'components/auth/CreatePassword/components/styles';
import { BtnPrimary } from 'components/shared/Button';
import ContactUs from 'components/shared/ContactUs';
import { MarginTop32px, MarginTop40px } from 'components/shared/Margin';
import { Header, TextRegular } from 'components/shared/Text';
import AppRoutes from 'constants/routes';

interface ICreatePasswordUnsuccessful {
  headerText: string;
  descriptionText: string;
  buttonText: string;
}

const CreatePasswordUnsuccessful = ({
  headerText,
  descriptionText,
  buttonText,
}: ICreatePasswordUnsuccessful) => {
  const navigate = useNavigate();

  const handleOnClick = () =>
    navigate(AppRoutes.ForgotPassword, { replace: true });

  return (
    <Container>
      <ExclamationIcon />
      <Header>{headerText}</Header>
      <TextRegular>{descriptionText}</TextRegular>
      <MarginTop40px />
      <BtnPrimary onClick={handleOnClick}>{buttonText}</BtnPrimary>
      <MarginTop32px />
      <ContactUs />
    </Container>
  );
};

export default CreatePasswordUnsuccessful;
