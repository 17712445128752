import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox } from 'antd';

import { ALL_CONSUMERS } from 'components/communities/Dashboard/constants';
import {
  ArrowDownIcon,
  ArrowUpIcon,
} from 'components/shared/CustomSelect/styles';
import { CustomSelectItem } from 'constants/types';

import {
  StyledSelect,
  StyledSelectItemMenu,
  StyledSelectMenu,
  StyledSelectWrapper,
  StyledValueWrapper,
} from './styles';

export interface CustomSelectProps {
  isDisabled?: boolean;
  items: CustomSelectItem[];
  onChange: (items: CustomSelectItem[]) => void;
  selectWidth?: string;
}

const CustomCheckboxSelect = ({
  isDisabled,
  items,
  onChange,
  selectWidth,
}: CustomSelectProps) => {
  const { t } = useTranslation();
  const [selectedOptions, setSelectedOptions] = useState(items);
  const [isSelectVisible, setSelectVisible] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    onChange(selectedOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions]);

  useEffect(() => {
    setAllConsumers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  const hasAllSelected = items.length === selectedOptions.length;

  const setAllConsumers = () => {
    setSelectedOptions(items);
    onChange(items);
  };

  const handleCheckboxChange = ({ label, key }: CustomSelectItem) => {
    // Prevent to set a checkbox value while all consumers value are chosen
    if (selectedOptions.find((option) => option.key === ALL_CONSUMERS)) return;

    if (selectedOptions.find((option) => option.key === key)) {
      return setSelectedOptions((prev) =>
        prev.filter((item) => item.key !== key),
      );
    }

    setSelectedOptions((prev) => [...prev, { label, key }]);
  };

  const handleCheckboxAllChange = () => {
    if (hasAllSelected) {
      return setSelectedOptions([]);
    }

    setAllConsumers();
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setSelectVisible(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectRef]);

  const renderSelectValue = useMemo(() => {
    if (hasAllSelected) return t('all_consumers');
    if (selectedOptions.length === 1) return selectedOptions[0].label;
    if (selectedOptions.length > 1) return t('multiple_consumers');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions]);

  return (
    <StyledSelectWrapper disabled={isDisabled} ref={selectRef}>
      <StyledSelect
        width={selectWidth}
        onClick={() => setSelectVisible(!isSelectVisible)}
      >
        <StyledValueWrapper>{renderSelectValue}</StyledValueWrapper>
        <div onClick={() => setSelectVisible(!isSelectVisible)}>
          {isSelectVisible ? <ArrowUpIcon /> : <ArrowDownIcon />}
        </div>
      </StyledSelect>
      <StyledSelectMenu display={isSelectVisible}>
        <StyledSelectItemMenu withBottomBorder={true}>
          <Checkbox
            onChange={handleCheckboxAllChange}
            checked={hasAllSelected}
            value={ALL_CONSUMERS}
          >
            {t('select_all_consumers')}
          </Checkbox>
        </StyledSelectItemMenu>
        {items.map(({ label, key }) => (
          <StyledSelectItemMenu key={key}>
            <Checkbox
              onChange={(e) => handleCheckboxChange({ key, label })}
              checked={
                !!selectedOptions.find((option) => option.label === label)
              }
              value={key}
            >
              {label}
            </Checkbox>
          </StyledSelectItemMenu>
        ))}
      </StyledSelectMenu>
    </StyledSelectWrapper>
  );
};

export default CustomCheckboxSelect;
