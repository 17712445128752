import { styled } from 'styled-components';

import { COLORS } from 'constants/colors';

export const WidgetCostSavedImage = styled.img`
  width: 49px;
  height: 49px;
  margin-right: 8px;
`;

export const WidgetEuro = styled.span`
  color: ${COLORS.TextSecondary};
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  margin-left: 5px;
`;

export const TinyColumnContainer = styled.div`
  margin-top: 25px;
  height: 55px;
  width: 100%;
`;
