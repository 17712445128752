import styled from 'styled-components';

import { SkeletonLine } from 'components/shared/Skeleton';

export const StyledTitleSkeleton = styled(SkeletonLine)`
  margin-left: 8px;
`;

export const StyledOrgNameSkeleton = styled(SkeletonLine)`
  margin-bottom: 2px;
`;
