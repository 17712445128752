import { styled } from 'styled-components';
import { ScreenLarge } from 'constants/screen-breakpoints';
import { COLORS } from 'constants/colors';

export const StyledInfoWrapper = styled.div`
  background-color: ${COLORS.Green1};
  border: 1px solid rgba(0, 0, 0, 0.06);
  height: 60px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 40px;
  padding: 0px 20px;
`;

export const StyledCommunityRow = styled.div`
  display: flex;
  align-items: center;
  background-color: ${COLORS.BgContainer};
  padding: 10px 15px 10px 15px;
  column-gap: 60px;
  ${ScreenLarge`
    column-gap: 20px;
  `}
`;

export const StyledCommunityTitle = styled.h3`
  font-size: 20px;
  font-weight: 600;
`;

export const StyleCommunityInfoItem = styled.div`
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  display: flex;
  align-items: center;
`;

export const StlyedCommunitySubtitle = styled.div`
  color: rgba(0, 0, 0, 0.65);
  margin-right: 10px;
`;

export const StlyedCommunityAmount = styled.div`
  color: rgba(0, 0, 0, 0.88);
  margin-right: 10px;
`;
