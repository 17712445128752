import { v4 as uuidv4 } from 'uuid';

import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CommunityIcon } from 'components/members/Dashboard/components/SummaryDetails/components/Title/styles';
import { SkeletonLine } from 'components/shared/Skeleton';
import { communityInfoItems } from './constants';
import {
  StlyedCommunityAmount,
  StlyedCommunitySubtitle,
  StyleCommunityInfoItem,
  StyledCommunityRow,
  StyledCommunityTitle,
  StyledInfoWrapper,
} from './styles';

interface CommunityInfoProps {
  community?: Record<string, string | number>;
  isLoadingCommunity?: boolean;
}

const CommunityInfo: FC<CommunityInfoProps> = ({
  community,
  isLoadingCommunity,
}) => {
  const { t } = useTranslation();

  return (
    <StyledInfoWrapper>
      <StyleCommunityInfoItem>
        <CommunityIcon />
        <StlyedCommunitySubtitle>
          {t('community').toUpperCase()}
        </StlyedCommunitySubtitle>
        {isLoadingCommunity ? (
          <SkeletonLine active width={160} height={24} />
        ) : (
          <StyledCommunityTitle>{community?.name}</StyledCommunityTitle>
        )}
      </StyleCommunityInfoItem>
      <StyledCommunityRow>
        {communityInfoItems(t).map((item) => (
          <StyleCommunityInfoItem key={uuidv4()}>
            {item.icon}
            <StlyedCommunityAmount>
              {isLoadingCommunity
                ? item.loadingSkeleton
                : community?.[item.value]}
            </StlyedCommunityAmount>
            <StlyedCommunitySubtitle>
              {item.subtitle.replace('#', '').toUpperCase()}
            </StlyedCommunitySubtitle>
          </StyleCommunityInfoItem>
        ))}
      </StyledCommunityRow>
    </StyledInfoWrapper>
  );
};

export default CommunityInfo;
