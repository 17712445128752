import React, { FC } from 'react';

import PdfComponent from './components/PdfComponent';
import { StyledPdfModal } from './styles';

interface IPdfViewerProps {
  isModalOpen: boolean;
  setIsModalOpen: any;
  pdfFile: any;
}
const PdfViewer: FC<IPdfViewerProps> = ({
  isModalOpen,
  setIsModalOpen,
  pdfFile,
}) => {
  return (
    <>
      <StyledPdfModal
        footer={<></>}
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
      >
        <PdfComponent pdfFile={pdfFile} />
      </StyledPdfModal>
    </>
  );
};

export default PdfViewer;
