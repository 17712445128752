import { styled } from 'styled-components';

import { Skeleton } from 'antd';

export const SkeletonLine = styled(Skeleton.Input)<{
  height?: number;
  width?: number;
}>`
  min-height: ${({ height }) => (height ? height : 24)}px;
  max-height: ${({ height }) => (height ? height : 24)}px;
  min-width: ${({ width }) =>
    width ? width + 'px!important' : 'auto!important'};
  max-width: ${({ width }) => (width ? width + 'px' : 'auto')};
`;
