import { getAppRole } from 'utils';

import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'antd';
import { SkeletonLine } from 'components/shared/Skeleton';
import { USER_ROLES } from 'constants/types';
import { useUpdatePaymentMutation } from 'services/paymentManagment';

import {
  DetailCardBlock,
  DetailCardLabel,
  DetailCardNoPaymentLabel,
  DetailCardTitle,
  DetailCardValue,
} from '../../../../../Profile/components/Card/styles';
import { DetailCardRow } from '../../../../../Profile/styles';
import { StyledButtonRow } from '../../styles';

interface PaymentMethodCardProps {
  title: string;
  labelWidth: string;
  iban?: string;
  paymentMethodId?: string;
  refetch?: () => void;
  paymentMethodAdded: boolean;
  isLoading?: boolean;
}

const PaymentMethodCard: FC<PaymentMethodCardProps> = ({
  title,
  labelWidth,
  iban,
  paymentMethodId,
  paymentMethodAdded,
  refetch,
  isLoading,
}) => {
  const { t } = useTranslation();
  const role = getAppRole();
  const [
    updatePayment,
    { isLoading: isLoadingUpdatePayment, isSuccess, data },
  ] = useUpdatePaymentMutation();

  const handleChangePayment = async () => {
    try {
      await updatePayment(paymentMethodId);
      refetch?.();
    } catch (error) {}
  };

  useEffect(() => {
    if (isSuccess) {
      window.location.replace(data?.sessionUrl!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  return (
    <DetailCardBlock>
      <DetailCardTitle>{title}</DetailCardTitle>
      <DetailCardRow>
        <DetailCardLabel width={labelWidth}>
          {t('payment_type')}
        </DetailCardLabel>
        {isLoading && <SkeletonLine active size="large" height={20} />}
        {!isLoading && (
          <DetailCardValue>
            {paymentMethodAdded ? (
              t('direct_debit')
            ) : (
              <DetailCardNoPaymentLabel>
                {t('no_payment_method_set')}
              </DetailCardNoPaymentLabel>
            )}
          </DetailCardValue>
        )}
      </DetailCardRow>
      {role === USER_ROLES.CONSUMER && (
        <DetailCardRow>
          <DetailCardLabel width={labelWidth}>IBAN</DetailCardLabel>
          <DetailCardValue>{iban}</DetailCardValue>
        </DetailCardRow>
      )}
      {paymentMethodId && (
        <StyledButtonRow>
          <Button
            onClick={handleChangePayment}
            disabled={isLoadingUpdatePayment}
          >
            {t('change_payment_method')}
          </Button>
        </StyledButtonRow>
      )}
    </DetailCardBlock>
  );
};

export default PaymentMethodCard;
