import { useTranslation } from 'react-i18next';

import { Form, Formik } from 'formik';

import {
  BtnContainer,
  ErrorContainer,
  ErrorIcon,
  ErrorMsg,
  ErrorText,
  HeaderCell,
  HeaderCellLeft,
  TableWrapper,
} from 'components/pricing/components/TaxPrices/styles';
import { PriceType } from 'components/pricing/components/TaxPrices/types';
import { BtnDefault, BtnSmallPrimary } from 'components/shared/Button';
import { IDBTaxPrices } from 'services/taxPrices.types';

import DateRange from '../../../DateRange';
import PriceRow from './components/PriceRow';
import { accisePricesInitialValues } from './modify-accise-prices.helper';
import { taxAccisePricesSchema } from './modify-accise-prices.schema';
import { HeaderRow, Table } from './styles';

interface IModifyAccisePrices {
  taxPrices?: IDBTaxPrices;
  onClose: () => void;
  onDelete: (id: string) => void;
  onSave: (values: IDBTaxPrices) => void;
}

const ModifyAccisePrices = ({
  taxPrices,
  onClose,
  onDelete,
  onSave,
}: IModifyAccisePrices) => {
  const { t } = useTranslation();
  const hasTaxPrices = Boolean(taxPrices);
  const initialValues = {
    ...taxPrices,
    accisePerMWh: taxPrices?.accisePerMWh || accisePricesInitialValues,
  } as IDBTaxPrices;

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={initialValues}
      validationSchema={taxAccisePricesSchema(t)}
      onSubmit={(values) => onSave(values)}
    >
      {({ isValid, isSubmitting, errors, validateForm, handleSubmit }) => {
        const isSaveBtnDisabled = isSubmitting || !isValid;
        const handleOnSave = () => {
          validateForm();

          if (!isValid) {
            return;
          }

          handleSubmit();
        };

        return (
          <Form>
            <div>
              <DateRange priceType={PriceType.ACCISE} />
              <TableWrapper>
                <Table>
                  <HeaderRow>
                    <HeaderCellLeft>{`${t(
                      'electricity',
                    )}, TTC`}</HeaderCellLeft>
                    <HeaderCell>{t('price_ht')}</HeaderCell>
                    <HeaderCell>{t('price_vat')}</HeaderCell>
                    <HeaderCell>{t('price_ttc')}</HeaderCell>
                  </HeaderRow>
                  <PriceRow
                    title={t('energy_private_house')}
                    description="(<36 kVA)"
                    path="private"
                  />
                  <PriceRow
                    title={t('small_and_medium_companies')}
                    description="(>36 kVA)"
                    path="medium"
                  />
                  <PriceRow
                    title={t('large_company')}
                    description="(>250 kVA)"
                    path="large"
                  />
                </Table>
              </TableWrapper>
              <ErrorMsg>
                <ErrorMsg>
                  {!isValid && Object.keys(errors).length > 0 && (
                    <ErrorContainer>
                      <ErrorIcon />
                      <ErrorText>{t('please_fill_all_fields')}</ErrorText>
                    </ErrorContainer>
                  )}
                </ErrorMsg>
              </ErrorMsg>
              <BtnContainer>
                <BtnDefault onClick={onClose}>{t('cancel')}</BtnDefault>
                {hasTaxPrices && (
                  <BtnDefault
                    danger
                    onClick={() => onDelete(taxPrices?._id || '')}
                  >
                    {t('delete')}
                  </BtnDefault>
                )}
                <BtnSmallPrimary
                  isDisabled={isSaveBtnDisabled}
                  onClick={handleOnSave}
                >
                  {t('save_new_price')}
                </BtnSmallPrimary>
              </BtnContainer>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ModifyAccisePrices;
