import { ChangeEvent } from 'react';

import { useFormikContext } from 'formik';

import { FieldStyled } from 'components/pricing/components/TaxPrices/styles';

import { Cell } from '../PriceRow/styles';

type IField = {
  property: 'ht' | 'vat' | 'ttc';
};

const PriceField = ({ property }: IField) => {
  const { values, errors, setFieldValue, setErrors } = useFormikContext();

  const isDisabled = property === 'vat' || property === 'ttc';
  const err = errors as any;
  const vals = values as any;

  const isError = !isDisabled && !!err?.discountedAccisePerMWh?.[property];
  const value = vals?.discountedAccisePerMWh?.[property];
  const placeholder = isDisabled ? '0.00' : '32.07';

  const handleOnChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const ht = Number(event.target.value) as any;
    const vat = parseFloat(((ht / 100) * 20).toFixed(2));
    const ttc = parseFloat((ht * 1.2).toFixed(2));

    await setFieldValue('discountedAccisePerMWh.ht', ht);
    await setFieldValue('discountedAccisePerMWh.vat', vat);
    await setFieldValue('discountedAccisePerMWh.ttc', ttc);
  };

  const handleOnFocus = () => {
    setErrors({});
  };

  return (
    <Cell>
      <FieldStyled
        error={isError ? 'error' : undefined}
        type="number"
        disabled={isDisabled}
        value={value || ''}
        name={`discountedAccisePerMWh.${property}`}
        placeholder={placeholder}
        onFocus={handleOnFocus}
        onChange={handleOnChange}
      />
    </Cell>
  );
};

export default PriceField;
