import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { API_URLS, REQUEST_METHOD } from '../constants/global';
import { baseQuery } from './baseQuery';
import { IAddPrmPrice, IDBPrmPrice } from './types';

export const prmPricesApi = createApi({
  reducerPath: 'prmPricesApi',
  baseQuery,
  endpoints: (builder) => ({
    getPrmPriceByCommunityId: builder.query<
      IDBPrmPrice[],
      { communityId: string }
    >({
      query: ({ communityId }) => {
        return {
          url: `${API_URLS.PRM_PRICE}/${communityId}`,
          method: REQUEST_METHOD.GET,
        };
      },
    }),
    addPrmPrice: builder.mutation<
      void,
      { communityId: string; prmId: string; addPrmPrice: IAddPrmPrice }
    >({
      query: ({ communityId, prmId, addPrmPrice }) => ({
        url: `${API_URLS.PRM_PRICE}/${communityId}/add/${prmId}`,
        method: REQUEST_METHOD.POST,
        body: addPrmPrice,
      }),
    }),
    editPrmPrice: builder.mutation<
      void,
      { periodId: string; prmId: string; updatePrmPrice: IAddPrmPrice }
    >({
      query: ({ periodId, prmId, updatePrmPrice }) => ({
        url: `${API_URLS.PRM_PRICE}/${prmId}/update/${periodId}`,
        method: REQUEST_METHOD.PUT,
        body: updatePrmPrice,
      }),
    }),
    deletePrmPrice: builder.mutation<void, { prmId: string; cppa: string }>({
      query: ({ prmId, cppa }) => ({
        url: `${API_URLS.PRM_PRICE}/${prmId}/delete/${cppa}`,
        method: REQUEST_METHOD.PUT,
      }),
    }),
  }),
  tagTypes: ['prmPricesApi'],
});

export const {
  useGetPrmPriceByCommunityIdQuery,
  useAddPrmPriceMutation,
  useEditPrmPriceMutation,
  useDeletePrmPriceMutation,
} = prmPricesApi;
