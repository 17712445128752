import { styled } from 'styled-components';
import { COLORS } from 'constants/colors';

export const StyledHeader = styled.div`
  font-size: 30px;
  font-weight: 600;
  text-transform: capitalize;
`;

export const StyledWrapper = styled.div`
  background: ${COLORS.BgLayout};
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledContainer = styled.div`
  padding: 32px;
  width: 1440px;
  margin: 0 auto;
  box-sizing: border-box;
`;

export const StyledOutletWrapper = styled.div`
  width: 724px;
`;

export const StyledBody = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
