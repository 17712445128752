import React, { useState } from 'react';

import { FormikValues } from 'formik';

import EmailForm from 'components/auth/ForgotPassword/components/EmailForm';
import ForgotPasswordSuccess from 'components/auth/ForgotPassword/components/ForgotPasswordSuccess';
import ForgotPasswordUnsuccessful from 'components/auth/ForgotPassword/components/ForgotPasswordUnsuccessful';
import AuthWrapper from 'components/shared/AuthWrapper';
import { usePutForgotPasswordMutation } from 'services/userManagement';

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState('');
  const [putForgotPassword, { isError, isSuccess }] =
    usePutForgotPasswordMutation();

  const handleOnSubmit = ({ email }: FormikValues) => {
    putForgotPassword(email);
    setEmail(email);
  };

  const handleOnResendEmail = () => putForgotPassword(email);

  if (isSuccess) {
    return (
      <AuthWrapper>
        <ForgotPasswordSuccess
          email={email}
          onResendEmail={handleOnResendEmail}
        />
      </AuthWrapper>
    );
  }

  if (isError) {
    return (
      <AuthWrapper>
        <ForgotPasswordUnsuccessful />
      </AuthWrapper>
    );
  }

  return (
    <AuthWrapper>
      <EmailForm onSubmit={handleOnSubmit} />
    </AuthWrapper>
  );
};

export default ForgotPassword;
