import { styled } from 'styled-components';

import { COLORS } from 'constants/colors';

export const StyledWrapper = styled.div`
  width: 141px;
  height: 102px;
  padding: 12px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  box-sizing: border-box;
`;

export const StyledDate = styled.div`
  color: ${COLORS.TextSecondary};
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 10px;
`;

export const StyledPercent = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;

export const StyledPercentage = styled.span`
  color: ${COLORS.TextSecondary};
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
`;
