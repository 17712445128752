import { v4 as uuidv4 } from 'uuid';

import { useEffect, useState } from 'react';

const useMappedConsumers = (consumers: any) => {
  const [mappedConsumers, setMappedConsumers] = useState([]);

  useEffect(() => {
    if (consumers.length > 0) {
      const uniqueData = consumers.reduce((acc: any, current: any) => {
        const user = acc.find(
          (consumer: any) => consumer?.user?._id === current?.user?._id,
        );

        if (!user) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);

      const newMappedConsumers = uniqueData
        .filter(
          (consumer: any) =>
            consumer?.user?.additionalInfo?.hasSpecialTariffs === true,
        )
        .map((consumer: any) => {
          const firstName = consumer?.user?.firstName;
          const middleName = consumer?.user?.middleName;
          const lastName = consumer?.user?.lastName;

          const name = middleName
            ? `${firstName} ${middleName} ${lastName}`
            : `${firstName} ${lastName}`;

          return {
            key: uuidv4(),
            label: firstName && lastName ? name : consumer?.user?._id,
            value: name,
            data: consumer?.user,
          };
        });

      setMappedConsumers(newMappedConsumers);
    }
  }, [consumers]);

  return mappedConsumers;
};

export default useMappedConsumers;
