import styled from 'styled-components';

import { InfoCircleOutlined } from '@ant-design/icons';

import { COLORS } from 'constants/colors';
import {
  BREAKPOINTS_RESOLUTION,
  ScreenMedium,
} from 'constants/screen-breakpoints';

import { TextRegular } from '../../shared/Text';

export const PageWrapper = styled.div`
  width: 1440px;
`;
export const Container = styled.div`
  padding: 32px 0;
  display: flex;
  flex-direction: column;
  width: 1440px;
`;

export const HeaderWrapper = styled.div`
  padding: 0 32px;
  display: flex;
  justify-content: space-between;
`;

export const BtnWrapper = styled.div`
  min-width: 224px;
`;

export const CardsContainer = styled.div`
  display: flex;
  gap: 24px;
  margin: 32px 0;
  padding: 0 32px;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;

  @media (max-width: ${BREAKPOINTS_RESOLUTION.Medium}px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const Card = styled.div<{ isEmpty?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 440px;
  height: 260px;
  background-color: ${({ isEmpty }) =>
    isEmpty ? COLORS.Grey2 : COLORS.BgContainer};

  margin-left: ${({ isEmpty }) => (isEmpty ? '32px' : 0)};

  border-radius: 6px;
  border: ${({ isEmpty }) =>
    isEmpty ? 'none' : '1px solid rgba(0, 0, 0, 0.06)'};

  overflow-wrap: break-word;
  overflow: hidden;
  cursor: pointer;
  ${ScreenMedium`
    width: 100%;
  `}
`;

export const MapWrapper = styled.div`
  padding: 0 32px;
`;

export const MapContainer = styled.div`
  display: flex;
  height: 458px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  background: ${COLORS.BgContainer};
`;

export const CardHeader = styled.div`
  height: 48px;
  align-items: center;
  display: flex;
  width: 100%;
`;

export const CardTitle = styled.span`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  padding-left: 16px;
`;

export const CardBody = styled.div`
  display: flex;
  width: calc(100% - 32px);
  padding: 16px;
  border-top: 1px solid ${COLORS.Grey2};
`;

export const CardBodyWrapper = styled.div<{ size?: number }>`
  display: flex;
  flex: ${({ size }) => (size ? size : 1)};

  margin-top: 2px;
`;

export const ChartCardLine = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 0 8px;
`;

export const ChartCardLineItem = styled.div`
  display: flex;
  align-items: center;
`;

export const CardBodyColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  :first-child {
    margin-top: 0;
  }

  :last-child {
    margin-top: 0;
  }
`;

export const CardChartContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 2px;
  background: ${COLORS.BgLayout};
`;

export const CardChartTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 4px;
`;

export const PopoverIcon = styled(InfoCircleOutlined)`
  margin-left: 5px;
  width: 14px;
  height: 14px;
  color: ${COLORS.Grey4};
`;

export const Content = styled.div``;

export const TextWrapper = styled.div<{ width?: string }>`
  width: ${({ width }) => (width ? width : '90px')};
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space: nowrap;
  overflow: hidden;
  -webkit-line-clamp: 3;
  line-height: 1.2;
`;

export const ChartWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 4px;
`;

export const EnergyIcon = styled.div`
  width: 3px;
  height: 3px;
  flex-shrink: 0;
  margin-right: 4px;

  border-radius: 3px;
  background: ${COLORS.BgContainer};
`;

export const EnergyIconYellow = styled(EnergyIcon)`
  border: 3px solid ${COLORS.Yellow6};
`;

export const EnergyIconOrange = styled(EnergyIcon)`
  border: 3px solid ${COLORS.Orange6};
`;

export const popoverOverlayStyles = {
  width: '195px',
};

export const TextLabel = styled(TextRegular)`
  font-size: 11px;
`;

export const LegendText = styled(TextRegular)<{ bold?: boolean }>`
  font-size: 11px;
  line-height: normal;
  font-weight: ${({ bold }) => (bold ? 600 : 400)};
`;
