import { FC, ReactNode } from 'react';

import { DetailCardRow } from '../../styles';
import {
  DetailCardBlock,
  DetailCardLabel,
  DetailCardTitle,
  DetailCardValue,
} from './styles';

interface DetailCardProps {
  title: string;
  labels?: Record<string, string>;
  labelWidth?: string;
  firstValue?: string | ReactNode;
  secondValue?: string | number | null | ReactNode;
  thirdValue?: string | ReactNode;
  fourthValue?: string | number | null | ReactNode;
  containerWidth?: string;
  valueWidth?: string;
}

const DetailCard: FC<DetailCardProps> = ({
  title,
  labels,
  firstValue = 'N/A',
  secondValue = 'N/A',
  thirdValue = 'N/A',
  fourthValue = 'N/A',
  labelWidth,
  containerWidth,
  valueWidth,
}) => {
  return (
    <DetailCardBlock width={containerWidth}>
      <DetailCardTitle>{title}</DetailCardTitle>
      <DetailCardRow>
        <DetailCardLabel width={labelWidth}>{labels?.first}</DetailCardLabel>
        <DetailCardValue width={valueWidth}>{firstValue}</DetailCardValue>
      </DetailCardRow>
      <DetailCardRow>
        <DetailCardLabel width={labelWidth}>{labels?.second}</DetailCardLabel>
        <DetailCardValue width={valueWidth}>{secondValue}</DetailCardValue>
      </DetailCardRow>
      <DetailCardRow>
        <DetailCardLabel width={labelWidth}>{labels?.third}</DetailCardLabel>
        <DetailCardValue width={valueWidth}>{thirdValue}</DetailCardValue>
      </DetailCardRow>
      <DetailCardRow>
        <DetailCardLabel width={labelWidth}>
          {labels?.fourth || ''}
        </DetailCardLabel>
        <DetailCardValue width={valueWidth}>
          {labels?.fourth ? fourthValue : ''}
        </DetailCardValue>
      </DetailCardRow>
    </DetailCardBlock>
  );
};

export default DetailCard;
