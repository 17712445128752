import { formatTooltipLabel } from 'utils';

import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { PERIOD } from 'constants/types';

import { formatNumberWithSpace, getStringWithUnderscore } from '../../utils';
import {
  StyledDate,
  StyledKw,
  StyledPower,
  StyledRow,
  StyledType,
  StyledWrapper,
} from '../AreaChart/styles';

type TooltipDataType = {
  color: string;
  data: any;
  mappingData: any;
  marker: boolean;
  name: string;
  title: string;
  value: string;
  x: number;
  y: number;
};

interface CustomTooltipProps {
  data: TooltipDataType[];
  period: PERIOD;
}

interface CustomTooltipBlockProps {
  power: string;
  type: string;
}

const CustomTooltipBlock: FC<CustomTooltipBlockProps> = ({ power, type }) => {
  const { t } = useTranslation();
  const areaType = getStringWithUnderscore(type).toLowerCase();

  return (
    <StyledRow>
      <StyledPower>
        {power === '0'
          ? 0
          : formatNumberWithSpace(Math.abs(Number.parseInt(power)))}
        <StyledKw> kW - </StyledKw>
      </StyledPower>
      <StyledType>{t(areaType)}</StyledType>
    </StyledRow>
  );
};

const CustomTooltip: FC<CustomTooltipProps> = ({ data, period }) => {
  const { t } = useTranslation();
  if (!data[0]?.data?.tooltipData) return <></>;
  return (
    <StyledWrapper>
      <StyledDate>
        {formatTooltipLabel(
          data[0].data?.tooltipData,
          data[0].title,
          period,
          t,
        )}
      </StyledDate>
      {data.map((item) => (
        <CustomTooltipBlock
          key={item.name}
          power={item.value}
          type={item.name}
        />
      ))}
    </StyledWrapper>
  );
};

export default CustomTooltip;
