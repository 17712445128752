import React from 'react';
import { useTranslation } from 'react-i18next';

import { useFormikContext } from 'formik';

import BillingFullName from 'components/members/createConsumer/ContactInfo/BillingInfo/BillingFullName';
import FormikInput from 'components/shared/Input/FormikInput';
import PhoneNumberInput from 'components/shared/Input/PhoneNumberInput';
import { InputGroup } from 'components/shared/InputGroup';

import { initialValues } from '../../constants';

const fullNameKeys = {
  firstName: 'additionalInfo.legalPerson.firstName',
  lastName: 'additionalInfo.legalPerson.lastName',
  middleName: 'additionalInfo.legalPerson.middleName',
  email: '',
  phoneNumber: '',
};

const LegalPerson = () => {
  const { t } = useTranslation();
  const { values, errors, setFieldValue } =
    useFormikContext<typeof initialValues>();

  return (
    <>
      <BillingFullName
        label={t('legal_person_name').toString()}
        values={fullNameKeys}
      />

      <InputGroup
        label={t('legal_person_email_address').toString()}
        error={errors.additionalInfo?.legalPerson?.email}
      >
        <FormikInput
          fieldName="additionalInfo.legalPerson.email"
          placeholder="Ex. mail@mail.com"
        />
      </InputGroup>

      <InputGroup
        label={t('legal_person_position').toString()}
        error={errors?.additionalInfo?.legalPerson?.position}
      >
        <FormikInput
          fieldName="additionalInfo.legalPerson.position"
          placeholder="Ex. Chief executive officer"
        />
      </InputGroup>

      <InputGroup
        label={t('legal_person_phone_number').toString()}
        error={errors.additionalInfo?.legalPerson?.contactNumber}
      >
        <PhoneNumberInput
          hasError={Boolean(errors.additionalInfo?.legalPerson?.contactNumber)}
          phone={values.additionalInfo.legalPerson.contactNumber}
          onChange={(contactNumber) =>
            setFieldValue(
              'additionalInfo.legalPerson.contactNumber',
              contactNumber,
            )
          }
        />
      </InputGroup>
    </>
  );
};

export default LegalPerson;
