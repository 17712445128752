import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';

import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useFormikContext } from 'formik';

import engLocale from 'antd/es/date-picker/locale/en_US';
import frLocale from 'antd/es/date-picker/locale/fr_FR';

import { SUPPORTED_LANGUAGES } from 'constants/global';
import { useGetCommunitiesQuery } from 'services/communityManagement';
import { IDBTaxPrices } from 'services/taxPrices.types';

import { capitalizeAllWords, getFormattedUserName } from 'utils/index';

import { extractExistingDateRanges } from '../../tax-prices.helper';
import { selectTaxPrices } from '../../tax-prices.slice';
import { PriceType } from '../../types';
import useMappedConsumers from '../DiscountedAccisePrices/useMappedConsumers';
import {
  Container,
  DashWrapper,
  DatePickerStyled,
  Dropdown,
  LabelContainer,
  Line,
  SelectContainer,
  Title,
} from './styles';

type IDateRange = {
  priceType: PriceType;
};

type SelectItem = {
  key: string;
  label: string;
  value: string;
  data: object;
};

const DateRangeComponent = ({ priceType }: IDateRange) => {
  const { t, i18n } = useTranslation();
  const [consumers, setConsumers] = useState([]);
  const [userId, setUserId] = useState(null);

  const mappedConsumers = useMappedConsumers(consumers);

  const { data: communities = [], isLoading } = useGetCommunitiesQuery();
  const taxPrices = useSelector(selectTaxPrices);

  const locale = useMemo(() => {
    if (i18n.language === SUPPORTED_LANGUAGES.English) return engLocale;
    return frLocale;
  }, [i18n.language]);

  const { values, errors, setFieldValue, setErrors } =
    useFormikContext<IDBTaxPrices>();
  const isCommunityDropdownDisabled =
    !!values?.discountedAccisePerMWh?.communityId;
  const isConsumerDropdownDisabled =
    priceType === PriceType.DISCOUNTED_ACCISE && mappedConsumers.length === 0;
  const consumerName = getFormattedUserName(values?.user);
  const err = errors as any;
  const hasStartDateError = !!err?.startDate;
  const hasEndDateError = !!err?.endDate;
  const hasCommunityError = !!err?.discountedAccisePerMWh?.communityName;
  const hasConsumerError = !!err?.discountedAccisePerMWh?.consumerName;
  const handleStartDateChange = (_: unknown, date: string | string[]) => {
    setFieldValue('startDate', date);
  };

  const handleEndDateChange = (_: unknown, date: string | string[]) => {
    setFieldValue('endDate', date);
  };

  const handleOnFocus = () => {
    setErrors({});
  };

  const disabledDate = (current: any) =>
    extractExistingDateRanges(taxPrices, priceType, userId).some(
      (range) => current >= range.start && current <= range.end,
    );

  const handleOnCommunitySelect = (_: any, { data }: any) => {
    setConsumers(data.consumers);
    setFieldValue('discountedAccisePerMWh.communityId', data.id);
    setFieldValue('discountedAccisePerMWh.communityName', data.communityName);
    setFieldValue('userId', null);
    setFieldValue('discountedAccisePerMWh.consumerName', '');
  };

  const handleOnConsumerSelect = (_: any, { data }: any) => {
    const consumerName = data?.middleName
      ? `${data?.firstName} ${data?.middleName} ${data?.lastName}`
      : `${data?.firstName} ${data?.lastName}`;

    setFieldValue('discountedAccisePerMWh.consumerName', consumerName);
    setFieldValue('userId', data._id);
    setUserId(data._id);
  };

  const communitiesMapped = communities.map((community) => ({
    key: uuidv4(),
    label: community?.communityName,
    value: community?.id,
    data: community,
  })) as SelectItem[];

  return (
    <Container>
      {priceType === PriceType.DISCOUNTED_ACCISE && (
        <Line>
          <LabelContainer>
            <Title>{capitalizeAllWords(t('community'))}</Title>
          </LabelContainer>
          <Dropdown
            showSearch
            loading={isLoading}
            disabled={isCommunityDropdownDisabled}
            status={hasCommunityError ? 'error' : undefined}
            placeholder={capitalizeAllWords(t('community'))}
            getPopupContainer={(node: any) => node.parentNode}
            defaultValue={values?.discountedAccisePerMWh?.communityName}
            options={communitiesMapped}
            optionFilterProp="label"
            filterSort={(optionA: any, optionB: any) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
            onFocus={handleOnFocus}
            onSelect={handleOnCommunitySelect}
          />
          <SelectContainer>
            <Title>{capitalizeAllWords(t('consumer'))}</Title>
            <Dropdown
              showSearch
              disabled={isConsumerDropdownDisabled}
              status={hasConsumerError ? 'error' : undefined}
              placeholder={capitalizeAllWords(t('consumer'))}
              getPopupContainer={(node: any) => node.parentNode}
              defaultValue={consumerName}
              options={mappedConsumers}
              optionFilterProp="label"
              filterSort={(optionA: any, optionB: any) =>
                (optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toLowerCase())
              }
              onFocus={handleOnFocus}
              onSelect={handleOnConsumerSelect}
            />
          </SelectContainer>
        </Line>
      )}
      <Line>
        <LabelContainer>
          <Title>{t('action_period')}</Title>
        </LabelContainer>
        <DatePickerStyled
          locale={locale}
          defaultValue={
            values.startDate ? dayjs(values.startDate, 'YYYY-MM-DD') : undefined
          }
          status={hasStartDateError ? 'error' : undefined}
          minDate={dayjs().add(1, 'day')}
          disabledDate={disabledDate}
          onFocus={handleOnFocus}
          onChange={handleStartDateChange}
          getPopupContainer={(node: HTMLElement) =>
            node.parentNode as HTMLElement
          }
          popupStyle={{ position: 'absolute' }}
        />
        <DashWrapper>–</DashWrapper>
        <DatePickerStyled
          locale={locale}
          defaultValue={
            values.endDate ? dayjs(values.endDate, 'YYYY-MM-DD') : undefined
          }
          status={hasEndDateError ? 'error' : undefined}
          minDate={
            values.startDate ? dayjs(values.startDate, 'YYYY-MM-DD') : dayjs()
          }
          disabledDate={disabledDate}
          onFocus={handleOnFocus}
          onChange={handleEndDateChange}
          getPopupContainer={(node: HTMLElement) =>
            node.parentNode as HTMLElement
          }
          popupStyle={{ position: 'absolute' }}
        />
      </Line>
    </Container>
  );
};

export default DateRangeComponent;
