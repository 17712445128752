import { getAppRole } from 'utils';
import { useTranslation } from 'react-i18next';
import { PageHeader, Wrapper } from 'components/communities/styles';
import { COLORS } from 'constants/colors';
import { USER_ROLES } from 'constants/types';
import { useGetMemberByIdQuery } from 'services/pmoMemberDashboardManagment';
import { useSettingsByRole } from './useSettingsByRole';
import {
  MailIcon,
  StyledMail,
  StyledProfileHeader,
  StyledProfileRow,
  StyledProfileTitle,
} from './styles';

const Settings = () => {
  const { t } = useTranslation();
  const { settingsConfig } = useSettingsByRole(getAppRole());
  const { data: member } = useGetMemberByIdQuery(undefined);

  const memberName = `${member?.firstName} ${member?.middleName} ${member?.lastName}`;
  const userRole = member?.role || USER_ROLES.NO_ROLE;
  const isAdmin = [USER_ROLES.ADMIN.toString()].includes(userRole);
  const title = isAdmin ? t('oyo_admin') : memberName;

  return (
    <Wrapper>
      <PageHeader background={COLORS.BgContainer}>
        <StyledProfileHeader>
          <StyledProfileRow>
            <StyledProfileTitle>{title}</StyledProfileTitle>
          </StyledProfileRow>
          <StyledMail>
            <MailIcon /> {member?.email}
          </StyledMail>
        </StyledProfileHeader>
      </PageHeader>
      {settingsConfig}
    </Wrapper>
  );
};

export default Settings;
