import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { USER_ROLES } from 'constants/types';
import ConsumerProfileTab from '../ConsumerDashboard/components/ProfileDataTab';
import ProducerProfileTab from '../ProducerDashboard/components/ProfileDataTab';
import ProfileData from './components/ProfileData';
import LanguageSelect from 'components/localization/language-select/LanguageSelect';
import {
  StyledLanguageContainer,
  StyledLanguageTitle,
  StyledLanguageWrapper,
  StyledWrapper,
} from './styles';

export const useProfileByRole = (userRole: USER_ROLES) => {
  const { t } = useTranslation();

  const profileConfig = useMemo(() => {
    switch (userRole) {
      case USER_ROLES.CONSUMER: {
        return (
          <StyledWrapper hasTopMargin={false}>
            <ConsumerProfileTab />
            <StyledLanguageWrapper>
              <StyledLanguageContainer>
                <StyledLanguageTitle>
                  {t('language_settings')}
                </StyledLanguageTitle>
                <LanguageSelect />
              </StyledLanguageContainer>
            </StyledLanguageWrapper>
          </StyledWrapper>
        );
      }
      case USER_ROLES.PRODUCER: {
        return (
          <StyledWrapper hasTopMargin={false}>
            <ProducerProfileTab />
            <StyledLanguageWrapper>
              <StyledLanguageContainer>
                <StyledLanguageTitle>
                  {t('language_settings')}
                </StyledLanguageTitle>
                <LanguageSelect />
              </StyledLanguageContainer>
            </StyledLanguageWrapper>
          </StyledWrapper>
        );
      }
      default:
        return (
          <StyledWrapper hasTopMargin={true}>
            <StyledLanguageWrapper>
              <ProfileData />
              <StyledLanguageContainer style={{ marginTop: '24px' }}>
                <StyledLanguageTitle>
                  {t('language_settings')}
                </StyledLanguageTitle>
                <LanguageSelect />
              </StyledLanguageContainer>
            </StyledLanguageWrapper>
          </StyledWrapper>
        );
    }
  }, [userRole, t]);

  return { profileConfig };
};
