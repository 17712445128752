import { getAppRole, getUniqeConsumersById, generateEmptyArray } from 'utils';

import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Flex } from 'antd';

import { Container } from 'components/communities/styles';
import { PlusOutlined } from 'components/members/createConsumer/ConsumerType/Icons';
import { SorterTypes } from 'components/pricing/types';
import { BtnPrimary } from 'components/shared/Button';
import { Community, IDBPrm, USER_ROLES } from 'constants/types';
import { useGetPrmPriceByCommunityIdQuery } from 'services/prmPricesManagement';
import { INDEXATION } from 'services/types';

import ConsumerList from './ConsumerList';
import CommunityInfo from './Info';
import PricesForm from './PrmPrices/PricesForm';
import ConsumerSearch from './Search';
import ConsumerSorter from './Sorter';
import { StlyedBtnWrapper, StlyedTabWrapper } from './styles';
import { searchConsumerByName, sortConsumersByType } from './utils';

interface CommunityTabProps {
  community?: Community;
  isLoadingCommunity?: boolean;
}

export interface IFormInitialData {
  communityId?: string;
  consumerId?: string;
  prmValue?: string;
  period?: any;
  cppa?: string;
  indexation?: INDEXATION;
  prmPriceId?: string;
  prmId?: string;
  startDate?: string;
  endDate?: string;
  HS_HH?: number;
  HS_LH?: number;
  LS_HH?: number;
  LS_LH?: number;
  RH?: number;
}

const CommunityTab: FC<CommunityTabProps> = ({
  community,
  isLoadingCommunity,
}) => {
  const { t } = useTranslation();
  const [initialFormData, setInitialFormData] =
    useState<IFormInitialData | null>(null);
  const [sortedConsumers, setSortedConsumers] = useState<any>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [sorterValue, setSorterValue] = useState<SorterTypes>(
    SorterTypes.CONSUMER_NAME,
  );
  const userRole = getAppRole();

  const {
    data: prmPricesData,
    refetch,
    isLoading: isLoadingPrmPrices,
  } = useGetPrmPriceByCommunityIdQuery({
    communityId: community?.id || '',
  });

  const consumersLength = useMemo(() => {
    if (community?.consumers) {
      return getUniqeConsumersById(community?.consumers).length;
    }
    return [];
  }, [community?.consumers]);

  const producersLength = useMemo(() => {
    return (
      community &&
      Object.keys(
        community?.producers.reduce((acc: any, curr: any) => {
          if (curr.user) {
            acc[curr.user._id] = curr.user;
          }
          return acc;
        }, {}),
      ).length
    );
  }, [community]);

  const communityInfo: Record<string, string | number> | {} = useMemo(() => {
    if (community) {
      return {
        name: community?.communityName,
        producers: producersLength,
        consumers: consumersLength,
        pvPower: community?.pvPower + ' MW',
        location: community?.location,
      };
    }
    return {};
  }, [community, consumersLength]);

  const isAdmin = useMemo(() => userRole === USER_ROLES.ADMIN, [userRole]);
  const isFormModalOpen = useMemo(() => !!initialFormData, [initialFormData]);

  const openFormModal = useCallback(
    (initialValues: IFormInitialData) => setInitialFormData(initialValues),
    [],
  );
  const closeFormModal = useCallback(() => {
    setInitialFormData(null);
    refetch();
  }, []);

  useEffect(() => {
    const consumerMap = Array.from(
      new Map(
        community?.consumers
          .filter((prm: IDBPrm) => prm && prm.user)
          .map((consumer: any, index: number) => [
            consumer.user._id,
            { ...consumer.user, prm: consumer, index },
          ]),
      ),
      ([_, value]) => ({ ...value }),
    );

    const consumersWithPrms = community?.consumers.reduce(
      (acc: any, current: IDBPrm) => {
        const consumerIndex = acc.findIndex(
          (consumer: any) => consumer._id === current.user?._id,
        );
        if (consumerIndex >= 0) {
          if (acc[consumerIndex].prms) {
            acc[consumerIndex].prms.push(current);
          } else {
            acc[consumerIndex] = { ...acc[consumerIndex], prms: [current] };
          }
        }
        return acc;
      },
      consumerMap,
    );

    let consumersWithPrmPrices = prmPricesData?.reduce((acc, current) => {
      const consumerIndex = acc.findIndex(
        (consumer: any) => consumer._id === current.prm.user?._id,
      );
      if (consumerIndex >= 0) {
        const prmIndex = acc[consumerIndex].prms.findIndex(
          (prm: IDBPrm) => prm._id === current.prm._id,
        );
        if (prmIndex >= 0) {
          if (acc[consumerIndex].prms[prmIndex].prmPrices) {
            acc[consumerIndex].prms[prmIndex].prmPrices.push(current);
          } else {
            const updatedPrm = {
              ...acc[consumerIndex].prms[prmIndex],
              prmPrices: [current],
            };
            acc[consumerIndex].prms[prmIndex] = updatedPrm;
          }
        }
      }

      return acc;
    }, consumersWithPrms);

    if (searchValue) {
      consumersWithPrmPrices = searchConsumerByName(
        consumersWithPrmPrices as any,
        searchValue,
      );
    }

    consumersWithPrmPrices = sortConsumersByType(
      consumersWithPrmPrices as any,
      sorterValue,
    );

    setSortedConsumers(consumersWithPrmPrices);
  }, [community?.consumers, searchValue, sorterValue, prmPricesData]);

  const handleAddNewPrices = openFormModal;

  return (
    <StlyedTabWrapper>
      <Container>
        {isAdmin && (
          <CommunityInfo
            isLoadingCommunity={isLoadingCommunity}
            community={communityInfo}
          />
        )}
        <Flex align="center" justify="space-between">
          <Flex align="center" gap={60}>
            <ConsumerSearch setSearchValue={setSearchValue} />
            <ConsumerSorter setSorterValue={setSorterValue} />
          </Flex>
          {isAdmin && (
            <StlyedBtnWrapper>
              <BtnPrimary
                additionalStyles={{ boxShadow: 'none' }}
                onClick={() =>
                  handleAddNewPrices({ communityId: community?.id })
                }
              >
                <Flex align="center" gap={10}>
                  <PlusOutlined />
                  {t('add_new_prices')}
                </Flex>
              </BtnPrimary>
            </StlyedBtnWrapper>
          )}
        </Flex>
        <ConsumerList
          consumers={
            isLoadingCommunity ? generateEmptyArray(5) : sortedConsumers
          }
          openFormModal={openFormModal}
          isLoading={isLoadingCommunity || isLoadingPrmPrices}
        />
      </Container>
      {isFormModalOpen && (
        <PricesForm
          initialData={initialFormData}
          isModalOpen={isFormModalOpen}
          closeModal={closeFormModal}
        />
      )}
    </StlyedTabWrapper>
  );
};

export default CommunityTab;
