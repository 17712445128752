import { Cell, TextBold, TextRegular } from '../CompanyType/styles';

type ICompanyTypeItem = {
  property: 'ht' | 'vat' | 'ttc';
  title: string;
  rows: any;
};

const CompanyTypeItem = ({ title, rows, property }: ICompanyTypeItem) => {
  return (
    <>
      <Cell>
        <TextBold>{title}</TextBold>
      </Cell>
      {Object.keys(rows).map((key) => (
        <Cell key={key}>
          {property === 'ttc' ? (
            <TextBold>{rows[key][property]}</TextBold>
          ) : (
            <TextRegular>{rows[key][property]}</TextRegular>
          )}
        </Cell>
      ))}
    </>
  );
};

export default CompanyTypeItem;
