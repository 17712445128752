import { ADDRESS_TYPE } from 'components/shared/AddressAutocomplete/types';

export interface IFields {
  text: string;
  address: string;
  postcode: number | string;
  coordinates?: [number, number];
}

export interface IChangeAddress {
  text: string;
  address: string;
  postcode: number | string;
  coordinates?: [number, number];
  type: ADDRESS_TYPE;
}

export enum Prices {
  static = 'static',
  dynamic = 'dynamic',
}
