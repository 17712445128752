import dayjs from 'dayjs';

import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { TextRegular } from 'components/shared/Text';
import CustomTooltip from 'components/shared/Tooltip';

import {
  StyledCalendarIcon,
  StyledIndexIcon,
  StyledPriceCol,
  StyledPriceContainer,
  StyledPriceTypeText,
  StyledPriceUnitText,
  StyledPriceValue,
  StyledPricesContainer,
  StyledPricesTitle,
  StyledPricesTitleContainer,
  customTooltipStyles,
  StyledPricePeriodSmall,
  StyledPricePeriodBig,
  StyledPriceInfoCol,
  StyledContractInfoCol,
  StyledPriceItemCol,
} from './styles';

interface ActualPricesProps {
  period: any;
}

const ActualPrices: FC<ActualPricesProps> = ({ period }) => {
  const { t } = useTranslation();
  const { scheduleTypes, startDate, endDate, index } = period;

  return (
    <StyledPricesContainer>
      <StyledContractInfoCol>
        <StyledPricesTitleContainer>
          <StyledPricesTitle>{t('actual_prices')}</StyledPricesTitle>
          <CustomTooltip
            tooltip={<TextRegular>{t('actual_prices_tooltip')}</TextRegular>}
            iconSize={16}
            wrapperStyles={customTooltipStyles}
            containerStyles={customTooltipStyles}
          />
        </StyledPricesTitleContainer>
        <StyledPricePeriodSmall>
          <div>
            <StyledCalendarIcon />
            <span>
              {`${dayjs(startDate).format('DD.MM.YYYY')} - ${dayjs(
                endDate,
              ).format('DD.MM.YYYY')}`}
            </span>
          </div>
          <div>
            <StyledIndexIcon />
            <span>{index}</span>
          </div>
        </StyledPricePeriodSmall>
      </StyledContractInfoCol>
      <StyledPriceInfoCol>
        <StyledPricePeriodBig>
          <div>
            <StyledCalendarIcon />
            <span>
              {`${dayjs(startDate).format('DD.MM.YYYY')} - ${dayjs(
                endDate,
              ).format('DD.MM.YYYY')}`}
            </span>
          </div>
          <div>
            <StyledIndexIcon />
            <span>{index}</span>
          </div>
        </StyledPricePeriodBig>
      </StyledPriceInfoCol>
      <StyledPriceItemCol>
        <StyledPriceContainer>
          <div>
            <StyledPriceTypeText>{t('hshp')}</StyledPriceTypeText>
            <span>,</span>
            <StyledPriceUnitText> {t('eur_kwh')}</StyledPriceUnitText>
          </div>
          <StyledPriceValue>{scheduleTypes.HS_HH}</StyledPriceValue>
        </StyledPriceContainer>
      </StyledPriceItemCol>
      <StyledPriceItemCol>
        <StyledPriceContainer>
          <div>
            <StyledPriceTypeText>{t('hshc')}</StyledPriceTypeText>
            <span>,</span>
            <StyledPriceUnitText> {t('eur_kwh')}</StyledPriceUnitText>
          </div>
          <StyledPriceValue>{scheduleTypes.HS_LH}</StyledPriceValue>
        </StyledPriceContainer>
      </StyledPriceItemCol>
      <StyledPriceItemCol>
        <StyledPriceContainer>
          <div>
            <StyledPriceTypeText>{t('bshp')}</StyledPriceTypeText>
            <span>,</span>
            <StyledPriceUnitText> {t('eur_kwh')}</StyledPriceUnitText>
          </div>
          <StyledPriceValue>{scheduleTypes.LS_HH}</StyledPriceValue>
        </StyledPriceContainer>
      </StyledPriceItemCol>
      <StyledPriceItemCol>
        <StyledPriceContainer>
          <div>
            <StyledPriceTypeText>{t('bshc')}</StyledPriceTypeText>
            <span>,</span>
            <StyledPriceUnitText> {t('eur_kwh')}</StyledPriceUnitText>
          </div>
          <StyledPriceValue>{scheduleTypes.LS_LH}</StyledPriceValue>
        </StyledPriceContainer>
      </StyledPriceItemCol>
      <StyledPriceItemCol>
        <StyledPriceContainer>
          <div>
            <StyledPriceTypeText>{t('hdp')}</StyledPriceTypeText>
            <span>,</span>
            <StyledPriceUnitText> {t('eur_kwh')}</StyledPriceUnitText>
          </div>
          <StyledPriceValue>{scheduleTypes.RH}</StyledPriceValue>
        </StyledPriceContainer>
      </StyledPriceItemCol>
      <StyledPriceCol width="5%"></StyledPriceCol>
    </StyledPricesContainer>
  );
};

export default ActualPrices;
