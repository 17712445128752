import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Formik } from 'formik';

import {
  BtnContainer,
  ErrorContainer,
  ErrorIcon,
  ErrorMsg,
  ErrorText,
  FieldStyled,
  TableWrapper,
} from 'components/pricing/components/TaxPrices/styles';
import { PriceType } from 'components/pricing/components/TaxPrices/types';
import { BtnDefault, BtnSmallPrimary } from 'components/shared/Button';
import { TextBold } from 'components/shared/Text';
import { IDBTaxPrices } from 'services/taxPrices.types';

import DateRange from '../../../DateRange';
import { indexationSchema } from './modify-indexation.schema';
import { Cell, HeaderRow, Row, Table } from './styles';

interface IModifyIndexation {
  taxPrices?: IDBTaxPrices;
  onClose: () => void;
  onDelete: (id: string) => void;
  onSave: (values: IDBTaxPrices) => void;
}

const ModifyIndexation = ({
  taxPrices,
  onClose,
  onDelete,
  onSave,
}: IModifyIndexation) => {
  const { t } = useTranslation();
  const hasTaxPrices = Boolean(taxPrices);
  const initialValues = {
    ...taxPrices,
    index: taxPrices?.index || undefined,
  } as IDBTaxPrices;

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={initialValues}
      validationSchema={indexationSchema(t)}
      onSubmit={(values) => onSave(values)}
    >
      {({
        isValid,
        isSubmitting,
        values,
        errors,
        validateForm,
        setErrors,
        setFieldValue,
        handleSubmit,
      }) => {
        const isSaveBtnDisabled = isSubmitting || !isValid;
        const handleOnSave = () => {
          validateForm();

          if (!isValid) {
            return;
          }

          handleSubmit();
        };

        const handleOnFocus = () => {
          setErrors({});
        };

        const handleOnChange = async (event: ChangeEvent<HTMLInputElement>) => {
          await setFieldValue('index', Number(event.target.value));
        };

        return (
          <Form>
            <div>
              <DateRange priceType={PriceType.INDEX} />
              <TableWrapper>
                <Table>
                  <Row>
                    <HeaderRow>
                      <TextBold>{t('index_coeficient')}</TextBold>
                    </HeaderRow>
                    <Cell>
                      <FieldStyled
                        error={errors.index}
                        type="number"
                        value={values.index || ''}
                        name="value"
                        placeholder="1.025"
                        onFocus={handleOnFocus}
                        onChange={handleOnChange}
                      />
                    </Cell>
                  </Row>
                </Table>
              </TableWrapper>
              <ErrorMsg>
                <ErrorMsg>
                  {!isValid && Object.keys(errors).length > 0 && (
                    <ErrorContainer>
                      <ErrorIcon />
                      <ErrorText>{t('please_fill_all_fields')}</ErrorText>
                    </ErrorContainer>
                  )}
                </ErrorMsg>
              </ErrorMsg>
              <BtnContainer>
                <BtnDefault onClick={onClose}>{t('cancel')}</BtnDefault>
                {hasTaxPrices && (
                  <BtnDefault
                    danger
                    onClick={() => onDelete(taxPrices?._id || '')}
                  >
                    {t('delete')}
                  </BtnDefault>
                )}
                <BtnSmallPrimary
                  isDisabled={isSaveBtnDisabled}
                  onClick={handleOnSave}
                >
                  {t('save_new_index')}
                </BtnSmallPrimary>
              </BtnContainer>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ModifyIndexation;
