import qs from 'qs';

import { createApi } from '@reduxjs/toolkit/query/react';

import { API_URLS, REQUEST_METHOD } from 'constants/global';
import { Community, PERIOD } from 'constants/types';

import { getDateRange } from 'utils/url/getDateRange';

import { baseQuery } from './baseQuery';
import { CreateCommunityPayload } from './types';

export const communityManagement = createApi({
  reducerPath: 'createCommunity',
  baseQuery,
  endpoints: (builder) => ({
    createCommunity: builder.mutation<any, CreateCommunityPayload>({
      query: (data) => ({
        url: API_URLS.CREATE_COMMUNITY,
        method: REQUEST_METHOD.POST,
        body: data,
      }),
    }),
    getCommunity: builder.query<Community, void>({
      query: () => ({
        url: API_URLS.GET_COMMUNITY,
        method: REQUEST_METHOD.GET,
      }),
    }),
    getCommunityById: builder.query<Community, { communityId: string }>({
      query: ({ communityId }) => ({
        url: `${API_URLS.GET_COMMUNITY}/${communityId}`,
        method: REQUEST_METHOD.GET,
      }),
    }),
    getCommunityByUserId: builder.query<Community, { userId: string }>({
      query: ({ userId }) => ({
        url: `${API_URLS.GET_COMMUNITY}/${userId}`,
        method: REQUEST_METHOD.GET,
      }),
    }),
    getCommunities: builder.query<Community[], void>({
      query: () => ({
        url: API_URLS.GET_COMMUNITIES,
        method: REQUEST_METHOD.GET,
      }),
    }),
    getConsumers: builder.query<any, { userId?: string }>({
      query: ({ userId }) => ({
        url: `${API_URLS.GET_COMMUNITY_CONSUMERS}?${qs.stringify({
          userId,
        })}`,
        method: REQUEST_METHOD.GET,
      }),
    }),
    getCommunityPrices: builder.query<
      any,
      { prmId?: any; communityId?: string }
    >({
      query: ({ communityId, prmId }) => ({
        url: `${API_URLS.GET_COMMUNITY_PRICES}?communityId=${communityId}`,
        method: REQUEST_METHOD.GET,
      }),
    }),
    getCommunityElectricityConsumption: builder.query<any, any>({
      query: ({ startDate, endDate, communityId }) => ({
        url: `${API_URLS.GET_COMMUNITY_ELECTRICITY_CONSUMPTION}?${qs.stringify({
          startDate,
          endDate,
          communityId,
        })}`,
        method: REQUEST_METHOD.GET,
      }),
    }),
    getCommunityEarnings: builder.query<{ earnings: number }, any>({
      query: ({ startDate, endDate, communityId }) => ({
        url: `${API_URLS.GET_COMMUNITY_EARNINGS}?${qs.stringify({
          startDate,
          endDate,
          communityId,
        })}`,
        method: REQUEST_METHOD.GET,
      }),
    }),
    getCommunityInvoiceHistory: builder.query<any, void>({
      query: () => ({
        url: `${API_URLS.GET_COMMUNITY_INVOICE_HISTORY}`,
        method: REQUEST_METHOD.GET,
      }),
    }),
    getCommunityFinancialData: builder.query<any, { period: PERIOD }>({
      query: ({ period }) => ({
        url: `${API_URLS.GET_COMMUNITY_FINANCIAL_DATA}?${qs.stringify(
          getDateRange(period),
        )}`,
        method: REQUEST_METHOD.GET,
      }),
    }),
  }),

  tagTypes: ['communities'],
});

export const {
  useCreateCommunityMutation,
  useGetCommunityQuery,
  useGetCommunityByIdQuery,
  useGetCommunitiesQuery,
  useGetConsumersQuery,
  useGetCommunityElectricityConsumptionQuery,
  useGetCommunityEarningsQuery,
  useGetCommunityInvoiceHistoryQuery,
  useGetCommunityFinancialDataQuery,
} = communityManagement;
