import { styled } from 'styled-components';

import { SubHeader } from 'components/shared/Text';
import { COLORS } from 'constants/colors';

export const StyledAddTitle = styled(SubHeader)`
  display: flex;
  align-items: center;
  column-gap: 3px;
  margin: 0;
  color: ${COLORS.Green8};
  font-weight: 600;
  cursor: pointer;
  text-align: right;
  width: 100%;
  margin-bottom: 20px;
  svg path {
    fill: ${COLORS.Green8};
  }
`;

export const StyledInputsRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  column-gap: 20px;
`;
export const StyledDeletePrmButton = styled.div`
  color: ${COLORS.ErrorBase};
  font-size: 14px;
  line-height: 22px;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
`;

export const StyledPrmFieldWrapper = styled.div`
  position: relative;
  width: 100%;
`;
