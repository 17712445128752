import { styled } from 'styled-components';

import { COLORS } from 'constants/colors';

export const StyledPrmWrapper = styled.div<{ pointerEvents: string }>`
  width: 100%;
  min-height: 156px;
  background-color: ${COLORS.BgLayout};
  padding: 12px 16px;
  box-sizing: border-box;
  pointer-events: ${(props) => props.pointerEvents};
  margin-bottom: 20px;
`;

export const StyledPrmHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 6px;
  border-bottom: 1px solid ${COLORS.ColorSplit};
`;

export const StyledPrmTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;

export const StyledPrmEditButton = styled.div`
  display: flex;
  align-items: center;
  column-gap: 3px;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  pointer-events: visible;
`;

export const StyledPrmRow = styled.div`
  display: flex;
  align-items: start;
  column-gap: 20px;
  margin-top: 20px;
`;

export const StyledDisabledBtn = styled.div`
  min-width: 412px;
  display: flex;
  align-items: center;
  column-gap: 15px;
  box-sizing: border-box;
  padding: 8px 12px;
  border-radius: 6px;
  border: 1px solid ${COLORS.BgContainerDisabled};
  background: ${COLORS.BgContainerDisabled};
`;

export const StyledDisabledBtnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

export const StyledDisabledBtnLabel = styled.div`
  font-size: 14px;
  line-height: 22px;
`;

export const StyledGivenNameWrapper = styled.div`
  width: 412px;
`;

export const StyledPrmButtonsRow = styled.div`
  display: flex;
  justify-content: end;
  column-gap: 32px;
  align-items: center;
  margin-top: 20px;
`;

export const StyledPrmSaveButton = styled.div`
  min-width: 189px;
`;
export const StyledPrmCancelButton = styled.div`
  width: 83px;
`;
