import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Flex } from 'antd';

import { MarginTop32px } from 'components/shared/Margin';
import { IDBPrmPrice } from 'constants/types';

import ConsumerItem from '../ConsumerItem';
import { IFormInitialData } from '../index';
import { StyledNotFound } from './styles';

interface ConsumerListProps {
  consumers: IDBPrmPrice[];
  openFormModal: (data: IFormInitialData) => void;
  isLoading?: boolean;
}

const ConsumerList: FC<ConsumerListProps> = ({
  consumers,
  openFormModal,
  isLoading,
}) => {
  const { t } = useTranslation();

  if (consumers.length === 0)
    return (
      <Flex justify="center" align="center">
        <StyledNotFound>{t('no_results_was_found')}</StyledNotFound>
      </Flex>
    );

  return (
    <>
      <MarginTop32px />
      <Flex vertical gap={20}>
        {consumers.map((consumer) => (
          <ConsumerItem
            isLoading={isLoading}
            consumer={consumer}
            openFormModal={openFormModal}
          />
        ))}
      </Flex>
    </>
  );
};

export default ConsumerList;
