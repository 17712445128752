import { styled } from 'styled-components';

import { Radio } from 'antd';

import { SubHeader, TextRegular } from 'components/shared/Text';
import { COLORS } from 'constants/colors';

export const StyledSubHeader = styled(SubHeader)`
  display: flex;
  align-items: center;
  column-gap: 5px;
  text-align: start;
`;

export const StyledWrapper = styled.div`
  width: 100%;
  margin-bottom: 32px;
`;

export const StyledPricesWrapper = styled.div`
  border-radius: 6px;
  background-color: ${COLORS.ColorFillTertiary};
  padding: 16px;
`;

export const StyledRadio = styled(Radio)`
  &:hover .ant-radio-inner {
    border-color: ${COLORS.Green7} !important;
  }

  .ant-radio-checked .ant-radio-inner {
    background-color: ${COLORS.BgContainer};
    border-color: ${COLORS.Green7};

    &::after {
      background-color: ${COLORS.Green7};
    }
  }
`;

export const StyledTooltipInfo = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const StyledTextInfo = styled(TextRegular)`
  margin: 0;
  display: inline-block;
  color: ${COLORS.TextSecondary};
`;

export const StyledRadioWrapper = styled.div`
  border-bottom: 1px solid ${COLORS.ColorSplit};
  margin-bottom: 16px;
  padding-bottom: 8px;
`;
