import jwt from 'jwt-decode';
import { DateTime } from 'luxon';

import { TFunction } from 'i18next';

import {
  COMMUNITIES_NAV_LINKS_ROLE_CONSUMER,
  COMMUNITIES_NAV_LINKS_ROLE_OYO_ADMIN,
  COMMUNITIES_NAV_LINKS_ROLE_PMO,
  COMMUNITIES_NAV_LINKS_ROLE_PRODUCER,
  USER_MENU_ITEMS_ROLE_CONSUMER,
  USER_MENU_ITEMS_ROLE_OYO_ADMIN,
  USER_MENU_ITEMS_ROLE_PMO,
  USER_MENU_ITEMS_ROLE_PRODUCER,
  USER_ROLES,
  dayLabels,
  monthLabels,
} from 'constants/global';
import AppRoutes from 'constants/routes';
import { IDBPrm, IDBUser, PERIOD } from 'constants/types';
import { IMember } from 'services/types';

import { LOCAL_STORAGE_KEYS } from './local-storage';

const isTuesday = DateTime.now().weekday === 2;
const decodeToken = (token: string) => jwt(token);

export const capitalizeAllWords = (str: string) =>
  str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');

export const checkValidPhoneNumber = (contactNumber: string | number) => {
  const stringNumberValue = String(contactNumber);
  if (!stringNumberValue.startsWith('+')) {
    return '+' + contactNumber;
  } else {
    return contactNumber;
  }
};

export const isValidRole = (role: USER_ROLES) => {
  const token: any = localStorage.getItem(LOCAL_STORAGE_KEYS.IdToken);
  if (!token) return false;

  const decodedToken: any = decodeToken(token);

  return decodedToken?.oyo_roles.includes(role);
};

export const getAppRole = () => {
  const token: any = localStorage.getItem(LOCAL_STORAGE_KEYS.IdToken);
  if (!token) return USER_ROLES.NO_ROLE;

  const decodedToken: any = decodeToken(token);

  return decodedToken?.oyo_roles[0];
};

export const getConsumerLegalInitials = () => {
  const token: any = localStorage.getItem(LOCAL_STORAGE_KEYS.IdToken);
  if (!token) return null;

  const { given_name, family_name }: any = decodeToken(token);

  return `${given_name.charAt(0)}${family_name.charAt(0)}`.toUpperCase();
};

export const getAuthUser = () => {
  const token: any = localStorage.getItem(LOCAL_STORAGE_KEYS.IdToken);
  if (!token) return null;

  const user: any = decodeToken(token);

  return user;
};

export const uppercaseWord = (word: string) => {
  return word.toString().toUpperCase();
};

export const getNestedObjectValue = (nestedObj: any, path: string) => {
  return path
    .split('.')
    .reduce(
      (obj, key) => (obj && obj[key] !== 'undefined' ? obj[key] : undefined),
      nestedObj,
    );
};

export const getBaseRoute = () => {
  switch (getAppRole()) {
    case USER_ROLES.OYO_ADMIN: {
      return AppRoutes.Communities;
    }

    case USER_ROLES.PMO: {
      return AppRoutes.MyCommunity;
    }

    case USER_ROLES.CONSUMER:
    case USER_ROLES.PRODUCER: {
      return AppRoutes.Dashboard;
    }

    default: {
      return AppRoutes.SignIn;
    }
  }
};

type NavLink = {
  title: string;
  path: AppRoutes;
  isDisabled?: boolean;
};

export const getNavLinks = (): NavLink[] => {
  switch (getAppRole()) {
    case USER_ROLES.OYO_ADMIN: {
      return COMMUNITIES_NAV_LINKS_ROLE_OYO_ADMIN;
    }

    case USER_ROLES.PMO: {
      return COMMUNITIES_NAV_LINKS_ROLE_PMO;
    }

    case USER_ROLES.CONSUMER: {
      return COMMUNITIES_NAV_LINKS_ROLE_CONSUMER;
    }

    case USER_ROLES.PRODUCER: {
      return COMMUNITIES_NAV_LINKS_ROLE_PRODUCER;
    }

    default: {
      return [];
    }
  }
};

export function uniqBy<T>(
  array: T[],
  iteratee: ((item: T) => any) | string,
): T[] {
  const seen = new Set();
  return array.filter((item) => {
    let key;
    if (typeof iteratee === 'function') {
      key = iteratee(item);
    } else {
      key = item[iteratee as keyof T];
    }

    if (!seen.has(key)) {
      seen.add(key);
      return true;
    }
    return false;
  });
}

export const mapPeriodToValue = (period: PERIOD): string => {
  if (
    PERIOD.PREVIOUS_DAY === period ||
    (isTuesday && PERIOD.CURRENT_WEEK === period)
  )
    return 'hour';
  if (PERIOD.CURRENT_WEEK === period) return 'dayOfWeek';
  if (PERIOD.LAST_SEVEN_DAYS === period) return 'dayOfWeek';
  if (PERIOD.CURRENT_MONTH === period) return 'dayOfMonth';
  if (PERIOD.LAST_MONTH === period) return 'dayOfMonth';

  return 'month';
};

export const mapDate = (value: string, period: PERIOD, t: TFunction) => {
  if ([PERIOD.CURRENT_YEAR, PERIOD.LAST_YEAR].includes(period)) {
    return t(monthLabels[value]);
  }

  if (period === PERIOD.LAST_SEVEN_DAYS) {
    return t(dayLabels[value]);
  }

  return String(value);
};

export const camelCaseTo18nKey = (camelCaseString: string, t: TFunction) => {
  let result = camelCaseString.replace(/([A-Z])/g, '_$1').toLowerCase();

  if (result.startsWith('_')) {
    result = result.substring(1);
  }

  return t(result);
};

export const makeRedirect = (redirectUrl: string) => {
  const aTag = document.createElement('a');
  aTag.href = redirectUrl;
  const clickEvt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  aTag.dispatchEvent(clickEvt);
  aTag.remove();
};

export const downloadFile = (file?: Blob | string, fileName?: string) => {
  /*
    We need here to add '\ufeff' which is a special Unicode character
    that indicates the byte order and the encoding scheme of the text.

    Some software applications specifically look for the BOM character in
    UTF-8 encoded files to correctly interpret them as UTF-8 encoded text files.
    For instance, Microsoft Excel might encounter issues recognizing a UTF-8
    encoded CSV file without the BOM character, potentially causing the
    display of characters to be inaccurate.
   */
  const hasTextContent = typeof file === 'string' && file.length !== 0;
  const hasDataContent = file instanceof Blob && file.size !== 0;
  const initialContent = hasTextContent || hasDataContent ? '\uFEFF' : '';

  const fileContent = new Blob([initialContent + file], {
    type: 'text/csv;charset=utf-8;',
  });
  const aTag = document.createElement('a');
  aTag.download = fileName || 'emptyCsv';
  aTag.href = window.URL.createObjectURL(fileContent);

  const clickEvt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  aTag.dispatchEvent(clickEvt);
  aTag.remove();
};

export const formatTooltipLabel = (
  item: any,
  title: string,
  period: PERIOD,
  t: TFunction,
) => {
  const fullMonthLabel = {
    [t('february')]: t('february_full'),
    [t('april')]: t('april_full'),
    [t('march')]: t('march_full'),
    [t('may')]: t('may_full'),
    [t('june')]: t('june_full'),
    [t('july')]: t('july_full'),
    [t('august')]: t('august_full'),
    [t('september')]: t('september_full'),
    [t('october')]: t('october_full'),
    [t('november')]: t('november_full'),
    [t('december')]: t('december_full'),
    [t('january')]: t('january_full'),
  };

  const lastMonth = mapDate(item['month'], PERIOD.LAST_YEAR, t);
  const currentMonth = t(
    DateTime.now().toLocaleString({ month: 'long' }).toLocaleLowerCase(),
  );
  const currentYear = DateTime.now().year;
  const hour = item?.hour?.length > 2 ? item.hour : `${item.hour}:00`;

  switch (period) {
    case PERIOD.PREVIOUS_DAY:
      return `${item.day} ${currentMonth} ${hour}`;

    case PERIOD.LAST_SEVEN_DAYS:
      return `${item.dayOfMonth} ${currentMonth} ${currentYear} ${title.slice(
        5,
        12,
      )}`;

    case PERIOD.CURRENT_MONTH:
      return `${item.dayOfMonth} ${fullMonthLabel[currentMonth]}`;

    case PERIOD.LAST_MONTH:
      return `${fullMonthLabel[lastMonth]} ${item.dayOfMonth}`;

    default:
      return `${fullMonthLabel[title]} ${item?.year}`;
  }
};

export const getFormattedUserName = (user?: IDBUser | IMember): string => {
  if (!user || (!user.firstName && !user.lastName)) {
    return '';
  }

  return user.middleName
    ? `${user.firstName} ${user.middleName} ${user.lastName}`
    : `${user.firstName} ${user.lastName}`;
};

export const getUniqeConsumersById = (consumers: any) => {
  const uniqeConsumers = consumers.reduce(
    (acc: IDBPrm[], currentValue: IDBPrm) => {
      const duplicate = acc.find(
        (consumer) => consumer.user?._id === currentValue.user?._id,
      );

      //Remove duplicates of consumers
      if (!duplicate) {
        acc.push(currentValue);
      }

      return acc;
    },
    [],
  );
  return uniqeConsumers;
};

export const getUseMenuByRole = () => {
  switch (getAppRole()) {
    case USER_ROLES.OYO_ADMIN: {
      return USER_MENU_ITEMS_ROLE_OYO_ADMIN;
    }
    case USER_ROLES.PMO: {
      return USER_MENU_ITEMS_ROLE_PMO;
    }
    case USER_ROLES.CONSUMER: {
      return USER_MENU_ITEMS_ROLE_CONSUMER;
    }
    case USER_ROLES.PRODUCER: {
      return USER_MENU_ITEMS_ROLE_PRODUCER;
    }
    default: {
      return [];
    }
  }
};

export const generateEmptyArray = (length: number) =>
  [...Array(length)].map((_, i) => i);
