import { FC, useCallback, useState } from 'react';
import { useCollapse } from 'react-collapsed';
import { Flex } from 'antd';
import {
  StyledQuestionContainer,
  StyledAnswerContainer,
  ArrowButton,
  ArrowDown,
  ArrowUp,
  StyledQuestion,
} from '../styles';

interface FaqItemProps {
  question: string;
  answer: JSX.Element;
}

const FaqItem: FC<FaqItemProps> = ({ question, answer }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { getCollapseProps, getToggleProps } = useCollapse({
    isExpanded,
  });

  const toggleShowHistoricalPrices = useCallback(
    () => setIsExpanded((val) => !val),
    [],
  );

  return (
    <Flex vertical>
      <StyledQuestionContainer
        {...getToggleProps({
          onClick: toggleShowHistoricalPrices,
        })}
      >
        <StyledQuestion isExpanded={isExpanded}>{question}</StyledQuestion>
        <ArrowButton>{isExpanded ? <ArrowUp /> : <ArrowDown />}</ArrowButton>
      </StyledQuestionContainer>
      <section {...getCollapseProps()}>
        <StyledAnswerContainer>{answer}</StyledAnswerContainer>
      </section>
    </Flex>
  );
};

export default FaqItem;
