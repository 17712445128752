import React from 'react';

import styled from 'styled-components';

import { COLORS } from 'constants/colors';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 8px;
  height: 8px;
  border: 1px solid ${COLORS.BgContainer};
  border-radius: 2px;

  margin-right: 4px;
`;

const Icon = styled.div<{ color?: string }>`
  width: 2px;
  height: 2px;
  border: 3px solid ${({ color }) => (color ? color : '')};
  border-radius: 2px;
`;

interface IEnergyIcon {
  color: string;
}

const EnergyIcon = ({ color }: IEnergyIcon) => (
  <Container>
    <Icon color={color} />
  </Container>
);

export default EnergyIcon;
