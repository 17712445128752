import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { API_URLS, REQUEST_METHOD } from '../constants/global';
import { baseQuery } from './baseQuery';
import { IDBPrm, IPrmPaymentStatusMapped } from './types';

export const prmApi = createApi({
  reducerPath: 'prmApi',
  baseQuery,
  endpoints: (builder) => ({
    getPrmByUserId: builder.query<IDBPrm[], { userId?: string }>({
      query: ({ userId }) => ({
        url: `${API_URLS.GET_USER_PRM}${userId ? `?userId=${userId}` : ''}`,
        method: REQUEST_METHOD.GET,
      }),
    }),
    updateUserPrm: builder.mutation<any, any>({
      query: ({ name, power, prmValue, gridPrice, id }) => ({
        body: { name, power, prmValue, gridPrice },
        url: `${API_URLS.UPDATE_USER_PRM}/${id}`,
        method: REQUEST_METHOD.PUT,
      }),
    }),
    deleteUserPrm: builder.mutation<any, { prmId: string }>({
      query: ({ prmId }) => ({
        url: `${API_URLS.PRM}/${prmId}`,
        method: REQUEST_METHOD.DELETE,
      }),
    }),
    getPrmStatuses: builder.query<
      IPrmPaymentStatusMapped[],
      { userId: string }
    >({
      query: ({ userId }) => ({
        url: `${API_URLS.GET_USER_PRM_STATUSES}?userId=${userId}`,
        method: REQUEST_METHOD.GET,
      }),
    }),
  }),
  tagTypes: ['prmApi'],
});

export const {
  useGetPrmByUserIdQuery,
  useUpdateUserPrmMutation,
  useDeleteUserPrmMutation,
  useGetPrmStatusesQuery,
} = prmApi;
