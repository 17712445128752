import { EnergyTypes } from 'constants/energy-types';

export const emptyCommunityData = [
  {
    type: EnergyTypes.MY_SELF_CONSUMPTION,
    value: 0,
  },
  {
    type: EnergyTypes.COMMUNITY_SELF_CONSUMPTION,
    value: 0,
  },
  {
    type: EnergyTypes.GRID_CONSUMPTION,
    value: 0,
  },
];

export const benchMarkData = {
  mySelfConsumption: 4345,
  communityAverage: 2345,
};

export const infoData = {
  consumers: 1,
  proudcers: 2,
  pvPower: 3,
};
