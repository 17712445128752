import { styled } from 'styled-components';
import { Tag } from 'antd/lib';
import { COLORS, colorWithOpacity } from 'constants/colors';

export const StyledIssued = styled(Tag)`
  background: #ecfccb;
  border: 1px solid #a3e635;
  color: #3f6212;
`;

export const StyledPaid = styled(Tag)`
  background: ${COLORS.Green1};
  border: 1px solid ${COLORS.Green3};
  color: ${COLORS.Green8};
  width: 100%;
  text-align: center;
`;

export const StyledOverdue = styled(Tag)`
  background: ${COLORS.Red1};
  border: 1px solid ${COLORS.Red2};
  color: ${COLORS.Red7};
`;

export const StyledPaymentError = styled(StyledOverdue)``;

export const StyledCancelled = styled(Tag)`
  background: ${colorWithOpacity(COLORS.TextBase, 0.06)};
  border: 1px solid ${colorWithOpacity(COLORS.TextBase, 0.06)};
  color: ${colorWithOpacity(COLORS.TextBase, 0.65)};
`;

export const StyledAwaitingPayment = styled(Tag)`
  background: ${COLORS.Yellow1};
  border: 1px solid ${COLORS.Yellow6};
  color: ${COLORS.Yellow8};
`;
