import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { COLORS } from 'constants/colors';

type PhoneNumberInputProps = {
  hasError: boolean;
  phone: string | number | null;
  onChange: (phone: string) => void;
};

const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({
  hasError,
  phone,
  onChange,
}) => {
  const [hasFocus, setFocus] = useState(false);

  const currentPhoneNumber = phone !== null ? phone.toString() : '';
  const handleOnFocus = () => setFocus(true);
  const handleOnBlur = () => setFocus(false);

  const focusShadowStyles = hasFocus
    ? {
        boxShadow: '0 0 0 2px rgba(5, 42, 12, 0.3)',
        borderInlineEndWidth: '1px',
        outline: 0,
      }
    : undefined;

  return (
    <PhoneInput
      inputStyle={{
        height: '40px',
        width: '100%',
      }}
      country={'fr'}
      containerStyle={{
        height: '40px',
        border: `1px solid ${
          hasError && !hasFocus ? COLORS.ErrorBase : 'transparent'
        }`,
        borderColor: hasFocus ? COLORS.Green6 : 'transparent',
        borderRadius: '6px',
        ...focusShadowStyles,
      }}
      buttonStyle={{
        marginRight: '8px',
        borderTopLeftRadius: '6px',
        borderBottomLeftRadius: '6px',
      }}
      countryCodeEditable={false}
      value={currentPhoneNumber}
      onChange={(phone) => onChange(phone)}
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}
    />
  );
};

export default PhoneNumberInput;
