import { useParams, useSearchParams } from 'react-router-dom';

import PmoUserData from 'components/communities/Dashboard/components/Profile/components/PmoUserData';
import { PageHeader, StyledPricesWrapper } from 'components/communities/styles';
import TabsComponent from 'components/shared/Tabs';
import { COLORS } from 'constants/colors';
import { USER_ROLES } from 'constants/types';
import { useGetCommunityByIdQuery } from 'services/communityManagement';
import { useGetMemberByIdQuery } from 'services/pmoMemberDashboardManagment';

import PmoMemberSummaryDetails from './components/SummaryDetails';
import { StyledPMOBody, StyledPMOContainer, StyledPMOName } from './styles';
import { useTabsConfig } from './useTabsConfig';
import { getFormattedUserName } from 'utils';
import { SkeletonLine } from 'components/shared/Skeleton';

const MembersDashboard = () => {
  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const { tabsConfig, activeTabKey, handleChangeTab } = useTabsConfig();

  const { role, communityId } = Object.fromEntries(searchParams);
  const {
    data: community,
    isLoading: isCommunityDataLoading,
    refetch: reFetchCommunityData,
  } = useGetCommunityByIdQuery({ communityId });
  const {
    data: userData,
    isLoading: isUserDataLoading,
    refetch: reFetchUserData,
  } = useGetMemberByIdQuery(id);
  const isPMOMember = role === USER_ROLES.PMO_ADMIN;

  const handleOnReFetchData = () => {
    reFetchCommunityData();
    reFetchUserData();
  };

  const renderCommunityDashboard = () => [
    <PageHeader background={COLORS.BgLayout}>
      <PmoMemberSummaryDetails />
    </PageHeader>,
    <TabsComponent
      backgroundColor={COLORS.BgContainer}
      activeTabKey={activeTabKey as string}
      onChange={handleChangeTab}
      items={tabsConfig}
    />,
  ];

  const renderPMOProfile = () => (
    <>
      <StyledPMOContainer>
        {isUserDataLoading ? (
          <SkeletonLine active size="large" height={30} />
        ) : (
          <StyledPMOName>{getFormattedUserName(userData)}</StyledPMOName>
        )}
      </StyledPMOContainer>
      <StyledPMOBody>
        <PmoUserData
          isLoading={isCommunityDataLoading}
          communityPmoOrgName={community?.pmoOrgName}
          refetchCommunityData={handleOnReFetchData}
        />
      </StyledPMOBody>
    </>
  );

  return (
    <StyledPricesWrapper>
      {isPMOMember ? renderPMOProfile() : renderCommunityDashboard()}
    </StyledPricesWrapper>
  );
};

export default MembersDashboard;
