import React from 'react';
import { Outlet } from 'react-router-dom';

import Header from 'components/shared/Header';
import { StyledPageWrapper } from 'components/shared/MainOutlet/styles';

import Container from '../Container';

const MainOutlet: React.FC = () => {
  return (
    <Container>
      <Header />
      <StyledPageWrapper>
        <Outlet />
      </StyledPageWrapper>
    </Container>
  );
};

export default MainOutlet;
