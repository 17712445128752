import { styled } from 'styled-components';

import { SubHeader } from 'components/shared/Text';
import { COLORS } from 'constants/colors';

export const StyledAddTitle = styled(SubHeader)`
  display: flex;
  align-items: center;
  justify-content: end;
  column-gap: 3px;
  margin: 0;
  color: ${COLORS.Green8};
  font-weight: 600;
  cursor: pointer;
  text-align: right;
  width: 100%;

  svg path {
    fill: ${COLORS.Green8};
  }
`;
