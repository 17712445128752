import { ColumnsType } from 'antd/es/table';

import { TFunction, t } from 'i18next';

import { sharedOnCell } from './utils';

export const columns = (t: TFunction) => [
  {
    title: t('consumer_name').toUpperCase(),
    dataIndex: 'consumerName',
    sorter: (a: any, b: any) =>
      a.sorterConsumerName.localeCompare(b.sorterConsumerName),
    onCell: sharedOnCell,
  },
  {
    title: t('period').toUpperCase(),
    dataIndex: 'period',
    sorter: (report: any, nextReport: any) => {
      const getComparableDate = (period: string) => {
        const [day, month, year] = period.split(' ')[2].split('/');
        return new Date(+year, +month, +day).getTime();
      };

      const date = getComparableDate(report?.sorterPeriod);
      const nextDate = getComparableDate(nextReport?.sorterPeriod);

      return date - nextDate; // Compare dates directly
    },
    onCell: sharedOnCell,
  },
];
