import { getAppRole, generateEmptyArray } from 'utils';

import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import { Input, Table } from 'antd';
import {
  FilterValue,
  SorterResult,
  TableCurrentDataSource,
  TablePaginationConfig,
} from 'antd/lib/table/interface';

import { SearchOutlined } from '@ant-design/icons';
import ModalMessage from 'components/shared/ModalMessage';
import Loader from 'components/shared/Loader';
import { MarginTop68px } from 'components/shared/Margin';
import { SubHeader } from 'components/shared/Text';
import { USER_ROLES } from 'constants/global';
import { Member } from 'constants/types';
import { useGetMembersQuery } from 'services/membersManagement';
import { useDeleteUserMutation } from 'services/userManagement';
import { useDebounce } from 'utils/hooks';
import { SkeletonLine } from 'components/shared/Skeleton';
import { columns } from './columns';
import {
  StyledEmptyDataWrapper,
  StyledInputWrapper,
  TableWrapper,
} from './styles';

const MembersTable: React.FC = () => {
  const { t } = useTranslation();
  const [deleteUser, { isLoading: isLoadingDeleteUser }] =
    useDeleteUserMutation();
  const {
    data: members = [],
    isLoading: isLoadingMembers,
    refetch: refetchMembers,
  } = useGetMembersQuery();
  const role = getAppRole();

  const [showedData, setShowedData] = useState(() => members);
  const [filteredData, setFilteredData] = useState<Member[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>(
    {
      current: 1,
      pageSize: 10,
    },
  );
  const [memberToDelete, setMemberToDelete] = useState<{
    userId: string;
    userName: string;
  } | null>(null);

  const debouncedSearchValue = useDebounce(searchValue, 1000);

  const filteredMembers = useMemo(() => {
    let result = members;

    if (debouncedSearchValue) {
      result = result.filter((member) => {
        const fullName = member.middleName
          ? `${member.firstName} ${member.middleName} ${member.lastName}`
          : `${member.firstName} ${member.lastName}`;
        const address = member.address?.street;
        return Object.values(member).some(
          (memberValue) =>
            String(memberValue)
              ?.toLowerCase()
              .includes(debouncedSearchValue?.toLowerCase()) ||
            String(fullName)
              ?.toLowerCase()
              .includes(debouncedSearchValue?.toLowerCase()) ||
            String(address)
              ?.toLowerCase()
              .includes(debouncedSearchValue?.toLowerCase()),
        );
      });
    }

    return result;
  }, [debouncedSearchValue, members]);

  useEffect(() => {
    setShowedData((prevMembers) => {
      if (isLoadingMembers) return prevMembers;

      const sortedMembers = [...filteredMembers].sort((a, b) =>
        a.firstName?.toLowerCase() > b.firstName?.toLowerCase() ? 1 : -1,
      );

      return shallowEqual(sortedMembers, prevMembers)
        ? prevMembers
        : sortedMembers;
    });
  }, [isLoadingMembers, filteredMembers]);

  useEffect(() => {
    if (role === USER_ROLES.PMO) {
      setShowedData((prev) =>
        prev.filter((member) => member.role !== USER_ROLES.PMO),
      );
    }
  }, [isLoadingMembers, role]);

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Member> | SorterResult<Member>[],
    extra: TableCurrentDataSource<Member>,
  ) => {
    const currentData = extra.currentDataSource;
    setFilteredData(currentData);
  };

  const displayPagination = useMemo(() => {
    return (
      !isLoadingMembers &&
      showedData &&
      showedData.length > 10 &&
      (filteredData.length > 10 || filteredData.length === 0)
    );
  }, [filteredData, showedData, isLoadingMembers]);

  const handlePaginationChange = (page: number, pageSize: number) => {
    setTablePagination((prev) => ({ ...prev, current: page, pageSize }));
  };

  const isDisabledInput = members && members?.length > 0 ? false : true;

  const onRemoveConsumer = useCallback(async () => {
    await deleteUser({ userId: memberToDelete?.userId as string });
    setMemberToDelete(null);
    refetchMembers();
  }, [memberToDelete, refetchMembers]);

  const tableColumns = useMemo(() => {
    // show community column only for OYO ADMIN
    return role === USER_ROLES.OYO_ADMIN
      ? columns(t, setMemberToDelete).map((column) =>
          isLoadingMembers
            ? {
                ...column,
                render: () => (
                  <SkeletonLine active block width={90} height={22} />
                ),
              }
            : column,
        )
      : columns(t, setMemberToDelete).filter(
          ({ dataIndex }: any) => dataIndex !== 'communityName',
        );
  }, [columns, role, isLoadingMembers]);

  const loadingTableData = generateEmptyArray(10);

  return (
    <TableWrapper>
      {memberToDelete && (
        <ModalMessage
          messageType="warining"
          title={t('delete_consumer_modal_title', {
            userName: memberToDelete.userName,
          })}
          subTitle={t('delete_consumer_modal_text')}
          btnText={t('ok')}
          handleBtn={onRemoveConsumer}
          onCancelClick={() => setMemberToDelete(null)}
        />
      )}
      <StyledInputWrapper>
        <Input
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder={t('search').toString()}
          prefix={<SearchOutlined style={{ opacity: 0.3 }} />}
          disabled={isDisabledInput}
        />
      </StyledInputWrapper>
      {isLoadingDeleteUser && (
        <>
          <MarginTop68px />
          <Loader />
        </>
      )}
      {!showedData.length && !isLoadingMembers && (
        <StyledEmptyDataWrapper>
          <SubHeader>{t('no_members_yet').toUpperCase()}</SubHeader>
        </StyledEmptyDataWrapper>
      )}
      {(isLoadingMembers || (!isLoadingMembers && showedData.length > 0)) && (
        <Table
          columns={tableColumns}
          rowKey={(record) =>
            record._id +
            record.address?.city +
            record.firstName +
            record.lastName
          }
          dataSource={isLoadingMembers ? loadingTableData : showedData}
          onChange={handleTableChange}
          pagination={
            displayPagination
              ? {
                  ...tablePagination,
                  position: ['bottomCenter'],
                  showSizeChanger: true,
                  onChange: handlePaginationChange,
                  showTotal: (total) => `${t('total')} ${total} ${t('items')}`,
                }
              : false
          }
        />
      )}
    </TableWrapper>
  );
};

export default MembersTable;
