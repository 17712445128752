import { TFunction } from 'i18next';

import { COLORS } from 'constants/colors';

export const InfoItems = (t: TFunction) => [
  {
    color: COLORS.Green6,
    title: t('earned_from_community'),
    unit: '€',
  },
  {
    color: COLORS.geekBlue6,
    title: t('earned_tax_refund'),
    unit: '€',
  },
];

export const getColumnColorByType = (type: string) => {
  switch (type) {
    case 'earned_tax_refund':
      return COLORS.geekBlue6;
    case 'earned_from_community':
      return COLORS.Green6;
    default:
      return COLORS.BgContainerDisabled;
  }
};

export const costEarnedTypes = ['earned_from_community', 'earned_tax_refund'];

export const legendCostEarnedItems = (t: TFunction) => [
  {
    value: 'earned_from_community',
    name: t('earned_from_community'),
    marker: { symbol: 'square', style: { fill: COLORS.Green6 } },
  },
  {
    value: 'earned_tax_refund',
    name: t('tax_refund'),
    marker: { symbol: 'square', style: { fill: COLORS.geekBlue6 } },
  },
];
