import { initReactI18next } from 'react-i18next';

import i18n from 'i18next';

import { SUPPORTED_LANGUAGES } from 'constants/global';

import commonEn from './resources/en/common.json';
import commonFr from './resources/fr/common.json';
import faqEn from './resources/en/faq.json';
import faqFr from './resources/fr/faq.json';

const resources = {
  [SUPPORTED_LANGUAGES.English]: {
    translation: commonEn,
    faq: faqEn,
  },
  [SUPPORTED_LANGUAGES.Français]: {
    translation: commonFr,
    faq: faqFr,
  },
};

let defaultLang = SUPPORTED_LANGUAGES.Français;

i18n.use(initReactI18next).init({
  resources,
  lng: defaultLang,
  fallbackLng: SUPPORTED_LANGUAGES.English,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
