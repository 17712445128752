import { checkValidPhoneNumber, getNestedObjectValue } from 'utils';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { FormikProvider, useFormik } from 'formik';

import {
  StyledHeader,
  StyledTab,
  StyledTabsWrapper,
  StyledWrapper,
} from 'components/communities/CreateCommunity/styles';
import ActionInfoTab from 'components/shared/ActionInfoTab';
import Container from 'components/shared/Container';
import FullScreenLoader from 'components/shared/Loader/FullScreenLoader';
import Stepper from 'components/shared/Stepper/Stepper';
import { CREATE_PRODUCER_STEPS, CREATE_STEPS } from 'constants/global';
import AppRoutes from 'constants/routes';
import { useCreateProducerMutation } from 'services/membersManagement';

import { getProducerSchema } from 'utils/validation/schemas';

import LegalData from './LegalData';
import ProducerDetails from './ProducerDetails';
import Settings from './Settings';
import { initialValues } from './constants';

const CreateProducer: React.FC = () => {
  const [activeStep, setActiveStep] = useState(CREATE_STEPS.FIRST);
  const [hasToBeValidated, setHasToBeValidated] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [createProducer, { isError, isSuccess, isLoading }] =
    useCreateProducerMutation();

  const setupProducerSchema = getProducerSchema(t);
  const formik = useFormik({
    initialValues,
    validationSchema: setupProducerSchema,
    validateOnChange: hasToBeValidated,
    validateOnBlur: false,
    onSubmit: async (values: typeof initialValues) => {
      const transformedValues = JSON.parse(JSON.stringify(values));
      transformedValues.additionalInfo.siretNumber = parseInt(
        transformedValues.additionalInfo.siretNumber,
      );
      transformedValues.contactNumber = checkValidPhoneNumber(
        values.contactNumber!,
      );
      transformedValues.additionalInfo.legalPerson.contactNumber =
        checkValidPhoneNumber(
          transformedValues.additionalInfo.legalPerson.contactNumber,
        );
      transformedValues.additionalInfo.billingPerson.contactNumber =
        checkValidPhoneNumber(
          transformedValues.additionalInfo.billingPerson.contactNumber,
        );
      transformedValues.additionalInfo.vatNumber =
        'FR' + transformedValues.additionalInfo.vatNumber;
      formik.setSubmitting(true);

      await formik.validateForm();
      if (formik.isValid) {
        try {
          await createProducer(transformedValues).unwrap();
        } catch (err) {
          console.error(err); // TODO add error handling
        }
      }
    },
  });

  const validateFields = async (fields: string[]): Promise<boolean> => {
    const errors = await formik.validateForm();
    const isValid = fields.every(
      (field) => !getNestedObjectValue(errors, field),
    );
    if (isValid) formik.setErrors({});

    return isValid;
  };

  const areValidFields = (fields: string[]) => {
    return fields
      .map((field) => formik.getFieldMeta(field))
      .every((fieldMeta) => {
        return fieldMeta && !fieldMeta.error;
      });
  };

  const handleConfirm = async (isValid: boolean) => {
    await setHasToBeValidated(!isValid);

    if (isValid) {
      setActiveStep((prev) => prev + 1);
      setTimeout(() => formik.setErrors({}), 0);
    }
  };

  const handleBackButtonClick = () => {
    if (activeStep === CREATE_STEPS.FIRST) return navigate(AppRoutes.Members);
    setActiveStep((prev) => prev - 1);
    setHasToBeValidated(true);
  };

  const handleSuccessClick = () => {
    setActiveStep(CREATE_STEPS.FIRST);
    formik.resetForm();
  };

  return (
    <Container>
      <StyledWrapper>
        {isLoading && <FullScreenLoader />}
        {!isSuccess ? (
          <>
            <StyledHeader>{t('add_new_producer')}</StyledHeader>
            <Stepper steps={CREATE_PRODUCER_STEPS} activeStep={activeStep} />
            <FormikProvider value={formik}>
              <StyledTabsWrapper>
                <StyledTab active={activeStep === CREATE_STEPS.FIRST}>
                  <ProducerDetails
                    hasToBeValidated={hasToBeValidated}
                    onConfirmForm={handleConfirm}
                    onBackButtonClick={handleBackButtonClick}
                    validateFields={validateFields}
                    areValidFields={areValidFields}
                  />
                </StyledTab>
                <StyledTab active={activeStep === CREATE_STEPS.SECOND}>
                  <LegalData
                    onBackButtonClick={handleBackButtonClick}
                    onConfirmForm={handleConfirm}
                    validateFields={validateFields}
                    areValidFields={areValidFields}
                    hasToBeValidated={hasToBeValidated}
                  />
                </StyledTab>
                <StyledTab active={activeStep === CREATE_STEPS.THIRD}>
                  <Settings
                    isError={isError}
                    setHasToBeValidated={setHasToBeValidated}
                    onBackButtonClick={handleBackButtonClick}
                    validateFields={validateFields}
                    hasToBeValidated={hasToBeValidated}
                  />
                </StyledTab>
              </StyledTabsWrapper>
            </FormikProvider>
          </>
        ) : (
          <ActionInfoTab
            title="new_producer_added"
            text="new_producer_has_been_successfully_added_to_the_community"
            handleClick={handleSuccessClick}
            buttonText="back_to_members"
            redirectOnClick={AppRoutes.Members}
          />
        )}
      </StyledWrapper>
    </Container>
  );
};

export default CreateProducer;
