import { numberOrEmptyStr } from 'utils/forms';

import { ADDRESS_TYPE, Suggestion } from './types';

interface IPostcodePresentable {
  suggestion: Suggestion;
  types: ADDRESS_TYPE;
}
export const getPostcodePresentable = ({
  suggestion,
  types,
}: IPostcodePresentable) => {
  const postcodeContext = suggestion.context?.filter((context) =>
    context.id.startsWith(ADDRESS_TYPE.POSTCODE),
  );
  const postcodeValue = postcodeContext?.[0]?.text || '';

  switch (types) {
    case ADDRESS_TYPE.NO_TYPE:
    case ADDRESS_TYPE.POSTCODE: {
      return numberOrEmptyStr(suggestion?.text);
    }

    default: {
      return numberOrEmptyStr(postcodeValue);
    }
  }
};

export const getTextPresentable = ({
  suggestion,
  types,
}: IPostcodePresentable) => {
  if (!suggestion) {
    return '';
  }

  const placeContext = suggestion.context?.filter((context) =>
    context.id.startsWith(ADDRESS_TYPE.PLACE),
  );
  const city = placeContext?.[0]?.text || '';

  switch (types) {
    case ADDRESS_TYPE.NO_TYPE:
    case ADDRESS_TYPE.POSTCODE: {
      const [, city] = suggestion.place_name.split(',');
      return city?.trim() || '';
    }

    case ADDRESS_TYPE.ADDRESS: {
      return city || suggestion.text?.trim() || '';
    }

    default: {
      return suggestion.text?.trim() || '';
    }
  }
};

export const getListItemPresentable = ({
  suggestion,
  types,
}: IPostcodePresentable) => {
  switch (types) {
    case ADDRESS_TYPE.POSTCODE: {
      return suggestion?.text;
    }

    default: {
      return suggestion?.place_name;
    }
  }
};
