import { styled } from 'styled-components';
import { Button as AntdButton } from 'antd';
import { EditTwoTone } from '@ant-design/icons';

import { BtnDefault } from 'components/shared/Button';
import { COLORS } from 'constants/colors';

export const StyledBlockWrapper = styled.div`
  box-sizing: border-box;
  background-color: ${COLORS.BgContainer};
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.06);
`;

export const StyledBlockHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
`;

export const StyledBlockTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
`;

export const StyledContainer = styled.div`
  padding: 24px 0;
  margin: 0 auto;
`;

const BlockItemMaxWidth1 = '99%';
const BlockItemMaxWidth2 = '48%';
const BlockItemMaxWidth3 = '32%';
export const widthByCount = (count: number) => {
  switch (count) {
    case 1:
      return BlockItemMaxWidth1;
    case 2:
      return BlockItemMaxWidth2;
    case 3:
      return BlockItemMaxWidth3;
    default:
      return BlockItemMaxWidth3;
  }
};

export const StyledBlockBody = styled.div<{ count: number }>`
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(${({ count }) => widthByCount(count)}, 1fr)
  );
  column-gap: 24px;
  row-gap: 24px;
  padding: 16px 24px;
`;

export const StyledButtonRow = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 50px;
`;

export const StlyedProfileSupportText = styled.span`
  color: ${COLORS.TextBase};
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
`;

export const StyledSupportWrapper = styled.div`
  padding-left: 24px;
`;

export const StyledProfileSupportEmail = styled.a`
  padding-left: 4px;
  color: ${COLORS.Green6};
`;

export const Button = styled(BtnDefault)`
  height: 28px;
`;

export const EditIcon = styled(EditTwoTone).attrs(({ disabled }) => ({
  twoToneColor: disabled ? COLORS.Grey3 : COLORS.Green6,
}))``;

export const StyledResendInvitationLink = styled(AntdButton)`
  padding: 0 8px;
  height: auto;
  color: ${COLORS.Green7};
  font-weight: 600;
`;
