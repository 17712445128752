import { getAppRole } from 'utils';
import { v4 as uuidv4 } from 'uuid';

import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PlusOutlines from 'components/communities/Communities/img/PlusOutlined.svg';
import { BtnWrapper } from 'components/communities/Communities/styles';
import { BtnPrimary } from 'components/shared/Button';
import Image from 'components/shared/Image';
import { USER_ROLES } from 'constants/types';
import { IDBTaxPrices, ITaxPrices } from 'services/taxPrices.types';

import { BlockContainer, BlockHeader, Title, Wrapper } from '../../styles';
import { PriceType, PriceVariant } from '../../types';
import EmptyPrices from '../EmptyPrices';
import ModifyPrices from '../ModifyPrices';
import TurpeTable from './components/TurpeTable';

const TurpePrices = (data: ITaxPrices) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editTaxes, setEditTaxes] = useState<IDBTaxPrices | undefined>(
    undefined,
  );

  const { current, upcoming, historical } = data;
  const { t } = useTranslation();
  const userRole = getAppRole();

  const isProducer = useMemo(
    () => userRole === USER_ROLES.PRODUCER,
    [userRole],
  );

  const handleOnEdit = (taxPrice: IDBTaxPrices) => {
    setIsModalVisible(true);
    setEditTaxes(taxPrice);
  };

  const handleOnModalVisibleChange = (isModalVisible: boolean) => {
    setIsModalVisible(isModalVisible);
    setEditTaxes(undefined);
  };

  const renderCurrentPrices = () => {
    if (!current?.length) return;

    return current.map((taxPrice) => (
      <TurpeTable
        key={uuidv4()}
        priceVariant={PriceVariant.CURRENT}
        taxPrices={taxPrice}
      />
    ));
  };

  const renderUpcommingPrices = () => {
    if (!upcoming?.length) return;

    return upcoming.map((taxPrice) => (
      <TurpeTable
        key={uuidv4()}
        priceVariant={PriceVariant.UPCOMMING}
        taxPrices={taxPrice}
        onEdit={() => handleOnEdit(taxPrice)}
      />
    ));
  };

  const renderHistoricalPrices = () => {
    if (!historical?.length) return;

    return (
      <TurpeTable
        priceVariant={PriceVariant.HISTORICAL}
        taxPrices={historical[0]}
      />
    );
  };

  return (
    <BlockContainer>
      <Wrapper>
        <BlockHeader>
          <Title>{t('tax_price', { priceType: PriceType.TURPE })}</Title>
          {!isProducer && (
            <BtnWrapper>
              <BtnPrimary onClick={() => setIsModalVisible(true)}>
                <Image src={PlusOutlines} alt="PlusIcon" margin="0 5px 0 0" />
                {t('add_new_tax_prices', { priceType: PriceType.TURPE })}
              </BtnPrimary>
            </BtnWrapper>
          )}
        </BlockHeader>
        {renderCurrentPrices()}
        {renderUpcommingPrices()}
        {renderHistoricalPrices()}
        <ModifyPrices
          isModalVisible={isModalVisible}
          priceType={PriceType.TURPE}
          taxPrices={editTaxes}
          onModalVisibleChange={handleOnModalVisibleChange}
        />
        <EmptyPrices
          {...data}
          priceType={PriceType.TURPE}
          priceKey="turpePerMWh"
        />
      </Wrapper>
    </BlockContainer>
  );
};

export default TurpePrices;
