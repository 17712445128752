import { useTranslation } from 'react-i18next';
import type { FC } from 'react';
import { WidgetTitle } from 'components/communities/Charts/ConsumerSummaryView/styles';
import { formatNumberWithSpace } from 'components/communities/Charts/ElectricityConsumption/utils';
import CostSavingSvg from 'components/communities/Charts/img/CostSaving.svg';
import { popoverOverlayStyles } from 'components/communities/Communities/styles';
import { WidgetWrapper } from 'components/communities/styles';
import Popover from 'components/shared/Popover';
import { SkeletonLine } from 'components/shared/Skeleton';

import {
  WidgetAmount,
  WidgetAmountType,
  WidgetImage,
  WidgetRow,
} from '../../styles';

interface WidgetEarningsProps {
  amount?: number | string;
  isLoading?: boolean;
}

const WidgetEarnings: FC<WidgetEarningsProps> = ({ amount, isLoading }) => {
  const { t } = useTranslation();
  return (
    <WidgetWrapper>
      <WidgetTitle>
        {t('earnings')}
        <Popover
          context={t('the_sum_of_consumer_and_producers_invoices')}
          overlayStyles={popoverOverlayStyles}
        />
      </WidgetTitle>
      <WidgetRow>
        <WidgetImage src={CostSavingSvg} />
        {isLoading && <SkeletonLine active height={30} width={185} />}
        {!isLoading && (
          <WidgetAmount>
            {amount ? formatNumberWithSpace(amount) : 'N/A'}
            <WidgetAmountType>€</WidgetAmountType>
          </WidgetAmount>
        )}
      </WidgetRow>
    </WidgetWrapper>
  );
};

export default WidgetEarnings;
