import { TextBold, TextRegular } from 'components/shared/Text';

import PriceRowLine from '../PriceRowLine';
import { CellWrapper, Header, Row } from './styles';

type IPriceRow = {
  title: string;
  description: string;
  path: 'private' | 'medium' | 'large';
};

const PriceRow = ({ title, description, path }: IPriceRow) => (
  <Row>
    <Header>
      <CellWrapper>
        <TextBold>{title}</TextBold>
        <TextRegular>{description}</TextRegular>
      </CellWrapper>
    </Header>
    <PriceRowLine property="ht" path={path} />
    <PriceRowLine property="vat" path={path} />
    <PriceRowLine property="ttc" path={path} />
  </Row>
);

export default PriceRow;
