import { ReactNode } from 'react';

import CloseIcon from './img/CloseOutlined.png';
import { CloseBtn, Container, Content, Header, Modal, Title } from './styles';

interface IModalWindow {
  isVisible: boolean;
  title: string;
  children: ReactNode;
  onClose: () => void;
}

const ModalComponent = ({
  isVisible,
  title,
  children,
  onClose,
}: IModalWindow) => {
  if (!isVisible) return null;

  return (
    <Container>
      <Modal>
        <Content>
          <Header>
            <Title>{title}</Title>
            <CloseBtn src={CloseIcon} alt="CloseIcon" onClick={onClose} />
          </Header>
          {children}
        </Content>
      </Modal>
    </Container>
  );
};

export default ModalComponent;
