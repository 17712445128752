import { PriceVariant } from 'components/pricing/components/TaxPrices/types';
import { IDBTaxPrices } from 'services/taxPrices.types';

import EditPriceBtn from '../../../EditPriceBtn';
import { AlignCell, Cell, Row, TextBold, TextRegular } from '../styles';
import { getFormattedUserName } from 'utils';

type ITableRow = {
  hasEdit?: boolean;
  priceVariant: PriceVariant;
  price: IDBTaxPrices;
  onEdit?: () => void;
};

const TableRow = ({ hasEdit, priceVariant, price, onEdit }: ITableRow) => {
  if (!price.discountedAccisePerMWh) return null;
  const consumerName = getFormattedUserName(price?.user);

  return (
    <Row hasEdit={hasEdit} type={priceVariant}>
      <AlignCell>
        <TextBold>{price.discountedAccisePerMWh?.communityName}</TextBold>
      </AlignCell>
      <AlignCell>
        <TextBold>{consumerName}</TextBold>
      </AlignCell>
      <Cell>
        <TextRegular>{price.discountedAccisePerMWh?.condition}</TextRegular>
      </Cell>
      <Cell>
        <TextRegular>{price.discountedAccisePerMWh?.ht}</TextRegular>
      </Cell>
      <Cell>
        <TextRegular>{price.discountedAccisePerMWh?.vat}</TextRegular>
      </Cell>
      <Cell>
        <TextRegular>{price.discountedAccisePerMWh?.ttc}</TextRegular>
      </Cell>
      {hasEdit && (
        <Cell>
          <EditPriceBtn priceVariant={priceVariant} onClick={onEdit} />
        </Cell>
      )}
    </Row>
  );
};

export default TableRow;
