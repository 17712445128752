import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SkeletonLine } from 'components/shared/Skeleton';
import { ThunderboltIcon } from 'components/communities/Charts/SummaryView/components/Title/styles';
import { TextRegular } from 'components/shared/Text';
import CustomTooltip from 'components/shared/Tooltip';

import {
  EditIcon,
  StyledEmptyGridTitle,
  StyledGridBlock,
  StyledGridHeader,
  StyledGridSubTitle,
  StyledGridTitle,
  StyledPriceCol,
  StyledPriceContainer,
  StyledPriceTypeText,
  StyledPriceUnitText,
  StyledPriceValue,
  StyledPricesContainer,
  StyledPrmEditButton,
  StyledPriceItemCol,
  StyledEmptyContainer,
  SkeletonInputWrapper,
} from './styles';
import ModifyGridPrices from '../ModifyGridPrices';
import { PrmData } from '../PrmBody/types';
import { useGetGridPricesByPrmIdQuery } from 'services/gridPrices.service';

interface ElectricityGridPricesProps {
  prmData?: PrmData;
}

const ElectricityGridPrices: FC<ElectricityGridPricesProps> = ({ prmData }) => {
  const { t } = useTranslation();
  const {
    data: gridPrices,
    isLoading: isLoadingGridPrices,
    refetch,
  } = useGetGridPricesByPrmIdQuery({ id: prmData?.id }, { skip: !prmData?.id });

  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleOnReFetch = async () => {
    await refetch();
  };

  const gridPricesByHours = [
    gridPrices?.HS_HH,
    gridPrices?.HS_LH,
    gridPrices?.LS_HH,
    gridPrices?.LS_LH,
    gridPrices?.RH,
  ];

  const hasData = !!gridPrices;
  const isStaticType =
    gridPrices &&
    gridPricesByHours.every((gridPrice) => gridPrice === gridPricesByHours[0]);

  const renderGridPrices = () => {
    if (!hasData) return null;

    return [
      <StyledEmptyContainer />,
      <StyledPriceItemCol>
        <StyledPriceContainer>
          <div>
            <StyledPriceTypeText>{t('hshp')}</StyledPriceTypeText>
            <span>,</span>
            <StyledPriceUnitText> {t('eur_kwh')}</StyledPriceUnitText>
          </div>
          <StyledPriceValue>
            {isLoadingGridPrices ? (
              <SkeletonInputWrapper>
                <SkeletonLine block active />
              </SkeletonInputWrapper>
            ) : (
              gridPrices?.HS_HH
            )}
          </StyledPriceValue>
        </StyledPriceContainer>
      </StyledPriceItemCol>,
      <StyledPriceItemCol>
        <StyledPriceContainer>
          <div>
            <StyledPriceTypeText>{t('hshc')}</StyledPriceTypeText>
            <span>,</span>
            <StyledPriceUnitText> {t('eur_kwh')}</StyledPriceUnitText>
          </div>
          <StyledPriceValue>
            {isLoadingGridPrices ? (
              <SkeletonInputWrapper>
                <SkeletonLine block active />
              </SkeletonInputWrapper>
            ) : (
              gridPrices?.HS_LH
            )}
          </StyledPriceValue>
        </StyledPriceContainer>
      </StyledPriceItemCol>,
      <StyledPriceItemCol>
        <StyledPriceContainer>
          <div>
            <StyledPriceTypeText>{t('bshp')}</StyledPriceTypeText>
            <span>,</span>
            <StyledPriceUnitText> {t('eur_kwh')}</StyledPriceUnitText>
          </div>
          <StyledPriceValue>
            {isLoadingGridPrices ? (
              <SkeletonInputWrapper>
                <SkeletonLine block active />
              </SkeletonInputWrapper>
            ) : (
              gridPrices?.LS_HH
            )}
          </StyledPriceValue>
        </StyledPriceContainer>
      </StyledPriceItemCol>,
      <StyledPriceItemCol>
        <StyledPriceContainer>
          <div>
            <StyledPriceTypeText>{t('bshc')}</StyledPriceTypeText>
            <span>,</span>
            <StyledPriceUnitText> {t('eur_kwh')}</StyledPriceUnitText>
          </div>
          <StyledPriceValue>
            {isLoadingGridPrices ? (
              <SkeletonInputWrapper>
                <SkeletonLine block active />
              </SkeletonInputWrapper>
            ) : (
              gridPrices?.LS_LH
            )}
          </StyledPriceValue>
        </StyledPriceContainer>
      </StyledPriceItemCol>,
      <StyledPriceItemCol>
        <StyledPriceContainer>
          <div>
            <StyledPriceTypeText>{t('hdp')}</StyledPriceTypeText>
            <span>,</span>
            <StyledPriceUnitText> {t('eur_kwh')}</StyledPriceUnitText>
          </div>
          <StyledPriceValue>
            {isLoadingGridPrices ? (
              <SkeletonInputWrapper>
                <SkeletonLine block active />
              </SkeletonInputWrapper>
            ) : (
              gridPrices?.RH
            )}
          </StyledPriceValue>
        </StyledPriceContainer>
      </StyledPriceItemCol>,
    ];
  };

  return (
    <StyledGridBlock>
      <StyledGridHeader>
        <StyledGridTitle>
          {t('electricity_price_of_the_grid')}
          <CustomTooltip
            tooltip={
              <TextRegular>{t('by_providing_this_information')}</TextRegular>
            }
          />
        </StyledGridTitle>
        <StyledGridSubTitle>
          {isLoadingGridPrices && (
            <SkeletonLine active width={100} height={30} />
          )}
          {!isLoadingGridPrices && hasData ? <ThunderboltIcon /> : null}
          {!isLoadingGridPrices &&
            hasData &&
            !isStaticType &&
            t('dynamic_price')}
          {!isLoadingGridPrices && hasData && isStaticType && t('static_price')}
        </StyledGridSubTitle>
      </StyledGridHeader>
      <StyledPricesContainer hasData={hasData}>
        {renderGridPrices()}
        {!hasData && (
          <StyledEmptyGridTitle>{t('add_grid_prices')}</StyledEmptyGridTitle>
        )}
        <StyledPriceCol>
          {!isLoadingGridPrices && (
            <StyledPrmEditButton onClick={() => setIsModalVisible(true)}>
              <EditIcon /> {t('update')}
            </StyledPrmEditButton>
          )}
        </StyledPriceCol>
      </StyledPricesContainer>
      <ModifyGridPrices
        isModalOpen={isModalVisible}
        closeModal={() => setIsModalVisible(false)}
        gridPrices={gridPrices}
        prmData={prmData}
        onRefetchGridPrices={handleOnReFetch}
      />
    </StyledGridBlock>
  );
};

export default ElectricityGridPrices;
