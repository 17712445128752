import { FillPaint } from 'mapbox-gl';

import { COLORS } from 'constants/colors';

export const perimeterLayerStyles: FillPaint = {
  'fill-color': [
    'let',
    'density',
    ['/', ['get', 'population'], ['get', 'sq-km']],
    [
      'interpolate',
      ['linear'],
      ['zoom'],
      8,
      [
        'interpolate',
        ['linear'],
        ['var', 'density'],
        274,
        ['to-color', COLORS.Green4],
        1551,
        ['to-color', COLORS.Green4],
      ],
      10,
      [
        'interpolate',
        ['linear'],
        ['var', 'density'],
        274,
        ['to-color', COLORS.Green4],
        1551,
        ['to-color', COLORS.Green4],
      ],
    ],
  ],
  'fill-opacity': 0.25,
};
