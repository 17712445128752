import { styled } from 'styled-components';

import { Button } from 'antd';

import { COLORS } from 'constants/colors';

export const StyledHeaderBlock = styled.div<{ isAdmin: boolean }>`
  background: ${COLORS.BgLayout};
  padding: ${({ isAdmin }) => (isAdmin ? '0' : '0 0 16px')};
  width: 100%;
`;

export const StyledHeaderButton = styled(Button)`
  width: 200px;
  font-weight: 600;
`;

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledLoaderWrapper = styled.div`
  margin-top: 10%;
`;
