import { styled } from 'styled-components';

export const CostEarnedBodyRow = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  padding: 16px 24px;
  justify-content: space-between;
  width: 100%;
  position: relative;
  gap: 16px;
`;

export const StyledEmptyInfoText = styled.div`
  position: absolute;
  width: 298px;
  left: 95px;
  top: 90px;
  z-index: 100;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
`;
