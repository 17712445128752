import React from 'react';

export const SkeletonPieChart = () => {
  return (
    <svg
      width="288"
      height="290"
      viewBox="0 0 288 290"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="288" height="290" rx="2" fill="#F5F5F5" />
      <circle cx="144" cy="145" r="117" fill="white" />
      <circle
        className="pie-chart-circle"
        cx="144"
        cy="145"
        r="113"
        fill="url(#paint0_linear_14263_313312)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_14263_313312"
          x1="31"
          y1="157.955"
          x2="257"
          y2="157.955"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E7E7E7" />
          <stop offset="1" stop-color="#D7D7D7" />
          <stop offset="1.0001" stop-color="#666666" />
        </linearGradient>
      </defs>
    </svg>
  );
};
