import { RingProgress } from '@ant-design/plots';
import { Skeleton } from 'antd';
import { COLORS } from 'constants/colors';

import { StyledRepartitionRing } from '../../styles';

const RingProggresChart = ({
  percent,
  isLoading,
}: {
  percent: number;
  isLoading?: boolean;
}) => {
  const config = {
    height: 54,
    width: 55,
    autoFit: false,
    percent: percent,
    color: [COLORS.Green5, '#D4D5D6'],
    innerRadius: 0.55,
    radius: 0.98,
    statistic: undefined,
    lineWidth: 10,
    progressStyle: {
      style: {
        lineWidth: 10,
      },
    },
  };
  return (
    <StyledRepartitionRing isLoading={isLoading}>
      <RingProgress
        {...config}
        loading={isLoading}
        loadingTemplate={<Skeleton.Avatar size={52} active />}
      />
    </StyledRepartitionRing>
  );
};

export default RingProggresChart;
