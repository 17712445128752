import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useFormikContext } from 'formik';

import { StyledForm } from 'components/auth/SignIn/styles';
import { StyledCheckboxesWrapper } from 'components/communities/CreateCommunity/add-pmo/styles';
import { BtnPrimary, BtnSecondary } from 'components/shared/Button';
import Checkbox from 'components/shared/Checkbox';
import { InputGroup } from 'components/shared/InputGroup';
import { MarginTop24px } from 'components/shared/Margin';
import { COLORS } from 'constants/colors';

import { useAppSelector } from 'utils/hooks';

import { ConsumerType } from '../ConsumerType/constants';
import { initialValues } from '../constants';
import BillingAddressForm from './BillingAddress';
import BillingInfo from './BillingInfo';
import PersonData from './PersonData';

interface ConsumerDetailsProps {
  validateFields: (fields: string[]) => Promise<boolean>;
  onBackButtonClick: () => void;
  isError: boolean;
  hasToBeValidated: boolean;
  setHasToBeValidated: (validate: boolean) => void;
}

const billingInfoValues = {
  email: 'additionalInfo.billingPerson.email',
  firstName: 'additionalInfo.billingPerson.firstName',
  lastName: 'additionalInfo.billingPerson.lastName',
  middleName: 'additionalInfo.billingPerson.middleName',
  phoneNumber: 'additionalInfo.billingPerson.contactNumber',
};

const currentFields = [
  'additionalInfo.billingPerson.firstName',
  'additionalInfo.billingPerson.lastName',
  'additionalInfo.billingPerson.contactNumber',

  'additionalInfo.legalPerson.email',
  'additionalInfo.legalPerson.contactNumber',

  'additionalInfo.agreementSigned',
  'additionalInfo.cppaSigned',
  'additionalInfo.enedisConsentSigned',
];

const ContactInfo: React.FC<ConsumerDetailsProps> = ({
  isError,
  hasToBeValidated,
  validateFields,
  onBackButtonClick,
  setHasToBeValidated,
}) => {
  const { t } = useTranslation();

  const { isValid, values, handleSubmit, setFieldError, setFieldValue } =
    useFormikContext<typeof initialValues>();

  const { consumerType, isSameAddress } = useAppSelector(
    (state) => state.consumer,
  );

  useEffect(() => {
    async function setFieldsAndValidate() {
      if (isSameAddress) {
        await setFieldValue(
          'additionalInfo.billingAddress.street',
          values.address.street,
        );
        await setFieldValue(
          'additionalInfo.billingAddress.postalCode',
          values.address.postalCode,
        );
        await setFieldValue(
          'additionalInfo.billingAddress.building',
          values.address.building,
        );
        await setFieldValue(
          'additionalInfo.billingAddress.city',
          values.address.city,
        );

        if (hasToBeValidated) {
          await validateFields(currentFields);
        }
      }
    }

    setFieldsAndValidate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSameAddress]);

  const handleButtonClick = async () => {
    setHasToBeValidated(true);
    await validateFields(currentFields);

    if (
      consumerType !== ConsumerType.privateHouse &&
      (!values.additionalInfo.legalPerson.position ||
        !values.additionalInfo.legalPerson.firstName ||
        !values.additionalInfo.legalPerson.lastName)
    ) {
      if (!values.additionalInfo.legalPerson.position) {
        setFieldError(
          'additionalInfo.legalPerson.position',
          t('validation.legal_person_position_is_required').toString(),
        );
      }
      if (!values.additionalInfo.legalPerson.firstName) {
        setFieldError(
          'additionalInfo.legalPerson.firstName',
          t('validation.legal_person_name_is_required').toString(),
        );
      }
      if (!values.additionalInfo.legalPerson.lastName) {
        setFieldError(
          'additionalInfo.legalPerson.lastName',
          t('validation.legal_person_name_is_required').toString(),
        );
      }
    } else {
      handleSubmit();
    }
  };

  return (
    <StyledForm>
      <PersonData />

      <BillingInfo values={billingInfoValues} />

      <BillingAddressForm />

      <StyledCheckboxesWrapper>
        <Checkbox
          name="additionalInfo.cppaSigned"
          isError={hasToBeValidated && !values.additionalInfo.cppaSigned}
          label={t('cppa_is_signed')}
          errorText={t('validation.cppa_agreement_sign_required').toString()}
        />
        <Checkbox
          name="additionalInfo.agreementSigned"
          isError={hasToBeValidated && !values.additionalInfo.agreementSigned}
          label={t('pmo_membership_agreement_is_signed')}
          errorText={t(
            'validation.pmo_membership_agreement_sign_required',
          ).toString()}
        />
        <Checkbox
          name="additionalInfo.enedisConsentSigned"
          isError={
            hasToBeValidated && !values.additionalInfo.enedisConsentSigned
          }
          label={t('enedis_consent_form_is_signed')}
          errorText={t(
            'validation.please_accept_enedis_consent_form_is_signed',
          ).toString()}
        />
      </StyledCheckboxesWrapper>

      <InputGroup
        errorCentered={true}
        error={isError ? t('something_went_wrong').toString() : ''}
      >
        <>
          <BtnPrimary
            onClick={handleButtonClick}
            isDisabled={hasToBeValidated && !isValid}
          >
            {t('add_new_consumer')}
          </BtnPrimary>
          <MarginTop24px />
          <BtnSecondary color={COLORS.TextBase} onClick={onBackButtonClick}>
            {t('back')}
          </BtnSecondary>
        </>
      </InputGroup>
    </StyledForm>
  );
};

export default ContactInfo;
