import { styled } from 'styled-components';

import { Modal } from 'antd';

export const LoaderContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const StyledLoader = styled.div<{ padding?: boolean }>`
  max-width: 150px;
  max-height: 150px;
  padding: ${(props) => (props.padding ? '0px' : '20px')};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const CustomModal = styled(Modal)`
  .ant-modal-content {
    background: transparent;
    box-shadow: none;
  }

  .ant-modal-body {
    padding-top: 100px;
    display: flex;
    justify-content: center;
  }
`;
