import { styled } from 'styled-components';

import { Button, Space } from 'antd';

import Image from 'components/shared/Image';
import { Header } from 'components/shared/Text';
import { COLORS } from 'constants/colors';
import { MAX_CONTENT_WIDTH } from 'constants/screen-breakpoints';

export const StyledHeaderWrapper = styled(Space)`
  display: flex;
  align-items: end;
  padding: 0;
  max-width: ${MAX_CONTENT_WIDTH}px;
  justify-content: space-between;
`;
export const StyledHeader = styled(Header)`
  margin: 0;
`;

export const StyledContainer = styled.div<{ offset: number }>`
  padding-top: ${({ offset }) => `${offset}px`};
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: ${MAX_CONTENT_WIDTH}px;
  width: 100%;
`;

export const StyledMemberContainer = styled(Space)`
  padding: 0 32px;
  width: 100%;
`;

export const StyledDropdownButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  color: ${COLORS.TextBase};
  background-color: ${COLORS.SuccessBase};
  transition: none;
  padding: 0px 16px;
  height: 40px;
  column-gap: 10px;

  &:hover {
    color: ${COLORS.TextBase} !important;
    background-color: ${COLORS.Green7} !important;
  }

  &:disabled {
    background-color: ${COLORS.Grey2};
    color: ${COLORS.Grey3};
    border: 1px solid ${COLORS.Grey3};
  }

  &:hover:disabled {
    background-color: ${COLORS.Grey2} !important;
    color: ${COLORS.Grey3} !important;
  }
`;

export const StyledDropdownArrow = styled(Image)<{
  isVisible?: boolean;
}>`
  transform: rotate(${(props) => (props.isVisible ? '0deg' : '180deg')});
  transition: transform 0.3s ease;
`;

export const StyledPrimaryBtn = styled.div`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 24px;
`;
