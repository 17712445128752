import styled from 'styled-components';

import {
  ApiTwoTone,
  EnvironmentTwoTone,
  ThunderboltTwoTone,
  UserOutlined,
} from '@ant-design/icons';

import { TextBold } from 'components/shared/Text';
import { COLORS } from 'constants/colors';

export const SummaryTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
`;

export const SummaryItemValue = styled(TextBold)`
  margin-right: 8px;
`;

export const SummaryItemDescription = styled(TextBold)<{ prefix?: any }>`
  color: ${COLORS.TextSecondary};
  margin-right: ${(props) => (props.prefix ? '4px' : '0px')};
`;

export const LocationIcon = styled(EnvironmentTwoTone).attrs(() => ({
  twoToneColor: COLORS.Green6,
}))`
  font-size: 14px;
  margin-right: 6px;
`;

export const UserIcon = styled(UserOutlined)`
  color: ${COLORS.Green6};
  width: 14px;
  height: 14px;
  margin-right: 6px;
`;

export const ConnectionIcon = styled(ApiTwoTone).attrs(() => ({
  twoToneColor: COLORS.Green6,
}))`
  font-size: 12px;
  margin-right: 6px;
`;

export const ThunderboltIcon = styled(ThunderboltTwoTone).attrs(() => ({
  twoToneColor: COLORS.Green6,
}))`
  font-size: 12px;
  margin-right: 6px;
`;

export const LineDivider = styled(TextBold)`
  color: ${COLORS.TextSecondary};
  margin: 0 8px;
`;
