import styled from 'styled-components';

interface IImage {
  margin?: string;
  padding?: string;
  clickable?: boolean;
}

const Image = styled.img<IImage>`
  margin: ${({ margin }) => (margin ? margin : '')};
  padding: ${({ padding }) => (padding ? padding : '')};
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'initial')};
`;

export default Image;
